import React from "react";
import $ from "jquery";
import { alertWarning } from "../utilidades/Alertas";
import { MODAL_AGREGAR_ESTATUS } from "./ConstantsSeguimientoEntregas";

export class FormaAddEstatusEnvio extends React.Component {
  state = {
    iEstatus: "",
    iFecha: "",
    iDetalles: "",
    data: "",
    porteo: null,
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_AGREGAR_ESTATUS}`).toggleClass("is-active");
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    let { iEstatus, iFecha, iDetalles } = this.state;
    if (iEstatus && iFecha && iDetalles) {
      this.props.add({
        Id_paquete: this.props.idPaquete,
        iEstatus,
        iFecha,
        iDetalles,
      });
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll("select");
    elements.forEach((mySelect) => (mySelect.value = ""));

    let inputs = document.querySelectorAll("input");
    inputs.forEach((input) => (input.value = ""));

    let textarea = document.querySelector("textarea");
    textarea.value = "";
    this.setState({
      iEstatus: "",
      iFecha: "",
      iDetalles: "",
      data: "",
    });
  };

  componentDidMount() {
    //this.getPorteo(sessionStorage.getItem('porteo'));
  }

  render() {
    return (
      <div id={MODAL_AGREGAR_ESTATUS} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Agregar estatus</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="label">Numero de seguimiento </label>{" "}
                    <span>{this.props.numeroSeguimiento}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iEstatus">
                      Estatus
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEstatus"
                          name="iEstatus"
                          value={this.state.iEstatus}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un estatus
                          </option>
                          <option value="animadora">Animadora</option>
                          <option value="entregado">Entregado</option>
                          <option value="devolución">Devolución</option>
                          <option value="salió a ruta">Salió a ruta</option>
                          <option value="visitado">Visitado</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="column is-6">
                    <label className="label" htmlFor="iFecha">
                      Fecha
                    </label>
                    <input
                      id="iFecha"
                      name="iFecha"
                      value={this.state.iFecha}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-12">
                    <label className="label is-success" htmlFor="iDetalles">
                      Detalles
                    </label>
                    <textarea
                      id="iDetalles"
                      name="iDetalles"
                      onChange={this.handleInputChange}
                      className="textarea"
                      placeholder="..."
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
