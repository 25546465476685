import React from 'react'

// Components
import { Valores } from '../../components/homepage/Valores'
import { Preguntas } from '../../components/homepage/Preguntas'
import { MisionVision } from '../../components/homepage/MisionVision'
import { QuienesSomos } from '../../components/homepage/QuienesSomos'

// image
import EmpleadosImg from '../../images/Scroll/LOGISTIC.jpeg'

export default class Conocenos extends React.Component {
    render() {
        return (
            <div>
                <div className=''>
                    <figure className="image is-16by9">
                        <img src={EmpleadosImg} alt='Los mejores empleados' />
                    </figure>
                </div>
                <QuienesSomos />
                <MisionVision />
                <Valores />
                <Preguntas />
            </div>
        )
    }
}