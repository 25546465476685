import React from "react";
import $ from "jquery";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DetallesTransportes } from "../reportes/DetallesTransportes";
import { MODAL_DETALLES_TRANSPORTE } from "./ConstantsTransportes";

export class ModalDetallesTransporte extends React.Component {
  handleClick(e) {
    $(`#${MODAL_DETALLES_TRANSPORTE}`).toggleClass("is-active");
  }

  render() {
    return (
      <div id={MODAL_DETALLES_TRANSPORTE} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Detalles de transporte</p>
            <button
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            ></button>
          </header>

          {this.props.selectedItem ? (
            <section className="modal-card-body">
              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-3">
                    {
                      <PDFDownloadLink
                        className="button is-rounded is-danger"
                        document={
                          <DetallesTransportes data={this.props.selectedItem} />
                        }
                        fileName="DetallesTransporte.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Generando..." : "Descargar"
                        }
                      </PDFDownloadLink>
                    }
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos particulares
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label">Placa</label>
                    <label>{this.props.selectedItem["placa"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">No. Serie</label>
                    <label>{this.props.selectedItem["no_serie"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">No. Económico</label>
                    <label>{this.props.selectedItem["no_economico"]}</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label">Tipo de unidad</label>
                    <label>{this.props.selectedItem["tipo_unidad"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">Modelo</label>
                    <label>{this.props.selectedItem["modelo"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">Color</label>
                    <label>{this.props.selectedItem["color"]}</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label">Marca</label>
                    <label>{this.props.selectedItem["marca"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">Submarca</label>
                    <label>{this.props.selectedItem["submarca"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">Camper</label>
                    <label>
                      {this.props.selectedItem["camper"] === 1 ? "Si" : "No"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label">Estado de registro</label>
                    <label>{this.props.selectedItem["estado_registro"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">Refrendo</label>
                    <a
                      href={this.props.selectedItem["refrendo"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.selectedItem["refrendo"]}
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label">Kilometraje</label>
                    <label>{this.props.selectedItem["kilometraje"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">Nivel de gasolina (ltrs)</label>
                    <label>{this.props.selectedItem["nivel_gasolina"]}</label>
                  </div>
                  <div className="column is-4">
                    <label className="label">Capacidad de tanque (ltrs)</label>
                    <label>
                      {this.props.selectedItem["capacidad_gasolina"]}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label">
                      Rendimiento técnico (km/ltr)
                    </label>
                    <label>
                      {this.props.selectedItem["rendimiento_tecnico"]}
                    </label>
                  </div>
                  <div className="column is-4">
                    <label className="label">
                      Periodo verificación vehicular
                    </label>
                    <label>
                      {this.props.selectedItem["verificacion_vehicular"]}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos de la poliza
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="label">Empresa de seguro</label>
                    <label>{this.props.selectedItem["empresa_seguro"]}</label>
                  </div>
                  <div className="column">
                    <label className="label">Poliza de seguro</label>
                    <label>{this.props.selectedItem["poliza_seguro"]}</label>
                  </div>
                  <div className="column">
                    <label className="label">Fecha de vencimiento</label>
                    <label>
                      {
                        this.props.selectedItem["poliza_vencimiento"].split(
                          "T"
                        )[0]
                      }
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Estatus financiero
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  {this.props.selectedItem["status_financiero"] ? (
                    <div className="column">
                      <label className="label">Status financiero</label>
                      <label>
                        {this.props.selectedItem["status_financiero"]}
                      </label>
                    </div>
                  ) : null}
                  {this.props.selectedItem["status_financiero"] ===
                  "Credito" ? (
                    <div className="column">
                      <label className="label">Fecha de vencimiento</label>
                      <label>
                        {
                          this.props.selectedItem[
                            "status_financiero_vencimiento"
                          ].split("T")[0]
                        }
                      </label>
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
          ) : null}
        </div>
      </div>
    );
  }
}
