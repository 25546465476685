import React from 'react'

// carousel 
import 'bulma-carousel/dist/css/bulma-carousel.min.css'
import bulmaCarousel from 'bulma-carousel/dist/js/bulma-carousel'

import SloganImg from '../../images/Scroll/SLOGAN.jpg'
import PersonasImg from '../../images/Scroll/LOGISTIC.jpeg'
import VehiculosImg from '../../images/Scroll/VEHICULOS.jpg'
import PaqueteImg from '../../images/Scroll/PAQUETE.jpg'

export class Carousel extends React.Component {
    componentDidMount() {
        bulmaCarousel.attach('#carousel-imgs', {
            slidesToScroll: 1,
            slidesToShow: 1,
            autoplay: true,
            infinite: true,
            pagination: '',
            duration: 1000,
            autoplaySpeed: 6000,
            pauseOnHover: true
        });
    }
    render() {
        return (
            <div className=''>
                <section className="hero is-large has-carousel">
                    <div id="carousel-imgs" className="hero-carousel">
                        <div className="item-1">
                            <figure className="image is-16by9 is-covered">
                                <img style={{ opacity: .5}} loading='lazy' className="is-background" src={SloganImg} alt="Para nosotros las barreras no existen" />
                            </figure>
                        </div>
                        <div className="item-2">
                            <figure className="image is-16by9 is-covered">
                                <img style={{ opacity: .5}} loading='lazy' className="is-background" src={PersonasImg} alt="Los mejores empleados" />
                            </figure>
                        </div>
                        <div className="item-3">
                            <figure className="image is-16by9 is-covered">
                                <img style={{ opacity: .5}} loading='lazy' className="is-background" src={VehiculosImg} alt="Vehiculos confiables" />
                            </figure>
                        </div>
                        <div className="item-4">
                            <figure className="image is-16by9 is-covered">
                                <img style={{ opacity: .5}} loading='lazy' className="is-background" src={PaqueteImg} alt="Paquetes seguros" />
                            </figure>
                        </div>
                    </div>
                    <div className="hero-body">
                        
                    </div>
                </section>
                <br />
            </div>
        )
    }
}