import React from 'react'

export default class FormInformacion extends React.Component {
    state = {
        nombre: '',
        empresa: '',
        telefono: '',
        email: '',
        ciudad: '',
        asunto: ''
    }
    inputNombre = (e) => {
        this.setState({ nombre: e.target.value })
    }
    inputEmpresa = (e) => {
        this.setState({ empresa: e.target.value })
    }
    inputTelefono = (e) => {
        this.setState({ telefono: e.target.value })
    }
    inputEmail = (e) => {
        this.setState({ email: e.target.value })
    }
    inputCiudad = (e) => {
        this.setState({ ciudad: e.target.value })
    }
    inputAsunto = (e) => {
        this.setState({ asunto: e.target.value })
    }
    handleSubmit = (e) => {
        e.preventDefault()
    }
    render() {
        return (
            <form>
                <label className='label'>Nombre completo*</label>
                <input onChange={this.inputNombre} placeholder='Roberto Llamas Torres' type='text' className='input' required />
                <label className='label'>Empresa*</label>
                <input onChange={this.inputEmpresa} placeholder='Empresa, Inc.' type='text' className='input' required />
                <label className='label'>Teléfono*</label>
                <input onChange={this.inputTelefono} placeholder='3411568974' type='tel' className='input' required />
                <label className='label'>Email*</label>
                <input onChange={this.inputEmail} placeholder='correo@gmail.com' type='text' className='input' required />
                <label className='label'>Ciudad/Estado*</label>
                <input onChange={this.Ciudad} placeholder='CD Guzmán' type='text' className='input' required />
                <label className='label'>Asunto*</label>
                <input onChange={this.inputAsunto} placeholder='Colaboración' type='text' className='input' required />
                <br/>
                <br/>
                <button onClick={this.handleSubmit} className='button is-link'>ENVIAR</button>
            </form>
        )
    }
}