import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

import $ from "jquery";
import { Table } from "../utilidades/Table";
import { FormAddTrabajador } from "./FormAddTrabajador";
import { FormModTrabajador } from "./FormModTrabajador";
import { DeleteTrabajador } from "./DeleteTrabajador";
import { ModalDetallesTrabajador } from "./ModalDetallesTrabajador";
import { alertSuccess, alertError } from "../utilidades/Alertas";
import { ReporteLicencias } from "./ReporteLicencias";
import { FormTransferenciaP } from "./FormTransferenciaP";
import { SelectPorteo } from "../utilidades/SelectPorteo";
import { InputSearch } from "../common/InputSearch";
import { ButtonRounded } from "../common/ButtonRounded";
import { getColumns } from "./ColumnsPorteadores";
import { SelectOption } from "../common/SelectOption";
import {
  MODAL_ADD_TRABAJADOR,
  MODAL_REPORTE_LICENCIAS,
  MODAL_TRANSFERENCIA,
} from "./ConstantsPorteadores";
import {
  clearSession,
  formatPorteoData,
  hasAccess,
} from "../utilidades/DataHelpers";
import { ROLES } from "../utilidades/Constantes";
import { Service } from "../../services/Service";
import { INVENTARIO, TRABAJADORES } from "../../services/Config";

class Porteadores extends React.Component {
  state = {
    datos: [],
    inputNombre: "",
    selectedItem: "",
    descargar: false,
    table: null,
    inputPorteo: "",
    isMounted: false,
    currentPage: 0,
    totalPages: 0,
    completedData: [],
    dataForFilters: [],
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.datos);
      //De momento los filtros como parametros a las Tablas con Paginación ya no son necesarios ya que se actualizó la funcionalidad localmente
      // table.setFilter([
      //   {
      //     field: "nombre_trabajador",
      //     type: "like",
      //     value: this.state.inputNombre,
      //   },
      //   {
      //     field: "Id_porteo",
      //     type: "like",
      //     value: this.state.inputPorteo.split("/")[0],
      //   },
      // ]);
    }
  };

  filtrarTabla = async (valorAFiltrar, filtro) => {
    if (filtro === "Id_porteo") {
      valorAFiltrar = valorAFiltrar.split("/")[0];
    }

    const datosFiltrados = this.state.dataForFilters.filter((element) => {
      const campo = element[filtro].toString().toLowerCase();
      return campo.includes(valorAFiltrar.toLowerCase());
    });
    this.setState({
      datos: datosFiltrados,
    });
  };

  handleInputChange = (e) => {
    this.filtrarTabla(e.target.value, "nombre_trabajador");
    this.setState({ inputNombre: e.target.value });
  };

  handleInputPorteo = (e) => {
    this.filtrarTabla(e.target.value, "Id_porteo");
    this.setState({ inputPorteo: e.target.value });
  };

  //Controles de Paginación de la Tabla
  first = () => {
    this.setState({ currentPage: 0 });
    this.setState({
      datos: this.state.completedData[0],
    });
    //Reseteo de los inputs a valores predeterminados
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputNombre: "",
    });
  };
  prev = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
    this.setState({
      datos: this.state.completedData[this.state.currentPage - 1],
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputNombre: "",
    });
  };
  next = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.setState({
      datos: this.state.completedData[this.state.currentPage + 1],
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputNombre: "",
    });
  };
  last = () => {
    this.setState({ currentPage: this.state.completedData.length - 1 });
    this.setState({
      datos: this.state.completedData[this.state.completedData.length - 1],
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputNombre: "",
    });
  };

  modalAddTrabajador(e) {
    if (
      sessionStorage.getItem("rol") === "admin" ||
      sessionStorage.getItem("rol") === "RH"
    ) {
      $("#selectPorteo").toggleClass("is-active");
    } else {
      $(`#${MODAL_ADD_TRABAJADOR}`).toggleClass("is-active");
    }
  }

  modalReportes() {
    $(`#${MODAL_REPORTE_LICENCIAS}`).toggleClass("is-active");
  }

  modalTransferencia(e) {
    $(`#${MODAL_TRANSFERENCIA}`).toggleClass("is-active");
  }

  getTrabajadores = async () => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    let result = await Service.getRawResponse(
      TRABAJADORES + `?por=${sessionStorage.getItem("porteo")}`
    );
    let data = [];
    // Verifica si el componente aún está montado
    if (this.state.isMounted) {
      if (result.status === 200) {
        data = await result.json();
        const dataSegmentada = data
          .map((elemento, indice, arreglo) => {
            if (indice % 10 === 0) {
              return arreglo.slice(indice, indice + 10);
            }
            return null; // O puedes devolver null para omitir elementos que no deben estar en el nuevo arreglo.
          })
          .filter(Boolean); // Filtramos los elementos nulos generados por el map.
        this.setState({ dataForFilters: data }); //Data completa
        this.setState({ completedData: dataSegmentada }); //Data completa segmentada por cada 10 elementos
        this.setState({ datos: dataSegmentada[this.state.currentPage] }); //Data renderizada en 10 elementos
        this.setState({ totalPages: dataSegmentada.length });
      } else {
        this.setState({ datos: [] });
      }
      this.props.setLoading(false);
    }
  };

  getPorteo = async (por) => {
    let res = null;
    console.log("Porteo elegido:" + por);
    // eslint-disable-next-line eqeqeq
    if (por == 0) {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403) {
      clearSession();
    } else {
      this.setState({ porteo: "" });
    }
  };

  //Añadir un trabajador
  add = async (formData) => {
    try {
      let res = await Service.postSimpleRawResponse(TRABAJADORES, formData);
      if (res.status === 200) {
        let r = await res.text();
        if (r === "Trabajador existente")
          alertError("Ya existe un trabajador con ese código", 4000, "center");
        else {
          alertSuccess("Trabajador agregado", 4000, "center");
          this.getTrabajadores(""); //'',sessionStorage.getItem('porteo'));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Modificar un trabajador
  mod = async (formData) => {
    try {
      let res = await Service.putSimpleRawResponse(TRABAJADORES, formData);
      if (res.status === 200) {
        let r = await res.text();
        if (r === "Trabajador existente")
          alertError("Ya existe un trabajador con ese código", 4000, "center");
        else {
          alertSuccess("Trabajador modificado", 4000, "center");
          this.getTrabajadores(""); //'',sessionStorage.getItem('porteo'));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Eliminar un trabajador
  remove = async () => {
    try {
      let trabajador = { id: this.state.selectedItem["Id_trabajador"] };
      let res = await Service.deleteRawResponse(TRABAJADORES, trabajador);
      if (res.status === 200) {
        alertSuccess("Trabajador eliminado", 4000, "center");
        this.getTrabajadores(""); //'',sessionStorage.getItem('porteo'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Realizar transferencia
  transferencia = async (transferencia) => {
    try {
      let res = await Service.postRawResponse(
        TRABAJADORES + "/setTransferencia",
        transferencia
      );
      if (res.status === 200) {
        alertSuccess("Transferencia realizada", 4000, "center");
        this.getTrabajadores("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.setState({ isMounted: true });
    this.getTrabajadores();
    let role = sessionStorage.getItem("rol");
    console.log("role: " + role);
    console.log(typeof role);
    if (role == "RH" || role == "admin") {
      this.getPorteo(0);
      console.log(0);
    } else {
      this.getPorteo(sessionStorage.getItem("porteo"));
      console.log(1);
    }
  }
  componentWillUnmount() {
    this.setState({ isMounted: false }); // Establece la bandera isMounted en false cuando el componente se desmonta
  }

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="columns">
            <div className="column is-3">
              <label className="title is-5">Lista de Trabajadores</label>
            </div>

            <div className="column is-5">
              <InputSearch
                onChange={this.handleInputChange.bind(this)}
                name="inputNombre"
                placeholder="Nombre"
                key="buscarPorNombrePorteadores"
              />
            </div>

            <div className="column is-2">
              <div className="control">
                {hasAccess(this.props.userInfo.rol, [
                  ROLES.ADMIN,
                  ROLES.ENCARGADO_PORTEO,
                  ROLES.RH,
                ]) ? (
                  <ButtonRounded
                    onClick={this.modalAddTrabajador}
                    tooltip="Nuevo trabajador"
                    icon="plus"
                    classColor="is-success"
                  />
                ) : null}
                <span> </span>
                {hasAccess(this.props.userInfo.rol, [
                  ROLES.ADMIN,
                  ROLES.ENCARGADO_PORTEO,
                  ROLES.RH,
                ]) ? (
                  <ButtonRounded
                    onClick={this.modalTransferencia}
                    tooltip="Nuevo Transferencia"
                    icon="mail-reply"
                    classColor="is-warning"
                  />
                ) : null}
                <label> </label>
                <span> </span>
                <ButtonRounded
                  onClick={this.modalReportes}
                  tooltip="Reportes"
                  icon="file-text-o"
                  classColor="is-link"
                />
              </div>
            </div>

            <div className="column is-2">
              <div className="control is-expanded">
                <div className="select is-fullwidth">
                  <SelectOption
                    name="inputPorteo"
                    value={this.state.inputPorteo}
                    onChange={this.handleInputPorteo}
                    defaultValue="Porteo"
                    data={formatPorteoData(this.props.userInfo.porteo)}
                    mapValueFrom="Id_porteo"
                    mapTextFrom="nombre"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-12 is-expanded">
              <Table
                columns={getColumns(this.props.userInfo.rol)}
                setItem={this.setItem}
                getTable={this.getTable}
              />
              <div className="controls-table">
                <div className="">
                  Página {this.state.currentPage + 1} de {this.state.totalPages}
                </div>
                <div>
                  <ButtonRounded
                    onClick={this.first}
                    tooltip="Primera página"
                    icon="angle-double-left"
                    classColor="is-light has-text-black"
                  />
                  <ButtonRounded
                    onClick={this.prev}
                    disabled={this.state.currentPage === 0 ? true : false}
                    tooltip="Anterior"
                    icon="angle-left"
                    classColor="is-light has-text-black"
                  />
                  <ButtonRounded
                    onClick={this.next}
                    tooltip="Siguiente"
                    icon="angle-right"
                    classColor="is-light has-text-black"
                    disabled={
                      this.state.currentPage ===
                      this.state.completedData.length - 1
                        ? true
                        : false
                    }
                  />
                  <ButtonRounded
                    onClick={this.last}
                    tooltip="Ultima página"
                    icon="angle-double-right"
                    classColor="is-light has-text-black"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row"></div>
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_PORTEO,
          ROLES.RH,
        ]) ? (
          <FormAddTrabajador add={this.add} />
        ) : null}
        <SelectPorteo modal="#formaddtrabajador" />
        {this.props.userInfo.rol === ROLES.ADMIN ||
        this.props.userInfo.rol === ROLES.RH ? (
          <FormModTrabajador
            mod={this.mod}
            selectedItem={this.state.selectedItem}
          />
        ) : null}
        {this.props.userInfo.rol === ROLES.ADMIN ||
        this.props.userInfo.rol === ROLES.RH ? (
          <DeleteTrabajador remove={this.remove} />
        ) : null}
        <ModalDetallesTrabajador selectedItem={this.state.selectedItem} />
        <ReporteLicencias />
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_PORTEO,
          ROLES.RH,
        ]) ? (
          <FormTransferenciaP transferencia={this.transferencia} />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Porteadores);
