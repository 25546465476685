import React from "react"

export const MovimientoItem = ({ dia, fecha, hora, estatus, detalles}) => {
    return <div >
    <div className='row'>
        <div className='columns'>
            <div className='column is-4'>
                
                <div className='has-text-bold'>{fecha}</div>
            </div>
            <div className='column is-8'>
                <div className='has-text-bold'>{estatus}</div>
                <div>{detalles}</div>
            </div>
        </div>
    </div>
    <hr />
</div>
}