import React from 'react'

import { Title } from './Title'
import AdmonImg from '../../images/ADMON.jpg'

export const Listas = () => (
    <div className='container hero-body'>
        <div className='columns'>
            <div className='column is-6 is-offset-6'>
                <Title inverse={true}>SERVICIOS</Title>
            </div>

        </div>
        <div className=''>
            <div style={{ paddingBottom: 30 }} className='columns'>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} className='column is-6'>
                    <figure className="image is-3by2">
                        <img loading="lazy" className="is-img-rounded" src={AdmonImg} alt='Los mejores servicios' />
                    </figure>
                </div>
                <div className='column is-6 girl-back has-text-weight-bold'>
                    <ul style={{ listStylePosition: "inside" }}>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Entregas On-Time.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Entrega a detalle (ultima milla), llegamos a todos tus clientes.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Seguimiento de pedidos, (lograr la entrega 1 y 2 visitas).</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Canales de comunicación definidos.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Call center.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Bodega en diferentes puntos.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Estructura Organizacional, bien definida.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Facturación.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Generación de manifiestos de entradas.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Generación de manifiestos de salidas.</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Capacitación del personal</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Tecnología</li>
                    </ul>
                </div>

            </div>
            <div className='row'>
                <Title>SEGURIDAD EN <br className='show-element'/>TODAS NUESTRAS UNIDADES</Title>
            </div>
            <div className='columns cars-back'>
                <div className='column is-6 filtro has-text-weight-bold'>
                    <ul style={{ listStylePosition: "inside" }}>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Unidades con GPS</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Programa de mantenimiento correctivo y preventivo</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Check list</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Bitácora de viaje</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Programa de renovación de licencias</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Póliza se seguros</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Procedimiento de contratación de personal administrativo y operativo</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Expedientes de personal</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Centro de monitoreo</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Protocolos de reacción (post-siniestro)</li>
                        <li><span className='fa fa-chevron-right has-text-link'></span> Capacitación y evidencias</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
)

