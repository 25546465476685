import React from "react";
import { alertWarning } from "../utilidades/Alertas";
import $ from "jquery";
import { MODAL_ADD_PROVEEDOR } from "./ConstantsProveedores";
import { Service } from "../../services/Service";
import { PROVEEDORES } from "../../services/Config";

export class FormAddProveedor extends React.Component {
  state = {
    iNombreP: "",
    iAPP: "",
    iAMP: "",
    iTelefonoP: "",
    iCorreoP: "",
    iEstadoP: "",
    iCiudadP: "",
    iDomicilioP: "",
    iCPP: "",
    iInsumoP: "",
    iCategoriaP: "",
    iCategoriaText: "",
    iEmpresaP: "",
    iRealizaMantenimientos: "",
    estados: "",
    ciudades: "",
    nuevaCategoria: 0,
  };

  changeInputCategoria = () => {
    this.setState({ nuevaCategoria: !this.state.nuevaCategoria });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "iEstadoP") {
      this.getCiudades(e.target.value);
    }

    if (e.target.name === "iRealizaMantenimientos") {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    }
  };

  handleClick = (e) => {
    $(`#${MODAL_ADD_PROVEEDOR}`).toggleClass("is-active");
    this.limpiarC();
    this.setState({ nuevaCategoria: false });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='text'],input[type='email'],input[type='tel'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iNombreP: "",
      iAPP: "",
      iAMP: "",
      iTelefonoP: "",
      iCorreoP: "",
      iEstadoP: "",
      iCiudadP: "",
      iDomicilioP: "",
      iCPP: "",
      iInsumoP: "",
      iCategoriaP: "",
      iCategoriaText: "",
      iEmpresaP: "",
      iRealizaMantenimientos: 0,
    });
  };

  //Añadir estados
  getEstados = async () => {
    let result = await Service.getRawResponse( PROVEEDORES + `/estados`);
    if (result.status === 200) this.setState({ estados: await result.json() });
    else this.setState({ estados: [] });
  };

  //Añadir ciudades
  getCiudades = async (estado) => {
    let result = await Service.getRawResponse(PROVEEDORES + `/ciudades?estado=${estado}`);
    if (result.status === 200) this.setState({ ciudades: await result.json() });
    else this.setState({ ciudades: [] });
  };

  //Handle submit
  handleSubmmit = (e) => {
    e.preventDefault();
    const {
      iNombreP,
      iAPP,
      iAMP,
      iTelefonoP,
      iCorreoP,
      iEstadoP,
      iCiudadP,
      iDomicilioP,
      iCPP,
      iInsumoP,
      iCategoriaP,
      iCategoriaText,
      iEmpresaP,
      iRealizaMantenimientos,
    } = this.state;
    if (
      iNombreP &&
      iAPP &&
      iAMP &&
      iTelefonoP &&
      iCorreoP &&
      iEstadoP &&
      iCiudadP &&
      iDomicilioP &&
      iCPP &&
      iInsumoP &&
      (iCategoriaP || iCategoriaText) &&
      iEmpresaP
    ) {
      this.props.add({
        iNombreP,
        iAPP,
        iAMP,
        iTelefonoP,
        iCorreoP,
        iEstadoP,
        iCiudadP,
        iDomicilioP,
        iCPP,
        iInsumoP,
        iCategoriaP: iCategoriaText ? iCategoriaText : iCategoriaP,
        iEmpresaP,
        iRealizaMantenimientos,
      });
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  componentDidMount() {
    this.getEstados();
  }

  render() {
    return (
      <div id={MODAL_ADD_PROVEEDOR} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Agregar proveedor</p>
            <button
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            ></button>
          </header>

          <section className="modal-card-body">
            <form>
              <div className="">
                <div className="row">
                  <div className="columns">
                    <div className="column is-4">
                      <label className="label" htmlFor="iNombreP">
                        Nombre
                      </label>
                      <input
                        id="iNombreP"
                        name="iNombreP"
                        value={this.state.iNombreP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="text"
                        placeholder="Nombre"
                      />
                    </div>
                    <div className="column is-4">
                      <label className="label" htmlFor="iAPP">
                        Apellido paterno
                      </label>
                      <input
                        id="iAPP"
                        name="iAPP"
                        value={this.state.iAPP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="text"
                        placeholder="Apellido paterno"
                      />
                    </div>

                    <div className="column is-4">
                      <label className="label" htmlFor="iAMP">
                        Apellido materno
                      </label>
                      <input
                        id="iAMP"
                        name="iAMP"
                        value={this.state.iAMP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="text"
                        placeholder="Apellido materno"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="columns ">
                    <div className="column is-4">
                      <label className="label" htmlFor="iTelefonoP">
                        Teléfono
                      </label>
                      <input
                        id="iTelefonoP"
                        name="iTelefonoP"
                        value={this.state.iTelefonoP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="tel"
                        placeholder="Teléfono"
                      />
                    </div>
                    <div className="column is-4">
                      <label className="label" htmlFor="iCorreoP">
                        Correo
                      </label>
                      <input
                        id="iCorreoP"
                        name="iCorreoP"
                        value={this.state.iCorreoP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="email"
                        placeholder="Correo electrónico"
                      />
                    </div>
                    <div className="column is-4">
                      <label className="label" htmlFor="iEstadoP">
                        Estado
                      </label>
                      <div className="control">
                        <div className="select is-rounded is-success is-fullwidth">
                          <select
                            id="iEstadoP"
                            name="iEstadoP"
                            value={this.state.iEstadoP}
                            onChange={this.handleInputChange.bind(this)}
                          >
                            <option value="" defaultValue>
                              Seleccione un estado
                            </option>
                            {Object.values(this.state.estados).map((estado) => {
                              return (
                                <option
                                  key={estado.estado}
                                  value={estado.estado}
                                >
                                  {estado.estado}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="columns ">
                    <div className="column is-4">
                      <label className="label" htmlFor="iCiudadP">
                        Ciudad
                      </label>
                      <div className="control">
                        <div className="select is-rounded is-success is-fullwidth">
                          <select
                            id="iCiudadP"
                            name="iCiudadP"
                            value={this.state.iCiudadP}
                            onChange={this.handleInputChange.bind(this)}
                          >
                            <option value="" defaultValue>
                              Seleccione una ciudad
                            </option>
                            {Object.values(this.state.ciudades).map(
                              (ciudad) => {
                                return (
                                  <option
                                    key={ciudad.ciudad}
                                    value={ciudad.ciudad}
                                  >
                                    {ciudad.ciudad}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="column is-4">
                      <label className="label" htmlFor="iDomicilioP">
                        Domicilio
                      </label>
                      <input
                        id="iDomicilioP"
                        name="iDomicilioP"
                        value={this.state.iDomicilioP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="text"
                        placeholder="Domicilio"
                      />
                    </div>
                    <div className="column is-4">
                      <label className="label" htmlFor="iCPP">
                        Código Postal
                      </label>
                      <input
                        id="iCPP"
                        name="iCPP"
                        value={this.state.iCPP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="number"
                        placeholder="Código postal"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="columns ">
                    <div className="column is-4">
                      <label className="label" htmlFor="iInsumoP">
                        Tipo de insumo
                      </label>
                      <div className="control">
                        <div className="select is-rounded is-success is-fullwidth">
                          <select
                            id="iInsumoP"
                            name="iInsumoP"
                            value={this.state.iInsumoP}
                            onChange={this.handleInputChange.bind(this)}
                          >
                            <option value="" defaultValue>
                              Seleccione un insumo
                            </option>
                            <option value="Servicio">Servicio</option>
                            <option value="Producto">Producto</option>
                            <option value="Servicio y producto">
                              Servicio y producto
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="column is-4">
                      <span className="is-space-between">
                        <span
                          style={{
                            display: "inline-block",
                            marginBottom: "0.5em",
                          }}
                        >
                          <label className="label" htmlFor="iCategoriaP">
                            Categoría
                          </label>
                        </span>
                        <span>
                          <a
                            onClick={this.changeInputCategoria}
                            data-tooltip="Nueva categoría"
                            className="has-tooltip-left has-text-success is-outlined"
                          >
                            <span className="icon is-small">
                              <i
                                className={
                                  this.state.nuevaCategoria
                                    ? "fa fa-times"
                                    : "fa fa-plus"
                                }
                              ></i>
                            </span>
                          </a>
                        </span>
                      </span>
                      <div className="control">
                        {this.state.nuevaCategoria ? (
                          <input
                            id="iCategoriaText"
                            name="iCategoriaText"
                            value={this.state.iCategoriaText}
                            onChange={this.handleInputChange.bind(this)}
                            className="input is-rounded is-success"
                            type="text"
                            placeholder="Categoria"
                          />
                        ) : (
                          <div className="select is-rounded is-success is-fullwidth">
                            <select
                              id="iCategoriaP"
                              name="iCategoriaP"
                              value={this.state.iCategoriaP}
                              onChange={this.handleInputChange.bind(this)}
                            >
                              <option value="" defaultValue>
                                Seleccione una categoría
                              </option>
                              {this.props.categorias
                                ? Object.values(this.props.categorias).map(
                                    (categoria) => {
                                      return (
                                        <option
                                          key={categoria.Id_categoria}
                                          value={categoria.nombre}
                                        >
                                          {categoria.nombre}
                                        </option>
                                      );
                                    }
                                  )
                                : null}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="column is-4">
                      <label className="label" htmlFor="iEmpresaP">
                        Empresa
                      </label>
                      <input
                        id="iEmpresaP"
                        name="iEmpresaP"
                        value={this.state.iEmpresaP}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="text"
                        placeholder="Empresa"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="columns ">
                    <div className="column is-4">
                      <label className="checkbox">
                        <input
                          name="iRealizaMantenimientos"
                          id="iRealizaMantenimientos"
                          value={this.state.iRealizaMantenimientos}
                          onChange={this.handleInputChange.bind(this)}
                          type="checkbox"
                        />{" "}
                        Realiza mantenimientos
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
