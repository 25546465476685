// react
import React from 'react';

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table/lib'
// fuente a utilizar
import ProductSans from './style/ProductSansRegular.ttf'
import ProductSansBold from './style/ProductSansBold.ttf'
// estilos
import { styles } from './style/Styles'
//LOGOTIPO
import logo from './../../images/LOGOLOGISTIC.png'

Font.register({
    family: 'ProductSans',
    fonts: [
        { src: ProductSans }, // font-style: normal, font-weight: normal
        { src: ProductSansBold, fontWeight: 'bold' },
    ]
});

export const InventarioES = ({ porteo, periodo, movimiento, data }) => (
    <Document>
        <Page size="A4" style={styles.page} >
            <View style={styles.logoTitle}>
                <Image style={styles.image} src={logo} />
                <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
            </View>
            <View style={styles.info}>
                <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
                <Text style={styles.contacto}>Tel: (341) 410-6511 ó (341) 410-6458</Text>
            </View>
            <Text style={styles.subtitulo}>Reporte E/S</Text>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Porteo:</Text>
                    <Text style={styles.parametros}>{porteo}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Periodo:</Text>
                    <Text style={styles.parametros}>{periodo}</Text>
                </View>
            </View>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Movimiento:</Text>
                    <Text style={styles.parametros}>{movimiento}</Text>
                </View>
            </View>
            {
                movimiento === 'Entrada' ?
                    <View >
                        <Table data={data}>
                            <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                <TableCell style={styles.tablaHeader} weighting={0.05}>#</TableCell>
                                <TableCell style={styles.tablaHeader} weighting={0.3}>ARTICULO</TableCell>
                                <TableCell style={styles.tablaHeader} weighting={0.2}>CANTIDAD</TableCell>
                                <TableCell style={styles.tablaHeader} weighting={0.2}>FECHA</TableCell>
                            </TableHeader>
                            <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                <DataTableCell style={styles.tabla} weighting={0.05} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                                <DataTableCell style={styles.tabla} weighting={0.3} getContent={(r) => r.codigo_producto} />
                                <DataTableCell style={styles.tabla} weighting={0.2} getContent={(r) => r.cantidad_movimiento.toLocaleString()} />
                                <DataTableCell style={styles.tabla} weighting={0.2} getContent={(r) => r.fecha_movimiento.substring(0, 10)} />
                            </TableBody>
                        </Table>
                    </View>
                    :
                    movimiento === 'Salida' ?
                        <View >
                            <Table data={data}>
                                <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <TableCell style={styles.tablaHeader} weighting={0.05}>#</TableCell>
                                    <TableCell style={styles.tablaHeader} weighting={0.3}>ARTICULO</TableCell>
                                    <TableCell style={styles.tablaHeader} weighting={0.2}>CANTIDAD</TableCell>
                                    <TableCell style={styles.tablaHeader} weighting={0.2}>FECHA</TableCell>
                                    <TableCell style={styles.tablaHeader} weighting={0.295}>DESTINATARIO</TableCell>
                                </TableHeader>
                                <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <DataTableCell style={styles.tabla} weighting={0.05} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                                    <DataTableCell style={styles.tabla} weighting={0.3} getContent={(r) => r.codigo_producto} />
                                    <DataTableCell style={styles.tabla} weighting={0.2} getContent={(r) => r.cantidad_movimiento.toLocaleString()} />
                                    <DataTableCell style={styles.tabla} weighting={0.2} getContent={(r) => r.fecha_movimiento.substring(0, 10)} />
                                    <DataTableCell style={styles.tabla} weighting={0.295} getContent={(r) => r.destinatario_movimiento} />
                                </TableBody>
                            </Table>
                        </View>
                        :
                        <View>
                            <Table data={data}>
                                <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <TableCell style={styles.tablaHeader} weighting={0.05}>#</TableCell>
                                    <TableCell style={styles.tablaHeader}>ARTICULO</TableCell>
                                    <TableCell style={styles.tablaHeader}>CANTIDAD</TableCell>
                                    <TableCell style={styles.tablaHeader}>E/S</TableCell>
                                    <TableCell style={styles.tablaHeader}>FECHA</TableCell>
                                    <TableCell style={styles.tablaHeader}>DESTINATARIO</TableCell>
                                </TableHeader>
                                <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <DataTableCell style={styles.tabla} weighting={0.05} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                                    <DataTableCell style={styles.tabla} getContent={(r) => r.codigo_producto} />
                                    <DataTableCell style={styles.tabla} getContent={(r) => r.cantidad_movimiento.toLocaleString()} />
                                    <DataTableCell style={styles.tabla} getContent={(r) => r.accion_movimiento} />
                                    <DataTableCell style={styles.tabla} getContent={(r) => r.fecha_movimiento.substring(0, 10)} />
                                    <DataTableCell style={styles.tabla} getContent={(r) => r.destinatario_movimiento} />
                                </TableBody>
                            </Table>
                        </View>
            }
        </Page>
    </Document>
);