import React from 'react'
import { alertWarning } from '../utilidades/Alertas'
import $ from 'jquery'
import {MODAL_MODIFICAR_ARTICULO} from "./ConstantsInventario";
import { Service } from "../../services/Service"
import { INVENTARIO } from '../../services/Config'; 

export class ModificarArticulo extends React.Component {
    state = {
        iNombreAm: '',
        iMarcaAm: '',
        iCategoriaAm: '',
        iCodigoAm: '',
        iPrecioAm: '',
        iDescripcionAm: '',
        iMedidaAm: '-',
        iCajasAm: 0,
        iPiezasAm: '',
        iFacturaAm: '',
        iProveedorAm: '',
        iMinAm: '',
        iMaxAm: '',
        iImagenAm: '',
        proveedores: '',
        flag: true
    }



    // Get each character from the input Nombre
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    _inputImagen = (e) => {
        $("#filenamem").text(e.target.files[0].name);
        document.getElementById("imgView").src = URL.createObjectURL(e.target.files[0]);
        this.setState({ iImagenAm: e.target.files[0] });
    }

    handleClick = (e) => {
        $(`#${MODAL_MODIFICAR_ARTICULO}`).toggleClass("is-active");
        this.props.turnOn();
        this.limpiarC();
    }


    limpiarC = () => {
        $("#filenamem").text('Sin imagen');
        this.setState({
            iNombreAm: '',
            iMarcaAm: '',
            iCategoriaAm: '',
            iCodigoAm: '',
            iPrecioAm: '',
            iDescripcionAm: '',
            iMedidaAm: '-',
            iCajasAm: 0,
            iPiezasAm: '',
            iFacturaAm: '',
            iProveedorAm: '',
            iMinAm: '',
            iMaxAm: '',
            iImagenAm: ''
        })
    }

    //Añadir proveedores
    añadirProveedores = () => {
        Service.getRawResponse( INVENTARIO + '/getProveedores')
            .then(response => response.json())
            .then(result => {
                this.setState({ proveedores: result })
            });
    }

    // backend 
    handleSubmmit = (e) => {
        let {
            iNombreAm,
            iMarcaAm,
            iCategoriaAm,
            iCodigoAm,
            iPrecioAm,
            iDescripcionAm,
            iMedidaAm,
            iCajasAm,
            iPiezasAm,
            iFacturaAm,
            iProveedorAm,
            iMinAm,
            iMaxAm,
            iImagenAm
        } = this.state;
        if (
            iNombreAm &&
            iMarcaAm &&
            iCategoriaAm &&
            iCodigoAm &&
            iPrecioAm &&
            iDescripcionAm &&
            iFacturaAm &&
            iProveedorAm &&
            iMinAm &&
            iMaxAm
        ) {
            let cantidad;
            if (!iCajasAm || iCajasAm === 0) {
                cantidad = iPiezasAm;
            }
            else {
                cantidad = iPiezasAm * iCajasAm;
            }
            let formData = new FormData();
            formData.append('id', this.props.selectedItem['Id_producto']);
            formData.append('porteo', sessionStorage.getItem('porteo'));
            formData.append('nombre', iNombreAm);
            formData.append('marca', iMarcaAm);
            formData.append('categoria', iCategoriaAm);
            formData.append('codigo', iCodigoAm);
            formData.append('precio', iPrecioAm);
            formData.append('descripcion', iDescripcionAm);
            formData.append('medida', iMedidaAm);
            formData.append('cantidad', cantidad);
            formData.append('factura', iFacturaAm);
            formData.append('proveedor', iProveedorAm);
            formData.append('minimo', iMinAm);
            formData.append('maximo', iMaxAm);
            formData.append('file', iImagenAm);

            this.props.update(formData)
            this.handleClick();
        }
        else
            alertWarning("Faltan Campos", 4000, "center")
    }

    initProv = () => {
        this.setState({
            iNombreAm: this.props.selectedItem['nombre_producto'],
            iMarcaAm: this.props.selectedItem['marca_producto'],
            iCategoriaAm: this.props.selectedItem['categoria_producto'],
            iCodigoAm: this.props.selectedItem['codigo_producto'],
            iPrecioAm: this.props.selectedItem['precio_producto'],
            iDescripcionAm: this.props.selectedItem['descripcion_producto'],
            iMedidaAm: this.props.selectedItem['unidad_de_medida'],
            iPiezasAm: this.props.selectedItem['cantidad_producto'],
            iFacturaAm: this.props.selectedItem['factura_producto'],
            iProveedorAm: this.props.selectedItem['producto_proveedor'],
            iMinAm: this.props.selectedItem['minimo_producto'],
            iMaxAm: this.props.selectedItem['maximo_producto']
        });
        this.props.turnOff();
    }

    existe = () => {
        this.setState({
            flag: false
        });
    }

    componentDidMount() {
        this.añadirProveedores();
    }

    render() {
        if ($(`#${MODAL_MODIFICAR_ARTICULO}`).is(":visible") && this.props.flag) {
            this.initProv();
        }
        return (
            <div id={MODAL_MODIFICAR_ARTICULO} className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Nuevo Articulo</p>
                        <button title='Cerrar' onClick={this.handleClick} className="delete" aria-label="close"/>
                    </header>
                    <section className="modal-card-body">
                        <form id="formularioMod" encType="multipart/form-data">
                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iNombreAm'>Nombre</label>
                                        <input
                                            id='iNombreAm'
                                            name='iNombreAm'
                                            value={this.state.iNombreAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="text"
                                            placeholder="Nombre" />
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iMarcaAm'>Marca</label>
                                        <input
                                            id='iMarcaAm'
                                            name='iMarcaAm'
                                            value={this.state.iMarcaAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="text"
                                            placeholder="Marca" />
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iCategoriaAm'>Categoría</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                    id='iCategoriaAm'
                                                    name='iCategoriaAm'
                                                    value={this.state.iCategoriaAm}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione una categoria</option>
                                                    {this.props.categorias ?
                                                        Object.values(this.props.categorias).map((categoria) => {
                                                            return (<option key={categoria.Id_categoria} value={categoria.nombre}>{categoria.nombre}</option>)
                                                        })
                                                        :
                                                        null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iCodigoAm'>Código</label>
                                        <input
                                            id='iCodigoAm'
                                            name='iCodigoAm'
                                            value={this.state.iCodigoAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="text"
                                            placeholder="Código" />
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iPrecioAm'>Precio unitario</label>
                                        <input
                                            id='iPrecioAm'
                                            name='iPrecioAm'
                                            value={this.state.iPrecioAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="number"
                                            placeholder="Precio" />
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iDescripcionAm'>Descripción</label>
                                        <input
                                            id='iDescripcionAm'
                                            name='iDescripcionAm'
                                            value={this.state.iDescripcionAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="text"
                                            placeholder="Descripción" />
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className='label' htmlFor='iMedidaAm'>Unidad de medida</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                    id='iMedidaAm'
                                                    name='iMedidaAm'
                                                    value={this.state.iMedidaAm}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione una unidad de medida</option>
                                                    {this.props.unidadesMedida ?
                                                        Object.values(this.props.unidadesMedida).map((um) => {
                                                            return (<option key={um.Id_unidad_de_medida} value={um.nombre}>{um.nombre}</option>)
                                                        })
                                                        :
                                                        null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.iMedidaAm === 'Caja' ?
                                            <div className='column is-4'>
                                                <label className="label" htmlFor='iCajasAm'>Cantidad de cajas</label>
                                                <input
                                                    id='iCajasAm'
                                                    name='iCajasAm'
                                                    value={this.state.iCajasAm}
                                                    onChange={this.handleInputChange.bind(this)}
                                                    className="input is-rounded is-success"
                                                    type="number"
                                                    placeholder="Cajas" />
                                            </div>
                                            :
                                            null
                                    }

                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iPiezasAm'>Cantidad</label>
                                        <input
                                            id='iPiezasAm'
                                            name='iPiezasAm'
                                            value={this.state.iPiezasAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="number"
                                            placeholder="Piezas" />
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iFacturaAm'>Factura</label>
                                        <input
                                            id='iFacturaAm'
                                            name='iFacturaAm'
                                            value={this.state.iFacturaAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="text"
                                            placeholder="Factura" />
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iProveedorAm'>Proveedor</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                    id='iProveedorAm'
                                                    name='iProveedorAm'
                                                    value={this.state.iProveedorAm}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione un proveedor</option>
                                                    {
                                                        Object.values(this.state.proveedores).map((proveedor) => {
                                                            return (<option key={proveedor.Id_proveedor} value={proveedor.empresa_proveedor}>{proveedor.empresa_proveedor}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iMinAm'>Mínimo</label>
                                        <input
                                            id='iMinAm'
                                            name='iMinAm'
                                            value={this.state.iMinAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="number"
                                            placeholder="Mínimo" />
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label" htmlFor='iMaxAm'>Máximo</label>
                                        <input
                                            id='iMaxAm'
                                            name='iMaxAm'
                                            value={this.state.iMaxAm}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="number"
                                            placeholder="Máximo" />
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-12'>
                                        <label className="label" htmlFor='iImagenAm'>Imagen</label>
                                        {
                                            this.props.selectedItem['img_producto'] ?
                                                <figure className="image is-128x128">
                                                    <img id="imgView" src={this.props.selectedItem['img_producto']} alt={this.props.selectedItem['nombre_producto']} />
                                                </figure>
                                                :
                                                null
                                        }
                                        <br />
                                        <div id="iImagenAm" className="file has-name is-success is-fullwidth">
                                            <label className="file-label">
                                                <input onChange={this._inputImagen} className="file-input" type="file" name="resume" accept="image/*" />
                                                <span className="file-cta">
                                                    <span className="file-icon">
                                                        <i className="fa fa-file-image-o"></i>
                                                    </span>
                                                    <span className="file-label">
                                                        Seleccionar imagen…
                                            </span>
                                                </span>
                                                <span id='filenamem' className="file-name" style={{ textAlign: "center" }}>
                                                    {this.props.selectedItem['nombre_producto']}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </section>
                    <footer className="modal-card-foot">
                        <button onClick={this.handleSubmmit} className="button is-rounded is-success">Guardar</button>
                        <button onClick={this.handleClick} className="button is-rounded is-warning" >Cancelar</button>
                    </footer>
                </div>
                {
                    document.getElementById('iCajasAm') != null && this.state.flag ? (
                        this.existe()
                    ) :
                        null
                }
            </div>
        )
    }
}