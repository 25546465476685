import React from "react";
import $ from "jquery";

import { MODAL_SALIDA_RUTA } from "./ConstantsEstatusDePiso";
import { alertWarning } from "../../utilidades/Alertas";
import { SelectOption } from "../../common/SelectOption";
import { InputIcon } from "../../common/InputIcon";
import { Table } from "../../utilidades/Table";
import { Service } from "../../../services/Service";
import { BODEGA } from "../../../services/Config";

let columns = [
  { title: "#", field: "id", width: 60 },
  { title: "Número de guia", field: "numero_guia" },
  {
    title: "Agregar",
    field: "agregar",
    align: "center",
    formatter: "tickCross",
    cellClick: function (e, cell) {
      cell.getRow().getData().agregar = !cell.getRow().getData().agregar;
    },
  },
];

export class ModalSalidaRuta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iTransporte: "",
      iChoferResponsable: "",
      iGasolinaInicial: "",
      iKmInicial: "",
      iFechaHora: "",
      iObservacionesUnidad: "",
      rutasData: [
        { Id_ruta: 1, nombre: "Norte", selected: false },
        { Id_ruta: 2, nombre: "Sur", selected: false },
        { Id_ruta: 3, nombre: "Atajo", selected: false },
      ],
      paquetes: [],
      transportes: [],
      choferes: [],
      nivelesGasolina: [
        {
          Id_nivel: "1/4",
          valor: "1/4",
        },
        {
          Id_nivel: "1/2",
          valor: "1/2",
        },
        {
          Id_nivel: "3/4",
          valor: "3/4",
        },
        {
          Id_nivel: "1",
          valor: "1",
        },
      ],
    };
  }

  getPaquetesSelected = (paquetes) => {
    let selectedPaquetes = paquetes.filter(
      // eslint-disable-next-line eqeqeq
      (paquete) => paquete.agregar == true || paquete.agregar == "true"
    );
    return selectedPaquetes;
  };

  getRutasSelected = (rutas) => {
    // eslint-disable-next-line eqeqeq
    let selectedRoutes = rutas.filter((ruta) => ruta.selected == true);
    return selectedRoutes;
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.paquetes);
    }
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='datetime-local'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      //salida a ruta
      iMontoCargado: "",
      iLitrosCargados: "",
      totalPaquetes: "0",
      iGasolinaInicial: "",
      iKmInicial: "",
      iObservacionesUnidad: "",
      iTransporte: "",
      iChoferResponsable: "",
      iFechaHora: "",
      rutasData: [],
      paquetes: [],
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_SALIDA_RUTA}`).toggleClass("is-active");
    this.limpiarC();
  };

  rutaOnChange = (e) => {
    let selected = e.target.getAttribute("data-selected");
    let idRuta = e.target.getAttribute("data-id_ruta");

    selected = selected === "true" || selected === true ? false : true;

    e.target.setAttribute("data-selected", selected);

    this.setState((prevState) => ({
      rutasData: prevState.rutasData.map((obj) =>
        // eslint-disable-next-line eqeqeq
        obj.Id_ruta == idRuta ? Object.assign(obj, { selected: selected }) : obj
      ),
    }));
  };

  agregarPaquete = (e) => {
    e.preventDefault();

    let { iCodigo, paquetes } = this.state;

    if (iCodigo) {
      paquetes.push({
        id: paquetes.length + 1,
        numero_guia: iCodigo,
        agregar: true,
      });

      this.setState({ paquetes: paquetes, iCodigo: "" });
      $("#iCodigo").val("");
    }
  };

  // backend
  handleSubmmit = (e) => {
    const {
      iTransporte,
      iChoferResponsable,
      iGasolinaInicial,
      iKmInicial,
      iFechaHora,
      iObservacionesUnidad,
      paquetes,
      rutasData,
    } = this.state;

    if (
      iTransporte &&
      iChoferResponsable &&
      iGasolinaInicial &&
      iKmInicial &&
      iFechaHora &&
      iObservacionesUnidad &&
      paquetes
    ) {
      this.props.add({
        porteo: sessionStorage.getItem("porteo"),
        iTransporte,
        iChoferResponsable,
        iGasolinaInicial,
        iKmInicial,
        iFechaHora,
        iObservacionesUnidad,
        paquetes: this.getPaquetesSelected(paquetes),
        rutas: this.getRutasSelected(rutasData),
      });
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  //Get Rutas
  getRutas = async () => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    let result = await Service.getRawResponse( BODEGA + 
      `/getRutas?por=${sessionStorage.getItem("porteo")}`
    );
    if (result.status === 200)
      this.setState({ rutasData: await result.json() });
    else this.setState({ rutasData: [] });
  };

  //Get Transportes
  getTransportes = async () => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    let result = await Service.getRawResponse( BODEGA + 
      `/getTransportes?por=${sessionStorage.getItem("porteo")}`
    );
    if (result.status === 200)
      this.setState({ transportes: await result.json() });
    else this.setState({ transportes: [] });
  };

  //Get Choferes
  getChoferes = async () => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    let result = await Service.getRawResponse( BODEGA + 
      `/getChoferes?por=${sessionStorage.getItem("porteo")}`
    );
    if (result.status === 200) this.setState({ choferes: await result.json() });
    else this.setState({ choferes: [] });
  };

  componentDidMount() {
    this.getRutas();
    this.getTransportes();
    this.getChoferes();
  }

  render() {
    return (
      <div id={MODAL_SALIDA_RUTA} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Registrar salida a ruta</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label">Transporte:</label>
                    <SelectOption
                      name="iTransporte"
                      defaultValue="Transporte"
                      value={this.state.iTransporte}
                      onChange={this.handleInputChange.bind(this)}
                      data={this.state.transportes}
                      mapValueFrom="Id_transporte"
                      mapTextFrom="valor"
                      classColor="is-success"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label">Chofer Responsable:</label>
                    <SelectOption
                      name="iChoferResponsable"
                      defaultValue="Chofer"
                      value={this.state.iChoferResponsable}
                      onChange={this.handleInputChange.bind(this)}
                      data={this.state.choferes}
                      mapValueFrom="Id_usuario"
                      mapTextFrom="valor"
                      classColor="is-success"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iGasolinaInicial">
                      Nivel de gasolina inicial
                    </label>
                    <SelectOption
                      name="iGasolinaInicial"
                      defaultValue="Gasolina inicial"
                      value={this.state.iGasolinaInicial}
                      onChange={this.handleInputChange.bind(this)}
                      data={this.state.nivelesGasolina}
                      mapValueFrom="Id_nivel"
                      mapTextFrom="valor"
                      classColor="is-success"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iKmInicial">
                      Kilometraje inicial
                    </label>
                    <input
                      name="iKmInicial"
                      value={this.state.iKmInicial}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaHora">
                      Fecha y hora
                    </label>
                    <input
                      name="iFechaHora"
                      value={this.state.iFechaHora}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="datetime-local"
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <label className="label" htmlFor="iObservacionesUnidad">
                      Observaciones de la unidad
                    </label>
                    <textarea
                      name="iObservacionesUnidad"
                      value={this.state.iObservacionesUnidad}
                      onChange={this.handleInputChange.bind(this)}
                      className="textarea is-success"
                      rows="1"
                      placeholder="Observaciones de la unidad de transporte"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <label className="label" htmlFor="iRutas">
                      Rutas
                    </label>
                    <div
                      className="input is-rounded is-success"
                      style={{ paddingBottom: "2em", paddingTop: "2em" }}
                    >
                      {this.state.rutasData !== null ? (
                        Object.values(this.state.rutasData).map((item) => {
                          return (
                            <a
                              className={`button is-rounded
                                                            ${
                                                              item.selected
                                                                ? ""
                                                                : ""
                                                            }`}
                              style={{ marginRight: "1em" }}
                              key={item.Id_ruta}
                              data-id_ruta={item.Id_ruta}
                              data-selected="false"
                              onClick={this.rutaOnChange.bind(this)}
                            >
                              {" "}
                              {item.nombre}
                            </a>
                          );
                        })
                      ) : (
                        <a className="button is-rounded" key="No hay datos">
                          {" "}
                          'No hay datos'
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle">Lista de paquetes</label>
                  </div>
                  <div className="column">
                    <span className="has-text-bold has-text-right">
                      Total de paquetes:{" "}
                      {
                        this.state.paquetes.filter(
                          (paquete) => paquete.agregar === true
                        ).length
                      }
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="label" htmlFor="iFolioEmbarque">
                      Número de guias
                    </label>
                    <div className="field has-addons">
                      <InputIcon icon="barcode" />
                      <div className="control is-expanded is-rounded">
                        <input
                          id="iCodigo"
                          name="iCodigo"
                          onChange={this.handleInputChange.bind(this)}
                          className="input is-rounded is-success"
                          type="text"
                          placeholder="Ingrese los codigos..."
                        />
                      </div>
                      <button
                        className="button is-link is-rounded"
                        onClick={this.agregarPaquete}
                        tooltip="Agregar"
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <Table
                      columns={columns}
                      setItem={this.setItem}
                      getTable={this.getTable}
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
