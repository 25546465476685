import React from 'react'

import { Link, Redirect } from 'react-router-dom'

import '../../../node_modules/font-awesome/css/font-awesome.css'

// REDUX
import { connect } from 'react-redux'
import { Sesion } from '../../redux/actions/SesionActions'
//import { verifyUser } from '../../services/SesionService'
//import { Service } from '../../services/Service'
import { API_URL, SESION } from '../../services/Config'
import { ROLES } from '../utilidades/Constantes'
import { Service } from '../../services/Service'
class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputUser: '',
            inputPassword: '',
            message: ''
        }
    }

    // Get each character from the input User
    _inputUser = (e) => {
        this.setState({ inputUser: e.target.value })
    }

    // Get each character from the input Password
    _inputPassword = (e) => {
        this.setState({ inputPassword: e.target.value })
    }

    // Backend 
    verificarUsuario = async () => {

        const { inputUser, inputPassword } = this.state

        const user = {
            inputUser,
            inputPassword
        };

        await Service.postRawResponse(SESION, user)
            .then(res => res.json())
            .then(result => {
                if (result['flag']) {

                    if(result['rol'] === ROLES.CHOFER) {
                        this.setState({ message: "Tipo de usuario no valido" });
                    } else {
                        sessionStorage.setItem('user', this.state.inputUser);
                        sessionStorage.setItem('pass', this.state.inputPassword);
                        sessionStorage.setItem('rol', result['rol']);
                        sessionStorage.setItem('porteo', result['Id_porteo']);
                        sessionStorage.setItem('access_token', result['token'])
                        this.props.setUserInfo({ user: this.state.inputUser, rol: result['rol'], porteo: result['Id_porteo'] })
                        this.setState({ message: "" });
                    }
                }
                else {
                    this.setState({ message: "Usuario o contraseña incorrectos" });
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.setLoading(true);
        this.verificarUsuario()
        this.props.setLoading(false);
    }

    render() {
        if (sessionStorage.getItem('user') && sessionStorage.getItem('pass'))
            return <Redirect to="/home" />
        return (
            <form>
                <div className="field">
                    <label className="label" htmlFor='user'>Usuario</label>
                    <div className="control has-icons-left has-icons-right ">
                        <input onChange={this._inputUser} id='user' className="input" type="text" placeholder="Usuario" />
                        <span className="icon is-small is-left">
                            <i className="fa fa-user"></i>
                        </span>
                    </div>
                </div>

                <div className="field">
                    <label className="label" htmlFor='password'>Contraseña</label>
                    <p className="control has-icons-left">
                        <input onChange={this._inputPassword} id='password' className="input" type="password" placeholder="Contraseña" />
                        <span className="icon is-small is-left">
                            <i className="fa fa-lock"></i>
                        </span>
                    </p>
                </div>

                <div className="field">
                    <p className="control">

                        <Link to='/home'>
                            <button onClick={this.handleSubmit} className="button is-success is-fullwidth is-focused">
                                Ingresar
                            </button>
                        </Link>
                    </p>
                </div>

                <p id='error' className="help is-danger has-text-centered">{this.state.message}</p>

            </form>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (isLoading) => dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
        setUserInfo: (userInfo) => dispatch({ userInfo: userInfo, type: Sesion.SET_USER_INFO })
    }
}


export default connect(null, mapDispatchToProps)(LoginForm)