import React from "react";

import $ from "jquery";
import { MODAL_BORRAR_SERVICIO } from "./ConstantsConfiguraciones";

export class FormDeleteServicio extends React.Component {
  state = {
    iIdServicio: "",
  };

  handleSubmmit = (e) => {
    this.props.remove();
    this.handleClick();
  };

  handleClick = (e) => {
    $(`#${MODAL_BORRAR_SERVICIO}`).toggleClass("is-active");
    // this.limpiarC();
  };

  //Limpiar inputs
  // limpiarC = () => {
  //   let elements = document.querySelectorAll("select");
  //   for (const element of elements) {
  //     element.value = "";
  //   }
  //   this.setState({
  //     iServicio: "",
  //   });
  // };

  render() {
    return (
      <div id={MODAL_BORRAR_SERVICIO} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Confirmación</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="content">
              <p>
                <label className="label">
                  ¿Esta seguro de eliminar este servicio de mantenimiento?
                </label>
              </p>
              <p id="deletedata"></p>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-danger"
            >
              Eliminar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-success"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
