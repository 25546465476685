import React from 'react'
import { HeadInfo } from '../components/homepage/HeadInfo'
import { ContactoInfo } from '../components/homepage/ContactoInfo'
import { Footer } from '../components/homepage/Footer'
import HomeBar from '../components/homepage/HomeBar'
export default class MainPage extends React.Component {
  render() {
    return (
      <div className="is-font-home is-back-home">
        <HeadInfo />
        <HomeBar />
        {this.props.children}
        <ContactoInfo />
        <Footer />
      </div>
    )
  }
}