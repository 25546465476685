// react
import React from "react";

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from "@react-pdf/renderer";
// fuente a utilizar
import ProductSans from "./style/ProductSansRegular.ttf";
import ProductSansBold from "./style/ProductSansBold.ttf";
// estilos
import { styles } from "./style/Styles";
//LOGOTIPO
import logo from "./../../images/LOGOLOGISTIC.png";

Font.register({
  family: "ProductSans",
  fonts: [
    { src: ProductSans }, // font-style: normal, font-weight: normal
    { src: ProductSansBold, fontWeight: "bold" },
  ],
});

export const DetallesTransportes = ({ porteo, categoria, data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.logoTitle}>
        <Image style={styles.image} src={logo} />
        <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
      </View>
      <View style={styles.info}>
        <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
        <Text style={styles.contacto}>
          Tel: (341) 410-6511 ó (341) 410-6458
        </Text>
      </View>
      <Text
        style={{
          fontSize: "16",
          color: "#4a4963",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        DETALLES DE TRANSPORTE
      </Text>
      <Text style={styles.subtituloBold}>Datos Particulares</Text>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Placa:</Text>
          <Text style={styles.parametros}>{data.placa}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>No. Serie:</Text>
          <Text style={styles.parametros}>{data.no_serie}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>No. Económico:</Text>
          <Text style={styles.parametros}>{data.no_economico}</Text>
        </View>
      </View>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Tipo de unidad:</Text>
          <Text style={styles.parametros}>{data.tipo_unidad}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Modelo:</Text>
          <Text style={styles.parametros}>{data.modelo}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Color:</Text>
          <Text style={styles.parametros}>{data.color}</Text>
        </View>
      </View>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Marca:</Text>
          <Text style={styles.parametros}>{data.marca}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Submarca:</Text>
          <Text style={styles.parametros}>{data.submarca}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Camper:</Text>
          <Text style={styles.parametros}>
            {data.camper === 1 ? "Si" : "No"}
          </Text>
        </View>
      </View>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Estado de registro:</Text>
          <Text style={styles.parametros}>{data.estado_registro}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Refrendo:</Text>
          <Text style={styles.parametros}>{data.refrendo}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Kilometraje:</Text>
          <Text style={styles.parametros}>{data.kilometraje}</Text>
        </View>
      </View>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Nivel del tanque (ltrs):</Text>
          <Text style={styles.parametros}>{data.nivel_gasolina}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Capacidad del tanque (ltrs):</Text>
          <Text style={styles.parametros}>{data.capacidad_gasolina}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Rendimiento técnico:</Text>
          <Text style={styles.parametros}>{data.rendimiento_tecnico}</Text>
        </View>
      </View>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>
            Periodo verificación vehicular:
          </Text>
          <Text style={styles.parametros}>{data.verificacion_vehicular}</Text>
        </View>
      </View>
      <Text style={styles.subtituloBold}>Datos de la poliza </Text>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Empresa de seguro:</Text>
          <Text style={styles.parametros}>{data.empresa_seguro}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Poliza de seguro:</Text>
          <Text style={styles.parametros}>{data.poliza_seguro}</Text>
        </View>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Fecha de vencimiento:</Text>
          <Text style={styles.parametros}>
            {data.poliza_vencimiento.split("T")[0]}
          </Text>
        </View>
      </View>
      <Text style={styles.subtituloBold}>Estatus financiero</Text>
      <View style={styles.distribucionHorizontal}>
        <View style={styles.distribucionVertical}>
          <Text style={styles.textoCampos}>Estatus financiero:</Text>
          <Text style={styles.parametros}>{data.status_financiero}</Text>
        </View>
        {data.status_financiero !== "Pagado" ? (
          <View style={styles.distribucionVertical}>
            <Text style={styles.textoCampos}>Fecha de vencimiento:</Text>
            <Text style={styles.parametros}>
              {data.status_financiero_vencimiento
                ? data.status_financiero_vencimiento.split("T")[0]
                : data.status_financiero_vencimiento}
            </Text>
          </View>
        ) : null}
      </View>
    </Page>
  </Document>
);
