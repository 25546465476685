// URL BASE DEL SERVER
export const API_URL        = process.env.REACT_APP_API_URL

// RUTAS DE CADA MODULO
export const SESION         = '/VerifUser'
export const PROVEEDORES    = '/Proveedores'
export const INVENTARIO     = '/Inventario'
export const MANTENIMIENTO  = '/Mantenimiento'
export const REPORTES       = '/Reportes'
export const NOTIFICACIONES = '/Notificaciones'
export const TRANSPORTES    = '/Transportes'
export const TRABAJADORES   = '/Trabajadores'
export const BODEGA         = '/Bodega'
export const ONTIME         = '/Ontime'
export const CONSUMO_GASOLINA = '/ConsumoGasolina'
export const SEGUIMIENTO_ENTREGAS = '/SeguimientoEntregas'