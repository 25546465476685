import React from "react";
import $ from "jquery";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { InventarioProductos } from "../reportes/InventarioProductos";
import { alertWarning, alertError } from "../utilidades/Alertas";
import { MODAL_REPORTE_PRODUCTOS } from "./ConstantsInventario";
import { Service } from "../../services/Service";
import { INVENTARIO, REPORTES } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

export class ReporteProductos extends React.Component {
  state = {
    iPorteoRPR: "",
    iCategoriaRPR: "",
    descargar: false,
    data: "",
    porteo: null,
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({ descargar: false });
  };

  handleClick = (e) => {
    $(`#${MODAL_REPORTE_PRODUCTOS}`).toggleClass("is-active");
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    let { iPorteoRPR, iCategoriaRPR } = this.state;
    if (iPorteoRPR && iCategoriaRPR) {
      this.setState({ data: "" });
      this.getReporte();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll("select");
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iPorteoRPR: "",
      iCategoriaRPR: "",
      descargar: false,
      data: "",
    });
  };

  getReporte = async () => {
    let res = await Service.getRawResponse( REPORTES + 
      `/getReporteProducto?por=${this.state.iPorteoRPR}&cat=${this.state.iCategoriaRPR}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ data: r });
      this.setState({ descargar: true });
    } else {
      alertError("No existen datos para generar el reporte", 4000, "center");
    }
  };

  getPorteo = async (por) => {
    console.log(por);
    let res = null;
    if (por == 0) {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403){
      clearSession()
    } else this.setState({ porteo: "" });
  };

  componentDidMount() {
    this.getPorteo(sessionStorage.getItem("porteo"));
  }

  render() {
    return (
      <div id={MODAL_REPORTE_PRODUCTOS} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Reporte de Productos</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iPorteoRPR">
                      Porteo
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iPorteoRPR"
                          name="iPorteoRPR"
                          value={this.state.iPorteoRPR}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un porteo
                          </option>
                          {
                            sessionStorage.getItem("porteo") == 0 ? (
                              <option key="0" value="0">
                                Todos
                              </option>
                            ) : (
                              null
                            )
                          }
                          {this.state.porteo !== null ? (
                            Object.values(this.state.porteo).map((p) => {
                              return (
                                <option
                                  key={p.Id_porteo}
                                  value={p.Id_porteo + "/" + p.nombre}
                                >
                                  {p.nombre}
                                </option>
                              );
                            })
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="column is-6">
                    <label className="label" htmlFor="iCategoriaRPR">
                      Categoría
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iCategoriaRPR"
                          name="iCategoriaRPR"
                          value={this.state.iCategoriaRPR}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una categoria
                          </option>
                          <option value="Todas">Todas</option>
                          <option value="Refacciones">Refacciones</option>
                          <option value="Inmobiliario/Activos">
                            Inmobiliario/Activos
                          </option>
                          <option value="Herramientas">Herramientas</option>
                          <option value="Limpieza">Limpieza</option>
                          <option value="Papelería">Papelería</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-3">
                    {this.state.descargar ? (
                      <PDFDownloadLink
                        className="button is-rounded is-danger"
                        document={
                          <InventarioProductos
                            porteo={this.state.iPorteoRPR.split("/")[1]}
                            categoria={this.state.iCategoriaRPR}
                            data={this.state.data}
                          />
                        }
                        fileName={
                          "InventarioProductos_" +
                          new Date().toLocaleDateString() +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Generando..." : "Descargar"
                        }
                      </PDFDownloadLink>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Generar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
