import $ from 'jquery'
import { viewIcon } from '../utilidades/TableIcons'
import { MODAL_DETALLES_CONSUMO } from './ConstantsConsumo';

export const COLUMNS_CONSUMO_GASOLINA = [
    { title: "TRANSPORTE", field: "placa", width: 150 },
    // { title: "RUTA", field: "ruta", width: 90 },
    { title: "CONSUMO AL MOMENTO (LTRS)", field: "consumo_litros" },
    {
        title: "CONSUMO AL MOMENTO ($)", field: "consumo_dinero", width: 150,
        hozAlign: "center",
        formatter: "money", formatterParams: {
            decimal: ".",
            thousand: ",",
            symbol: "$",
            precision: 2,
        }
    },
    {
        title: "RENDIMIENTO TÉCNICO", field: "rendimiento_tecnico",
        formatter: function (cell, formatterParams) {
            return ("<span>" + cell.getValue() + " KM / LTR</span>")
        }
    },
    {
        title: "RENDIMIENTO REAL", field: "rendimiento_real",
        formatter: function (cell, formatterParams) {
            let rendimientoreal = parseFloat(cell.getValue())
            let rendimientotecnico = parseFloat(cell.getRow().getData().rendimiento_tecnico)
            if (rendimientoreal >= (rendimientotecnico - 1.5) && rendimientoreal <= (rendimientotecnico + 1.5)) {
                return (
                    "<span style='color:green; font-weight: bold; display: inline-block; line-height: .25em; padding: .75em; width: 135px;'>" +
                    (!isNaN(rendimientoreal) ? rendimientoreal.toFixed(2) : 0 )+
                    " KM / LTR</span>"
                );
            } else {
                return (
                    "<span style='color:red; font-weight: bold; display: inline-block; line-height: .25em; padding: .75em; width: 135px;'>" +
                    (!isNaN(rendimientoreal) ? rendimientoreal.toFixed(2) : 0) +
                    " KM / LTR</span>"
                );
            }
        }
    },
    {
        formatter: viewIcon, width: 40, cellClick: function (e, cell) {
            $(`#${MODAL_DETALLES_CONSUMO}`).toggleClass("is-active");
        }
    }
]

export const COLUMNS_CONSUMO_DETALLES = [
    { title: "FECHA", field: "fecha", formatter: (cell) => new Date(cell.getValue()).toLocaleString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })},
    { title: "CHOFER", field: "chofer" },
    {
        title: "IMPORTE", field: "monto_cargado", width: 150,
        hozAlign: "center",
        formatter: "money", formatterParams: {
            decimal: ".",
            thousand: ",",
            symbol: "$",
            precision: 2,
        }
    },
    { title: "CONSUMO LITROS", field: "consumo_litros" },
    { title: "KM INI", field: "kilometraje_inicial" },
    { title: "KM FIN", field: "kilometraje_final" },
    { title: "KM RECORRIDOS", field: "kilometraje_recorrido" },
    { title: "RENDIMIENTO", field: "rendimiento", formatter: (cell) => `${!isNaN(cell.getValue) ? parseFloat(cell.getValue()).toFixed(2) : 0} KM / LTR`},
    { title: "TIPO", field: "tipo" }
]