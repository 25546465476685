export const COLUMNS_PORTEADORES = [
    //{ title: "#", field: "numero" },
    { title: "Porteador", field: "name" },
    { title: "Total de cajas recep.", field: "total", bottomCalc: "sum" },
    { title: "Cajas en tiempo", field: "entregados", bottomCalc: "sum" },
    { title: "%", field: "entregados_porcentaje", bottomCalc: "avg" },
    { title: "GPS", field: "entregados_gps", bottomCalc: "sum" },
    { title: "%", field: "entregados_gps_porcentaje", bottomCalc: "avg" }
];

export const COLUMNS_ENTREGAS = [
    //{ title: "#", field: "numero" },
    {title: 'Folio embarque', field: 'folio_embarque'},
    {title: 'Fecha recepcion', field: 'fecha_recepcion_embarque', formatter: (cell) => new Date(cell.getData().fecha_recepcion_embarque).toLocaleString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })},
    {title: 'Fecha factura', field: 'fecha_factura_embarque', formatter: (cell) => new Date(cell.getData().fecha_factura_embarque).toLocaleString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })},
    {title: 'Folio paquete', field: 'num_guia'},
    {title: 'Fecha', field: 'fecha', formatter: (cell) => new Date(cell.getData().fecha).toLocaleString('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' })},
    {title: 'Estatus', field: 'estatus'},
    {title: 'GPS', field: 'gps'},
    {title: 'Porteador', field: 'name'}
];