import React from "react";
import $ from "jquery";
import { alertWarning } from "../utilidades/Alertas";
import { MODAL_ADD_TRABAJADOR } from "./ConstantsPorteadores";
import { Service } from "../../services/Service";
import { PROVEEDORES } from "../../services/Config";

export class FormAddTrabajador extends React.Component {
  state = {
    iNomT: "",
    iAPT: "",
    iAMT: "",
    iCelT: "",
    iTelT: "",
    iCor: "",
    iEst: "",
    iCiu: "",
    iCal: "",
    iCP: "",
    iNomEmergencia: "",
    iNumEmergencia: "",
    iNSS: "",
    iFI: "",
    iTS: "",
    iNE: "",
    iPue: "",
    iFN: "",
    iFV: "",
    iAct: true,
    iFoto: "",
    url: "",
    estados: "",
    ciudades: "",
    iParentesco: "",
    iGradoEstudios: "",
    iEdoCivil: "",
    iTalla: "",
    iBanco: "",
    iNumCuenta: "",
    iNumTarjeta: "",
    iVitalicia: false,
    iAplicaLicencia: false,
  };

  handleClick = (e) => {
    $(`#${MODAL_ADD_TRABAJADOR}`).toggleClass("is-active");
    this.limpiarC();
  };

  handleInputChange = (e) => {
    if (e.target.name === "iEst") {
      this.getCiudades(e.target.value);
    }
    if (e.target.name === "iAct" || e.target.name === "iVitalicia" || e.target.name === "iAplicaLicencia") {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  //Limpiar inputs
  limpiarC = () => {
    $("#filename").text("Sin imagen");
    var elements = document.querySelectorAll(
      "input[type='text'],input[type='file'],input[type='number'],select,input[type='checkbox'"
    );
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].name === "iVitalicia" || elements[i].name === "iAplicaLicencia") {
        elements[i].checked = false;
      }
      elements[i].value = "";
    }
    this.setState({
      iNomT: "",
      iAPT: "",
      iAMT: "",
      iCelT: "",
      iTelT: "",
      iCor: "",
      iEst: "",
      iCiu: "",
      iCal: "",
      iCP: "",
      iNomEmergencia: "",
      iNumEmergencia: "",
      iNSS: "",
      iFI: "",
      iTS: "",
      iNE: "",
      iPue: "",
      iFN: "",
      iFV: null,
      iAct: true,
      iFoto: "",
      url: "",
      iParentesco: "",
      iGradoEstudios: "",
      iEdoCivil: "",
      iTalla: "",
      iBanco: "",
      iNumCuenta: "",
      iNumTarjeta: "",
      iVitalicia: false,
    });
  };

  _inputImg = (e) => {
    $("#filename").text(e.target.files[0].name);
    this.setState({
      iFoto: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleSubmmit = (e) => {
    e.preventDefault();
    const {
      iNomT,
      iAPT,
      iAMT,
      iCelT,
      iTelT,
      iCor,
      iEst,
      iCiu,
      iCal,
      iCP,
      iNomEmergencia,
      iNumEmergencia,
      iNSS,
      iFI,
      iTS,
      iNE,
      iPue,
      iFN,
      iFV,
      iAct,
      iFoto,
      iParentesco,
      iGradoEstudios,
      iEdoCivil,
      iTalla,
      iBanco,
      iNumCuenta,
      iNumTarjeta,
      iVitalicia,
      iAplicaLicencia
    } = this.state;
    if (
      iNomT &&
      iAPT &&
      iAMT &&
      iCelT &&
      iTelT &&
      iCor &&
      iEst &&
      iCiu &&
      iCal &&
      iCP &&
      iNomEmergencia &&
      iNumEmergencia &&
      iNSS &&
      iFI &&
      iTS &&
      iNE &&
      iPue &&
      iFN &&
      iFoto &&
      iParentesco &&
      iGradoEstudios &&
      iEdoCivil &&
      iTalla &&
      iBanco &&
      iNumCuenta &&
      iNumTarjeta
    ) {
      var formData = new FormData();
      formData.append("porteo", sessionStorage.getItem("porteo"));
      formData.append("iNomT", iNomT);
      formData.append("iAPT", iAPT);
      formData.append("iAMT", iAMT);
      formData.append("iCelT", iCelT);
      formData.append("iTelT", iTelT);
      formData.append("iCor", iCor);
      formData.append("iEst", iEst);
      formData.append("iCiu", iCiu);
      formData.append("iCal", iCal);
      formData.append("iCP", iCP);
      formData.append("iNomEmergencia", iNomEmergencia);
      formData.append("iNumEmergencia", iNumEmergencia);
      formData.append("iNSS", iNSS);
      formData.append("iFI", iFI);
      formData.append("iTS", iTS);
      formData.append("iNE", iNE);
      formData.append("iPue", iPue);
      formData.append("iFN", iFN);
      formData.append("iFV", (iVitalicia === true || !iAplicaLicencia) ? null : iFV);
      formData.append("iVitalicia", (iAplicaLicencia) ? iVitalicia : false);
      formData.append("iAct", iAct);
      formData.append("file", iFoto);
      formData.append("iParentesco", iParentesco);
      formData.append("iGradoEstudios", iGradoEstudios);
      formData.append("iEdoCivil", iEdoCivil);
      formData.append("iTalla", iTalla);
      formData.append("iBanco", iBanco);
      formData.append("iNumCuenta", iNumCuenta);
      formData.append("iNumTarjeta", iNumTarjeta);
      formData.append("iAplicaLicencia", iAplicaLicencia);
      this.props.add(formData);
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  //Añadir estados
  getEstados = async () => {
    let result = await Service.getRawResponse( PROVEEDORES + `/estados`);
    if (result.status === 200) this.setState({ estados: await result.json() });
    else this.setState({ estados: [] });
  };

  //Añadir ciudades
  getCiudades = async (estado) => {
    let result = await Service.getRawResponse( PROVEEDORES + `/ciudades?estado=${estado}`);
    if (result.status === 200) this.setState({ ciudades: await result.json() });
    else this.setState({ ciudades: [] });
  };

  componentDidMount() {
    this.getEstados();
  }

  render() {
    return (
      <div id={MODAL_ADD_TRABAJADOR} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Nuevo Trabajador</p>
            <button
              title="Cerrar"
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form id="formularioAdd" encType="multipart/form-data">
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos personales
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNomT">
                      Nombre
                    </label>
                    <input
                      id="iNomT"
                      name="iNomT"
                      value={this.state.iNomT}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Nombre(s)"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iAPT">
                      Apellido Paterno
                    </label>
                    <input
                      id="iAPT"
                      name="iAPT"
                      value={this.state.iAPT}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Apellido paterno"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iAMT">
                      Apellido Materno
                    </label>
                    <input
                      id="iAMT"
                      name="iAMT"
                      value={this.state.iAMT}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Apellido materno"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iCelT">
                      Número de celular
                    </label>
                    <input
                      id="iCelT"
                      name="iCelT"
                      value={this.state.iCelT}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Número de celular"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iTelT">
                      Número de la empresa
                    </label>
                    <input
                      id="iTelT"
                      name="iTelT"
                      value={this.state.iTelT}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Número de teléfono"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCor">
                      Correo electrónico
                    </label>
                    <input
                      id="iCor"
                      name="iCor"
                      value={this.state.iCor}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="email"
                      placeholder="Correo electrónico"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iFN">
                      Fecha de nacimiento
                    </label>
                    <input
                      id="iFN"
                      name="iFN"
                      value={this.state.iFN}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNSS">
                      NSS
                    </label>
                    <input
                      id="iNSS"
                      name="iNSS"
                      value={this.state.iNSS}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="NSS"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iTS">
                      Tipo de sangre
                    </label>
                    <input
                      id="iTS"
                      name="iTS"
                      value={this.state.iTS}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Tipo de sangre"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iEst">
                      Estado
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEst"
                          name="iEst"
                          value={this.state.iEst}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un estado
                          </option>
                          {this.state.estados
                            ? Object.values(this.state.estados).map(
                                (estado) => {
                                  return (
                                    <option
                                      key={estado.estado}
                                      value={estado.estado}
                                    >
                                      {estado.estado}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCiu">
                      Ciudad
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iCiu"
                          name="iCiu"
                          value={this.state.iCiu}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una ciudad
                          </option>
                          {this.state.ciudades
                            ? Object.values(this.state.ciudades).map(
                                (ciudad) => {
                                  return (
                                    <option
                                      key={ciudad.ciudad}
                                      value={ciudad.ciudad}
                                    >
                                      {ciudad.ciudad}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCal">
                      Calle y número
                    </label>
                    <input
                      id="iCal"
                      name="iCal"
                      value={this.state.iCal}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Calle #"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iCP">
                      Código Postal
                    </label>
                    <input
                      id="iCP"
                      name="iCP"
                      value={this.state.iCP}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Código postal"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iGradoEstudios">
                      Max. grado estudios
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iGradoEstudios"
                          name="iGradoEstudios"
                          value={this.state.iGradoEstudios}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Primaria">Primaria</option>
                          <option value="Secundaria">Secundaria</option>
                          <option value="Bachillerato">Bachillerato</option>
                          <option value="Licenciatura">Licenciatura</option>
                          <option value="Maestría">Maestría</option>
                          <option value="Doctorado">Doctorado</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iEdoCivil">
                      Estado civil
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEdoCivil"
                          name="iEdoCivil"
                          value={this.state.iEdoCivil}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Soltero">Soltero</option>
                          <option value="Casado">Casado</option>
                          <option value="Divorciado">Divorciado</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iTalla">
                      Talla de camiseta
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iTalla"
                          name="iTalla"
                          value={this.state.iTalla}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="XS">XS</option>
                          <option value="S">S</option>
                          <option value="M">M</option>
                          <option value="L">L</option>
                          <option value="XL">XL</option>
                          <option value="XXL">XXL</option>
                          <option value="XXXL">XXXL</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-8">
                    <label className="label" htmlFor="file-js-example">
                      Foto personal
                    </label>
                    {this.state.url ? (
                      <figure className="image is-128x128">
                        <img
                          style={{ width: 126, height: 126 }}
                          src={this.state.url}
                          alt={this.state.url}
                        />
                      </figure>
                    ) : (
                      null
                    )}
                    <br />
                    <div
                      id="file-js-example"
                      className="file has-name is-success is-fullwidth"
                    >
                      <label className="file-label">
                        <input
                          onChange={this._inputImg}
                          className="file-input"
                          type="file"
                          name="file"
                          accept="image/*"
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fa fa-file-image-o"></i>
                          </span>
                          <span className="file-label">
                            Seleccionar imagen…
                          </span>
                        </span>
                        <span
                          id="filename"
                          className="file-name"
                          style={{ textAlign: "center" }}
                        >
                          Sin imagen
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Contacto de emergencia
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNomEmergencia">
                      Nombre
                    </label>
                    <input
                      id="iNomEmergencia"
                      name="iNomEmergencia"
                      value={this.state.iNomEmergencia}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Nombre"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNumEmergencia">
                      Número de contacto
                    </label>
                    <input
                      id="iNumEmergencia"
                      name="iNumEmergencia"
                      value={this.state.iNumEmergencia}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Número"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iParentesco">
                      Parentesco
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iParentesco"
                          name="iParentesco"
                          value={this.state.iParentesco}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Padre/Madre">Padre/Madre</option>
                          <option value="Hermano/Hermana">
                            Hermano/Hermana
                          </option>
                          <option value="Esposo/Esposa">Esposo/Esposa</option>
                          <option value="Primo/Prima">Primo/Prima</option>
                          <option value="Tío/Tía">Tío/Tía</option>
                          <option value="Sobrino/Sobrina">
                            Sobrino/Sobrina
                          </option>
                          <option value="Hijo/Hija">Hijo/Hija</option>
                          <option value="Abuelo/Abuela">Abuelo/Abuela</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos de la empresa
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNE">
                      Número de Empleado
                    </label>
                    <input
                      id="iNE"
                      name="iNE"
                      value={this.state.iNE}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="No. Empelado"
                    />
                  </div>
                  <div className="column is-5">
                    <label className="label" htmlFor="iPue">
                      Puesto
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iPue"
                          name="iPue"
                          value={this.state.iPue}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un puesto
                          </option>
                          <option value="Chofer">Chofer</option>
                          <option value="Aux. Admin">Aux. Admin</option>
                          <option value="Intendente">Intendente</option>
                          <option value="Enc. de porteo">Enc. de porteo</option>
                          <option value="Enc. de mantenimiento">
                            Enc. de mantenimiento
                          </option>
                          <option value="Finanzas">Finanzas</option>
                          <option value="Recursos humanos">
                            Recursos humanos
                          </option>
                          <option value="Almacenista">Almacenista</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iFI">
                      Fecha de ingreso
                    </label>
                    <input
                      id="iFI"
                      name="iFI"
                      value={this.state.iFI}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="checkbox">
                      <input
                        name="iAplicaLicencia"
                        id="iAplicaLicencia"
                        value={this.state.iAplicaLicencia}
                        onChange={this.handleInputChange.bind(this)}
                        type="checkbox"
                      />{" "}
                    </label>
                    <label className="subtitle has-text-grey">
                      {" "}Licencia de conducir
                    </label>
                  </div>
                </div>
              </div>

              {this.state.iAplicaLicencia ? (
                <div className="row">
                  <div className="columns is-vcentered">
                    <div className="column is-4">
                      <label className="checkbox">
                        <input
                          name="iVitalicia"
                          id="iVitalicia"
                          value={this.state.iVitalicia}
                          onChange={this.handleInputChange.bind(this)}
                          type="checkbox"
                        />{" "}
                        Licencia vitalicia
                      </label>
                    </div>
                    {this.state.iVitalicia ? (
                      ""
                    ) : (
                      <div className="column is-5">
                        <label className="label" htmlFor="iFV">
                          Vencimiento de licencia
                        </label>
                        <input
                          id="iFV"
                          name="iFV"
                          value={this.state.iFV}
                          onChange={this.handleInputChange.bind(this)}
                          className="input is-rounded is-success"
                          type="date"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Información Bancaria
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iBanco">
                      Banco
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iBanco"
                          name="iBanco"
                          value={this.state.iBanco}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Santander">Santander</option>
                          <option value="Banamex">Banamex</option>
                          <option value="BBVA Bancomer">BBVA Bancomer</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNumCuenta">
                      Número de cuenta
                    </label>
                    <input
                      id="iNumCuenta"
                      name="iNumCuenta"
                      value={this.state.iNumCuenta}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNumTarjeta">
                      Número de tarjeta
                    </label>
                    <input
                      id="iNumTarjeta"
                      name="iNumTarjeta"
                      value={this.state.iNumTarjeta}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
