import React from 'react'

export const Footer = () => (
    <footer className="is-gray-color footer-login">
        <div className='is-gray-color'>
            <div className='columns ' style={{ alignItems: 'center' }}>
                <span className='column is-2 has-text-centered is-gray-color'>
                    <a href='# ' className='bd-footer-link has-text-white'>Aviso de privacidad</a>
                </span>
                <div className='column is-8 has-text-centered ' style={{ height: '100%'}}>
                    <p className='has-text-white'>Todos los derechos reservados <span><i className='fa fa-registered'></i></span> 2020 LOGISTIC EXPRESS DEL SUR S.A. DE C.V.</p>
                </div>
                <div className='column is-2 has-text-centered is-gray-color'>
                    <a href='# ' className='bd-footer-link has-text-white is-gray-color'>Términos de servicio</a>
                </div>
            </div>
        </div>
    </footer>
)

