import { API_URL } from './Config'

export class Service {

    static getAccessToken = () => {
        return sessionStorage.getItem('access_token')
    }

    static post = async (ruta, body) => {
        return await fetch(API_URL + ruta, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
            .then(response => response.json())
            .then(responseJSON => {

                if (responseJSON.status === 403) {
                    sessionStorage.clear()
                }
                if (responseJSON.errno) {
                    return ({ error: responseJSON })
                }

                return (responseJSON)
            })
            .catch(err => {
                console.error(err);
                return ({ error: err })
            });
    }

    static get = async (ruta) => {
        return await fetch(API_URL + ruta, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
            .then(response => response.json())
            .then(responseJSON => {

                if (responseJSON.status === 403) {
                    sessionStorage.clear()
                }

                if (responseJSON.errno) {
                    return ({ error: responseJSON })
                }

                return (responseJSON)
            })
            .catch(error => {
                console.log(error)
                return ({ error: error })
            });
    }

    static postRawResponse = async (ruta, body) => {
        return await fetch(API_URL + ruta, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
    }

    static postSimpleRawResponse = async (ruta, body) => {
        return await fetch(API_URL + ruta, {
            method: 'POST',
            body: body,
            headers: {
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
    }

    static putSimpleRawResponse = async (ruta, body) => {
        return await fetch(API_URL + ruta, {
            method: 'PUT',
            body: body,
            headers: {
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
    }

    static putRawResponse = async (ruta, body) => {
        return await fetch(API_URL + ruta, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
    }

    static getRawResponse = async (ruta) => {
        return await fetch(API_URL + ruta, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
    }

    static deleteRawResponse = async (ruta, body) => {
        return await fetch(API_URL + ruta, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Service.getAccessToken()
            }
        })
    }

}