import React from 'react'

import $ from 'jquery'
import { MODAL_DETALLES_TRABAJADOR } from './ConstantsPorteadores';

export class ModalDetallesTrabajador extends React.Component {

    handleClick(e) {
        $(`#${MODAL_DETALLES_TRABAJADOR}`).toggleClass("is-active");
    }

    render() {
        return (
            <div id={MODAL_DETALLES_TRABAJADOR} className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Detalles del trabajador</p>
                        <button onClick={this.handleClick} className="delete" aria-label="close"></button>
                    </header>

                    {
                        this.props.selectedItem ?
                            <section className="modal-card-body">
                                <div className='row has-text-centered'>
                                    <div className='columns'>
                                        <div className='column'>
                                            {
                                                this.props.selectedItem['activo_trabajador'] ?
                                                    <label className="subtitle has-text-success has-text-weight-bold">Activo</label>
                                                    :
                                                    <label className="subtitle has-text-danger has-text-weight-bold">No activo</label>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="subtitle has-text-grey">Datos personales</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Nombre</label>
                                            <label >{this.props.selectedItem['nombre_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Apellido Paterno</label>
                                            <label >{this.props.selectedItem['apellido_paterno_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Apellido Materno</label>
                                            <label >{this.props.selectedItem['apellido_materno_trabajador']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Número de celular</label>
                                            <label >{this.props.selectedItem['celular_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Número de la empresa</label>
                                            <label >{this.props.selectedItem['telefono_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Correo electrónico</label>
                                            <label >{this.props.selectedItem['correo_trabajador']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Fecha de nacimiento</label>
                                            <label >{this.props.selectedItem['fecha_nacimiento'].split('T')[0]}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">NSS</label>
                                            <label >{this.props.selectedItem['NSS_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Tipo de sangre</label>
                                            <label >{this.props.selectedItem['tipo_sangre']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Estado</label>
                                            <label >{this.props.selectedItem['estado_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Ciudad</label>
                                            <label >{this.props.selectedItem['ciudad_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Domicilio</label>
                                            <label >{this.props.selectedItem['domicilio_trabajador']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Código Postal</label>
                                            <label >{this.props.selectedItem['cp_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Máx. grado estudios</label>
                                            <label >{this.props.selectedItem['estudios']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Estado civil</label>
                                            <label >{this.props.selectedItem['estado_civil']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Talla de camiseta</label>
                                            <label >{this.props.selectedItem['talla_camiseta']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="subtitle has-text-grey">Contacto de emergencia</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Nombre</label>
                                            <label >{this.props.selectedItem['nombre_contacto_emergencia']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Número de contacto</label>
                                            <label >{this.props.selectedItem['numero_contacto_emergencia']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Parentesco</label>
                                            <label >{this.props.selectedItem['parentesco_contacto_emergencia']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="subtitle has-text-grey">Datos de la empresa</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Número de empleado</label>
                                            <label >{this.props.selectedItem['no_trabajador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Puesto</label>
                                            <label >{this.props.selectedItem['puesto']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Fecha de ingreso</label>
                                            <label >{this.props.selectedItem['fecha_ingreso'].split('T')[0]}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="subtitle has-text-grey">Licencia de conducir</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Licencia vitalicia</label>
                                            <label >{this.props.selectedItem['licencia_vitalicia'] === 1 ? 'Si' : 'No'}</label>
                                        </div>
                                        {
                                            this.props.selectedItem['licencia_vitalicia'] === 1 ? null :

                                                <div className='column is-4'>
                                                    <label className="label">Vencimiento de licencia</label>
                                                    <label >{this.props.selectedItem['vencimiento_licencia'] !== null ? this.props.selectedItem['vencimiento_licencia'].split('T')[0] : ''}</label>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="subtitle has-text-grey">Información bancaria</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label">Banco</label>
                                            <label >{this.props.selectedItem['banco']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Número de cuenta</label>
                                            <label >{this.props.selectedItem['no_cuenta']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label">Número de tarjeta</label>
                                            <label >{this.props.selectedItem['no_tarjeta']}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column'>
                                            <label className="subtitle has-text-grey">Foto personal</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="card-image">
                                        <figure className="image is-4by5">
                                            <img style={{ width: 256, height: 256 }} src={this.props.selectedItem['foto_trabajador']} alt={this.props.selectedItem['foto_trabajador']} />
                                        </figure>
                                    </div>
                                </div>
                            </section>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}