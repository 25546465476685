import React from "react";

import $ from "jquery";

export class DeleteNotificacion extends React.Component {
  handleClick(e) {
    $("#deletenotificacion").toggleClass("is-active");
  }

  handleSubmmit = (e) => {
    this.props.remove();
    this.handleClick();
  }

  render() {
    return (
      <div id="deletenotificacion" className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Confirmación</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="content">
              <p>
                <label className="label">
                  ¿Esta seguro de eliminar esta notificación?
                </label>
              </p>
              <p id="deletedata"></p>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button 
            onClick={this.handleSubmmit} 
            className="button is-rounded is-danger"
            >
              Eliminar
              </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-success"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
