import React from 'react'

import $ from 'jquery'
import { MODAL_DETALLES_MANTENIMIENTO } from './ConstantsMantenimiento';

export class ModalDetallesMantenimiento extends React.Component {

    handleClick = (e) => {
        $(`#${MODAL_DETALLES_MANTENIMIENTO}`).toggleClass("is-active");
    }

    render() {
        return (
            <div id={MODAL_DETALLES_MANTENIMIENTO} className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Detalles de Mantenimiento</p>
                        <button onClick={this.handleClick} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                            <div className=''>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='fecha'>Fecha</label>
                                            <label>
                                                {
                                                    this.props.selectedItem['fecha_mantenimiento'] !== undefined ?
                                                        this.props.selectedItem['fecha_mantenimiento'].split('T')[0]
                                                        :
                                                        null
                                                }
                                            </label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='operador'>Operador</label>
                                            <label>{this.props.selectedItem['operador']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='proveedor'>Proveedor</label>
                                            <label>{this.props.selectedItem['proveedor']}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='columns '>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='manoobra'>Mano de obra</label>
                                            <label>{'$'+this.props.selectedItem['costo_mano_obra_mantenimiento']}</label>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='taller'>Taller</label>
                                            <label>{this.props.selectedItem['taller']}</label>
                                        </div>
                                        <div className='column is-4 has-text-success'>
                                            <label className="label" htmlFor='total'>Total</label>
                                            <label>{'$'+this.props.selectedItem['total_mantenimiento']}</label>
                                        </div>

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='columns '>
                                        <div className='column is-6'>
                                            <label className="label" htmlFor='servicios'>Servicios</label>
                                            <textarea className='textarea' rows='7' readOnly value={this.props.getServicios(this.props.selectedItem['servicios'])}>
                                            </textarea>
                                        </div>
                                        <div className='column is-6'>
                                            <label className="label" htmlFor='refacciones'>Refacciones</label>
                                            <textarea className='textarea' rows='7' readOnly value={this.props.getRefacciones(this.props.selectedItem['refacciones'])}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button onClick={this.handleClick} className="button is-rounded is-warning" >Cerrar</button>
                    </footer>
                </div>
            </div>
        )
    }
}