import React from 'react'

import RutaSVG from '../../../icons/ruta.svg'
import ChoferSVG from '../../../icons/chofer.svg'
import TransporteSVG from '../../../icons/transporte.svg'
import FechaSVG from '../../../icons/fecha.svg'
import KmSVG from '../../../icons/km.svg'
import GaasolinaSVG from '../../../icons/gas.svg'
import TiempoSVG from '../../../icons/tiempo.svg'
import PedidosSVG from '../../../icons/pedidos.svg'

export const OverviewComponent = ({ ruta, chofer, transporte, fecha, km, gasolina, tiempoRecorrido, pedidos }) => (
    <div className="">
        <div className="row " style={{ display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
            <div className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={RutaSVG} />
                <span className="overview-item">Ruta</span>
                <span className="overview-item has-text-weight-bold">{ruta}</span>
            </div>
            <div className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={ChoferSVG} />
                <span className="overview-item">Chofer</span>
                <span className="overview-item has-text-weight-bold" >{chofer}</span>
            </div>
            <div className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={TransporteSVG} />
                <span className="overview-item">Transporte</span>
                <span className="overview-item has-text-weight-bold">{transporte}</span>
            </div>
            <div className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={FechaSVG} />
                <span className="overview-item">Fecha</span>
                <span className="overview-item has-text-weight-bold">{fecha}</span>
            </div>
        </div>
        <br/>
        <div className="row " style={{ display: "flex", flexDirection: "row"}}>
            <span className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={KmSVG} />
                <span className="overview-item">KM recorridos</span>
                <span className="overview-item has-text-weight-bold">{km}</span>
            </span>
            <span className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={GaasolinaSVG} />
                <span className="overview-item">Gasolina</span>
                <span className="overview-item has-text-weight-bold">{gasolina}</span>
            </span>
            <span className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={TiempoSVG} />
                <span className="overview-item">Tiempo recorrido</span>
                <span className="overview-item has-text-weight-bold">{tiempoRecorrido}</span>
            </span>
            <span className="overview-container" style={{ width: "25%"}}>
                <img alt="" className="overview-item" src={PedidosSVG} />
                <span className="overview-item">Pedidos/Hora</span>
                <span className="overview-item has-text-weight-bold">{pedidos}</span>
            </span>
        </div>
    </div>
)