// React
import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

// Components
import Login from './pages/Login'
import { NotFound } from './pages/NotFound'
import Home from './pages/privadas/Home'
import HomePage from './pages/landing-page/HomePage'
import Contacto from './pages/landing-page/Contacto'
import Conocenos from './pages/landing-page/Conocenos'
import MainPage from './pages/MainPage'
import LoadingPage from './components/utilidades/Loading'

// CSS
import './css/App.css'
import 'bulma/css/bulma.css'
import PrivateRoute from './pages/PrivateRoute';
import 'bulma-badge/dist/css/bulma-badge.min.css'
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css'
import '@creativebulma/bulma-tagsinput/dist/css/bulma-tagsinput.min.css'
import store from './redux/Store';

class App extends React.Component {
  render() {
    console.log(process.env.REACT_APP_API_URL)
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path='/home' component={Home} />
            <Route exact path='/login' component={Login} />
            <MainPage >
              <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/nosotros' component={Conocenos} />
                <Route exact path='/contacto' component={Contacto} />
                <Route component={NotFound} />
              </Switch>
            </MainPage>
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
        <LoadingPage />
      </Provider>
    );
  }
}

export default App;
