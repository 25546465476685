import { editIcon, deleteIcon } from "../utilidades/TableIcons";
import { MODAL_MODIFICAR_PORTEO } from "./ConstantsConfiguraciones";
import { MODAL_MODIFICAR_SERVICIO } from "./ConstantsConfiguraciones";
import { MODAL_BORRAR_SERVICIO } from "./ConstantsConfiguraciones";
import $ from "jquery";

export const COLUMNS_PORTEOS = [
  { title: "Id", field: "Id_porteo" },
  { title: "Nombre", field: "nombre" },
  {
    formatter: editIcon,
    width: 40,
    cellClick: function (e, cell) {
      $(`#${MODAL_MODIFICAR_PORTEO}`).toggleClass("is-active");
    },
  },
  //Por seguridad se recomienda solo eliminar porteos desde base de datos y no desde sistema
  // {
  //   formatter: deleteIcon,
  //   width: 40,
  //   headerSort: false,
  //   cellClick: function (e, cell) {
  //     $(`#${MODAL_BORRAR_USUARIO}`).toggleClass("is-active");
  //     $("#deletedata").text(cell.getRow().getData().nombre);
  //   },
  // },
];

export const COLUMNS_SERVICIOS = [
  { title: "Id", field: "Id_servicio" },
  { title: "Nombre", field: "nombre_servicio" },
  {
    formatter: editIcon,
    width: 40,
    cellClick: function (e, cell) {
      $(`#${MODAL_MODIFICAR_SERVICIO}`).toggleClass("is-active");
    },
  },
  //Por seguridad se recomienda solo eliminar servicios desde base de datos y no desde sistema
  {
    formatter: deleteIcon,
    width: 40,
    headerSort: false,
    cellClick: function (e, cell) {
      $(`#${MODAL_BORRAR_SERVICIO}`).toggleClass("is-active");
      $("#deletedata").text(cell.getRow().getData().nombre_servicio);
    },
  },
];
