import React from "react";
import $ from "jquery";
import { alertWarning } from "../utilidades/Alertas";
import { MODAL_ADD_TRANSPORTE } from "./ConstantsTransportes";
import { Service } from "../../services/Service";
import { PROVEEDORES } from "../../services/Config";

export class FormAddTransporte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iPlaca: "",
      iNoEco: "",
      iTipoUnidad: "",
      iNoSerie: "",
      iModelo: "",
      iColor: "",
      iMarca: "",
      iSubmarca: "",
      iCamper: "",
      iRefrendo: "",
      iEstadoR: "",
      iEmpresa: "",
      iPoliza: "",
      iPolizaVencimiento: "",
      iStatusF: "",
      iStatusFVencimiento: null,
      iKm: "",
      iNivelGasolina: "",
      iCapacidadGasolina: "",
      iRendimientoTecnico: "",
    };
  }

  handleClick = (e) => {
    $(`#${MODAL_ADD_TRANSPORTE}`).toggleClass("is-active");
    this.limpiarC();
  };

  handleInputChange = (e) => {
    if (e.target.name === "iStatusF")
      this.setState({ iStatusFVencimiento: null });
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='text'],input[type='file'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iPlaca: "",
      iNoEco: "",
      iTipoUnidad: "",
      iNoSerie: "",
      iModelo: "",
      iColor: "",
      iMarca: "",
      iSubmarca: "",
      iCamper: "",
      iRefrendo: "",
      iEstadoR: "",
      iEmpresa: "",
      iPoliza: "",
      iPolizaVencimiento: "",
      iStatusF: "",
      iStatusFVencimiento: null,
      iKm: "",
      iNivelGasolina: "",
      iCapacidadGasolina: "",
      iRendimientoTecnico: "",
    });
  };

  // backend
  handleSubmmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    let {
      iPlaca,
      iNoEco,
      iTipoUnidad,
      iNoSerie,
      iModelo,
      iColor,
      iMarca,
      iSubmarca,
      iCamper,
      iRefrendo,
      iEstadoR,
      iEmpresa,
      iPoliza,
      iPolizaVencimiento,
      iStatusF,
      iStatusFVencimiento,
      iKm,
      iNivelGasolina,
      iCapacidadGasolina,
      iRendimientoTecnico,
    } = this.state;
    if (
      iPlaca &&
      iNoEco &&
      iTipoUnidad &&
      iNoSerie &&
      iModelo &&
      iColor &&
      iMarca &&
      iSubmarca &&
      iCamper &&
      iRefrendo &&
      iEstadoR &&
      iEmpresa &&
      iPoliza &&
      iPolizaVencimiento &&
      iStatusF &&
      iKm &&
      iNivelGasolina &&
      iCapacidadGasolina &&
      iRendimientoTecnico
    ) {
      let formData = new FormData();
      formData.append("porteo", sessionStorage.getItem("porteo"));
      formData.append("iPlaca", iPlaca);
      formData.append("iNoEco", iNoEco);
      formData.append("iNoSerie", iNoSerie);
      formData.append("iTipoUnidad", iTipoUnidad);
      formData.append("iModelo", iModelo);
      formData.append("iColor", iColor);
      formData.append("iMarca", iMarca);
      formData.append("iSubmarca", iSubmarca);
      formData.append("iCamper", iCamper);
      formData.append("iStatusF", iStatusF);
      formData.append("iEmpresa", iEmpresa);
      formData.append("iPoliza", iPoliza);
      formData.append("iPolizaVencimiento", iPolizaVencimiento);
      formData.append("iEstadoR", iEstadoR);
      formData.append("iRefrendo", iRefrendo);
      formData.append("iStatusFVencimiento", iStatusFVencimiento);
      formData.append("iKm", iKm);
      formData.append("iNivelGasolina", iNivelGasolina);
      formData.append("iCapacidadGasolina", iCapacidadGasolina);
      formData.append("iRendimientoTecnico", iRendimientoTecnico);
      this.props.add(formData);
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  //Añadir estados
  getEstados = async () => {
    let result = await Service.getRawResponse(PROVEEDORES + "/estados");
    if (result.status === 200) {
      let estados = await result.json();
      this.setState({ estados: estados });
    } else {
      this.setState({ estados: [] });
    }
  };

  componentDidMount() {
    this.getEstados();
  }

  render() {
    return (
      <div id={MODAL_ADD_TRANSPORTE} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Nuevo Transporte</p>
            <button
              title="Cerrar"
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form id="formularioAdd" encType="multipart/form-data">
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos particulares
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iPlaca">
                      Placa
                    </label>
                    <input
                      id="iPlaca"
                      name="iPlaca"
                      value={this.state.iPlaca}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Placa"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNoEco">
                      No. Económico
                    </label>
                    <input
                      id="iNoEco"
                      name="iNoEco"
                      value={this.state.iNoEco}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="No. Económico"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iTipoUnidad">
                      Tipo de unidad
                    </label>
                    <input
                      id="iTipoUnidad"
                      name="iTipoUnidad"
                      value={this.state.iTipoUnidad}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Tipo de unidad"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNoSerie">
                      No. Serie
                    </label>
                    <input
                      id="iNoSerie"
                      name="iNoSerie"
                      value={this.state.iNoSerie}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="No. Serie"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iModelo">
                      Modelo
                    </label>
                    <input
                      id="iModelo"
                      name="iModelo"
                      value={this.state.iModelo}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Modelo"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iColor">
                      Color
                    </label>
                    <input
                      id="iColor"
                      name="iColor"
                      value={this.state.iColor}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Color"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iMarca">
                      Marca
                    </label>
                    <input
                      id="iMarca"
                      name="iMarca"
                      value={this.state.iMarca}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Marca"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iSubmarca">
                      Submarca
                    </label>
                    <input
                      id="iSubmarca"
                      name="iSubmarca"
                      value={this.state.iSubmarca}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Submarca"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCamper">
                      Camper
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iCamper"
                          name="iCamper"
                          value={this.state.iCamper}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una opción
                          </option>
                          <option value={1}>Si</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iEstadoR">
                      Estado de registro
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEstadoR"
                          name="iEstadoR"
                          value={this.state.iEstadoR}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un estado
                          </option>
                          {this.state.estados
                            ? Object.values(this.state.estados).map(
                                (estado) => {
                                  return (
                                    <option
                                      key={estado.estado}
                                      value={estado.estado}
                                    >
                                      {estado.estado}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-8">
                    <label className="label" htmlFor="iRefrendo">
                      Ubicación de refrendos (URL)
                    </label>
                    <input
                      id="iRefrendo"
                      name="iRefrendo"
                      value={this.state.iRefrendo}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="URL"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iKm">
                      Kilometraje
                    </label>
                    <input
                      id="iKm"
                      name="iKm"
                      value={this.state.iKm}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Kilometraje"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNivelGasolina">
                      Nivel de gasolina
                    </label>
                    <input
                      id="iNivelGasolina"
                      name="iNivelGasolina"
                      value={this.state.iNivelGasolina}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Nivel"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCapacidadGasolina">
                      Capacidad del tanque (litros)
                    </label>
                    <input
                      id="iCapacidadGasolina"
                      name="iCapacidadGasolina"
                      value={this.state.iCapacidadGasolina}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Litros"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iRendimientoTecnico">
                      Rendimiento técnico (km/litro)
                    </label>
                    <input
                      id="iRendimientoTecnico"
                      name="iRendimientoTecnico"
                      value={this.state.iRendimientoTecnico}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="km/litro"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos de la Póliza
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iEmpresa">
                      Empresa del seguro
                    </label>
                    <input
                      id="iEmpresa"
                      name="iEmpresa"
                      value={this.state.iEmpresa}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Empresa de seguro"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iPoliza">
                      Póliza de seguro
                    </label>
                    <input
                      id="iPoliza"
                      name="iPoliza"
                      value={this.state.iPoliza}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Póliza de seguro"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iPolizaVencimiento">
                      Fecha de vencimiento
                    </label>

                    <input
                      id="iPolizaVencimiento"
                      name="iPolizaVencimiento"
                      value={this.state.iPolizaVencimiento}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Estatus Financiero
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iStatusF">
                      Estatus financiero
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iStatusF"
                          name="iStatusF"
                          value={this.state.iStatusF}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una opción
                          </option>
                          <option value="Pagado">Pagado</option>
                          <option value="Credito">A crédito</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.iStatusF === "Credito" ? (
                    <div className="column is-4">
                      <label className="label" htmlFor="iStatusFVencimiento">
                        Fecha de vencimiento
                      </label>

                      <input
                        id="iStatusFVencimiento"
                        name="iStatusFVencimiento"
                        value={this.state.iStatusFVencimiento}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="date"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
