import React from "react";
import $ from "jquery";
import { MODAL_DETALLES_EMBARQUE } from "./ConstantsEmbarques";
import { Table } from "../../utilidades/Table";

let columns = [
  //     { title: '#', field: 'id', width: 60 },
  //     { title: "Número de guia", field: "numero_guia" },
  //     { title: "Cajas", field: "cajas" },
  //     { title: "Tipo de paquete", field: "tipo_paquete" }

  // {title:'Id_embarque',field:'Id_embarque'},
  // {title:'Id_paquete',field:'Id_paquete'},
  { title: "No. Guia", field: "num_guia" },
  { title: "Estatus", field: "estatus_actual" },
  { title: "Tipo", field: "tipo_paquete" },
  { title: "Nombre Cliente", field: "nombre_cliente" },
  { title: "Domicilio", field: "domicilio_entrega" },
  { title: "Cajas", field: "cajas" },
  { title: "Ruta", field: "ruta" },
  { title: "Destino", field: "destino" },
  { title: "Folio", field: "folio" },
  { title: "Factura", field: "factura" },
  {
    title: "GPS",
    field: "gps",
    formatter: (cell, formatterParams) => {
      var value = cell.getValue();
      // eslint-disable-next-line eqeqeq
      return value == 1
        ? `<i class="fa fa-check has-text-success"></i>`
        : `<span class="has-text-danger">X</span>`;
    },
  },
  { title: "Ciudad", field: "ciudad" },
  { title: "Peso", field: "peso" },
];

export class ModalDetallesEmbarque extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: null,
      paquetes: [
        { id: 1, numero_guia: "123333", cajas: "3444", tipo_paquete: "pedido" },
      ],
      iFolioEmbarque: "folio",
      iFechaFactura: "01/01/2022",
      iFechaRecepcion: "01/02/2022",
    };
  }

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  getTable = (table) => {
    if (table !== null) {
      console.log(this.props.paquetes);
      table.setData(this.props.paquetes);
    }
  };

  handleClick = (e) => {
    $(`#${MODAL_DETALLES_EMBARQUE}`).toggleClass("is-active");
  };

  componentDidMount() {}

  render() {
    return (
      <div id={MODAL_DETALLES_EMBARQUE} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card modal-lg">
          <header className="modal-card-head">
            <p className="modal-card-title">Detalle del embarque</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iFolioEmbarque">
                      Folio de embarque
                    </label>
                    <input
                      id="iFolioEmbarque"
                      name="iFolioEmbarque"
                      value={
                        this.props.embarque
                          ? this.props.embarque.folio_embarque
                          : null
                      }
                      className="input is-rounded is-success"
                      type="text"
                      readOnly
                      placeholder="125489789869"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iFechaRecepcion">
                      Fecha recepción
                    </label>
                    <input
                      id="iFechaRecepcion"
                      name="iFechaRecepcion"
                      value={
                        this.props.embarque
                          ? new Date(
                              this.props.embarque.fecha_recepcion_embarque
                            ).toLocaleDateString("es-MX")
                          : null
                      }
                      className="input is-rounded is-success"
                      type="text"
                      readOnly
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iFechaFactura">
                      Fecha factura
                    </label>
                    <input
                      id="iFechaFactura"
                      name="iFechaFactura"
                      value={
                        this.props.embarque
                          ? new Date(
                              this.props.embarque.fecha_factura_embarque
                            ).toLocaleDateString("es-MX")
                          : null
                      }
                      className="input is-rounded is-success"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle">Lista de paquetes</label>
                  </div>
                  <div className="column">
                    <span className="has-text-bold has-text-right">
                      Total de paquetes: {this.props.paquetes.length}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <Table
                      columns={columns}
                      setItem={this.setItem}
                      getTable={this.getTable}
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cerrar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
