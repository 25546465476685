import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

import $ from "jquery";
import { Table } from "../utilidades/Table";
import { editIcon, deleteIcon } from "../utilidades/TableIcons";
import Tabla from "./Tabla";
import { COLUMNS_PORTEOS, COLUMNS_SERVICIOS } from "./ColumnsConfiguraciones";
import { SelectOption } from "../common/SelectOption";
import { InputSearch } from "../common/InputSearch";
import { clearSession, formatPorteoData } from "../utilidades/DataHelpers";
import {
  MODAL_ADD_PORTEO,
  MODAL_ADD_SERVICIO,
} from "./ConstantsConfiguraciones";
// import { MODAL_BORRAR_USUARIO } from "./ConstantsConfiguraciones";

import { ButtonRounded } from "../common/ButtonRounded";
import { FormAddPorteo } from "./FormAddPorteo";
import { FormModificarPorteo } from "./FormModificarPorteo";
import { FormAddServicio } from "./FormAddServicio";
import { FormModificarServicio } from "./FormModificarServicio";
import { FormDeleteServicio } from "./FormDeleteServicio";
import { alertSuccess, alertError } from "../utilidades/Alertas";
import { Service } from "../../services/Service";

// define columns for transportes table

class Configuraciones extends React.Component {
  state = {
    data: [],
    flag: true,
    table: null,
    inputPorteo: "",
    activeItem: "porteos",
    datosPorteos: [],
    columnasPorteos: COLUMNS_PORTEOS,
    datosServicios: [],
    columnasServicios: COLUMNS_SERVICIOS,
    registroSeleccionado: "",
  };
  //Elemento seleccionado de la tabla
  manejarRegistroSeleccionado = (registro) => {
    this.setState({ registroSeleccionado: registro });
    // alert(registro.nombre);
  };

  handleInputPorteo = (e) => {
    this.setState({ inputPorteo: e.target.value });
  };

  // getTable = (table) => {
  //   if (table !== null) {
  //     table.setData(this.state.data);
  //     table.setFilter([
  //       {
  //         field: "nickname",
  //         type: "like",
  //         value: this.state.inputUsuario,
  //       },
  //       {
  //         field: "Id_porteo",
  //         type: "like",
  //         value: this.state.inputPorteo.split("/")[0],
  //       },
  //     ]);
  //   }
  // };

  modaladdPorteo(e) {
    $(`#${MODAL_ADD_PORTEO}`).toggleClass("is-active");
  }
  modaladdServicio(e) {
    $(`#${MODAL_ADD_SERVICIO}`).toggleClass("is-active");
  }

  getPorteos = async () => {
    if (sessionStorage.getItem("rol") === "admin") {
      let result = await Service.getRawResponse(
        `/Configuraciones/getPorteos?por=0`
      );
      if (result.status === 200) {
        this.setState({ datosPorteos: await result.json() });
      } else if (result.status === 403) {
        clearSession();
      } else {
        this.setState({ datosPorteos: [] });
      }
      this.props.setLoading(false);
    }
  };
  getServicios = async () => {
    if (sessionStorage.getItem("rol") === "admin") {
      let result = await Service.getRawResponse(
        `/Configuraciones/getServicios`
      );
      if (result.status === 200) {
        this.setState({ datosServicios: await result.json() });
      } else if (result.status === 403) {
        clearSession();
      } else {
        this.setState({ datosServicios: [] });
      }
      this.props.setLoading(false);
    }
  };
  //Añadir usuario
  addPorteo = async (data) => {
    let res = await Service.postRawResponse("/Configuraciones", data);
    if (res.status === 201) {
      this.props.setLoading(true);
      alertSuccess("Porteo registrado", 4000, "center");
      this.getPorteos();
    } else {
      alertError("Error", 4000, "center");
      console.log(res);
    }
  };
  //Añadir servicio
  addServicio = async (data) => {
    let res = await Service.postRawResponse("/Configuraciones/servicio", data);
    if (res.status === 201) {
      this.props.setLoading(true);
      alertSuccess("Servicio de mantenimiento registrado", 4000, "center");
      this.getServicios();
    } else {
      alertError("Error", 4000, "center");
      console.log(res);
    }
  };

  //Modificar un proveedor
  updatePorteo = async (data) => {
    let res = await Service.putRawResponse("/Configuraciones", data);
    if (res.status === 200) {
      this.props.setLoading(true);
      alertSuccess("Porteo modificado", 4000, "center");
      this.getPorteos();
    } else {
      alertError("Error", 4000, "center");
      console.log(res);
    }
  };
  //Modificar un servicio de mantenimiento
  updateServicio = async (data) => {
    let res = await Service.putRawResponse("/Configuraciones/servicio", data);
    if (res.status === 200) {
      this.props.setLoading(true);
      alertSuccess("Servicio modificado", 4000, "center");
      this.getServicios();
    } else {
      alertError("Error", 4000, "center");
      console.log(res);
    }
  };
  // //Eliminar un proveedor
  remove = async () => {
    const servicio = { id: this.state.registroSeleccionado.Id_servicio };
    const res = await Service.deleteRawResponse(
      "/Configuraciones/servicio",
      servicio
    );
    if (res.status === 200) {
      alertSuccess("Servicio eliminado", 4000, "center");
      this.getServicios();
    } else {
      alertError("Error", 4000, "center");
      console.log(res);
    }
  };

  turnOn = () => {
    this.setState({ flag: true });
  };

  turnOff = () => {
    this.setState({ flag: false });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.getPorteos();
    this.getServicios();
  }

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="column is-3">
            <label className="title is-5">
              Configuraciones generales del admin
            </label>
          </div>
        </div>

        <br />
        <div className="row">
          <div className="tabs">
            <ul>
              <li
                onClick={() => this.setState({ activeItem: "porteos" })}
                className={
                  this.state.activeItem === "porteos"
                    ? "active-chido"
                    : "btns-estatus-piso"
                }
              >
                Porteos
              </li>

              <li
                onClick={() => this.setState({ activeItem: "servicios" })}
                className={
                  this.state.activeItem === "servicios"
                    ? "active-chido"
                    : "btns-estatus-piso"
                }
              >
                Servicios de Mantenimiento
              </li>
            </ul>
          </div>
        </div>
        <div className="row mt-5">
          {this.state.activeItem === "porteos" ? (
            <div className="columns">
              <div className="column is-3">
                <label className="title is-5">Porteos</label>
              </div>
              <div className="column is-offset-8">
                <ButtonRounded
                  onClick={this.modaladdPorteo}
                  tooltip="Nuevo porteo"
                  icon="plus"
                  classColor="is-success"
                />
              </div>
            </div>
          ) : (
            <div className="columns">
              <div className="column is-3">
                <label className="title is-5">Servicios de mantenimiento</label>
              </div>
              <div className="column is-offset-8">
                <ButtonRounded
                  onClick={this.modaladdServicio}
                  tooltip="Nuevo servicio"
                  icon="plus"
                  classColor="is-success"
                />
              </div>
            </div>
          )}
        </div>
        <br />
        <div className="row">
          {this.state.activeItem === "porteos" ? (
            <div>
              <Tabla
                data={this.state.datosPorteos}
                columnas={this.state.columnasPorteos}
                registroSeleccionado={this.state.registroSeleccionado}
                onRegistroSeleccionado={this.manejarRegistroSeleccionado}
              />
              <FormAddPorteo
                userInfo={this.props.userInfo}
                addPorteo={this.addPorteo}
              />
              <FormModificarPorteo
                userInfo={this.props.userInfo}
                mod={this.updatePorteo}
                selectedItem={this.state.registroSeleccionado}
                turnOn={this.turnOn}
                turnOff={this.turnOff}
                flag={this.state.flag}
              />
            </div>
          ) : (
            <div>
              <Tabla
                data={this.state.datosServicios}
                columnas={this.state.columnasServicios}
                registroSeleccionado={this.state.registroSeleccionado}
                onRegistroSeleccionado={this.manejarRegistroSeleccionado}
              />
              <FormAddServicio
                userInfo={this.props.userInfo}
                addPorteo={this.addServicio}
              />
              <FormModificarServicio
                userInfo={this.props.userInfo}
                mod={this.updateServicio}
                selectedItem={this.state.registroSeleccionado}
                turnOn={this.turnOn}
                turnOff={this.turnOff}
                flag={this.state.flag}
              />
              <FormDeleteServicio remove={this.remove} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuraciones);
