import $ from 'jquery'
import { MODAL_AGREGAR_ESTATUS, MODAL_DETALLES_ENVIO } from './ConstantsSeguimientoEntregas';
import { viewIcon, addIcon } from '../utilidades/TableIcons'
import { ROLES } from '../utilidades/Constantes';

let COLUMNS_SEGUIMIENTO_ENTREGAS = [
    {
        title: "NÚMERO DE GUIA",
        field: "Num_guia"
    },
    {
        title: "ESTATUS",
        field: "estatus",
        formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value ?? '-';
        }
    },
    {
        title: "FECHA DE RECEPCIÓN", field: "fecha_recepcion_embarque",
        formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            if (value == null) return '-';

            let dia = new Date(value).toLocaleDateString('es-mx', { weekday: 'long' })
            let date = new Date(value).toLocaleDateString('es-mx')
            let indicador = "is-padding-detalles fa fa-circle indicador-" + dia
            return (
                "<span class='" + indicador + "'><i></i></span>" + date
            );
        }
    },
    {
        formatter: viewIcon, width: 40, cellClick: function (e, cell) {
            $(`#${MODAL_DETALLES_ENVIO}`).toggleClass("is-active");
        }
    },
];

let addStatusColumn = {
    formatter: addIcon, width: 40, cellClick: function (e, cell) {
        $(`#${MODAL_AGREGAR_ESTATUS}`).toggleClass("is-active");
    }
}

const addStatusOption = [ROLES.ADMIN, ROLES.ENCARGADO_PORTEO, ROLES.AUXILIAR_ADMINISTRATIVO]

export const getColumns = (userRole = "") => {
    if (addStatusOption.includes(userRole)
        && !COLUMNS_SEGUIMIENTO_ENTREGAS.includes(addStatusColumn)) {
        COLUMNS_SEGUIMIENTO_ENTREGAS.push(addStatusColumn)
    }
    return COLUMNS_SEGUIMIENTO_ENTREGAS;
}