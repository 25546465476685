import React from 'react'
import FlechasImg from '../../images/flechas.png'

export const Title = ({ children, subtitle, inverse = false }) => (
    <div className='flex-adjust' style={{ paddingBottom: '30px' }}>
        <img className={inverse ? 'girar-img' : null} style={{ display: 'inline', marginRight: "20px" }} loading="lazy" src={FlechasImg} alt='Logistic del sur' width='100px' />
        <div>
            <span className='has-text-weight-bold is-size-2 has-text-black'>{children}</span>
            <div className='home-title-bar'></div>
        </div>
    </div>
)

export const TitleAlternativo = ({ children }) => (
    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '30px' }}>
        <div className='has-text-weight-bold is-size-1 has-text-black main-title'>
            <img className='img-margin-right' loading="lazy" src={FlechasImg} alt='Logistic del sur' width='100px' />
            {children}
            <img loading="lazy" className='girar-img img-margin-left' src={FlechasImg} alt='Logistic del sur' width='100px' />
        </div>
    </div>
)
export const TitleAlternativoDos = ({ children }) => (
    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '30px' }}>
        <div className='has-text-weight-bold is-size-1 has-text-black main-title'>
            <img loading="lazy" className='girar-img img-margin-right' src={FlechasImg} alt='Logistic del sur' width='100px' />
            {children}
            <img loading="lazy" className='img-margin-left' src={FlechasImg} alt='Logistic del sur' width='100px' />
        </div>
    </div>
)