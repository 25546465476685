import React from "react";
import $ from "jquery";
import { Table } from "../utilidades/Table";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { InventarioFaltantes } from "../reportes/InventarioFaltantes";
import { alertError, alertSuccess, alertWarning } from "../utilidades/Alertas";
import Autosuggest from "react-autosuggest";
import { MODAL_REPORTE_FALTANTES } from "./ConstantsInventario";
import { Service } from "../../services/Service";
import { INVENTARIO } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

const columns = [
  {
    title: "",
    field: "imgs",
    width: 65,
    formatter: function (cell, formatterParams) {
      return "<img alt='image' src=" + cell.getValue() + " />";
    },
  },
  { title: "ARTICULO", field: "articulo" },
  { title: "STOCK SISTEMA", field: "stocks", hozAlign: "center" },
  { title: "STOCK REAL", field: "stockr", hozAlign: "center" },
];

export class ReporteFaltantes extends React.Component {
  state = {
    iCodigoRF: "",
    iCantidadRF: "",
    descargar: false,
    producto: "",
    faltantes: 0,
    data: [],
    dataF: [],
    porteo: "",
    iPorteo: "",
    tipoEntrada: true,
    iNombre: "",
    value: "",
    suggestions: [],
    table: null,
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequestedCod = async ({ value }) => {
    const res = await Service.getRawResponse( INVENTARIO +
      `/getProductoCN?por=${
        this.state.iPorteo.split("/")[0]
      }&cod=${value}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({
        suggestions: r.map((row) => ({
          name: row.nombre_producto,
          img: row.img_producto,
          id: row.codigo_producto,
        })),
      });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  onSuggestionsFetchRequestedNom = async ({ value }) => {
    const res = await Service.getRawResponse( INVENTARIO +
      `/getProductoCN?por=${
        this.state.iPorteo.split("/")[0]
      }&nom=${value}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({
        suggestions: r.map((row) => ({
          name: row.nombre_producto,
          img: row.img_producto,
          id: row.codigo_producto,
        })),
      });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.data);
    }
  };

  // tipo de entrada por nombre o por código
  tipoEntrada = (e) => {
    this.setState({
      tipoEntrada: e.target.value === "codigo" ? true : false,
      value: "",
      suggestions: [],
      iCantidadRF: "",
    });
  };

  // valor para el input del nombre
  inputNombre = (e) => {
    this.setState({ iNombre: e.target.value });
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({ descargar: false });
  };

  // onFocus
  reiniciarInput = (e) => {
    if (e.target.name === "nombre" || e.target.name === "codigo")
      this.setState({
        value: "",
      });
    else
      this.setState({
        [e.target.name]: "",
      });
  };

  //Limpiar inputs
  limpiarC = () => {
    var elements = document.querySelectorAll("input[type='text']");
    for (var i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iCodigoRF: "",
      iCantidadRF: "",
      descargar: false,
      producto: "",
      faltantes: 0,
      data: [],
      dataF: [],
      value: "",
      suggestions: [],
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_REPORTE_FALTANTES}`).toggleClass("is-active");
    this.limpiarC();
  };

  handleAgregar = (e) => {
    e.preventDefault();
    if (this.state.tipoEntrada) {
      if (
        document.getElementById("codigo").value.length > 0 &&
        document.getElementById("iCantidadRF").value.length > 0
      )
        this.getCodigo(this.state.iPorteo.split("/")[0], this.state.value);
      else alertWarning("Faltan campos", 4000, "center");
    } else {
      if (
        document.getElementById("nombre").value.length > 0 &&
        document.getElementById("iCantidadRF").value.length > 0
      )
        this.getCodigo(this.state.iPorteo.split("/")[0], this.state.value);
      else alertWarning("Faltan campos", 4000, "center");
    }
  };

  // backend
  handleSubmmit = (e) => {
    const { dataF } = this.state;
    if (dataF.length > 0) {
      this.getReporte(dataF);
    } else alertError("Reporte vacío", 4000, "center");
  };

  getReporte = async (datos) => {
    const res = await Service.postRawResponse( INVENTARIO + "/setFaltantes", datos);
    if (res.status === 200) {
      alertSuccess("Se ha actualizado la base de datos", 4000, "center");
      this.setState({ descargar: true });
      this.props.getArticulos();
    } else {
      alertError("No existen datos para generar el reporte", 4000, "center");
    }
  };

  getCodigo = async (por, cod) => {
    const res = await Service.getRawResponse( INVENTARIO + `/getCodigo?por=${por}&cod=${cod}`);
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ producto: r });
      const { producto, iCantidadRF, data, dataF } = this.state;
      var aux = data,
        auxF = dataF;
      aux.push({
        imgs: producto[0].img_producto,
        articulo: producto[0].nombre_producto,
        stocks: producto[0].cantidad_producto,
        stockr: iCantidadRF,
      });
      if (producto[0].cantidad_producto > this.state.iCantidadRF) {
        auxF.push({
          id: producto[0].Id_producto,
          Id_porteo: producto[0].Id_porteo,
          articulo: producto[0].nombre_producto,
          stocks: producto[0].cantidad_producto,
          stockr: iCantidadRF,
          faltantes: producto[0].cantidad_producto - iCantidadRF,
        });
        this.setState({ faltantes: this.state.faltantes + 1, dataF: auxF });
      }
      this.setState({ data: aux, value: "", iCantidadRF: "" });
    } else {
      alertError("No existe un producto con ese código", 4000, "center");
    }
  };

  getPorteo = async (por) => {
    let res = null;
    // eslint-disable-next-line eqeqeq
    if (por == 0) {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403){
      clearSession()
    } else {
      this.setState({ porteo: "" });
    }
  };

  componentDidMount() {
    this.getPorteo(sessionStorage.getItem("porteo"));
  }

  render() {
    const { value, suggestions } = this.state;
    return (
      <div id={MODAL_REPORTE_FALTANTES} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Reporte de Faltantes</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iPorteo">
                      Porteo
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iPorteo"
                          name="iPorteo"
                          value={this.state.iPorteo}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un porteo
                          </option>
                          {this.state.porteo !== null ? (
                            Object.values(this.state.porteo).map((p) => {
                              return (
                                <option
                                  key={p.Id_porteo}
                                  value={p.Id_porteo + "/" + p.nombre}
                                >
                                  {p.nombre}
                                </option>
                              );
                            })
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-6">
                    <label className="label">Agregar por:</label>
                    <div className="control">
                      <label className="radio">
                        <input
                          onChange={this.tipoEntrada}
                          type="radio"
                          name="tipo"
                          value="codigo"
                          defaultChecked
                        />{" "}
                        Código
                      </label>
                      <label className="radio">
                        <input
                          onChange={this.tipoEntrada}
                          type="radio"
                          name="tipo"
                          value="nombre"
                        />{" "}
                        Nombre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  {this.state.tipoEntrada ? (
                    <div className="column is-4">
                      <label className="label" htmlFor="iCodigoRF">
                        Código
                      </label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedCod
                        }
                        onSuggestionsClearRequested={() => {
                          this.setState({
                            suggestions: [],
                          });
                        }}
                        getSuggestionValue={(suggestion) => suggestion.id}
                        renderSuggestion={(suggestion) => (
                          <div>
                            <img
                              style={{ width: "50px" }}
                              src={suggestion.img}
                              alt={suggestion.img}
                            />
                            {suggestion.name}
                          </div>
                        )}
                        inputProps={{
                          placeholder: "Código",
                          id: "codigo",
                          name: "codigo",
                          onFocus: this.reiniciarInput.bind(this),
                          value,
                          className: "input is-rounded is-success",
                          onChange: this.onChange,
                        }}
                      />
                    </div>
                  ) : (
                    <div className="column is-4">
                      <label className="label" htmlFor="nombre">
                        Nombre
                      </label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedNom
                        }
                        onSuggestionsClearRequested={() => {
                          this.setState({
                            suggestions: [],
                          });
                        }}
                        getSuggestionValue={(suggestion) => suggestion.id}
                        renderSuggestion={(suggestion) => (
                          <div>
                            <img
                              style={{ width: "50px" }}
                              src={suggestion.img}
                              alt={suggestion.img}
                            />
                            {suggestion.name}
                          </div>
                        )}
                        inputProps={{
                          placeholder: "Nombre",
                          id: "nombre",
                          name: "nombre",
                          value,
                          className: "input is-rounded is-success",
                          onChange: this.onChange,
                        }}
                      />
                    </div>
                  )}

                  <div className="column is-4">
                    <label className="label" htmlFor="iCantidadRF">
                      Cantidad
                    </label>
                    <input
                      id="iCantidadRF"
                      name="iCantidadRF"
                      value={this.state.iCantidadRF}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      onFocus={this.reiniciarInput.bind(this)}
                      placeholder="Cantidad"
                    />
                  </div>

                  <div className="column is-4 button-bottom">
                    <button
                      onClick={this.handleAgregar}
                      className="button is-rounded is-success"
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <Table
                      data={this.state.data}
                      columns={columns}
                      getTable={this.getTable}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-3">
                    {this.state.descargar ? (
                      <PDFDownloadLink
                        className="button is-rounded is-danger"
                        document={
                          <InventarioFaltantes
                            porteo={this.state.porteo[0].nombre}
                            faltantes={this.state.faltantes}
                            data={this.state.dataF}
                          />
                        }
                        fileName={
                          "InventarioFaltantes_" +
                          new Date().toLocaleDateString() +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Generando..." : "Descargar"
                        }
                      </PDFDownloadLink>
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Generar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
