import React from 'react'

import TotalSVG from '../../../icons/total.svg'
import EntregadosSVG from '../../../icons/entregados.svg'
import VisitadosSVG from '../../../icons/visitados.svg'
import NoVisitadosSVG from '../../../icons/devoluciones.svg'

export const DetallesComponent = ({ total, entregados, visitados, noVisitado }) => (
    <div className="is-space-between-vertical" >
        <div className="row border-bot">
            <span className="title is-5">Detalles</span>
        </div>
        <div className="is-space-between-vertical">
            <span className="row is-center-vertical">
                <img alt="" src={TotalSVG} />
                <span className="detail-main-item">Total</span>
                <span >{total}</span>
            </span>
            <span className="row is-center-vertical">
                <img alt="" src={EntregadosSVG} />
                <span className="detail-main-item">Entregados</span>
                <span>{entregados}</span>
            </span>
            <span className="row is-center-vertical">
                <img alt="" src={VisitadosSVG} />
                <span className="detail-main-item">Visitados</span>
                <span>{visitados}</span>
            </span>
            <span className="row is-center-vertical">
                <img alt="" src={NoVisitadosSVG} />
                <span className="detail-main-item">No visitado</span>
                <span>{noVisitado}</span>
            </span>
        </div>
    </div>
)