import React from "react";
import { alertWarning } from "../utilidades/Alertas";
import $ from "jquery";

import { fecha } from "../utilidades/Fecha";
import { MODAL_TRANSFERENCIA } from "./ConstantsPorteadores";
import { Service } from "../../services/Service";
import { INVENTARIO, TRABAJADORES } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

export class FormTransferenciaP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iEmpleadoT: "",
      iOrigenT: "",
      iDestinoT: "",
      iObservacionesT: "",
      iFechaHoraT: "",
      porteoOrigen: null,
      porteosDestino: "",
      flag: true,
      empleados: null,
    };
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "iOrigenT") {
      this.porteoDestino(e.target.value);
      this.getEmpleados(e.target.value);
    }
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='datetime-local'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iEmpleadoT: "",
      iOrigenT: "",
      iDestinoT: "",
      iObservacionesT: "",
      iFechaHoraT: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_TRANSFERENCIA}`).toggleClass("is-active");
    this.setState({ flag: true });
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    let { iEmpleadoT, iOrigenT, iDestinoT, iObservacionesT, iFechaHoraT } =
      this.state;
    if (iEmpleadoT && iOrigenT && iDestinoT && iObservacionesT && iFechaHoraT) {
      this.props.transferencia({
        id: iEmpleadoT,
        iOrigen: iOrigenT,
        iDestino: iDestinoT,
        iObservaciones: iObservacionesT,
        iFechaHora: iFechaHoraT,
      });
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  porteoOrigen = async (por) => {
    let res = null;
    if (por == 0) {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      console.log(r);
      this.setState({ porteoOrigen: r });
    } else if (res.status === 403){
      clearSession()
    } else {
      this.setState({ porteoOrigen: "" });
    }
  };

  porteoDestino = async (por) => {
    let res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteosDestino: r });
    } else {
      this.setState({ porteoDestino: "" });
    }
  };

  getEmpleados = async (por) => {
    let res = await Service.getRawResponse(TRABAJADORES + `?por=${por}`);
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ empleados: r });
    } else {
      this.setState({ empleados: "" });
    }
  };

  componentDidMount() {
    this.porteoOrigen(sessionStorage.getItem("porteo"));
  }

  render() {
    if ($(`#${MODAL_TRANSFERENCIA}`).is(":visible") && this.state.flag) {
      this.setState({ iFechaHoraT: fecha(), flag: false });
    }
    return (
      <div id={MODAL_TRANSFERENCIA} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Nueva Transferencia</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iOrigenT">
                      Porteo Origen
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iOrigenT"
                          name="iOrigenT"
                          value={this.state.iOrigenT}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione el porteo de origen
                          </option>
                          {this.state.porteoOrigen !== null ? (
                            Object.values(this.state.porteoOrigen).map((p) => {
                              return (
                                <option key={p.Id_porteo} value={p.Id_porteo}>
                                  {p.nombre}
                                </option>
                              );
                            })
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iDestinoT">
                      Porteo Destino
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iDestinoT"
                          name="iDestinoT"
                          value={this.state.iDestinoT}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione el porteo destino
                          </option>
                          {Object.values(this.state.porteosDestino).map(
                            (porteo) => {
                              return (
                                <option
                                  key={porteo.Id_porteo}
                                  value={porteo.Id_porteo}
                                >
                                  {porteo.nombre}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <label className="label" htmlFor="iEmpleadoT">
                      Empleado
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEmpleadoT"
                          name="iEmpleadoT"
                          value={this.state.iEmpleadoT}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un empleado
                          </option>
                          {this.state.empleados !== null ? (
                            Object.values(this.state.empleados).map(
                              (empleado) => {
                                return (
                                  <option
                                    key={empleado.Id_trabajador}
                                    value={empleado.Id_trabajador}
                                  >
                                    {empleado.nombre_trabajador}{" "}
                                    {empleado.apellido_paterno_trabajador}{" "}
                                    {empleado.apellido_materno_trabajador}
                                  </option>
                                );
                              }
                            )
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <label className="label" htmlFor="iObservacionesT">
                  Observaciones
                </label>
                <textarea
                  id="iObservacionesT"
                  name="iObservacionesT"
                  value={this.state.iObservacionesT}
                  onChange={this.handleInputChange.bind(this)}
                  className="textarea is-success"
                  rows="3"
                  placeholder="Observaciones..."
                ></textarea>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaHoraT">
                      Fecha
                    </label>
                    <input
                      id="iFechaHoraT"
                      name="iFechaHoraT"
                      value={this.state.iFechaHoraT}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
