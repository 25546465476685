import React from 'react'

import $ from 'jquery'
import { MODAL_DETALLES_CONSUMO } from './ConstantsConsumo';
import { Table } from '../utilidades/Table';
import { COLUMNS_CONSUMO_DETALLES } from './ColumnsConsumoGasolina';

export class ModalDetallesConsumo extends React.Component {

    state = {
        data: ''
    }

    handleClick = (e) => {
        $(`#${MODAL_DETALLES_CONSUMO}`).toggleClass("is-active");
    }

    getTable = (table) => {
        if (table !== null) {
            table.setData(this.props.data);
        }
    };

    setItem = (selectedItem) => {
        this.setState({ selectedItem: selectedItem });
    }

    render() {
        return (
            <div id={MODAL_DETALLES_CONSUMO} className="modal">
                <div className="modal-background" />
                <div className="modal-card" style={{ width: "90%" }}>
                    <header className="modal-card-head">
                        <p className="modal-card-title">Detalles del consumo del transporte</p>
                        <button onClick={this.handleClick} className="delete" aria-label="close" />
                    </header>
                    <section className="modal-card-body">
                        <div className='row'>
                            <span className='has-text-bold is-inline-block-s'>TRANSPORTE:</span>
                            <span> {this.props.transporte}</span>
                        </div>
                        <br />
                        <div className='row'>
                            <Table
                                columns={COLUMNS_CONSUMO_DETALLES}
                                setItem={this.setItem}
                                getTable={this.getTable}
                            />
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <span></span>
                        <button onClick={this.handleClick} className="button is-rounded is-warning" >Cerrar</button>
                    </footer>
                </div>
            </div>
        )
    }
}