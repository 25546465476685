import React from 'react'

// Components
import { Iniciar } from '../components/homepage/Iniciar'
import { Footer } from '../components/homepage/Footer'
import Logo from '../images/LOGOLOGISTIC.png'

export default class Login extends React.Component {
    componentDidMount(){
        document.title = 'Login | Logistic Express del Sur'
    }
    render() {
        return (
            <div>
                <div className="navbar">
                    <a onClick={ () => document.title = 'Logistic Express del Sur | Soluciones Logísticas | Inicio'} href='/' style={{ display: 'flex', alignItems: 'center', paddingLeft: 30}}>
                        <img src={Logo} width="130" height="30" alt="Logistic Express del Sur" />
                    </a>
                </div>
                <hr className='black-line'/>
                <Iniciar />
                <Footer />
            </div>
        )
    }
}