// React
import React from "react";
// redux
import { Sesion } from "../../../redux/actions/SesionActions";
import { connect } from "react-redux";
// components
import { Table } from "../../utilidades/Table";

// jquery
import $ from "jquery";
import { alertSuccess, alertWarning } from "../../utilidades/Alertas";

//import { Bar } from 'react-chartjs-2'
import { SelectOption } from "../../common/SelectOption";
import { InputSearch } from "../../common/InputSearch";
import { ButtonDropdown } from "../../common/ButtonDropdown";
import { ButtonRounded } from "../../common/ButtonRounded";
import { COLUMNS_EMBARQUES } from "./ColumnsEmbarques";
import { AgregarEmbarqueMasivo } from "./AgregarEmbarqueMasivo";
import {
  MODAL_AGREGAR_EMBARQUE_MASIVO_JAFRA,
  MODAL_AGREGAR_EMBARQUE_MASIVO_OTRO,
} from "./ConstantsEmbarques";
import { ModalDetallesEmbarque } from "./ModalDetallesEmbarques";
import { Service } from "../../../services/Service";
import { BODEGA } from "../../../services/Config";
import { clearSession } from "../../utilidades/DataHelpers";

class Embarques extends React.Component {
  state = {
    buscar: "",
    data: [],
    inputPorteo: "",
    embarque: null,
    paquetes: [],
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.data);
      table.setFilter([
        {
          field: "folio_embarque",
          type: "like",
          value: this.state.buscar,
        },
        {
          field: "Id_porteo",
          type: "like",
          value: this.state.inputPorteo.split("/")[0],
        },
      ]);
    }
  };

  getEmbarque = async (row) => {
    let res = await Service.getRawResponse(BODEGA + `/Embarque?Id_embarque=${row.Id_embarque}`);
    if (res.status === 200) {
      let data = await res.json();
      this.setState({ embarque: data.embarque, paquetes: data.paquetes });
    } else {
      alertWarning("El embarque no se encontró", 2000, "center");
    }
  };

  getEmbarques = async () => {
    let porteo = this.state.inputPorteo;
    if (this.props.userInfo.porteo.length === 1) {
      porteo = this.props.userInfo.porteo[0].Id_porteo;
    } else if (porteo === "" && this.props.userInfo.porteo.length > 1) {
      porteo = 0;
    }
    let res = await Service.getRawResponse(BODEGA + `/Embarques?porteo=${porteo}`);
    if (res.status === 200) {
      this.setState({ data: await res.json() });
    } else if (res.status === 403) {
      clearSession()
    }else {
      console.log("Error al contactar el server");
    }
    this.props.setLoading(false);
  };

  //Añadir un embarque
  addEmbarques = async (embarque) => {
    console.log("SMN");
    let res = await Service.postRawResponse( BODEGA + "/Embarques", embarque);
    if (res.status === 201) {
      alertSuccess("Embarque agregado", 4000, "center");
      this.getEmbarques(); //'',sessionStorage.getItem('porteo'));
      //}
    } else {
      console.log(res);
    }
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  modalAgregarEmbarque(e) {
    $(`#${MODAL_AGREGAR_EMBARQUE_MASIVO_JAFRA}`).toggleClass("is-active");
  }

  modalAgregarEmbarqueOtros(e) {
    $(`#${MODAL_AGREGAR_EMBARQUE_MASIVO_OTRO}`).toggleClass("is-active");
  }

  componentDidMount() {
    this.props.setLoading(true);
    this.getEmbarques();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputPorteo !== this.state.inputPorteo) {
      this.getEmbarques();
    }
  }

  render() {
    return (
      <div className="container is-fluid box">

        <div className="row">
          <div className="columns">
            <div className="column is-10">
              <label className="title is-5"> Embarques </label>{" "}
            </div>{" "}
            <div className="column is-2 has-text-left">
              <div className="control">
                <div className="dropdown is-hoverable">
                  <div className="dropdown-trigger">
                    <ButtonRounded
                      tooltip="Agregar-Recepcion"
                      icon="plus"
                      classColor="is-success"
                    />
                    <label> </label>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="dropdown-menu4"
                    role="menu"
                  >
                    <div className="dropdown-content">
                      <ButtonDropdown
                        onClick={this.modalAgregarEmbarque}
                        classColor="is-success"
                        icon="cube"
                      >
                        Agregar Embarque JAFRA
                      </ButtonDropdown>
                      <hr className="dropdown-divider"></hr>
                      <ButtonDropdown
                        onClick={this.modalAgregarEmbarqueOtros}
                        classColor="is-success"
                        icon="arrow-right"
                      >
                        Agregar Embarque OTRO
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-3">
              <InputSearch
                onChange={this.handleInputChange}
                name="buscar"
                placeholder="Buscar..."
                key="buscarPor"
              />
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputChange}
                data={this.props.userInfo.porteo}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <Table
            columns={COLUMNS_EMBARQUES}
            getTable={this.getTable}
            setItem={this.getEmbarque}
          />
        </div>

        <AgregarEmbarqueMasivo
          cliente="JAFRA"
          add={this.addEmbarques}
          modalName={MODAL_AGREGAR_EMBARQUE_MASIVO_JAFRA}
          porteos={this.props.userInfo.porteo}
        />
        <AgregarEmbarqueMasivo
          cliente="OTROS"
          add={this.addEmbarques}
          modalName={MODAL_AGREGAR_EMBARQUE_MASIVO_OTRO}
          porteos={this.props.userInfo.porteo}
        />

        <ModalDetallesEmbarque
          embarque={this.state.embarque}
          paquetes={this.state.paquetes}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Embarques);
