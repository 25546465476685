import React from 'react'

import $ from 'jquery'
import { MODAL_DETALLES_PRODUCTO } from './ConstantsInventario';

export class ModalDetallesProducto extends React.Component {

    handleClick(e) {
        $(`#${MODAL_DETALLES_PRODUCTO}`).toggleClass("is-active");
    }

    render() {
        return (
            <div id={MODAL_DETALLES_PRODUCTO} className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{this.props.selectedItem.nombre_producto}</p>
                        <button onClick={this.handleClick} className="delete" aria-label="close"/>
                    </header>

                    {
                        this.props.selectedItem ?
                            <section className="modal-card-body">
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className="label">Código</label>
                                        <label name='Id_prod' >{this.props.selectedItem['Id_producto']}</label>
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label">Categoria</label>
                                        <label name='Id_prod' >{this.props.selectedItem['categoria_producto']}</label>
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label">Marca</label>
                                        <label name='Id_prod' >{this.props.selectedItem['marca_producto']}</label>
                                    </div>
                                </div>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className="label">Factura</label>
                                        <label name='Id_prod' >{this.props.selectedItem['factura_producto']}</label>
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label">Precio</label>
                                        <label name='Id_prod' >{this.props.selectedItem['precio_producto']}</label>
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label">Proveedor</label>
                                        <label name='Id_prod' >{this.props.selectedItem['producto_proveedor']}</label>
                                    </div>
                                </div>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className="label">Máximo</label>
                                        <label name='Id_prod' >{this.props.selectedItem['maximo_producto']}</label>
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label">Mínimo</label>
                                        <label name='Id_prod' >{this.props.selectedItem['minimo_producto']}</label>
                                    </div>
                                    <div className='column is-4'>
                                        <label className="label">Cantidad</label>
                                        <label name='Id_prod' >{this.props.selectedItem['cantidad_producto']}</label>
                                    </div>
                                </div>
                                <div className='columns'>
                                    <div className='column'>
                                        <label className="label">Descripcion</label>
                                        <label name='Id_prod' >{this.props.selectedItem['descripcion_producto']}</label>
                                    </div>
                                </div>
                                <div className="card-image">
                                    <figure className="image is-4by3">
                                        <img src={this.props.selectedItem['img_producto']} alt={this.props.selectedItem['nombre']} />
                                    </figure>
                                </div>
                            </section>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}