import React from "react";

import $ from "jquery";
import { Service } from "../../services/Service";
import { NOTIFICACIONES } from "../../services/Config";

export class AddNotificacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iNombreN: "",
      iDescripcionN: "",
      iCuantosKM: "",
      iTransporteN: "",
      transportes: ''
    }
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  //Limpiar inputs
  limpiarC = () => {

    var elements = document.querySelectorAll("input[type='text'],input[type='number'],select")
    for (var i = 0; i < elements.length; i++) {
      elements[i].value = ''
    }
    this.setState({
      iNombreN: '',
      iDescripcionN: '',
      iCuantosKM: '',
      iTransporteN: ''
    })
  }


  //Get transportes
  getTransportes = async () => {
    const res = await Service.getRawResponse( NOTIFICACIONES + '/getTransporte')
    const result = await res.json();
    this.setState({ transportes: result });
  }


  handleClick = (e) => {
    $("#addnotificacion").toggleClass("is-active");
    this.limpiarC();
    this.props.getFiltros();
  }

  // backend
  handleSubmmit = (e) => {
    e.preventDefault();
    const {
      iNombreN,
      iDescripcionN,
      iCuantosKM,
      iTransporteN
    } = this.state;
    if (
      iNombreN &&
      iDescripcionN &&
      iCuantosKM &&
      iTransporteN
    ) {
      this.props.add({
        iNombreN,
        iDescripcionN,
        iCuantosKM,
        iTransporteN
      });
      this.handleClick();
    }
    else
      alert("Faltan campos");
  }

  componentDidMount(){
    this.getTransportes();
  }

  render() {
    return (
      <div id="addnotificacion" className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Agregar Notificación</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iNombreN">
                      Nombre
                    </label>
                    <input
                      id='iNombreN'
                      name='iNombreN'
                      value={this.state.iNombreN}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Nombre de la notificación"
                    />
                  </div>

                  <div className="column is-6">
                    <label className="label" htmlFor="iDescripcionN">
                      Descripción
                    </label>
                    <input
                      id='iDescripcionN'
                      name='iDescripcionN'
                      value={this.state.iDescripcionN}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Descripción"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iCuantosKM">
                      Cada cuantos KM
                    </label>
                    <input
                      id='iCuantosKM'
                      name='iCuantosKM'
                      value={this.state.iCuantosKM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Km"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iTransporteN">
                      Transporte
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id='iTransporteN'
                          name='iTransporteN'
                          value={this.state.iTransporteN}
                          onChange={this.handleInputChange.bind(this)}>
                          <option value='' defaultValue>Seleccione un transporte</option>
                          <option value='Todos' defaultValue>Todos</option>
                          {
                            this.state.transportes != null ? (
                              Object.values(this.state.transportes).map((transporte) => {
                                return (<option key={transporte.placa} value={transporte.placa}>{transporte.placa}</option>)
                              })
                            ) : (
                                <option value=''>No hay datos</option>
                              )
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Agregar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
