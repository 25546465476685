// react
import React from 'react';

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table/lib'
// fuente a utilizar
import ProductSans from './style/ProductSansRegular.ttf'
import ProductSansBold from './style/ProductSansBold.ttf'
// estilos
import { styles } from './style/Styles'
//LOGOTIPO
import logo from './../../images/LOGOLOGISTIC.png'

Font.register({
    family: 'ProductSans',
    fonts: [
        { src: ProductSans }, // font-style: normal, font-weight: normal
        { src: ProductSansBold, fontWeight: 'bold' },
    ]
});

export const InventarioProductos = ({ porteo, categoria, data, f = new Date() }) => (
    <Document>
        <Page size="A4" style={styles.page} >
            <View style={styles.logoTitle}>
                <Image style={styles.image} src={logo} />
                <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
            </View>
            <View style={styles.info}>
                <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
                <Text style={styles.contacto}>Tel: (341) 410-6511 ó (341) 410-6458</Text>
            </View>
            <Text style={styles.subtitulo}>
                Reporte de Productos
                </Text>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Porteo:</Text>
                    <Text style={styles.parametros}>{porteo || 'Todos'}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Fecha:</Text>
                    <Text style={styles.parametros}>{f.getFullYear() + '-' + ('0' + (f.getMonth() + 1)).slice(-2) + '-' + ('0' + f.getDate()).slice(-2)}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Categoría:</Text>
                    <Text style={styles.parametros}>{categoria}</Text>
                </View>
            </View>
            <View>
                <Table data={data} >
                    <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false} >
                        <TableCell style={styles.tablaHeader} weighting={0.05}>#</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.35} >CÓDIGO</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.35}>ARTICULO</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.2}>PORTEO</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.2}>CANTIDAD</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.2}>TOTAL</TableCell>
                    </TableHeader>
                    <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <DataTableCell style={styles.tabla} weighting={0.05} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                        <DataTableCell style={styles.tabla} weighting={0.35} getContent={(r) => r.codigo_producto.toLocaleString()} />
                        <DataTableCell style={styles.tabla} weighting={0.35} getContent={(r) => r.nombre_producto} />
                        <DataTableCell style={styles.tabla} weighting={0.2} getContent={(r) => r.porteo} />
                        <DataTableCell style={styles.tabla} weighting={0.2} getContent={(r) => r.cantidad_producto.toLocaleString()} />
                        <DataTableCell style={styles.tabla} weighting={0.2} getContent={(r) => "$" + r.total.toLocaleString() } />
                    </TableBody>
                </Table>
            </View>
        </Page>
    </Document>
);