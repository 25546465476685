export const CATEGORIAS_PROVEEDORES = [
    { value: "Limpieza", text: "Limpieza" },
    { value: "Papelería", text: "Papelería" },
    { value: "Muebles", text: "Muebles" },
    { value: "Cómputo", text: "Cómputo" },
    { value: "Gasolina", text: "Gasolina" },
    { value: "Suspensión", text: "Suspensión" },
    { value: "Refacciones", text: "Refacciones" },
    { value: "Mantenimiento", text: "Mantenimiento" }
]

export const MODAL_ADD_PROVEEDOR = 'formaddproveedor'
export const MODAL_DELETE_PROVEEDOR = 'deleteproveedor'
export const MODAL_UPDATE_PROVEEDOR = 'formmodproveedor'