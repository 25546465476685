import React from 'react'

const toDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-")
    return new Date(year, month - 1, day)
}

function daysBetween(fecha) {
    var fechaAlta = toDate(fecha)
    var fechaHoy = new Date();
    var day_as_milliseconds = 86400000;
    var diff_in_millisenconds = fechaHoy - fechaAlta;
    return parseInt(diff_in_millisenconds / day_as_milliseconds);
}
export class Notificacion extends React.Component {
    state = {
        isChecked: false
    }
    inputCheckBox = (e) => {
        this.setState({ isChecked: e.target.checked })
        this.props.updateNotificacionMan({ ban: e.target.checked, rol: sessionStorage.getItem('rol'), Id_not: e.target.id });
    }

    iniciarCB = () => {
        let tipoUsuario = sessionStorage.getItem('rol')
        if (tipoUsuario === 'mantenimiento' & this.props.encargado) { this.setState({ isChecked: true }) }
        else if (tipoUsuario === 'porteo' & this.props.porteo) { this.setState({ isChecked: true }) }
        else if (tipoUsuario === 'chofer' & this.props.chofer) { this.setState({ isChecked: true }) }
    }

    componentDidMount() {
        this.iniciarCB()
    }

    render() {
        const { Id_not, color, icono, titulo, placa, texto, encargado, porteo, chofer, alta, baja } = this.props
        const iconoF = "fa fa-exclamation-" + icono

        let encargadoBan = "fa " + (encargado ? "fa-check-circle" : "fa-circle-o") + " fix-icon"
        let porteoBan = "fa " + (porteo ? "fa-check-circle" : "fa-circle-o") + " fix-icon"
        let choferBan = "fa " + (chofer ? "fa-check-circle" : "fa-circle-o") + " fix-icon"

        let tipoUsuario = sessionStorage.getItem('rol')

        if (tipoUsuario === 'mantenimiento' & this.state.isChecked) { encargadoBan = "fa fa-check-circle fix-icon"; }
        else if (tipoUsuario === 'porteo' & this.state.isChecked) { porteoBan = "fa fa-check-circle fix-icon" }
        else if (tipoUsuario === 'chofer' & this.state.isChecked) { choferBan = "fa fa-check-circle fix-icon" }

        return (
            <li key={placa} className={color}>
                <i className={iconoF}></i>
                <div className="notification">

                    {
                        baja === undefined
                            ? <a href='# ' className='arriba-derecha fix-icon'>
                                <span className="has-text-white fix-icon">
                                    <input id={Id_not} type='checkbox' checked={this.state.isChecked} onChange={this.inputCheckBox} />
                                </span>
                            </a>
                            : null
                    }
                    <p><b>{titulo}</b></p>
                    <p>{placa} - {texto}</p>
                    {
                        baja === undefined
                            ? <p>Hace {daysBetween(alta)} días</p>
                            : <p><strong>Alta:</strong>{alta}   <strong>Baja:</strong>{baja}</p>
                    }
                    <div className='noti-checks'>
                        <p className='fix-p'>
                            <span className="has-text-white fix-icon">
                                <i className={encargadoBan}></i>
                            </span>
                        Enc. Mnt.
                        </p>
                        <p className='fix-p'>
                            <span className="has-text-white fix-icon">
                                <i className={porteoBan}></i>
                            </span>
                        Porteo
                        </p>
                        <p className='fix-p'>
                            <span className="has-text-white fix-icon">
                                <i className={choferBan}></i>
                            </span>
                        Chofer
                        </p>
                    </div>
                </div>

            </li>
        )
    }
}