import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

import $ from "jquery";
import { FormAddMantenimiento } from "../mantenimiento/FormAddMantenimiento";
import { Table } from "../utilidades/Table";
import { FormAddTransporte } from "./FormAddTransporte";
import { FormModTransporte } from "./FormModTransporte";
import { DeleteTransporte } from "./DeleteTransporte";
import { ModalDetallesTransporte } from "./ModalDetallesTransporte";
import { alertSuccess, alertError } from "../utilidades/Alertas";
import { ReportePolizas } from "./ReportePolizas";
import { ReporteVerificaciones } from "./ReporteVerificaciones";
import { ReporteFlotaVehicular } from "./ReporteFlotaVehicular";
import { FormTransferenciaT } from "./FormTransferenciaT";
import { SelectPorteo } from "../utilidades/SelectPorteo";
import { InputSearch } from "../common/InputSearch";
import { ButtonRounded } from "../common/ButtonRounded";
import { SelectOption } from "../common/SelectOption";
import { ButtonDropdown } from "../common/ButtonDropdown";
import {
  getColumnsTransportes,
  getColumnsTransportesCirculacion,
} from "./ColumnsTransportes";
import {
  MODAL_ADD_TRANSPORTE,
  MODAL_REPORTE_FV,
  MODAL_REPORTE_POLIZA,
  MODAL_REPORTE_VERIFICACIONES,
  MODAL_SALIDA_TRANSPORTE,
  MODAL_TRANSFERENCIA,
} from "./ConstantsTransportes";
import {
  clearSession,
  formatPorteoData,
  hasAccess,
} from "../utilidades/DataHelpers";
import { ModalSalidaTransporte } from "./ModalSalidaTransporte";
import { ModalRegresoTransporte } from "./ModalRegresoTransporte";
import { ROLES } from "../utilidades/Constantes";
import { Service } from "../../services/Service";
import { INVENTARIO, MANTENIMIENTO, TRANSPORTES } from "../../services/Config";

class Transportes extends React.Component {
  state = {
    inputPlaca: "",
    selectedItem: "",
    table: null,
    inputPorteo: "",
    isMounted: false,
    datos: [],
    currentPage: 0,
    totalPages: 0,
    completedData: [],
    dataForFilters: [],
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.datos);
      //De momento los filtros como parametros a las Tablas con Paginación ya no son necesarios ya que se actualizó la funcionalidad localmente
      // table.setFilter([
      //   {
      //     field: "placa",
      //     type: "like",
      //     value: this.state.inputPlaca,
      //   },
      //   {
      //     field: "Id_porteo",
      //     type: "like",
      //     value: this.state.inputPorteo.split("/")[0],
      //   },
      // ]);
    }
  };

  handleInputChange = (e) => {
    this.filtrarTabla(e.target.value, "placa");
    this.setState({ inputPlaca: e.target.value });
    //this.getTransportes(e.target.value);
  };

  handleInputPorteo = (e) => {
    this.filtrarTabla(e.target.value.split("/")[0], "Id_porteo");
    this.setState({ inputPorteo: e.target.value });
  };

  //Cuando se usa un filtro específico no se toma en cuenta la Paginación por ello se filtra de toda la data disponible y no de la data segmentada
  filtrarTabla = (valorAFiltrar, campoAFiltrar) => {
    const datosFiltrados = this.state.dataForFilters.filter((element) => {
      const campo = element[campoAFiltrar].toString().toLowerCase();
      return campo.includes(valorAFiltrar.toLowerCase());
    });
    this.setState({
      datos: datosFiltrados,
    });
  };

  //Controles de Paginación de la Tabla
  first = () => {
    this.setState({ currentPage: 0 });
    this.setState({
      datos: this.state.completedData[0],
    });
    //Reseteo de los inputs a valores predeterminados
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputPlaca: "",
    });
  };
  prev = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
    this.setState({
      datos: this.state.completedData[this.state.currentPage - 1],
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputPlaca: "",
    });
  };
  next = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.setState({
      datos: this.state.completedData[this.state.currentPage + 1],
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputPlaca: "",
    });
  };
  last = () => {
    this.setState({ currentPage: this.state.completedData.length - 1 });
    this.setState({
      datos: this.state.completedData[this.state.completedData.length - 1],
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputPlaca: "",
    });
  };

  // turn on modals
  modalAddTransporte(e) {
    if (sessionStorage.getItem("rol") === "admin") {
      $("#selectPorteo").toggleClass("is-active");
    } else {
      $(`#${MODAL_ADD_TRANSPORTE}`).toggleClass("is-active");
    }
  }

  modalPolizas(e) {
    $(`#${MODAL_REPORTE_POLIZA}`).toggleClass("is-active");
  }
  modalVerificaciones(e) {
    $(`#${MODAL_REPORTE_VERIFICACIONES}`).toggleClass("is-active");
  }
  modalFlotaVehicular(e) {
    $(`#${MODAL_REPORTE_FV}`).toggleClass("is-active");
  }

  modalTransferencia(e) {
    $(`#${MODAL_TRANSFERENCIA}`).toggleClass("is-active");
  }

  modalRegistrarSalida(e) {
    $(`#${MODAL_SALIDA_TRANSPORTE}`).toggleClass("is-active");
  }

  getTransportes = async () => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    let result = await Service.getRawResponse(
      TRANSPORTES + `?por=${sessionStorage.getItem("porteo")}`
    );
    let data = [];
    // Verifica si el componente aún está montado
    if (this.state.isMounted) {
      if (result.status === 200) {
        data = await result.json();
        const dataSegmentada = data
          .map((elemento, indice, arreglo) => {
            if (indice % 10 === 0) {
              return arreglo.slice(indice, indice + 10);
            }
            return null; // O puedes devolver null para omitir elementos que no deben estar en el nuevo arreglo.
          })
          .filter(Boolean); // Filtramos los elementos nulos generados por el map.
        this.setState({ dataForFilters: data }); //Data completa
        this.setState({ completedData: dataSegmentada }); //Data completa segmentada por cada 10 elementos
        this.setState({ datos: dataSegmentada[this.state.currentPage] }); //Data renderizada en 10 elementos
        this.setState({ totalPages: dataSegmentada.length });
      } else this.setState({ datos: [] });

      this.props.setLoading(false);
    }
  };

  //Añadir un transporte
  add = async (formData) => {
    let res = await Service.postSimpleRawResponse(TRANSPORTES, formData);
    if (res.status === 201) {
      //let r = await res.text();
      //if (r === 'Transporte existente')
      //  alertError('Ya existe un producto con ese código', 4000, 'center')
      //else {
      alertSuccess("Transporte agregado", 4000, "center");
      this.getTransportes(""); //'',sessionStorage.getItem('porteo'));
      //}
    } else {
      alertError("No se pudo agregar el transporte", 4000, "center");
    }
  };

  //Modificar un transporte
  mod = async (formData) => {
    let res = await Service.putSimpleRawResponse(TRANSPORTES, formData);
    if (res.status === 200) {
      //let r = await res.text();
      //if (r === 'Transporte existente')
      //  alertError('Ya existe un producto con ese código', 4000, 'center')
      //else {
      alertSuccess("Transporte modificado", 4000, "center");
      this.getTransportes(""); //'',sessionStorage.getItem('porteo'));
      //}
    } else {
      alertError("No se pudo modificar el transporte", 4000, "center");
    }
  };

  //Eliminar un transporte
  remove = async () => {
    let transporte = { id: this.state.selectedItem["Id_transporte"] };
    let res = await Service.deleteRawResponse(TRANSPORTES, transporte);
    if (res.status === 200) {
      alertSuccess("Transporte eliminado", 4000, "center");
      this.getTransportes(""); //'',sessionStorage.getItem('porteo'));
    } else {
      alertError("No se pudo eliminar el transporte", 4000, "center");
    }
  };

  //Guardar mantenimiento
  saveMantenimiento = async (man) => {
    let res = await Service.postRawResponse(
      MANTENIMIENTO + "/setMantenimiento",
      man
    );
    if (res.status === 200) {
      alertSuccess("Mantenimiento agregado", 4000, "center");
    } else {
      alertError("No se pudo agregar el mantenimiento", 4000, "center");
    }
  };

  //Realizar transferencia
  transferencia = async (transferencia) => {
    let res = await Service.postRawResponse(
      TRANSPORTES + "/setTransferencia",
      transferencia
    );
    if (res.status === 200) {
      alertSuccess("Transferencia realizada", 4000, "center");
      this.getTransportes("");
    } else {
      alertError("No se pudo agregar la", 4000, "center");
    }
  };

  getPorteo = async (por) => {
    let res = null;
    if (por === 0) {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403) {
      clearSession();
    } else {
      this.setState({ porteo: "" });
    }
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.setState({ isMounted: true });
    this.getTransportes();
  }
  componentWillUnmount() {
    this.setState({ isMounted: false }); // Establece la bandera isMounted en false cuando el componente se desmonta
  }

  render() {
    return (
      <div className="container is-fluid box">
        <FormAddMantenimiento
          saveMantenimiento={this.saveMantenimiento}
          selectedItem={this.state.selectedItem}
        />
        <div className="row">
          <div className="columns">
            <div className="column is-3">
              <label className="title is-5">Lista de Transportes</label>
            </div>

            <div className="column is-5">
              <InputSearch
                onChange={this.handleInputChange.bind(this)}
                name="inputPlaca"
                placeholder="Placa"
                key="buscarPorPlacaTransportes"
              />
            </div>

            <div className="column">
              <div className="control">
                {hasAccess(this.props.userInfo.rol, [
                  ROLES.ADMIN,
                  ROLES.ENCARGADO_PORTEO,
                ]) ? (
                  <ButtonRounded
                    onClick={this.modalAddTransporte}
                    tooltip="Nuevo transporte"
                    icon="plus"
                    classColor="is-success"
                  />
                ) : null}
                <span> </span>
                {hasAccess(this.props.userInfo.rol, [
                  ROLES.ADMIN,
                  ROLES.ENCARGADO_PORTEO,
                ]) ? (
                  <ButtonRounded
                    onClick={this.modalTransferencia}
                    tooltip="Nuevo transferencia"
                    icon="mail-reply"
                    classColor="is-warning"
                  />
                ) : null}
                <label> </label>
                <span> </span>
                <div id="reportestransports" className="dropdown is-hoverable">
                  <div className="dropdown-trigger">
                    <button
                      data-tooltip="Reportes"
                      className="button is-rounded is-circle is-link"
                    >
                      <span className="icon is-large">
                        <i className="fa fa-file-text-o"></i>
                      </span>
                    </button>
                    <label> </label>
                  </div>
                  <div
                    className="dropdown-menu"
                    id="dropdown-menu3"
                    role="menu"
                  >
                    <div className="dropdown-content">
                      <ButtonDropdown
                        onClick={this.modalPolizas}
                        icon="file"
                        classColor="is-link"
                      >
                        Pólizas
                      </ButtonDropdown>
                      <hr className="dropdown-divider"></hr>

                      <ButtonDropdown
                        onClick={this.modalVerificaciones}
                        icon="check"
                        classColor="is-link"
                      >
                        Verificaciones
                      </ButtonDropdown>
                      <hr className="dropdown-divider"></hr>

                      <ButtonDropdown
                        onClick={this.modalFlotaVehicular}
                        icon="truck"
                        classColor="is-link"
                      >
                        Flota Vehicular
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputPorteo}
                data={formatPorteoData(this.props.userInfo.porteo)}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <Table
            columns={getColumnsTransportes(this.props.userInfo.rol)}
            setItem={this.setItem}
            getTable={this.getTable}
          />
          <div className="controls-table">
            <div className="">
              Página {this.state.currentPage + 1} de {this.state.totalPages}
            </div>
            <div>
              <ButtonRounded
                onClick={this.first}
                tooltip="Primera página"
                icon="angle-double-left"
                classColor="is-light has-text-black"
              />
              <ButtonRounded
                onClick={this.prev}
                disabled={this.state.currentPage === 0 ? true : false}
                tooltip="Anterior"
                icon="angle-left"
                classColor="is-light has-text-black"
              />
              <ButtonRounded
                onClick={this.next}
                tooltip="Siguiente"
                icon="angle-right"
                classColor="is-light has-text-black"
                disabled={
                  this.state.currentPage === this.state.completedData.length - 1
                    ? true
                    : false
                }
              />
              <ButtonRounded
                onClick={this.last}
                tooltip="Ultima página"
                icon="angle-double-right"
                classColor="is-light has-text-black"
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="columns">
            <div className="column is-3">
              <label className="title is-5">Transportes en circulación</label>
            </div>
            <div className="column is-offset-8">
              {hasAccess(this.props.userInfo.rol, [
                ROLES.ADMIN,
                ROLES.ENCARGADO_PORTEO,
                ROLES.ENCARGADO_ALMACEN,
              ]) ? (
                <ButtonRounded
                  onClick={this.modalRegistrarSalida}
                  tooltip="Nueva salida"
                  icon="plus"
                  classColor="is-success"
                />
              ) : null}
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <Table
            columns={getColumnsTransportesCirculacion(this.props.userInfo.rol)}
            setItem={this.setItem}
            getTable={this.getTable}
          />
        </div>

        <SelectPorteo modal="#formaddtransporte" />
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_PORTEO,
        ]) ? (
          <FormAddTransporte add={this.add} />
        ) : null}
        {this.props.userInfo.rol === ROLES.ADMIN ? (
          <FormModTransporte
            mod={this.mod}
            selectedItem={this.state.selectedItem}
          />
        ) : null}
        {this.props.userInfo.rol === ROLES.ADMIN ? (
          <DeleteTransporte remove={this.remove} />
        ) : null}
        <ModalDetallesTransporte selectedItem={this.state.selectedItem} />
        <ReportePolizas />
        <ReporteVerificaciones />
        <ReporteFlotaVehicular />
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_PORTEO,
        ]) ? (
          <FormTransferenciaT transferencia={this.transferencia} />
        ) : null}
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_PORTEO,
          ROLES.ENCARGADO_ALMACEN,
        ]) ? (
          <ModalSalidaTransporte />
        ) : null}
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_PORTEO,
        ]) ? (
          <ModalRegresoTransporte />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transportes);
