import React from "react";
import { DeleteNotificacion } from "./DeleteNotificacion";
// eslint-disable-next-line no-unused-vars
import $ from "jquery";

const toDate = (dateStr) => {
  const [year, month, day] = dateStr.split("-");
  return new Date(year, month - 1, day);
};

function daysBetween(fecha) {
  var fechaAlta = toDate(fecha);
  var fechaHoy = new Date();
  var day_as_milliseconds = 86400000;
  var diff_in_millisenconds = fechaHoy - fechaAlta;
  return parseInt(diff_in_millisenconds / day_as_milliseconds);
}

export class Notificacion2 extends React.Component {
  state = {
    isChecked: false,
  };

  inputCheckBox = (e) => {
    //$("#deletenotificacion").toggleClass("is-active");
    this.setState({ isChecked: e.target.checked });
    this.props.updateNotificacion({
      ban: !e.target.checked,
      Id_not: e.target.id,
    });
  };

  remove = () => {
    console.log("Borrar notificacion");
  };

  render() {
    const {
      Id_not,
      color,
      icono,
      titulo,
      placa,
      texto,
      encargado,
      porteo,
      chofer,
      alta,
      baja,
    } = this.props;
    const iconoF = "fa fa-exclamation-" + icono;

    return (
      <li key={placa} className={color}>
        <i className={iconoF}></i>
        <div className="notification">
          {baja === undefined ? (
            <a href="# " className="arriba-derecha fix-icon">
              <span className="has-text-white fix-icon">
                <input
                  id={Id_not}
                  type="checkbox"
                  checked={this.state.isChecked}
                  onChange={this.inputCheckBox}
                />
              </span>
            </a>
          ) : (
            null
          )}
          <p>
            <b>{titulo}</b>
          </p>
          <p>
            {placa} - {texto}
          </p>
          {baja === undefined ? (
            <p>Hace {daysBetween(alta)} días</p>
          ) : (
            <p>
              <strong>Alta:</strong>
              {alta} <strong>Baja:</strong>
              {baja}
            </p>
          )}
        </div>
        <DeleteNotificacion remove={this.remove} />
      </li>
    );
  }
}
