import $ from 'jquery'
import { editIcon, deleteIcon } from "../utilidades/TableIcons";
import { MODAL_DELETE_PROVEEDOR, MODAL_UPDATE_PROVEEDOR } from './ConstantsProveedores';
import { ROLES } from '../utilidades/Constantes'

let COLUMNS_PROVEEDORES = [
  { title: "CONTACTO", field: "nombre_proveedor" },
  { title: "TELÉFONO", field: "telefono_proveedor"},
  { title: "CIUDAD", field: "ciudad_proveedor"},
  { title: "DOMICILIO", field: "domicilio_proveedor"},
  { title: "ESTADO", field: "estado_proveedor"},
  { title: "INSUMO", field: "tipo_insumo" },
  { title: "CATEGORÍA", field: "categoria_proveedor"},
  { title: "EMPRESA", field: "empresa_proveedor"},
];

let editColumn = {
  formatter: editIcon,
  width: 40,
  headerSort: false,
  cellClick: function (e, cell) {
    $(`#${MODAL_UPDATE_PROVEEDOR}`).toggleClass("is-active");
  },
}

let deleteColumn = {
  formatter: deleteIcon,
  width: 40,
  headerSort: false,
  cellClick: function (e, cell) {
    $(`#${MODAL_DELETE_PROVEEDOR}`).toggleClass("is-active");
    $("#deletedata").text(cell.getRow().getData().nombre_proveedor);
  },
}
const editOption = [ROLES.ADMIN]
const deleteOption = [ROLES.ADMIN]

export const getColumns = (userRole = "") => {
  if (editOption.includes(userRole)
    && !COLUMNS_PROVEEDORES.includes(editColumn)) {
    COLUMNS_PROVEEDORES.push(editColumn)
  }
  if (deleteOption.includes(userRole)
    && !COLUMNS_PROVEEDORES.includes(deleteColumn)) {
    COLUMNS_PROVEEDORES.push(deleteColumn)
  }

  return COLUMNS_PROVEEDORES;
}