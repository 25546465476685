import { clearSession } from "../components/utilidades/DataHelpers";
import { INVENTARIO, PROVEEDORES } from "./Config";
import { Service } from "./Service";

// añadir estados
export let getEstados = async () => {
  let result = await Service.getRawResponse(PROVEEDORES + "/estados")
  if (result.status == 200) {
    let estados = await result.json();
    return estados;
  } else {
    return [];
  }
};

//Añadir ciudades
export let getCiudades = async (estado) => {
  let result = await Service.getRawResponse(PROVEEDORES + `/ciudades?estado=${estado}`);
  if (result.status == 200) {
    let ciudades = await result.json();
    return ciudades;
  } else {
    return [];
  }
};

export let getPorteo = async (por) => {
  let res = null;
  if (por == 0) {
    res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=${por}`);
  } else {
    res = await Service.getRawResponse(INVENTARIO + `/getPorteo?por=${por}`);
  }
  if (res.status === 200) {
    let r = await res.json();
    return r;
  } else if (res.status === 403){
    clearSession()
  } else {
    return "";
  }
};
