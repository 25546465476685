import { StyleSheet } from '@react-pdf/renderer';
export const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20,
        fontFamily: 'ProductSans'
    },
    section: {
        textAlign: 'justify',
        display: 'flex'
    },
    sectionDetalles: {
        padding: '10',
        textAlign: 'justify',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    unDetalle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    subtitulo: {
        textAlign: 'center',
        fontSize: '16',
        color: '#4a4963'
    },
    info: {
        padding: 15
    },
    contacto: {
        fontSize: '12',
        padding: '1',
        color: '#4a4963'
    },
    detalles: {
        fontSize: '14',
        padding: '5',
        color: '#4a4963',
        fontWeight: 'bold'
    },
    dinero: {
        fontSize: '14',
        color: '#f85f46',
        padding: '5'
    },
    parametros: {
        fontSize: '14',
        padding: '5',
        color: '#49b172'
    },
    tabla: {
        padding: 5,
        color: 'black',
        fontFamily: 'ProductSans',
        borderColor: '#FFFFFF',
        borderBottom: '1pt solid darkgray'
    },
    logoTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    titulo: {
        fontSize: '20',
        color: '#4a4963'
    },
    image: {
        width: 150
    },
    // estilos para detalles de transportes
    distribucionHorizontal: {
        flexDirection: "row"
    },
    distribucionVertical: {
        flexDirection: "column", width: "33%"
    },
    subtituloBold: {
        fontSize: '14',
        color: '#4a4963',
        paddingLeft: 5,
        paddingTop: 15,
        fontWeight: "bold"
    },
    textoCampos: {
        fontSize: '12',
        padding: '5',
        color: '#4a4963',
    },
    tablaHeader: {
        padding: 5,
        color: '#4a4963',
        fontFamily: 'ProductSans',
        fontWeight: 'bold',
        borderColor: '#f5f6fa',
        borderCollapse: 'collapse',
        backgroundColor: '#f5f6fa',
        borderBottomColor: '#FFFFFF'
    },
    tablaHeaderSmall: {
        padding: 1,
        color: '#4a4963',
        fontFamily: 'ProductSans',
        fontWeight: 'bold',
        borderColor: '#f5f6fa',
        borderCollapse: 'collapse',
        backgroundColor: '#f5f6fa',
        borderBottomColor: '#FFFFFF',
        fontSize: '7'
    },
    tablaSmall: {
        padding: 1,
        color: 'black',
        fontFamily: 'ProductSans',
        borderColor: '#FFFFFF',
        borderBottom: '1pt solid darkgray',
        fontSize: '8'
    }
});