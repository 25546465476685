import React from "react";

import ChartVerdeSVG from "../../icons/BarChart_verde.svg";

export const ConsumoItem = ({
  onClick,
  title,
  data,
  dataType,
  dataExtra = "",
  editable = false,
  onChange,
  inputName,
  showEditInput,
  setShowEditInput,
}) => {

  return (
    <div>
      <div className="row">
        <div className="is-size-5 is-space-between">
          {title}
          {editable ? (
            <a href="# " onClick={setShowEditInput} className="is-rounded">
              <span className="icon is-small is-left">
                {showEditInput ? (
                  <i className="fa fa-times has-text-grey"></i>
                ) : (
                  <i className="fa fa-pencil has-text-grey"></i>
                )}
              </span>
            </a>
          ) : null}
        </div>
      </div>
      <br />
      <div className="row">
        {!showEditInput ? (
          <div className="columns">
            <div className="column is-6">
              <div className="has-text-bold has-text-centered">
                {data} {dataType}
              </div>
              <div className="has-text-bold has-text-success has-text-centered">
                {dataExtra}
              </div>
            </div>
            <div className="column is-6">
              <img alt="" className="overview-item" src={ChartVerdeSVG} />
            </div>
          </div>
        ) : (
          <div className="columns column">
            <input
              onChange={onChange}
              name={inputName}
              className="input is-link is-rounded"
              placeholder="Nuevo dato"
            />
            <button onClick={onClick} className="button is-success is-rounded">
              Guardar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
