import React from "react";

export const ButtonDropdown = ({ onClick, icon, classColor, children }) => (
  <button onClick={onClick} className={`button dropdown-item ${classColor}`}>
    <span className="icon is-large">
      <i className={`fa fa-${icon}`}></i>
    </span>
    <span>{children}</span>
  </button>
);
