import React from "react";
import $ from "jquery";
import { fecha } from "../utilidades/Fecha";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { InventarioES } from "../reportes/InventarioES";
import { alertWarning, alertError } from "../utilidades/Alertas";
import { MODAL_REPORTE_ES } from "./ConstantsInventario";
import { Service } from "../../services/Service";
import { INVENTARIO, REPORTES } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

export class ReporteES extends React.Component {
  state = {
    iPorteoES: "",
    iMovimientoES: "",
    descargar: false,
    iFechaI: fecha(),
    iFechaF: fecha(),
    periodo: "",
    data: "",
    porteo: "",
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({ descargar: false });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='datetime-local'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iPorteoES: "",
      iMovimientoES: "",
      descargar: false,
      iFechaI: "",
      iFechaF: "",
      periodo: "",
      data: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_REPORTE_ES}`).toggleClass("is-active");
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    let { iPorteoES, iMovimientoES, iFechaI, iFechaF } = this.state;
    if (iPorteoES && iMovimientoES && iFechaI && iFechaF) {
      this.setState({ data: "" });
      this.getReporte(iPorteoES, iFechaI, iFechaF, iMovimientoES);
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  getReporte = async (por, feI, feF, acc) => {
    let res = await Service.getRawResponse( REPORTES + 
      `/getReporteES?por=${por}&feI=${feI}&feF=${feF}&acc=${acc}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({
        data: r,
        descargar: true,
        periodo: this.state.iFechaI + " / " + this.state.iFechaF,
      });
    } else
      alertError("No existen datos para generar el reporte", 4000, "center");
  };

  getPorteo = async (por) => {
    let res = null;
    if (por == 0) {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403){
      clearSession()
    } else {
      this.setState({ porteo: "" });
    }
  };

  componentDidMount() {
    this.getPorteo(sessionStorage.getItem("porteo"));
  }

  render() {
    return (
      <div id={MODAL_REPORTE_ES} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title" id="rep">
              Reporte de E/S
            </p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iPorteoES">
                      Porteo
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iPorteoES"
                          name="iPorteoES"
                          value={this.state.iPorteoES}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un porteo
                          </option>
                          {
                            sessionStorage.getItem("porteo") == 0 ? (
                              <option key="0" value="0">
                                Todos
                              </option>
                            ) : (
                              null
                            )
                          }
                          {this.state.porteo !== null ? (
                            Object.values(this.state.porteo).map((p) => {
                              return (
                                <option
                                  key={p.Id_porteo}
                                  value={p.Id_porteo + "/" + p.nombre}
                                >
                                  {p.nombre}
                                </option>
                              );
                            })
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="column is-6">
                    <label className="label" htmlFor="iMovimientoES">
                      Movimiento
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iMovimientoES"
                          name="iMovimientoES"
                          value={this.state.iMovimientoES}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un movimiento
                          </option>
                          <option value="E/S">E/S</option>
                          <option value="Entrada">Entrada</option>
                          <option value="Salida">Salida</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaI">
                      Fecha inicial
                    </label>
                    <input
                      id="iFechaI"
                      name="iFechaI"
                      value={this.state.iFechaI}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaF">
                      Fecha Final
                    </label>
                    <input
                      id="iFechaF"
                      name="iFechaF"
                      value={this.state.iFechaF}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-3">
                    {this.state.descargar ? (
                      <PDFDownloadLink
                        className="button is-rounded is-danger"
                        document={
                          <InventarioES
                            porteo={this.state.iPorteoES.split("/")[1]}
                            periodo={this.state.periodo}
                            movimiento={this.state.iMovimientoES}
                            data={this.state.data}
                          />
                        }
                        fileName={
                          "InventarioES_" +
                          new Date().toLocaleDateString() +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Generando..." : "Descargar"
                        }
                      </PDFDownloadLink>
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Generar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
        <table id="pdf_table"></table>
      </div>
    );
  }
}
