import React from "react";

// jquery
import $ from "jquery";

// components
import { ModalAddRefacciones } from "./ModalAddRefacciones";
import { ModalAddServicios } from "./ModalAddServicios";
import {
  MODAL_ADD_MANTENIMIENTO,
  MODAL_ADD_REFACCIONES,
  DETALLES_MANTENIMIENTO,
  MODAL_ADD_SERVICIOS,
  VENTANA_MANTENIMIENTO,
  VENTANA_PRINCIPAL,
} from "./ConstantsMantenimiento";
import { Service } from "../../services/Service";
import { MANTENIMIENTO } from "../../services/Config";

export class FormAddMantenimiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iFecha: "",
      iOperador: "",
      iProveedor: "",
      iManoObra: "",
      iServicios: "",
      iRefacciones: "",
      iTaller: "",
      porteadores: null,
      proveedores: null,
      servicios: null,
      refacciones: null,
      iComentarios: "",
    };
  }

  regresar = () => {
    $(`#${VENTANA_MANTENIMIENTO}`).hide("slow");
    $(`#${VENTANA_PRINCIPAL}`).show("slow");
    this.limpiarC();
  };

  modalServicios() {
    $(`#${MODAL_ADD_SERVICIOS}`).toggleClass("is-active");
  }

  modalRefacciones() {
    $(`#${MODAL_ADD_REFACCIONES}`).toggleClass("is-active");
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Limpiar inputs
  limpiarC = () => {
    document.getElementById("areaservicios").value = "";
    document.getElementById("arearefacciones").value = "";

    var elements = document.querySelectorAll(
      "input[type='text'],input[type='email'],input[type='tel'],input[type='number'],select"
    );
    for (var i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iFecha: "",
      iOperador: "",
      iProveedor: "",
      iManoObra: "",
      iServicios: "",
      iRefacciones: "",
      iTaller: "",
      iComentarios: "",
    });
  };

  handleSubmmit = (e) => {
    //if (this.validarCampos()) {
    var aux = [];
    this.state.refacciones.forEach((element) => {
      var json = {
        id: element.id,
        codigo: element.codigo,
        cantidad: element.cantidad,
        precio: element.precio,
      };
      aux.push(json);
    });
    console.log(aux);
    this.props.saveMantenimiento({
      porteo: this.props.selectedItem.Id_porteo,
      fecha: $("#iFecha").val(),
      operador: $("#iOperador").val(),
      proveedor: $("#iProveedor").val(),
      manoObra: $("#iManoObra").val(),
      taller: $("input:radio[name=iTaller]:checked").val(),
      item: this.props.selectedItem,
      servicios: this.state.servicios,
      refacciones: aux,
      comentarios: $("#iComentarios").val(),
      //taller :
    });
    this.regresar();
  };

  validarCampos = () => {
    if (
      $("#fechaman").val().length !== 0 &&
      $("#kilometraje").val().length !== 0 &&
      $("#operadorman").val().length !== 0 &&
      $("#proveedorman").val().length !== 0 &&
      $("#manoobra").val().length !== 0 &&
      $("#areaservicios").val().length !== 0 &&
      $("#arearefacciones").val().length !== 0
    )
      return true;
    else return false;
  };

  setServicios = (serv) => {
    this.setState({ servicios: serv });
    var valores = "";
    serv.forEach((element) => {
      if (element.servicio !== undefined) {
        valores = valores + element.servicio + "\n";
      }
    });
    document.getElementById("areaservicios").value = valores;
  };

  setRefacciones = (ref) => {
    this.setState({ refacciones: ref });
    var valores = "";
    ref.forEach((element) => {
      if (element.codigo !== undefined) {
        valores =
          valores + "[" + element.cantidad + "] " + element.codigo + "\n";
      }
    });
    document.getElementById("arearefacciones").value = valores;
  };

  getProveedores = async () => {
    const res = await Service.getRawResponse(
      MANTENIMIENTO + `/getProveedores?por=${sessionStorage.getItem("porteo")}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ proveedores: r });
    } else {
      this.setState({ proveedores: [] });
    }
  };

  getPorteadores = async () => {
    const res = await Service.getRawResponse(
      MANTENIMIENTO +
        `/getPorteadores?por=${
          $("#porteoId").val() || sessionStorage.getItem("porteo")
        }`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ porteadores: r });
    } else {
      this.setState({ porteadores: [] });
    }
  };

  componentDidMount() {
    this.getProveedores();
    this.getPorteadores();
  }

  render() {
    return (
      <div id={MODAL_ADD_MANTENIMIENTO} hidden>
        <div className="row">
          <div className="columns">
            <div className="is-center-vertical">
              <button
                onClick={this.regresar}
                title="Regresar"
                className="button is-rounded is-circle is-white"
              >
                <span className="icon is-large">
                  <i className="fa fa-arrow-left"></i>
                </span>
              </button>
            </div>
            <div className="column is-3">
              <label className="title is-5">Agregar Mantenimiento</label>
            </div>
            <div className="column is-5" id={DETALLES_MANTENIMIENTO}></div>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-3 is-offset-1">
              <label className="label" htmlFor="iFecha">
                Fecha
              </label>
              <input
                id="iFecha"
                name="iFecha"
                value={this.state.iFecha}
                onChange={this.handleInputChange.bind(this)}
                className="input is-rounded is-success"
                type="date"
              />
            </div>

            <div className="column is-3">
              <label className="label" htmlFor="iOperador">
                Operador/Chofer
              </label>
              <div className="control">
                <div className="select is-rounded is-success is-fullwidth">
                  <select
                    id="iOperador"
                    name="iOperador"
                    value={this.state.iOperador}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="" defaultValue>
                      Seleccione un operador
                    </option>
                    {this.props.porteadores ? (
                      Object.values(this.props.porteadores).map((porteador) => {
                        return (
                          <option
                            key={porteador.Id_trabajador}
                            value={porteador.nombre_trabajador}
                          >
                            {porteador.nombre_trabajador +
                              " " +
                              porteador.apellido_paterno_trabajador +
                              " " +
                              porteador.apellido_materno_trabajador}
                          </option>
                        );
                      })
                    ) : (
                      <option value=" ">No hay datos</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-3 is-offset-1">
              <label className="label" htmlFor="iProveedor">
                Proveedor
              </label>
              <div className="control">
                <div className="select is-rounded is-success is-fullwidth">
                  <select
                    id="iProveedor"
                    name="iProveedor"
                    value={this.state.iProveedor}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="" defaultValue>
                      Seleccione un proveedor
                    </option>
                    {this.props.proveedores ? (
                      Object.values(this.props.proveedores).map((proveedor) => {
                        return (
                          <option
                            key={proveedor.Id_proveedor}
                            value={proveedor.empresa_proveedor}
                          >
                            {proveedor.empresa_proveedor}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No hay datos</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div className="column is-3">
              <label className="label" htmlFor="iManoObra">
                Mano de obra $
              </label>
              <input
                id="iManoObra"
                name="iManoObra"
                value={this.state.iManoObra}
                onChange={this.handleInputChange.bind(this)}
                className="input is-rounded is-success"
                type="number"
                placeholder="950"
              />
            </div>

            <div className="column is-3">
              <label className="label" htmlFor="iTaller">
                Taller
              </label>
              <div className="control">
                <div className="row">
                  <label className="radio">
                    <input
                      name="iTaller"
                      value="Interno"
                      onChange={this.handleInputChange.bind(this)}
                      type="radio"
                      defaultChecked
                    />{" "}
                    Interno
                  </label>
                </div>
                <div className="row">
                  <label className="radio">
                    <input
                      name="iTaller"
                      value="Externo"
                      onChange={this.handleInputChange.bind(this)}
                      type="radio"
                    />{" "}
                    Externo
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-4 is-offset-1">
              <button
                onClick={this.modalServicios}
                className="button is-link is-inverted"
              >
                <span className="icon">
                  <i className="fa fa-plus"></i>
                </span>
                <span>Agregar Servicios</span>
              </button>
              <br />
              <textarea
                onChange={this._inputServicios}
                id="areaservicios"
                className="textarea"
                placeholder="..."
                rows="7"
                readOnly
              ></textarea>
            </div>
            <div className="column is-4 is-offset-1">
              <button
                onClick={this.modalRefacciones}
                className="button is-link is-inverted"
              >
                <span className="icon">
                  <i className="fa fa-plus"></i>
                </span>
                <span>Agregar Refacciones</span>
              </button>
              <br />
              <textarea
                onChange={this._inputRefacciones}
                id="arearefacciones"
                className="textarea"
                placeholder="..."
                rows="7"
                readOnly
              ></textarea>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-9 is-offset-1">
              <label className="label" htmlFor="iComentarios">
                Comentarios (Motivo de servicio)
              </label>
              <textarea
                id="iComentarios"
                name="iComentarios"
                onChange={this._inputComentariosMantenimiento}
                className="textarea"
                placeholder="..."
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>

        <div className="column is-3 is-offset-7">
          <button
            onClick={this.handleSubmmit}
            className="button is-rounded is-success"
          >
            Guardar
          </button>
          <label> </label>
          <button
            onClick={this.regresar}
            className="button is-rounded is-warning"
          >
            Cancelar
          </button>
        </div>

        <ModalAddRefacciones
          setRefacciones={this.setRefacciones}
          selectedItem={this.props.selectedItem}
        />
        <ModalAddServicios setServicios={this.setServicios} />
      </div>
    );
  }
}
