import React from "react";
import { alertWarning } from "../utilidades/Alertas";
import Autosuggest from "react-autosuggest";

// jquery
// eslint-disable-next-line no-unused-vars
import $, { map } from "jquery";

// components
import { Table } from "../utilidades/Table";
import { MODAL_ADD_REFACCIONES } from "./ConstantsMantenimiento";
import { Service } from "../../services/Service";
import { INVENTARIO, MANTENIMIENTO } from "../../services/Config";

// columns for table
let columns = [
  { title: "ARTICULO", field: "codigo" },
  { title: "CANTIDAD", field: "cantidad" },
  {
    title: "",
    field: "img",
    width: 65,
    formatter: function (cell, formatterParams) {
      return "<img alt='image' src=" + cell.getValue() + " />";
    },
  },
];

export class ModalAddRefacciones extends React.Component {
  state = {
    iCodigoMAR: "",
    iCantidadMAR: "",
    iArticulo: "",
    arrayRefacciones: [],
    selectedItem: "",
    tipoEntrada: true,
    iNombre: "",
    value: "",
    suggestions: [],
    table: null,
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.arrayRefacciones);
    }
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequestedCod = async ({ value }) => {
    let res = await Service.getRawResponse(
      MANTENIMIENTO +
        `/getRefaccion?por=${this.props.selectedItem["porteo"]}&cod=${value}&placa=${this.props.selectedItem["placa"]}`
    );
    if (res.status === 200) {
      let r = await res.json();
      console.log(r);
      this.setState({
        suggestions: r.map((row) => ({
          name: row.nombre_producto,
          img: row.img_producto,
          id: row.codigo_producto,
          cant_asignada: row.cantidad_asignada,
        })),
      });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  onSuggestionsFetchRequestedNom = async ({ value }) => {
    let res = await Service.getRawResponse(
      MANTENIMIENTO +
        `/getRefaccion?por=${this.props.selectedItem["porteo"]}&nom=${value}&placa=${this.props.selectedItem["placa"]}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({
        suggestions: r.map((row) => ({
          name: row.nombre_producto,
          img: row.img_producto,
          id: row.codigo_producto,
          cant_asignada: row.cantidad_asignada,
        })),
      });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  // tipo de entrada por nombre o por código
  tipoEntrada = (e) => {
    this.setState({
      tipoEntrada: e.target.value === "codigo" ? true : false,
      value: "",
      suggestions: [],
      iCantidadMAR: "",
    });
  };

  // valor para el input del nombre
  inputNombre = (e) => {
    this.setState({ iNombre: e.target.value });
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  limpiarC = () => {
    this.setState({
      iCodigoMAR: "",
      iCantidadMAR: "",
      iArticulo: "",
      arrayRefacciones: [],
      selectedItem: "",
      value: "",
      suggestions: [],
    });
  };

  // onFocus
  reiniciarInput = (e) => {
    if (e.target.name === "nombre" || e.target.name === "codigo")
      this.setState({
        value: "",
      });
    else
      this.setState({
        [e.target.name]: "",
      });
  };

  handleClick = (e) => {
    $(`#${MODAL_ADD_REFACCIONES}`).toggleClass("is-active");
    this.limpiarC();
  };

  handleAgregar = (e) => {
    e.preventDefault();
    console.log(this.state);
    if (this.state.tipoEntrada) {
      if (
        document.getElementById("codigo").value.length > 0 &&
        document.getElementById("iCantidadMAR").value.length > 0
      )
        this.getCodigo(this.props.selectedItem["porteo"], this.state.value);
      else alertWarning("Faltan campos", 4000, "center");
    } else {
      if (
        document.getElementById("nombre").value.length > 0 &&
        document.getElementById("iCantidadMAR").value.length > 0
      )
        this.getCodigo(this.props.selectedItem["porteo"], this.state.value);
      else alertWarning("Faltan campos", 4000, "center");
    }
  };

  // backend
  handleSubmmit = (e) => {
    this.props.setRefacciones(this.state.arrayRefacciones);
    $(`#${MODAL_ADD_REFACCIONES}`).toggleClass("is-active");
    this.limpiarC();
  };

  getCodigo = async (por, cod) => {
    let res = await Service.getRawResponse(
      MANTENIMIENTO + `/getCodigo?por=${por}&cod=${cod}`
    );
    if (res.status === 200) {
      let r = await res.json();
      let { iCantidadMAR, arrayRefacciones } = this.state;
      let aux = arrayRefacciones;
      aux.push({
        id: r[0].Id_producto,
        codigo: r[0].codigo_producto,
        nombre: r[0].nombre_producto,
        cantidad: iCantidadMAR,
        img: r[0].img_producto,
        precio: r[0].precio_producto,
      });
      this.setState({ arrayRefacciones: aux, value: "", iCantidadMAR: "" });
    } else {
      alertWarning("No existe una refacción con ese código", 2000, "center");
    }
  };
  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  getPorteo = async (por) => {
    const res = await Service.getRawResponse(
      INVENTARIO + `/getPorteo?por=${por}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ porteo: r });
    } else {
      this.setState({ porteo: "" });
    }
  };

  componentDidMount() {
    this.getPorteo(sessionStorage.getItem("porteo"));
  }

  render() {
    let { value, suggestions } = this.state;
    return (
      <div id={MODAL_ADD_REFACCIONES} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Agregar refacciones</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <label className="label">Agregar por:</label>
                <div className="control">
                  <label className="radio">
                    <input
                      onChange={this.tipoEntrada}
                      type="radio"
                      name="tipo"
                      value="codigo"
                      defaultChecked
                    />{" "}
                    Código
                  </label>
                  <label className="radio">
                    <input
                      onChange={this.tipoEntrada}
                      type="radio"
                      name="tipo"
                      value="nombre"
                    />{" "}
                    Nombre
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  {this.state.tipoEntrada ? (
                    <div className="column is-4">
                      <label className="label" htmlFor="codigo">
                        Código
                      </label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedCod
                        }
                        onSuggestionsClearRequested={() => {
                          this.setState({
                            suggestions: [],
                          });
                        }}
                        getSuggestionValue={(suggestion) => suggestion.id}
                        renderSuggestion={(suggestion) => (
                          <div>
                            <img
                              style={{ width: "50px" }}
                              src={suggestion.img}
                              alt={suggestion.img}
                            />
                            {suggestion.name}
                            <p>{"asignadas: " + suggestion.cant_asignada}</p>
                          </div>
                        )}
                        inputProps={{
                          placeholder: "Código",
                          id: "codigo",
                          name: "codigo",
                          onFocus: this.reiniciarInput.bind(this),
                          value,
                          className: "input is-rounded is-success",
                          onChange: this.onChange,
                        }}
                      />
                    </div>
                  ) : (
                    <div className="column is-4">
                      <label className="label" htmlFor="nombre">
                        Nombre
                      </label>
                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedNom
                        }
                        onSuggestionsClearRequested={() => {
                          this.setState({
                            suggestions: [],
                          });
                        }}
                        getSuggestionValue={(suggestion) => suggestion.id}
                        renderSuggestion={(suggestion) => (
                          <div>
                            <img
                              style={{ width: "50px" }}
                              src={suggestion.img}
                              alt={suggestion.img}
                            />
                            {suggestion.name}
                            <p>{"asignadas: " + suggestion.cant_asignada}</p>
                          </div>
                        )}
                        inputProps={{
                          placeholder: "Nombre",
                          id: "nombre",
                          name: "nombre",
                          value,
                          className: "input is-rounded is-success",
                          onChange: this.onChange,
                        }}
                      />
                    </div>
                  )}

                  <div className="column is-4">
                    <label className="label" htmlFor="iCantidadMAR">
                      Cantidad
                    </label>
                    <input
                      id="iCantidadMAR"
                      name="iCantidadMAR"
                      value={this.state.iCantidadMAR}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      onFocus={this.reiniciarInput.bind(this)}
                      placeholder="Cantidad"
                    />
                  </div>

                  <div className="column is-4 button-bottom">
                    <button
                      onClick={this.handleAgregar}
                      className="button is-rounded is-success"
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <Table
                      columns={columns}
                      setItem={this.setItem}
                      getTable={this.getTable}
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
