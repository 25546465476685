// react
import React from 'react';

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table/lib'
// fuente a utilizar
import ProductSans from './style/ProductSansRegular.ttf'
import ProductSansBold from './style/ProductSansBold.ttf'
// estilos
import { styles } from './style/Styles'
//LOGOTIPO
import logo from './../../images/LOGOLOGISTIC.png'

Font.register({
    family: 'ProductSans',
    fonts: [
        { src: ProductSans }, // font-style: normal, font-weight: normal
        { src: ProductSansBold, fontWeight: 'bold' },
    ]
});


export const Mantenimiento = ({ porteo, transporte, periodo, total, data }) => (
    <Document>
        <Page size="A4" orientation='landscape' style={styles.page} >
            <View style={styles.logoTitle}>
                <Image style={styles.image} src={logo} />
                <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
            </View>
            <View style={styles.info}>
                <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
                <Text style={styles.contacto}>Tel: (341) 410-6511 ó (341) 410-6458</Text>
            </View>

            <Text style={styles.subtitulo}>
                Reporte de Mantenimientos
                </Text>

            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Porteo:</Text>
                    <Text style={styles.parametros}>{porteo}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Periodo:</Text>
                    <Text style={styles.parametros}>{periodo}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Transporte:</Text>
                    <Text style={styles.parametros}>{transporte}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Total:</Text>
                    <Text style={styles.dinero}>{'$'+total.toLocaleString()}</Text>
                </View>

            </View>

            <View >
                <Table data={data} >
                    <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <TableCell style={styles.tablaHeader} weighting={0.05}>#</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.1}>TALLER</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.15}>PROVEEDOR</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.1}>PLACAS</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.1}>SUBTOTAL</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.25}>
                            <Table>
                                <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <TableCell style={styles.tablaHeader} weighting={1}>SERVICIOS</TableCell>
                                </TableHeader>
                            </Table>
                        </TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.25}>
                            <Table>
                                <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <TableCell style={styles.tablaHeader} weighting={0.5}>CANTIDAD</TableCell>
                                    <TableCell style={styles.tablaHeader} weighting={0.5}>REFACCIÓN</TableCell>
                                </TableHeader>
                            </Table>
                        </TableCell>
                    </TableHeader>
                    <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <DataTableCell style={styles.tabla} weighting={0.05} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                        <DataTableCell style={styles.tabla} weighting={0.1} getContent={(r) => r.taller} />
                        <DataTableCell style={styles.tabla} weighting={0.15} getContent={(r) => r.proveedor} />
                        <DataTableCell style={styles.tabla} weighting={0.1} getContent={(r) => r.placa} />
                        <DataTableCell style={styles.tabla} weighting={0.1} getContent={(r) => '$' + r.total_mantenimiento.toLocaleString()} />
                        <DataTableCell style={styles.tabla} weighting={0.25} getContent={(r) => (
                            <Table data={r.servicios}>
                                <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <DataTableCell style={styles.tabla} weighting={1} getContent={(re) => re.servicio} />
                                </TableBody>
                            </Table>
                        )} />
                        <DataTableCell style={styles.tabla} weighting={0.25} getContent={(r) => (
                            <Table data={r.refacciones}>
                                <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                                    <DataTableCell style={styles.tabla} weighting={0.5} getContent={(re) => re.cantidad.toLocaleString()} />
                                    <DataTableCell style={styles.tabla} weighting={0.5} getContent={(re) => re.codigo} />
                                </TableBody>
                            </Table>
                        )} />
                    </TableBody>
                </Table>
            </View>
        </Page>
    </Document>
);