export const MODAL_REPORTE_MANTENIMIENTOS = "reportemantenimientos"

export const MODAL_ADD_MANTENIMIENTO = 'ventanaman'

export const MODAL_DETALLES_MANTENIMIENTO = 'modaldetallesmantenimiento'

export const VENTANA_MANTENIMIENTO = 'ventanaman'
export const MODAL_ADD_REFACCIONES = 'agregarrefacciones'
export const MODAL_ADD_SERVICIOS = 'agregarservicios'
export const VENTANA_PRINCIPAL = 'principalman'

export const DETALLES_MANTENIMIENTO = 'detallesmantenimiento'
