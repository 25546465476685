// React
import React from "react";
import { connect } from "react-redux";

// components
import { Table } from "../utilidades/Table";
import FormAddArticulo from "./FormAddArticulo";
import { FormTransferencia } from "./FormTransferencia";
import { DeleteArticulo } from "./DeleteArticulo";
import { FormMovimiento } from "./FormMovimiento";
import { ModificarArticulo } from "./ModificarArticulo";
import { ReporteProductos } from "./ReporteProductos";
import { ReportePersonal } from "./ReportePersonal";
import { ReporteFaltantes } from "./ReporteFaltantes";
import { ReporteES } from "./ReporteES";
import { ModalDetallesProducto } from "./ModalDetalles";
import { alertSuccess, alertError, alertWarning } from "../utilidades/Alertas";
import { SelectPorteo } from "../utilidades/SelectPorteo";

// jquery
import $ from "jquery";
import { Sesion } from "../../redux/actions/SesionActions";
import { InputSearch } from "../common/InputSearch";
import { ButtonRounded } from "../common/ButtonRounded";
import { ButtonDropdown } from "../common/ButtonDropdown";
import { SelectOption } from "../common/SelectOption";
import { Card } from "./Card";
import { getColumns } from "./ColumnsInventario";
import {
  CATEGORIAS,
  MODAL_ADD_ARTICULO,
  MODAL_MOVIMIENTO,
  MODAL_REPORTES_MENU,
  MODAL_REPORTE_ES,
  MODAL_REPORTE_FALTANTES,
  MODAL_REPORTE_PERSONAL,
  MODAL_REPORTE_PRODUCTOS,
  MODAL_TRANSFERENCIA,
} from "./ConstantsInventario";
import {
  clearSession,
  formatPorteoData,
  hasAccess,
} from "../utilidades/DataHelpers";
import { ROLES } from "../utilidades/Constantes";
import { Service } from "../../services/Service";
import { INVENTARIO } from "../../services/Config";

class Inventario extends React.Component {
  state = {
    datos: [],
    selectedItem: "",
    id: "",
    flag: true,
    existencia: "",
    productos: "",
    inputCategoria: "",
    inputCodigo: "",
    table: null,
    inputPorteo: "",
    categorias: [],
    unidadesMedida: [],
    porteos: [],
    isMounted: false,
    currentPage: 1,
    totalPages: 1,
    completedData: [],
    dataForFilters: [],
    selectedOption: "opcion1",
    inputPlaceholder: "Buscar por código...",
    typingTimeout: null,
  };

  handleOptionChange = (event) => {
    this.setState({ selectedOption: event.target.value });

    // Cambia el placeholder del input en función de la opción seleccionada
    if (event.target.value === "opcion1") {
      this.setState({ inputPlaceholder: "Buscar por código..." });
    } else if (event.target.value === "opcion2") {
      this.setState({ inputPlaceholder: "Buscar por nombre..." });
    }
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.datos);
      //De momento los filtros como parametros a las Tablas con Paginación ya no son necesarios ya que se actualizó la funcionalidad localmente
      // table.setFilter([
      //   {
      //     field: "codigo_producto",
      //     type: "like",
      //     value: this.state.inputEmpresa,
      //   },
      //   {
      //     field: "categoria_producto",
      //     type: "like",
      //     value: this.state.inputCategoria,
      //   },
      //   {
      //     field: "Id_porteo",
      //     type: "like",
      //     value: this.state.inputPorteo.split("/")[0],
      //   },
      // ]);
    }
  };

  handleInputNameCode = (e) => {
    const text = e.target.value;
    if (this.state.selectedOption === "opcion1") {
      // Cancela el temporizador existente (si existe)
      clearTimeout(this.state.typingTimeout);

      // Inicia un nuevo temporizador para detectar cuando el usuario deja de escribir
      const newTypingTimeout = setTimeout(() => {
        // Realiza la acción deseada cuando el usuario ha terminado de escribir
        this.filtrarTabla(text, "code");
        console.log("Usuario ha terminado de escribir:", text);
      }, 1500); // Espera 1000 ms (1 segundo) después de que el usuario deje de escribir

      // Actualiza el estado del valor del input y el temporizador
      this.setState({ inputCodigo: text });
      this.setState({ typingTimeout: newTypingTimeout });
      this.setState({
        inputCategoria: "Categoria",
      });
      this.setState({
        inputPorteo: "Porteo",
      });
    } else if (this.state.selectedOption === "opcion2") {
      // Cancela el temporizador existente (si existe)
      clearTimeout(this.state.typingTimeout);

      // Inicia un nuevo temporizador para detectar cuando el usuario deja de escribir
      const newTypingTimeout = setTimeout(() => {
        // Realiza la acción deseada cuando el usuario ha terminado de escribir

        this.filtrarTabla(text, "name");
        console.log("Usuario ha terminado de escribir:", text);
      }, 1500); // Espera 1000 ms (1 segundo) después de que el usuario deje de escribir

      // Actualiza el estado del valor del input y el temporizador
      this.setState({ inputCodigo: text });
      this.setState({ typingTimeout: newTypingTimeout });
      this.setState({
        inputCategoria: "Categoria",
      });
      this.setState({
        inputPorteo: "Porteo",
      });
    }
  };
  handleInputCategoria = (e) => {
    this.filtrarTabla(e.target.value, "categoria");
    this.setState({ inputCategoria: e.target.value });
    this.setState({
      inputCodigo: "",
    });
    this.setState({
      inputPorteo: "Porteo",
    });
  };

  handleInputPorteo = (e) => {
    this.filtrarTabla(e.target.value.split("/")[0], "porteo");
    this.setState({ inputPorteo: e.target.value });
    this.setState({
      inputCodigo: "",
    });
    this.setState({
      inputCategoria: "Categoria",
    });
  };

  filtrarTabla = async (valorAFiltrar, filtro) => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    let result = [];

    result = await Service.getRawResponse(
      INVENTARIO +
        `/getProductosBy` +
        `?por=${sessionStorage.getItem("porteo")}&` +
        `filter=${filtro}&` +
        `value=${valorAFiltrar}`
    );
    let data = [];
    if (result.status === 200) {
      data = await result.json();
      this.setState({ datos: data }); //Data renderizada en 10 elementos
    } else this.setState({ datos: [] });
    this.props.setLoading(false);
  };

  //Controles de Paginación de la Tabla
  first = () => {
    this.setState({ currentPage: 1 });
    let data = this.getArticulos(1);
    this.setState({
      datos: data,
    });
    //Reseteo de los inputs a valores predeterminados
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputCodigo: "",
    });
  };
  prev = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
    let data = this.getArticulos(this.state.currentPage - 1);
    this.setState({
      datos: data,
    });
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputCodigo: "",
    });
  };
  next = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
    let data = this.getArticulos(this.state.currentPage + 1);
    this.setState({
      datos: data,
    });
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputCodigo: "",
    });
  };
  last = () => {
    this.setState({ currentPage: this.state.totalPages });
    let data = this.getArticulos(this.state.totalPages);
    this.setState({
      datos: data,
    });
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputPorteo: "Porteo",
    });
    this.setState({
      inputCodigo: "",
    });
  };

  // turn on modals
  modalAddArticulo = (e) => {
    if (sessionStorage.getItem("rol") === "admin") {
      $("#selectPorteo").toggleClass("is-active");
    } else {
      $(`#${MODAL_ADD_ARTICULO}`).toggleClass("is-active");
    }
  };

  modalTransferencia(e) {
    $(`#${MODAL_TRANSFERENCIA}`).toggleClass("is-active");
  }

  modalMovimiento(e) {
    $(`#${MODAL_MOVIMIENTO}`).toggleClass("is-active");
  }

  reportesMenu(e) {
    $(`#${MODAL_REPORTES_MENU}`).toggleClass("is-active");
  }

  modalProductos(e) {
    $(`#${MODAL_REPORTE_PRODUCTOS}`).toggleClass("is-active");
  }

  modalPersonal(e) {
    $(`#${MODAL_REPORTE_PERSONAL}`).toggleClass("is-active");
  }

  modalFaltantes(e) {
    $(`#${MODAL_REPORTE_FALTANTES}`).toggleClass("is-active");
  }

  modalES(e) {
    $(`#${MODAL_REPORTE_ES}`).toggleClass("is-active");
  }

  //Obtener articulos
  getArticulos = async (page) => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    let result = await Service.getRawResponse(
      INVENTARIO + `?por=${sessionStorage.getItem("porteo")}&` + `page=${page}`
    );
    let data = [];
    // Verifica si el componente aún está montado
    if (this.state.isMounted) {
      if (result.status === 200) {
        data = await result.json();
        this.setState({ datos: data }); //Data renderizada en 10 elementos
        this.sinExistencia();
        this.getTotalPaginas();
      } else this.setState({ datos: [] });

      this.props.setLoading(false);
    }
  };

  //Articulos sin existencia
  getTotalPaginas = async () => {
    let res = await Service.getRawResponse(
      INVENTARIO + `/getTotalPaginas?por=${sessionStorage.getItem("porteo")}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ totalPages: r[0]["total_paginas"] });
    } else this.setState({ totalPages: 1 });
  };

  //Obtener categorias
  getCategorias = async () => {
    let result = await Service.getRawResponse(INVENTARIO + `/getCategorias`);
    if (result.status === 200) {
      const res = await result.json();
      console.log(res);
      this.setState({ categorias: res });
    }
  };

  //Obtener unidades de medida
  getUnidadesMedida = async () => {
    let result = await Service.getRawResponse(
      INVENTARIO + `/getUnidadesMedida`
    );
    if (result.status === 200) {
      const res = await result.json();
      console.log(res);
      this.setState({ unidadesMedida: res });
    }
  };

  //Añadir un articulo
  add = async (formData) => {
    let res = await Service.postSimpleRawResponse(INVENTARIO, formData);
    if (res.status === 201) {
      alertSuccess("Producto agregado", 4000, "center");
      await this.getArticulos(1);
      //}
    } else if (res.status === 500) {
      alertError("Ocurrió un error", 4000, "center");
    }
  };

  //Modificar
  update = async (formData) => {
    let res = await Service.putSimpleRawResponse(INVENTARIO, formData);
    if (res.status === 200) {
      alertSuccess("Producto modificado", 4000, "center");
      this.getArticulos(1);
      //}
    } else if (res.status === 500) {
      alertError("Ocurrió un error", 4000, "center");
    }
  };

  //Eliminar un articulo
  remove = async () => {
    let articulo = {
      id: this.state.selectedItem["Id_producto"],
      porteo: this.state.selectedItem["Id_porteo"],
    };
    let res = await Service.deleteRawResponse(INVENTARIO, articulo);
    if (res.status === 200) {
      alertSuccess("Producto eliminado", 4000, "center");
      this.getArticulos(1);
    }
  };

  //Movimiento
  movimiento = async (mov) => {
    let res = await Service.postRawResponse(INVENTARIO + "/movimiento", mov);
    if (res.status === 200) {
      alertSuccess("Movimiento realizado", 4000, "center");
      this.getArticulos(1);
    } else if (res.status === 500) {
      alertError("Ocurrió un error", 4000, "center");
    }
  };

  //Transferencia
  transferencia = async (trans) => {
    let res = await Service.postRawResponse(
      INVENTARIO + "/transferencia",
      trans
    );
    if (res.status === 200) {
      alertSuccess("Transferencia realizada", 4000, "center");
      this.getArticulos(1);
    }
  };

  //Articulos sin existencia
  sinExistencia = async () => {
    let res = await Service.getRawResponse(
      INVENTARIO + `/getExistenciaCero?por=${sessionStorage.getItem("porteo")}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ existencia: r[0]["count(*)"] });
    } else this.setState({ existencia: 0 });
  };

  //Sin articulos
  sinArticulos = async () => {
    let res = await Service.getRawResponse(
      INVENTARIO + `/getSinExistencia?por=${sessionStorage.getItem("porteo")}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ datos: r });
      this.sinExistencia();
    }
  };

  //Sin articulos
  getPorteos = async (setLoading) => {
    let res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=0`);
    if (res.status === 200) {
      let r = await res.json();
      // console.log(r);
      this.setState({ porteos: r });
    } else if (res.status === 403) {
      clearSession();
    }
  };

  //Elemento seleccionado en la tabla
  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  turnOn = () => {
    this.setState({ flag: true });
  };

  turnOff = () => {
    this.setState({ flag: false });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.setState({ isMounted: true });
    this.getArticulos(this.state.currentPage);
    //this.getPorteos();
    this.getCategorias();
    this.getUnidadesMedida();
  }
  componentWillUnmount() {
    this.setState({ isMounted: false }); // Establece la bandera isMounted en false cuando el componente se desmonta
  }

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="columns">
            <div className="column is-9">
              <label className="title is-5">Inventario</label>
            </div>
            <div className="column is-3">
              {hasAccess(this.props.userInfo.rol, [
                ROLES.ADMIN,
                ROLES.ENCARGADO_COMPRAS,
              ]) ? (
                <ButtonRounded
                  onClick={this.modalAddArticulo}
                  tooltip="Nuevo Articulo"
                  icon="plus"
                  classColor="is-success"
                />
              ) : null}
              <label> </label>
              {hasAccess(this.props.userInfo.rol, [
                ROLES.ADMIN,
                ROLES.ENCARGADO_COMPRAS,
              ]) ? (
                <ButtonRounded
                  onClick={this.modalTransferencia}
                  tooltip="Nueva Transferencia"
                  icon="mail-reply"
                  classColor="is-warning"
                />
              ) : null}
              <label> </label>
              {hasAccess(this.props.userInfo.rol, [
                ROLES.ADMIN,
                ROLES.ENCARGADO_COMPRAS,
                ROLES.ENCARGADO_INVENTARIO,
              ]) ? (
                <ButtonRounded
                  onClick={this.modalMovimiento}
                  tooltip="Nuevo Movimiento"
                  icon="exchange"
                  classColor="is-danger"
                />
              ) : null}
              <label> </label>
              <div id="reportesmenu" className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                  <ButtonRounded
                    tooltip="Reportes"
                    icon="file-text-o"
                    classColor="is-link"
                  />
                  <label> </label>
                </div>
                <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                  <div className="dropdown-content">
                    <ButtonDropdown
                      onClick={this.modalProductos}
                      classColor="is-link"
                      icon="cubes"
                    >
                      Productos
                    </ButtonDropdown>
                    <hr className="dropdown-divider" />

                    <ButtonDropdown
                      onClick={this.modalPersonal}
                      classColor="is-link"
                      icon="user-o"
                    >
                      Personal
                    </ButtonDropdown>
                    <hr className="dropdown-divider" />

                    <ButtonDropdown
                      onClick={this.modalFaltantes}
                      classColor="is-link"
                      icon="times"
                    >
                      Faltantes
                    </ButtonDropdown>
                    <hr className="dropdown-divider" />

                    <ButtonDropdown
                      onClick={this.modalES}
                      classColor="is-link"
                      icon="arrows-h"
                    >
                      E/S
                    </ButtonDropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row columns">
          <div className="column control">
            <label className="radio">
              <input
                type="radio"
                name="foobar"
                value="opcion1"
                checked={this.state.selectedOption === "opcion1"}
                onChange={this.handleOptionChange}
              />{" "}
              Código
            </label>
            <label className="radio rounded">
              <input
                type="radio"
                name="foobar"
                value="opcion2"
                checked={this.state.selectedOption === "opcion2"}
                onChange={this.handleOptionChange}
              />{" "}
              Artículo
            </label>
          </div>
        </div>
        <div className="row">
          <div className="columns">
            <div className="column is-8">
              <InputSearch
                onChange={this.handleInputNameCode}
                name="inputCodigo"
                placeholder={this.state.inputPlaceholder}
                key="buscarPorCodigo"
              />
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputCategoria"
                defaultValue="Categoria"
                value={this.state.inputCategoria}
                onChange={this.handleInputCategoria}
                data={CATEGORIAS}
              />
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputPorteo}
                data={formatPorteoData(this.props.userInfo.porteo)}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
          </div>
          <div className="row">
            <Table
              data={this.state.datos}
              columns={getColumns(this.props.userInfo.rol)}
              setItem={this.setItem}
              getTable={this.getTable}
            />
            <div className="controls-table">
              <div className="">
                Página {this.state.currentPage} de {this.state.totalPages}
              </div>
              <div>
                <ButtonRounded
                  onClick={this.first}
                  tooltip="Primera página"
                  icon="angle-double-left"
                  classColor="is-light has-text-black"
                />
                <ButtonRounded
                  onClick={this.prev}
                  disabled={this.state.currentPage === 1 ? true : false}
                  tooltip="Anterior"
                  icon="angle-left"
                  classColor="is-light has-text-black"
                />
                <ButtonRounded
                  onClick={this.next}
                  tooltip="Siguiente"
                  icon="angle-right"
                  classColor="is-light has-text-black"
                  disabled={
                    this.state.currentPage === this.state.totalPages
                      ? true
                      : false
                  }
                />
                <ButtonRounded
                  onClick={this.last}
                  tooltip="Ultima página"
                  icon="angle-double-right"
                  classColor="is-light has-text-black"
                />
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="columns">
              <div className="column is-4">
                <Card
                  key="cardExistencia"
                  title="Sin Existencia"
                  onClick={this.sinArticulos.bind(this)}
                  data={this.state.existencia}
                  message="artículos"
                  classColor="is-danger"
                  icon="times-circle"
                />
              </div>
            </div>
          </div>
        </div>
        <SelectPorteo modal="#formaddarticulo" />
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_COMPRAS,
        ]) ? (
          <FormAddArticulo
            add={this.add}
            categorias={this.state.categorias}
            unidadesMedida={this.state.unidadesMedida}
          />
        ) : null}
        {this.props.userInfo.rol === ROLES.ADMIN ? (
          <ModificarArticulo
            update={this.update}
            turnOn={this.turnOn}
            turnOff={this.turnOff}
            selectedItem={this.state.selectedItem}
            flag={this.state.flag}
            categorias={this.state.categorias}
            unidadesMedida={this.state.unidadesMedida}
          />
        ) : null}
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_COMPRAS,
          ROLES.ENCARGADO_INVENTARIO,
        ]) ? (
          <FormTransferencia
            productos={this.state.productos}
            transferencia={this.transferencia}
          />
        ) : null}
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_COMPRAS,
          ROLES.ENCARGADO_INVENTARIO,
        ]) ? (
          <FormMovimiento
            productos={this.state.productos}
            movimiento={this.movimiento}
          />
        ) : null}
        {this.props.userInfo.rol === ROLES.ADMIN ? (
          <DeleteArticulo remove={this.remove} />
        ) : null}
        <ReporteProductos />
        <ReportePersonal />
        <ReporteFaltantes getArticulos={this.getArticulos} />
        <ReporteES selectedItem={this.state.selectedItem} />
        <ModalDetallesProducto selectedItem={this.state.selectedItem} />
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventario);
