// React
import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

// components
import { Table } from "../utilidades/Table";
import { SelectOption } from "../common/SelectOption";

import ChartVerdeSVG from "../../icons/BarChart_verde.svg";
import ChartAzulSVG from "../../icons/BarChart_azul.svg";
import ChartRojaSVG from "../../icons/BarChart_rojo.svg";

import $ from "jquery";
import { COLUMNS_ENTREGAS, COLUMNS_PORTEADORES } from "./ColumnsOntime";
import { InputSearch } from "../common/InputSearch";
import { formatPorteoDataSimple } from "../utilidades/DataHelpers";
import { Service } from "../../services/Service"
import { ONTIME } from "../../services/Config";

class Ontime extends React.Component {
  state = {
    buscarEntregas: "",
    filtroEntregas: "",
    dataP: [],
    dataE: [],
    table: null,
    selectedItem: "",
    inputTipo: "Pedido",
    promedioGrl: 0,
    promedioProductividad: 0,
    promedioGps: 0,
    inputMes: "",
    inputPorteo: "",
    months: [
      { mes: 1, nombre: "Enero" },
      { mes: 2, nombre: "Febrero" },
      { mes: 3, nombre: "Marzo" },
      { mes: 4, nombre: "Abril" },
      { mes: 5, nombre: "Mayo" },
      { mes: 6, nombre: "Junio" },
      { mes: 7, nombre: "Julio" },
      { mes: 8, nombre: "Agosto" },
      { mes: 9, nombre: "Septiembre" },
      { mes: 10, nombre: "Octubre" },
      { mes: 11, nombre: "Noviembre" },
      { mes: 12, nombre: "Diciembre" },
    ],
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.dataP);
    }
  };

  getTable2 = (table) => {
    if (table !== null) {
      table.setData(this.state.dataE);
      // table.setFilter([
      //   {
      //     field: "codigo_paquete",
      //     type: "like",
      //     value: this.state.buscarEntregas,
      //   },
      //   {
      //     field: "ruta_paquete",
      //     type: "like",
      //     value: this.state.filtroEntregas,
      //   },
      // ]);
    }
  };

  getPorteadores = async () => {
    let promedioGrl = 0,
      promedioProductividad = 0,
      promedioGps = 0,
      contador = 0;
    let porteo = this.state.inputPorteo;
    if (this.props.userInfo.porteo.length === 1) {
      porteo = this.props.userInfo.porteo[0].Id_porteo;
    } else if (porteo === "" && this.props.userInfo.porteo.length > 1) {
      porteo = 0;
    }
    const res = await Service.getRawResponse( ONTIME +
      `/Porteadores?tipo=${this.state.inputTipo}&mes=${this.state.inputMes}&porteo=${porteo}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ dataP: r });
      for (let i in r) {
        promedioGrl += r[i].entregados_porcentaje;
        promedioProductividad += r[i].entregados;
        promedioGps += r[i].entregados_gps_porcentaje;
        contador += 1;
      }

      if (isNaN(promedioGrl)) promedioGrl = 0;
      if (isNaN(promedioProductividad)) promedioProductividad = 0;
      if (isNaN(promedioGps)) promedioGps = 0;
      this.setState({
        promedioGrl: (promedioGrl / contador).toFixed(2),
        promedioProductividad: (promedioProductividad / contador).toFixed(2),
        promedioGps: (promedioGps / contador).toFixed(2),
      });
    } else {
      this.setState({ dataP: [] });
    }
  };

  getEntregas = async () => {
    let porteo = this.state.inputPorteo;
    if (this.props.userInfo.porteo.length === 1) {
      porteo = this.props.userInfo.porteo[0].Id_porteo;
    } else if (porteo === "" && this.props.userInfo.porteo.length > 1) {
      porteo = 0;
    }
    const res = await Service.getRawResponse( ONTIME + 
      `/Entregas?tipo=${this.state.inputTipo}&mes=${this.state.inputMes}&porteo=${porteo}`
    );
    if (res.status === 200) {
      const r = await res.json();
      console.log(r);
      this.setState({ dataE: r });
    } else {
      this.setState({ dataE: [] });
    }
    this.props.setLoading(false);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputTipo !== this.state.inputTipo) {
      this.getPorteadores();
      this.getEntregas();
    }
    if (prevState.inputMes !== this.state.inputMes) {
      this.getPorteadores();
      this.getEntregas();
    }
    if (prevState.inputPorteo !== this.state.inputPorteo) {
      this.getEntregas();
      this.getPorteadores();
    }
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleInputPorteo = (e) => {
    console.log(e.target.value);
    this.setState({ inputPorteo: e.target.value });
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.getPorteadores();
    this.getEntregas();
  }

  handleClick(e) {
    $(".modal").toggleClass("is-active");
  }

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="columns">
            <div className="column is-6">
              <label className="title is-5">Ontime</label>
            </div>

            <div className="column is-2">
              <div className="control is-expanded">
                <div className="select is-fullwidth">
                  <select
                    name="inputTipo"
                    value={this.state.inputTipo}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    <option value="">Tipo</option>
                    <option value="Pedido">Pedidos</option>
                    <option value="Premio">Premios</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="column is-2">
              <SelectOption
                name="inputMes"
                defaultValue="Mes"
                value={this.state.inputMes}
                onChange={this.handleInputChange.bind(this)}
                data={this.state.months}
                mapValueFrom="mes"
                mapTextFrom="nombre"
              />
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputPorteo.bind(this)}
                data={formatPorteoDataSimple(this.props.userInfo.porteo)}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns">
            <div className="column is-4 ">
              <div className="card">
                <div className="card-content">
                  <p className="title is-5">Evaluacion porteo gral.</p>
                  <div className="ontime-content">
                    <span className="subtitle is-3">
                      {!isNaN(this.state.promedioGrl)
                        ? this.state.promedioGrl
                        : 0}
                      %
                    </span>
                    <img alt="" className="overview-item" src={ChartVerdeSVG} />
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4 ">
              <div className="card">
                <div className="card-content">
                  <p className="title is-5">Productividad promedio</p>
                  <div className="ontime-content">
                    <span className="subtitle is-3">
                      {!isNaN(this.state.promedioProductividad)
                        ? this.state.promedioProductividad
                        : 0}
                    </span>
                    <img alt="" className="overview-item" src={ChartAzulSVG} />
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4 ">
              <div className="card">
                <div className="card-content">
                  <p className="title is-5">Evaluacion GPS</p>
                  <div className="ontime-content">
                    <span className="subtitle is-3">
                      {!isNaN(this.state.promedioGps)
                        ? this.state.promedioGps
                        : 0}
                      %
                    </span>
                    <img alt="" className="overview-item" src={ChartRojaSVG} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <label className="subtitle is-4">
            {" "}
            Resultados de los porteadores{" "}
          </label>{" "}
        </div>{" "}
        <div className="column is-2 has-text-right"></div>
        <div className="row">
          <Table
            columns={COLUMNS_PORTEADORES}
            setItem={this.setItem}
            getTable={this.getTable}
          />
        </div>
        <div className="row">
          <div className="columns">
            <div className="column is-7">
              <label className="subtitle is-4">
                {" "}
                Resultados de las entregas{" "}
              </label>
            </div>
            <div className="column is-5">
              <InputSearch
                onChange={this.handleInputChange.bind(this)}
                name="buscarEntregas"
                placeholder="Buscar..."
                key="buscarPorPlaca"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <Table
            columns={COLUMNS_ENTREGAS}
            setItem={this.setItem}
            getTable2={this.getTable2}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ontime);
