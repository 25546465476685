import React from "react";

import $ from "jquery";

// components
import { Table } from "../utilidades/Table";
import { editIcon, deleteIcon } from "../utilidades/TableIcons";
import { AddNotificacion } from "./AddNotificacion";
import { ModNotificacion } from "./ModNotificacion";
import { DeleteNotificacion } from "./DeleteNotificacion";

import { alertSuccess, alertError } from "../utilidades/Alertas";
import { Service } from "../../services/Service";
import { NOTIFICACIONES } from "../../services/Config";

const columns = [
  { title: "NOMBRE", field: "nombre", width: 300 },
  { title: "DESCRIPCIÓN", field: "descripcion", width: 300 },
  { title: "CADA CUANTOS KM", field: "cadaCuantosKm", width: 200 },
  { title: "TRANSPORTE", field: "transporte" },
  {
    formatter: editIcon,
    width: 40,
    cellClick: function (e, cell) {
      $("#modnotificacion").toggleClass("is-active");
    },
  },
  {
    formatter: deleteIcon,
    width: 40,
    cellClick: function (e, cell) {
      $("#deletenotificacion").toggleClass("is-active");
      $("#deletedata").text(cell.getRow().getData().nombre);
    },
  },
];
export class ConfiguracionNoti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "",
      data: "",
      filtros: "",
    };
  }

  _inputTipo = (e) => {
    this.get(e.target.value);
  };

  modalAgregar() {
    $("#addnotificacion").toggleClass("is-active");
  }

  //Añadir una notificación
  add = async (datos) => {
    try {
      const res = await Service.postRawResponse( NOTIFICACIONES + "/setConfiguracionN", datos);
      alertSuccess("Configuración de notificación agregada", 2000, "center");
      this.getFiltros();
      this.get("");
    } catch (e) {
      alertError("Hubo un error", 2000, "center");
    }
  };

  get = async (tra) => {
    try {
      const result = await Service.getRawResponse( NOTIFICACIONES + `/getConfiguracionN?tra=${tra}`);
      const cNotificaciones = await result.json();
      this.setState({ data: cNotificaciones });
    } catch (e) {}
  };

  update = async (notificacion) => {
    try {
      await Service.putRawResponse( NOTIFICACIONES + "/putConfiguracionN", notificacion);
      this.getFiltros();
      this.get("");
    } catch (err) {
      alertError("Hubo un error", 2000, "center");
    }
  };

  remove = async () => {
    try {
      const notificacion = { id: this.state.selectedItem["Id_cNotificacion"] };
      const res = await Service.deleteRawResponse( NOTIFICACIONES + "/deleteConfiguracionN", notificacion);
      if (res.status === 200) {
        alertSuccess("Notificacion eliminada", 2000, "center");
        this.get("");
        this.getFiltros();
      }
    } catch (err) {
      alertError("Hubo un error", 2000, "center");
    }
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
    console.log(this.state.selectedItem);
  };

  //Get filtros
  getFiltros = async () => {
    try {
      const response = await Service.getRawResponse( NOTIFICACIONES + "/getFiltros");
      const result = await response.json();
      this.setState({ filtros: result });
    } catch (e) {}
  };

  //Iniciar la lista
  componentDidMount() {
    this.get("");
    this.getFiltros();
  }

  render() {
    return (
      <div className="container box">
        <div className="row">
          <label className="title is-5">Configuración de Notificaciones</label>
        </div>
        <br />
        <div className="row">
          <div className="columns">
            <div className="column is-2">
              <div className="control">
                <div className="select is-fullwidth">
                  <select onChange={this._inputTipo}>
                    <option value="" defaultValue>
                      Transporte
                    </option>
                    {this.state.filtros != null ? (
                      Object.values(this.state.filtros).map((filtro) => {
                        return (
                          <option
                            key={filtro.transporte}
                            value={filtro.transporte}
                          >
                            {filtro.transporte}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No hay datos</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="column is-1">
              <button
                onClick={this.modalAgregar}
                data-tooltip="Nueva Notificación"
                className="button is-rounded is-circle is-success"
              >
                <span className="icon is-large">
                  <i className="fa fa-plus"></i>
                </span>
              </button>
              <label> </label>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <label className="title is-6">Notificaciones registradas</label>
        </div>
        <br />
        <div className="row">
          <Table
            data={this.state.data}
            columns={columns}
            setItem={this.setItem}
          />
        </div>
        <AddNotificacion add={this.add} getFiltros={this.getFiltros} />
        <ModNotificacion
          selectedItem={this.state.selectedItem}
          update={this.update}
        />
        <DeleteNotificacion remove={this.remove} />
      </div>
    );
  }
}
