import React from "react";

// jquery
import $ from "jquery";

// descargar pdf
import { PDFDownloadLink } from "@react-pdf/renderer";

// pdf
import { Mantenimiento } from "../reportes/Mantenimiento";
import { alertError } from "../utilidades/Alertas";
import { MODAL_REPORTE_MANTENIMIENTOS } from "./ConstantsMantenimiento";
import { Service } from "../../services/Service";
import { INVENTARIO, MANTENIMIENTO, REPORTES } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

export class ReporteMantenimientos extends React.Component {
  state = {
    iPorteo: "",
    iTransporte: "",
    iFechaI: "",
    iFechaF: "",
    descargar: false,
    total: 0,
    data: "",
    transportes: null,
    porteo: null,
  };

  // Get each character from the input
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({ descargar: false });
  };

  //Limpiar inputs
  limpiarC = () => {
    var elements = document.querySelectorAll(
      "input[type='datetime-local'],select"
    );
    for (var i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iPorteo: "",
      iTransporte: "",
      iFechaI: "",
      iFechaF: "",
      descargar: false,
      total: 0,
      data: "",
      periodo: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_REPORTE_MANTENIMIENTOS}`).toggleClass("is-active");
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    const { iPorteo, iFechaF, iFechaI, iTransporte } = this.state;
    this.getReporteMantenimineto(
      iPorteo.split("/")[0],
      iFechaI,
      iFechaF,
      iTransporte
    );
  };

  getReporteMantenimineto = async (por, feI, feF, tra) => {
    const res = await Service.getRawResponse( REPORTES +
      `/getReporteMantenimiento?por=${por}&feI=${feI}&feF=${feF}&tra=${tra}`
    );
    if (res.status === 200) {
      const r = await res.json();
      const totalRes = await Service.getRawResponse( MANTENIMIENTO + 
        `/getMantenimientosTotal?por=${por}&feI=${feI}&feF=${feF}&tra=${tra}`
      );
      if (totalRes.status === 200) {
        const total = await totalRes.json();
        this.setState({
          data: r,
          total: total[0].total,
          periodo: this.state.iFechaI + " / " + this.state.iFechaF,
        });
        this.setState({ descargar: true });
      } else {
        this.setState({ data: "", total: "" });
        alertError("No existen datos para generar el reporte", 1000, "center");
      }
    } else {
      this.setState({ data: "", total: "" });
      alertError("No existen datos para generar el reporte", 1000, "center");
    }
  };

  getPorteo = async (por) => {
    try {
      let res = null;
      if (por == 0) {
        res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
      } else {
        res = await Service.getRawResponse( INVENTARIO + `/getPorteo?por=${por}`);
      }
      if (res.status === 403){
        clearSession()
      }
      const r = await res.json();
      this.setState({ porteo: r });
    } catch (e) {
      this.setState({ porteo: "" });
    }
  };

  getTransportes = async () => {
    try {
      const res = await Service.getRawResponse(MANTENIMIENTO +
        `/getTransportes?por=${sessionStorage.getItem("porteo")}`
      );
      const r = await res.json();
      this.setState({ transportes: r });
    } catch (e) {
      this.setState({ transportes: "" });
    }
  };

  componentDidMount() {
    this.getPorteo(sessionStorage.getItem("porteo"));
    this.getTransportes();
  }

  render() {
    return (
      <div id={MODAL_REPORTE_MANTENIMIENTOS} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Reporte de Mantenimientos</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iPorteo">
                      Porteo
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iPorteo"
                          name="iPorteo"
                          value={this.state.iPorteo}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un porteo
                          </option>
                          {this.state.porteo != null ? (
                            Object.values(this.state.porteo).map((por) => {
                              return (
                                <option
                                  key={por.Id_porteo}
                                  value={por.Id_porteo + "/" + por.nombre}
                                >
                                  {por.nombre}
                                </option>
                              );
                            })
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="column is-6">
                    <label className="label" htmlFor="iTransporte">
                      Transporte
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iTransporte"
                          name="iTransporte"
                          value={this.state.iTransporte}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un transporte
                          </option>
                          <option value="Todos">Todos</option>
                          {this.state.transportes != null ? (
                            Object.values(this.state.transportes).map(
                              (transporte) => {
                                return (
                                  <option
                                    key={transporte.placa}
                                    value={transporte.placa}
                                  >
                                    {transporte.placa +
                                      " - " +
                                      transporte.marca +
                                      " - " +
                                      transporte.modelo}
                                  </option>
                                );
                              }
                            )
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaI">
                      Fecha inicial
                    </label>
                    <input
                      id="iFechaI"
                      name="iFechaI"
                      value={this.state.iFechaI}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaF">
                      Fecha Final
                    </label>
                    <input
                      id="iFechaF"
                      name="iFechaF"
                      value={this.state.iFechaF}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-3">
                    {this.state.descargar ? (
                      <PDFDownloadLink
                        className="button is-rounded is-danger"
                        document={
                          <Mantenimiento
                            porteo={this.state.iPorteo.split("/")[1]}
                            transporte={this.state.iTransporte}
                            total={this.state.total}
                            periodo={this.state.periodo}
                            data={this.state.data}
                          />
                        }
                        fileName={
                          "Mantenimiento_" +
                          new Date().toLocaleDateString() +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Generando..." : "Descargar"
                        }
                      </PDFDownloadLink>
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Generar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
