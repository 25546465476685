import React from 'react'
import { Link } from 'react-router-dom'


export const NotFound = () => (
    <div className='hero is-white is-fullheight'>
        <div className='hero-body'>
            <div className='container has-text-centered'>
                <p className='title is-1'>404</p>
                <p className='subtitle is-1'>Página no encontrada</p>
                <p>
                    <Link to='/'>
                        <button className='button is-rounded is-link'>Inicio</button>
                    </Link>
                </p>
            </div>
        </div>
    </div>
)