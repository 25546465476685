import React from 'react'

// Components
import AccionesContacto from '../../components/homepage/AccionesContacto'

export default class Contacto extends React.Component {
    render() {
        return (
            <div>
                <AccionesContacto />
            </div>
        )
    }
}