import React from 'react'
import FormInformacion from './FormInformacion'
import { Title } from './Title'

export default class AccionesContacto extends React.Component {

    render() {
        return (
            <div className='container hero-body'>
                <div className='columns'>
                    <div className='column is-6'>
                        <Title subtitle='Deja tu mensaje' inverse={true}>CONTÁCTANOS</Title>
                        <FormInformacion />
                    </div>
                    <div className='column is-6'>
                        <Title subtitle='Ubicación'>CD GUZMÁN, JAL.</Title>
                        <div className=''>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.260682023128!2d-103.47149718556621!3d19.701522737132592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842f873a601456f5%3A0xb4db235a6013f4cb!2sLogistic%20Express%20Del%20Sur%20S.A%20De%20C.V.!5e0!3m2!1ses-419!2smx!4v1596242418813!5m2!1ses-419!2smx"
                                title="mapadegoogle"
                                width="100%"
                                height="350"
                                frameBorder="0"
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"></iframe>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}