// React
import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

// components
import { Table } from "../utilidades/Table";
import { alertError, alertInfo, alertSuccess } from "../utilidades/Alertas";

// jquery
import $ from "jquery";
import { ReporteMantenimientos } from "./ReporteMantenimientos";
import { FormAddMantenimiento } from "./FormAddMantenimiento";
import { ModalDetallesMantenimiento } from "./ModalDetalles";
import { InputSearch } from "../common/InputSearch";
import {
  COLUMNS_HISTORY,
  getColumnsMantenimiento,
} from "./ColumnsMantenimiento";
import {
  MODAL_REPORTE_MANTENIMIENTOS,
  VENTANA_PRINCIPAL,
} from "./ConstantsMantenimiento";
import { SelectOption } from "../common/SelectOption";
import { clearSession, formatPorteoData, hasAccess } from "../utilidades/DataHelpers";
import { ROLES } from "../utilidades/Constantes";
import { Service } from "../../services/Service";
import { MANTENIMIENTO } from "../../services/Config";
// import { SelectPorteo } from "../utilidades/SelectPorteo";

class Mantenimiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "",
      datos: [],
      historial: [],
      operadores: "",
      serviciosL: "",
      serviciosList: "",
      refaccionesList: "",
      table: null,
      inputPlaca: "",
      inputPorteo: "",
    };
  }

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.datos);
      table.setFilter([
        {
          field: "placa",
          type: "like",
          value: this.state.inputPlaca,
        },
        {
          field: "Id_porteo",
          type: "like",
          value: this.state.inputPorteo.split("/")[0],
        },
      ]);
    }
  };

  getTable2 = (table) => {
    if (table !== null) {
      table.setData(this.state.historial);
    }
  };

  modalReportes(e) {
    $(`#${MODAL_REPORTE_MANTENIMIENTOS}`).toggleClass("is-active");
  }

  _inputPlacas = (e) => {
    this.setState({ inputPlaca: e.target.value });
  };

  handleInputPorteo = (e) => {
    this.setState({ inputPorteo: e.target.value });
  };

  //Busqueda por placas
  buscar = async (placa) => {
    if (sessionStorage.getItem("rol") === "admin") {
      sessionStorage.setItem("porteo", 0);
    }
    const res = await Service.getRawResponse(MANTENIMIENTO + 
      `/busquedaPlacas?pla=${placa}&por=${sessionStorage.getItem(
        "porteo"
      )}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ datos: r });
    } else {
      this.setState({ datos: "" });
    }
    this.props.setLoading(false);
  };

  getHistorial = async (pla) => {
    const res = await Service.getRawResponse(MANTENIMIENTO + `/getHistorial?pla=${pla}`);
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ historial: r });
    } else {
      this.setState({ historial: "" });
      alertInfo("No tiene mantenimientos", 1000, "center");
    }
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
    if (selectedItem["placa"] !== undefined) {
      this.getHistorial(selectedItem["placa"]);
      this.getProveedores();
      this.getPorteadores(selectedItem["porteo"]);
    }
  };

  getProveedores = async () => {
    const res = await Service.getRawResponse(MANTENIMIENTO + 
      `/getProveedores?por=${sessionStorage.getItem("porteo")}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ proveedores: r });
    } else if (res.status === 403){
      clearSession()
    } else {
      this.setState({ proveedores: [] });
    }
  };

  getPorteadores = async (porteoId) => {
    console.log(this.state.selectedItem);
    const res = await Service.getRawResponse(MANTENIMIENTO +
      `/getPorteadores?por=${
        porteoId || sessionStorage.getItem("porteo")
      }`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ porteadores: r });
    } else {
      this.setState({ porteadores: [] });
    }
  };

  //Guardar mantenimiento
  saveMantenimiento = async (man) => {
    const res = await Service.postRawResponse(MANTENIMIENTO, man);
    if (res.status === 200) {
      alertSuccess("Mantenimiento agregado", 4000, "center");
    } else {
      alertError("El mantenimiento no se pudo agregar", 4000, "center");
    }
  };

  getServicios = (json) => {
    if (json === undefined) return "";
    json = JSON.parse(json);
    let aux = "";
    for (var i in json) {
      aux += json[i].servicio + "\n";
    }
    return aux;
  };

  getRefacciones = (json) => {
    if (json === undefined) return "";
    json = JSON.parse(json);
    let aux = "";
    for (var i in json) {
      aux += "[" + json[i].cantidad + "] " + json[i].codigo + "\n";
    }
    return aux;
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.buscar("");
  }

  render() {
    return (
      <div className="container is-fluid" id={VENTANA_PRINCIPAL}>
        {hasAccess(this.props.userInfo.rol, [
          ROLES.ADMIN,
          ROLES.ENCARGADO_PORTEO,
          ROLES.ENCARGADO_MANTENIMIENTO,
          ROLES.ENCARGADO_ALMACEN,
        ]) ? (
          <FormAddMantenimiento
            saveMantenimiento={this.saveMantenimiento}
            selectedItem={this.state.selectedItem}
            proveedores={this.state.proveedores}
            porteadores={this.state.porteadores}
          />
        ) : null}
        <div className="row">
          <div className="columns">
            <div className="column is-10">
              <label className="title is-4">Mantenimiento de Transportes</label>
            </div>

            <div className="column is-2 has-text-right">
              <button
                onClick={this.modalReportes}
                data-tooltip="Nuevo Reporte"
                className="button is-rounded is-circle is-link"
              >
                <span className="icon is-large">
                  <i className="fa fa-file-text-o"></i>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-3">
              <label className="title is-5">Transportes registrados</label>
            </div>

            <div className="column is-5">
              <InputSearch
                onChange={this._inputPlacas}
                name="filtroPlacaMantenimiento"
                placeholder="Placas de la unidad..."
                key="buscarPorPlacaMantenimiento"
              />
            </div>

            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputPorteo}
                data={formatPorteoData(this.props.userInfo.porteo)}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <Table
            columns={getColumnsMantenimiento(this.props.userInfo.rol)}
            setItem={this.setItem}
            getTable={this.getTable}
          />
        </div>

        <div className="row">
          <label className="title is-5">Historial de servicios</label>
        </div>
        <br />

        <div className="row">
          <Table
            columns={COLUMNS_HISTORY}
            setItem={this.setItem}
            getTable2={this.getTable2}
          />
        </div>

        <ReporteMantenimientos />
        <ModalDetallesMantenimiento
          selectedItem={this.state.selectedItem}
          getServicios={this.getServicios}
          getRefacciones={this.getRefacciones}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mantenimiento);
