import React from 'react'
import { alertWarning } from '../utilidades/Alertas'
import $ from 'jquery'
import { MODAL_UPDATE_PROVEEDOR } from './ConstantsProveedores'
import { PROVEEDORES } from '../../services/Config'
import { Service } from '../../services/Service'

export class FormModProveedor extends React.Component {
    state = {
        Id_prov: '',
        iNombrePm: '',
        iAPPm: '',
        iAMPm: '',
        iTelefonoPm: '',
        iCorreoPm: '',
        iEstadoPm: '',
        iCiudadPm: '',
        iDomicilioPm: '',
        iCPPm: '',
        iInsumoPm: '',
        iCategoriaPm: '',
        iEmpresaPm: '',
        iEstadoPmID: '',
        estados: '',
        ciudades: '',
        iRealizaMantenimientosPm: 0 

    }

    // show modal form
    handleClick = (e) => {
        $(`#${MODAL_UPDATE_PROVEEDOR}`).toggleClass("is-active");
        this.limpiarC();
        this.props.turnOn();
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name === "iEstadoPm"){
            this.añadirCiudades({ estado: e.target.value });
        }
        if (e.target.name === "iRealizaMantenimientosPm") {
            this.setState({
              [e.target.name]: e.target.checked,
            });
        }
    }

    limpiarC = () => {

        let elements = document.querySelectorAll("input[type='text'],input[type='email'],input[type='tel'],input[type='number'],select")
        for (let i = 0; i < elements.length; i++) {
            elements[i].value = ''
        }
        this.setState({
            iNombrePm: '',
            iAPPm: '',
            iAMPm: '',
            iTelefonoPm: '',
            iCorreoPm: '',
            iEstadoPm: '',
            iCiudadPm: '',
            iDomicilioPm: '',
            iCPPm: '',
            iInsumoPm: '',
            iCategoriaPm: '',
            iEmpresaPm: '',
            iRealizaMantenimientosPm: 0,
            flag: true
        })
    }

    // Añadir estados
    añadirEstados = async () => {
        let result = await Service.getRawResponse(PROVEEDORES + '/estados');
        if(result.status === 200)
            this.setState({ estados: await result.json() });
        else
            this.setState({ estados: [] });
    }

    //Añadir ciudades
    añadirCiudades = async (datos) => {
        let result = await Service.getRawResponse(PROVEEDORES + `/ciudades?estado=${datos.estado}&ciudad=${datos.ciudad || ''}`);
        if(result.status === 200)
            this.setState({ ciudades: await result.json() });
        else
            this.setState({ ciudades: [] });
    }

    handleSubmmit = (e) => {
        e.preventDefault();
        const {
            iNombrePm,
            iAPPm,
            iAMPm,
            iTelefonoPm,
            iCorreoPm,
            iEstadoPm,
            iCiudadPm,
            iDomicilioPm,
            iCPPm,
            iInsumoPm,
            iCategoriaPm,
            iEmpresaPm,
            iRealizaMantenimientosPm
        } = this.state;
        if (
            iNombrePm &&
            iAPPm &&
            iAMPm &&
            iTelefonoPm &&
            iCorreoPm &&
            iEstadoPm &&
            iCiudadPm &&
            iDomicilioPm &&
            iCPPm &&
            iInsumoPm &&
            iCategoriaPm &&
            iEmpresaPm
        ) {
            let id = this.props.selectedItem['Id_proveedor'];
            this.props.update({
                id: id,
                iNombrePm,
                iAPPm,
                iAMPm,
                iTelefonoPm,
                iCorreoPm,
                iEstadoPm,
                iCiudadPm,
                iDomicilioPm,
                iCPPm,
                iInsumoPm,
                iCategoriaPm,
                iEmpresaPm,
                iRealizaMantenimientosPm
            });
            this.handleClick();
        }
        else
        alertWarning("Faltan Campos", 4000, "center")
    }

    limpiarCampos() {
        $("#nombreMod").val()
        $("#appMod").val()
        $("#apmMod").val()
        $("#telefonoMod").val()
        $("#correoMod").val()
        $("#estadoMod").val()
        $("#ciudadMod").val()
        $("#domicilioMod").val()
        $("#cpMod").val()
        $("#insumoMod").val()
        $("#categoriaMod").val()
        $("#empresaMod").val()
    }

    initProv = () => {

        this.setState({
            iNombrePm: this.props.selectedItem['nombre_proveedor'],
            iAPPm: this.props.selectedItem['apellido_paterno_proveedor'],
            iAMPm: this.props.selectedItem['apellido_materno_proveedor'],
            iTelefonoPm: this.props.selectedItem['telefono_proveedor'],
            iCorreoPm: this.props.selectedItem['correo_proveedor'],
            iEstadoPm: this.props.selectedItem['estado_proveedor'],
            iCiudadPm: this.props.selectedItem['ciudad_proveedor'],
            iDomicilioPm: this.props.selectedItem['domicilio_proveedor'],
            iCPPm: this.props.selectedItem['cp_proveedor'],
            iInsumoPm: this.props.selectedItem['tipo_insumo'],
            iCategoriaPm: this.props.selectedItem['categoria_proveedor'],
            iEmpresaPm: this.props.selectedItem['empresa_proveedor'],
            iRealizaMantenimientosPm: this.props.selectedItem['realiza_mantenimiento'],
        });

        this.añadirCiudades({ estado: this.props.selectedItem['estado_proveedor'], ciudad: this.props.selectedItem['ciudad_proveedor'] });
        this.props.turnOff();
    }

    componentDidMount() {
        this.añadirEstados();
    }

    render() {
        if ($(`#${MODAL_UPDATE_PROVEEDOR}`).is(":visible") && this.props.flag) {
            this.initProv();
        }
        return (
            <div id={MODAL_UPDATE_PROVEEDOR} className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Modificar proveedor </p>
                        <button onClick={this.handleClick} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        <form >
                            <div className=''>
                                <div className='row'>
                                    <div className='columns'>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iNombrePm'>Nombre</label>
                                            <input
                                                id='iNombrePm'
                                                name="iNombrePm"
                                                value={this.state.iNombrePm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="text"
                                                placeholder="Nombre" />
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iAPPm'>Apellido paterno</label>
                                            <input
                                                id='iAPPm'
                                                name='iAPPm'
                                                value={this.state.iAPPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="text"
                                                placeholder="Apellido paterno" />
                                        </div>

                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iAMPm'>Apellido materno</label>
                                            <input
                                                id='iAMPm'
                                                name='iAMPm'
                                                value={this.state.iAMPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="text"
                                                placeholder="Apellido materno" />
                                        </div>

                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='columns '>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iTelefonoPm'>Teléfono</label>
                                            <input
                                                id='iTelefonoPm'
                                                name='iTelefonoPm'
                                                value={this.state.iTelefonoPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="tel"
                                                placeholder="Teléfono" />
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iCorreoPm'>Correo</label>
                                            <input
                                                id='iCorreoPm'
                                                name='iCorreoPm'
                                                value={this.state.iCorreoPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="email"
                                                placeholder="Correo electrónico" />
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iEstadoPm'>Estado</label>
                                            <div className="control">
                                                <div className="select is-rounded is-success is-fullwidth">
                                                    <select
                                                        id='iEstadoPm'
                                                        name='iEstadoPm'
                                                        value={this.state.iEstadoPm}
                                                        onChange={this.handleInputChange.bind(this)} >
                                                        <option value='' defaultValue>Seleccione un estado</option>
                                                        {
                                                            Object.values(this.state.estados).map((estado) => {
                                                                return (<option key={estado.estado} value={estado.estado}>{estado.estado}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='columns '>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iCiudadPm'>Ciudad</label>
                                            <div className="control">
                                                <div className="select is-rounded is-success is-fullwidth">
                                                    <select
                                                        id='iCiudadPm'
                                                        name='iCiudadPm'
                                                        value={this.state.iCiudadPm}
                                                        onChange={this.handleInputChange.bind(this)}>
                                                        <option value='' defaultValue>Seleccione una ciudad</option>
                                                        {
                                                            Object.values(this.state.ciudades).map((ciudad) => {
                                                                return (<option key={ciudad.ciudad} value={ciudad.ciudad}>{ciudad.ciudad}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iDomicilioPm'>Domicilio</label>
                                            <input
                                                id='iDomicilioPm'
                                                name='iDomicilioPm'
                                                value={this.state.iDomicilioPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="text"
                                                placeholder="Domicilio" />
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iCPPm'>Código Postal</label>
                                            <input
                                                id='iCPPm'
                                                name='iCPPm'
                                                value={this.state.iCPPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="number"
                                                placeholder="Código postal" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns '>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iInsumoPm'>Tipo de insumo</label>
                                            <div className="control">
                                                <div className="select is-rounded is-success is-fullwidth">
                                                    <select
                                                        id='iInsumoPm'
                                                        name='iInsumoPm'
                                                        value={this.state.iInsumoPm}
                                                        onChange={this.handleInputChange.bind(this)}>
                                                        <option value='' defaultValue>Seleccione un insumo</option>
                                                        <option value='Servicio'>Servicio</option>
                                                        <option value='Producto'>Producto</option>
                                                        <option value='Servicio y producto'>Servicio y producto</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iCategoriaPm'>Categoria</label>
                                            <div className="control">
                                                <div className="select is-rounded is-success is-fullwidth">
                                                    <select
                                                        id='iCategoriaPm'
                                                        name='iCategoriaPm'
                                                        value={this.state.iCategoriaPm}
                                                        onChange={this.handleInputChange.bind(this)}>
                                                        <option value='' defaultValue >Seleccione una categoria</option>
                                                        {this.props.categorias ?
                                                            Object.values(this.props.categorias).map((categoria) => {
                                                                return (<option key={categoria.Id_categoria} value={categoria.nombre}>{categoria.nombre}</option>)
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='column is-4'>
                                            <label className="label" htmlFor='iEmpresaPm'>Empresa</label>
                                            <input
                                                id='iEmpresaPm'
                                                name='iEmpresaPm'
                                                value={this.state.iEmpresaPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                className="input is-rounded is-success"
                                                type="text"
                                                placeholder="Empresa" />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='columns '>
                                        <div className="column is-4">
                                            <label className="checkbox">
                                            <input
                                                name="iRealizaMantenimientosPm"
                                                id="iRealizaMantenimientosPm"
                                                checked={this.state.iRealizaMantenimientosPm}
                                                onChange={this.handleInputChange.bind(this)}
                                                type="checkbox"
                                            />{" "}
                                            Realiza mantenimientos
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                    <footer className="modal-card-foot">
                        <button onClick={this.handleSubmmit} className="button is-rounded is-success">Actualizar</button>
                        <button onClick={this.handleClick} className="button is-rounded is-warning" >Cancelar</button>
                    </footer>
                </div>
            </div>
        )
    }
}