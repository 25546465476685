// modulos
const DASHBOARD = "Dashboard";
const ONTIME = "Ontime";
const PERSONAL = "Porteadores";
const TRANSPORTES = "Transportes";
const MANTENIMIENTO = "Mantenimiento";
const INVENTARIO = "Inventario";
const PROVEEDORES = "Proveedores";
const BODEGA = "Embarques";
const EMBARQUES = "Embarques";
const ESTATUS_DE_PISO = "EstatusDePiso";
const SEGUIMIENTO_DE_ENTREGAS = "SeguimientoEntregas";
const CONSUMO_DE_GASOLINA = "ConsumoGasolina";
const USUARIOS = "Usuarios";
const CONFIGURACIONES = "Configuraciones";

export const MODULES = {
  DASHBOARD,
  ONTIME,
  PERSONAL,
  TRANSPORTES,
  MANTENIMIENTO,
  INVENTARIO,
  PROVEEDORES,
  BODEGA,
  EMBARQUES,
  ESTATUS_DE_PISO,
  SEGUIMIENTO_DE_ENTREGAS,
  CONSUMO_DE_GASOLINA,
  USUARIOS,
  CONFIGURACIONES,
};

// roles
const ADMIN = "admin";
const CHOFER = "chofer";
const ENCARGADO_PORTEO = "encargado_porteo";
const ENCARGADO_ALMACEN = "encargado_almacen";
const ENCARGADO_MANTENIMIENTO = "encargado_mantenimiento";
const ENCARGADO_COMPRAS = "encargado_compras";
const AUXILIAR_ADMINISTRATIVO = "auxiliar_administrativo";
const AUXILIAR_ALMACENISTA = "auxiliar_almacenista";
const ENCARGADO_INVENTARIO = "encargado_inventario";
const RH = "RH";

export const ROLES = {
  ADMIN,
  CHOFER,
  ENCARGADO_PORTEO,
  ENCARGADO_ALMACEN,
  ENCARGADO_MANTENIMIENTO,
  ENCARGADO_COMPRAS,
  AUXILIAR_ADMINISTRATIVO,
  AUXILIAR_ALMACENISTA,
  ENCARGADO_INVENTARIO,
  RH,
};

// todo: esto se debe guardar en el back para poder hacer un fetch
export const getRoles = () => {
  return [
    { id: ROLES.ADMIN, name: ROLES.ADMIN },
    { id: ROLES.CHOFER, name: ROLES.CHOFER },
    { id: ROLES.ENCARGADO_PORTEO, name: ROLES.ENCARGADO_PORTEO },
    { id: ROLES.ENCARGADO_ALMACEN, name: ROLES.ENCARGADO_ALMACEN },
    { id: ROLES.ENCARGADO_MANTENIMIENTO, name: ROLES.ENCARGADO_MANTENIMIENTO },
    { id: ROLES.ENCARGADO_COMPRAS, name: ROLES.ENCARGADO_COMPRAS },
    { id: ROLES.AUXILIAR_ADMINISTRATIVO, name: ROLES.AUXILIAR_ADMINISTRATIVO },
    { id: ROLES.AUXILIAR_ALMACENISTA, name: ROLES.AUXILIAR_ALMACENISTA },
    { id: ROLES.ENCARGADO_INVENTARIO, name: ROLES.ENCARGADO_INVENTARIO },
    { id: ROLES.RH, name: ROLES.RH },
  ];
};
