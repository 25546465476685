import React from "react";

// components
import { TitleAlternativo } from "./Title";

export const QuienesSomos = () => (
  <div className="container hero-body">
    <TitleAlternativo>CONÓCENOS</TitleAlternativo>
    <div className="row has-text-justified">
      Logistic Express del Sur surge en el año 2008 con la idea de mejorar los
      tiempos y la calidad de la entrega a nuestros clientes. Nuestro objetivo
      es ofrecer soluciones logísticas mediante servicios confiables, rápidos y
      seguros.
    </div>
  </div>
);
