import React from 'react'

// components
import LoginForm from './LoginForm'

// images
import Logo from '../../images/LOGOLOGISTIC.png'

export class Iniciar extends React.Component {
    render() {
        return (
            <div className='row'>
                <div className='columns hero-body is-vcentered is-centered'>
                    <div className='column is-3'>
                        <div className="box" style={{border: '1px solid lightgray'}}>
                            <figure className="Avatar has-text-centered">
                                <img className='circle-img' src={Logo} alt='Logo Logistic' />
                            </figure>
                            <p className="title is-4 has-text-centered has-text-black">Iniciar Sesión</p>
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}