// eslint-disable-next-line eqeqeq
import React from "react";

export const Card = ({ onClick, icon, classColor, title, data, message }) => (
  <article className={"message " + classColor}>
    <div className="message-header">
      <p> {title} </p>
    </div>
    <div className="message-body">
      <a
        href="# "
        className={
          "button is-rounded is-outlined is-static is-circle is-light " +
          classColor
        }
      >
        <span className="icon is-small is-left">
          <i className={"has-text-danger fa fa-" + icon}> </i>{" "}
        </span>{" "}
      </a>
      <label> </label>
      <button
        onClick={onClick}
        className={"is-inline button is-rounded ayuda is-light " + classColor}
      >
        <strong>
          {" "}
          {data} {message}{" "}
        </strong>{" "}
      </button>
    </div>
  </article>
);
