import $ from "jquery";
import { ROLES } from "../utilidades/Constantes";
import { addIcon, infoIcon } from "../utilidades/TableIcons";
import {
  DETALLES_MANTENIMIENTO,
  MODAL_ADD_MANTENIMIENTO,
  MODAL_DETALLES_MANTENIMIENTO,
} from "./ConstantsMantenimiento";

let COLUMNS_MANTENIMIENTO = [
  { title: "PLACA", field: "placa" },
  { title: "MARCA", field: "marca" },
  { title: "MODELO", field: "modelo" },
  { title: "PORTEO", field: "porteo" },
];

let addMantenimientoColumn = {
  formatter: addIcon,
  width: 40,
  cellClick: function (e, cell) {
    $(`#${MODAL_ADD_MANTENIMIENTO}`).show("slow");
    $(`#${DETALLES_MANTENIMIENTO}`).empty();
    $(`#${DETALLES_MANTENIMIENTO}`).append(
      "<Strong>Placa:</Strong> " + cell.getRow().getData().placa + " "
    );
    $(`#${DETALLES_MANTENIMIENTO}`).append(
      "<Strong>Marca:</Strong> " + cell.getRow().getData().marca + " "
    );
    $(`#${DETALLES_MANTENIMIENTO}`).append(
      "<Strong>Modelo:</Strong> " + cell.getRow().getData().modelo
    );
  },
};

const addMantenimientoOption = [
  ROLES.ADMIN,
  ROLES.ENCARGADO_PORTEO,
  ROLES.ENCARGADO_MANTENIMIENTO,
  ROLES.ENCARGADO_ALMACEN,
];

export const getColumnsMantenimiento = (userRole = "") => {
  if (
    addMantenimientoOption.includes(userRole) &&
    !COLUMNS_MANTENIMIENTO.includes(addMantenimientoColumn)
  ) {
    COLUMNS_MANTENIMIENTO.push(addMantenimientoColumn);
  }

  return COLUMNS_MANTENIMIENTO;
};

export const COLUMNS_HISTORY = [
  //{ title: "#", field: "no", cellClick: function (e, cell) { $('#modaldetallesmantenimiento').toggleClass('is-active') } },
  {
    title: "FECHA",
    field: "fecha_mantenimiento",
    width: 95,
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_MANTENIMIENTO}`).toggleClass("is-active");
    },
  },
  {
    title: "TALLER",
    field: "taller",
    width: 100,
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_MANTENIMIENTO}`).toggleClass("is-active");
    },
  },
  {
    title: "PROVEEDOR",
    field: "proveedor",
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_MANTENIMIENTO}`).toggleClass("is-active");
    },
  },
  //{ title: "REFACCIONES", field: "refacciones", cellClick: function (e, cell) { $(`#${MODAL_DETALLES_MANTENIMIENTO}`).toggleClass('is-active') } },
  {
    title: "TOTAL",
    field: "total_mantenimiento",
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_MANTENIMIENTO}`).toggleClass("is-active");
    },
  },
  {
    formatter: infoIcon,
    width: 40,
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_MANTENIMIENTO}`).toggleClass("is-active");
    },
  },
];
