import React from 'react'

export const Article = ({ title, children, color }) => (
    <article className={color} >
        <div className="message-header">
            <p>{title}</p>
        </div>
        <div className="message-body">
            {children}
              </div>
    </article>
)