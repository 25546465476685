// React
import React from "react";

// components
import { Table } from "../../utilidades/Table";

// jquery
import $ from "jquery";
import { DetallesComponent } from "./DetallesComponent";
import { OverviewComponent } from "./OverviewComponent";
import {
  VENTANA_DETALLES_RUTA,
  VENTANA_ESTATUS_DE_PISO,
} from "./ConstantsEstatusDePiso";
import { COLUMNS_PEDIDOS, COLUMNS_PREMIOS } from "./ColumnsEstatusDePiso";

export class DetallesDeRuta extends React.Component {
  state = {
    buscarPedidos: "",
    buscarPremios: "",
    rutaActual: "",
    folioPedidos: "",
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.props.dataPedidos);
    }
  };

  getTable2 = (table) => {
    if (table !== null) {
      table.setData(this.props.dataPremios);
    }
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  regresar = () => {
    $(`#${VENTANA_DETALLES_RUTA}`).hide("slow");
    $(`#${VENTANA_ESTATUS_DE_PISO}`).show("slow");
  };

  render() {
    return (
      <div id={VENTANA_DETALLES_RUTA} hidden>
        <div className="row">
          <div className="columns">
            <div className="is-center-vertical">
              <a
                onClick={this.regresar}
                title="Regresar"
                className="button is-rounded is-circle is-white"
              >
                <span className="icon is-large">
                  <i className="fa fa-arrow-left"></i>
                </span>
              </a>
            </div>
            <div className="column is-10">
              <label className="subtitle is-4">
                {" "}
                Detalles de la ruta {this.props.rutaActual.ruta}{" "}
              </label>{" "}
            </div>{" "}
            <div className="column is-2 has-text-right"></div>
          </div>
        </div>
        <br></br>

        <div className="box">
          <OverviewComponent
            ruta={this.props.rutaActual.ruta}
            chofer={this.props.rutaActual.porteador}
            transporte={this.props.rutaActual.vehiculo}
            fecha={(this.props.rutaActual.fecha + "").split("T")[0]}
            km="290 km"
            gasolina="$500.00"
            tiempoRecorrido="9 horas"
            pedidos="10 pedidos"
          />
        </div>

        <div className="row">
          <label className="title is-5 has-text-weight-bold">
            {" "}
            Folio de pedidos: {this.props.folioPedidos}{" "}
          </label>
        </div>
        <br />
        <div className="row ">
          <div className="column is-3">
            <div className="control has-icons-left">
              <input
                name="buscar"
                value={this.state.buscarPedidos}
                className="input is-rounded"
                type="text"
                placeholder="Buscar..."
                onChange={this.handleInputChange}
              />
              <span className="icon is-small is-left">
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="columns">
            <div className="column is-8">
              <Table columns={COLUMNS_PEDIDOS} getTable={this.getTable} />
            </div>
            <div className="column is-4">
              <DetallesComponent
                total={this.props.totalPedidos}
                entregados={this.props.totalPedidosEntregados}
                visitados={this.props.totalPedidosVisitados}
                noVisitado={this.props.totalPedidosNoVisitado}
              />
            </div>
          </div>
        </div>
        <br></br>

        <div className="row">
          <label className="title is-5 has-text-weight-bold">
            {" "}
            Folio de premios: {this.props.folioPremios}{" "}
          </label>
        </div>
        <br />
        <div className="row">
          <div className="column is-3">
            <div className="control has-icons-left">
              <input
                name="buscar"
                value={this.state.buscarPremios}
                className="input is-rounded"
                type="text"
                placeholder="Buscar..."
                onChange={this.handleInputChange}
              />
              <span className="icon is-small is-left">
                <i className="fa fa-search"></i>
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="columns">
            <div className="column is-8">
              <Table columns={COLUMNS_PREMIOS} getTable2={this.getTable2} />
            </div>
            <div className="column is-4">
              <DetallesComponent
                total={this.props.totalPremios}
                entregados={this.props.totalPremiosEntregados}
                visitados={this.props.totalPremiosVisitados}
                noVisitado={this.props.totalPremiosNoVisitado}
              />
            </div>
          </div>
        </div>
        <br></br>
      </div>
    );
  }
}
