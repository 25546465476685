import React from 'react'
import { MODAL_BORRAR_ARTICULO } from './ConstantsInventario'

import $ from 'jquery'

export class DeleteArticulo extends React.Component {
    state = {
        iMotivo: ''
    }

    handleSubmmit = (e) => {
        this.props.remove();
        this.handleClick();
    }

    handleClick = (e) =>{
        $(`#${MODAL_BORRAR_ARTICULO}`).toggleClass("is-active");
        this.limpiarC();
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    //Limpiar inputs
    limpiarC = () => {

        let elements = document.querySelectorAll("select")
        for (let i = 0; i < elements.length; i++) {
            elements[i].value = ''
        }
        this.setState({
            iMotivo: ''
        })
    }

    render() {
        return (
            <div id={MODAL_BORRAR_ARTICULO} className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Confirmación</p>
                        <button onClick={this.handleClick} title='Cerrar' className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        <div className='content'>
                            <p><label className='label'>¿Esta seguro de eliminar este articulo?</label></p>
                            <p id='deletedata'></p>

                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-4'>
                                        <label className='label' htmlFor='iMotivo'>Motivo</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                id='iMotivo'
                                                name='iMotivo'
                                                value={this.setState.iMotivo} 
                                                onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione un motivo</option>
                                                    <option value='Robo'>Robo</option>
                                                    <option value='No funcional'>No funcional</option>
                                                    <option value='Desgastado'>Desgastado</option>
                                                    <option value='Venta'>Venta</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button onClick={this.handleSubmmit} className="button is-rounded is-danger">Eliminar</button>
                        <button onClick={this.handleClick} className="button is-rounded is-success" >Cancelar</button>
                    </footer>
                </div>
            </div>
        )
    }
}