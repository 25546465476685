// react
import React from 'react';

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table/lib'
// fuente a utilizar
import ProductSans from './style/ProductSansRegular.ttf'
import ProductSansBold from './style/ProductSansBold.ttf'
// estilos
import { styles } from './style/Styles'
//LOGOTIPO
import logo from './../../images/LOGOLOGISTIC.png'

Font.register({
    family: 'ProductSans',
    fonts: [
        { src: ProductSans }, // font-style: normal, font-weight: normal
        { src: ProductSansBold, fontWeight: 'bold' },
    ]
});

export const TransportesFV = ({ porteo, categoria, data }) => (
    <Document>
        <Page size="A4" style={styles.page} >
            <View style={styles.logoTitle}>
                <Image style={styles.image} src={logo} />
                <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
            </View>
            <View style={styles.info}>
                <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
                <Text style={styles.contacto}>Tel: (341) 410-6511 ó (341) 410-6458</Text>
            </View>
            <Text style={styles.subtitulo}>
                Estatus financiero de la flota vehicular
                </Text>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Porteo:</Text>
                    <Text style={styles.parametros}>{porteo || 'Todos'}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Categoría:</Text>
                    <Text style={styles.parametros}>{categoria}</Text>
                </View>
            </View>
            {
            categoria === 'Credito' ?
            <View>
                <Table data={data} >
                    <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.05} >#</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1} >PLACAS</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1} >NO ECONOMICO</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1} >VENCE</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1} >MARCA</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.15} >NO PÓLIZA</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.2} >SERIE</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1} >FLOTA</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1} >MODELO</TableCell>
                    </TableHeader>
                    <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <DataTableCell style={styles.tablaSmall} weighting={0.05} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.placa} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.no_economico} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.status_financiero_vencimiento.split('T')[0]} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.marca} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.15} getContent={(r) => r.poliza_seguro} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.2} getContent={(r) => r.no_serie} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.flota} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.modelo} />
                        
                    </TableBody>
                </Table>
            </View>
            :
            <View>
                <Table data={data} >
                    <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.05}>#</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1}>PLACAS</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1}>NO ECONOMICO</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1}>MARCA</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.2}>NO PÓLIZA</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.25}>SERIE</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1}>FLOTA</TableCell>
                        <TableCell style={styles.tablaHeaderSmall} weighting={0.1}>MODELO</TableCell>
                    </TableHeader>
                    <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <DataTableCell style={styles.tablaSmall} weighting={0.05} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.placa} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.no_economico} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.marca} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.2} getContent={(r) => r.poliza_seguro} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.25} getContent={(r) => r.no_serie} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.flota} />
                        <DataTableCell style={styles.tablaSmall} weighting={0.1} getContent={(r) => r.modelo} />
                    </TableBody>
                </Table>
            </View>
            }
        </Page>
    </Document>
);