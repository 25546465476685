// React
import React from "react";
//redux
import { Sesion } from "../../../redux/actions/SesionActions";
import { connect } from "react-redux";
// components
import { Table } from "../../utilidades/Table";
import { SelectOption } from "../../common/SelectOption";

// jquery
import $ from "jquery";
import { DetallesDeRuta } from "./DetallesDeRuta";
import { alertSuccess, alertError } from "../../utilidades/Alertas";
import "./custom-table.css";
import "./btns-estatus-piso.css";
import { InputSearch } from "../../common/InputSearch";
import {
  COLUMNS_RUTA,
  COLUMNS_PISO,
  COLUMNS_PISO_OTROS_CLIENTES,
  getColumnsTransportesEnRuta,
} from "./ColumnsEstatusDePiso";
import {
  MODAL_SALIDA_RUTA,
  VENTANA_ESTATUS_DE_PISO,
} from "./ConstantsEstatusDePiso";
import { Icon } from "../../common/simple/Icon";
import { ModalRegresoRuta } from "./ModalRegresoRuta";
import { ModalSalidaRuta } from "./ModalSalidaRuta";
import { clearSession, getPorteoOrDefault } from "../../utilidades/DataHelpers";
import { Service } from "../../../services/Service";
import { BODEGA } from "../../../services/Config";

class EstatusDePiso extends React.Component {
  state = {
    activeItem: "Ruta",
    buscar: "",
    table: null,
    dataRuta: [],
    dataPiso: [],
    selectedItem: "",
    dataPedidos: [],
    dataPremios: [],
    totalPedidos: 0,
    totalPedidosEntregados: 0,
    totalPedidosVisitados: 0,
    totalPedidosNoVisitado: 0,
    totalPremios: 0,
    totalPremiosEntregados: 0,
    totalPremiosVisitados: 0,
    totalPremiosNoVisitado: 0,
    inputPorteo: "",
    dataTransporteRuta: [
      {
        folio_salida: "dada",
        placa: "sd",
        rutas: "dd",
        chofer: "s",
        quien_asigno: "s",
      },
    ],
    dataPisoOtrosClientes: [],
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(
        this.state.activeItem === "Ruta"
          ? this.state.dataRuta
          : this.state.dataPiso
      );
      table.setFilter([
        {
          field: "Id_porteo",
          type: "like",
          value: this.state.inputPorteo.split("/")[0],
        },
      ]);
      table.setColumns(
        this.state.activeItem === "Ruta" ? COLUMNS_RUTA : COLUMNS_PISO
      );
    }
  };

  getTableTransportesRuta = async (table) => {
    if (table !== null) {
      table.setData(this.state.dataTransporteRuta);
      table.setColumns(getColumnsTransportesEnRuta(this.props.userInfo.rol));
    }
  };

  getTablePisoOtrosClientes = (table) => {
    if (table !== null) {
      table.setData(this.state.dataPisoOtrosClientes);
      table.setColumns(COLUMNS_PISO_OTROS_CLIENTES);
    }
  };

  modalSalidaRuta(e) {
    $(`#${MODAL_SALIDA_RUTA}`).toggleClass("is-active");
  }

  getRuta = async () => {
    let porteo = getPorteoOrDefault(
      this.state.inputPorteo,
      this.props.userInfo.porteo
    );
    let res = await Service.getRawResponse( BODEGA + `/EstatusRuta?porteo=${porteo}`);
    if (res.status === 200) {
      const r = await res.json();
      console.log(r);
      this.setState({ dataRuta: r });
    } else if (res.status === 403){
      clearSession()
    } else {
      this.setState({ dataRuta: [] });
    }
  };

  getPiso = async () => {
    let porteo = getPorteoOrDefault(
      this.state.inputPorteo,
      this.props.userInfo.porteo
    );
    let res = await Service.getRawResponse( BODEGA + `/EstatusPiso?porteo=${porteo}`);
    if (res.status === 200) {
      const r = await res.json();
      console.log(r);
      this.setState({ dataPiso: r });
    } else {
      this.setState({ dataPiso: [] });
    }
    this.props.setLoading(false);
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
    this.getPedidos();
    this.getPremios();
  };

  setItemTransportesRuta = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  setItemPisoOtrosClientes = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  getPedidos = async () => {
    let totalPedidos = 0,
      totalPedidosEntregados = 0,
      totalPedidosVisitados = 0,
      totalPedidosNoVisitado = 0;
    let res = await Service.getRawResponse( BODEGA + 
      `/getPedidos?id_salida_reparto=${this.state.selectedItem.id_salida_reparto}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ dataPedidos: r });
      r.forEach((element) => {
        totalPedidos += 1;
        if (element.status === "Entregado") {
          totalPedidosEntregados += 1;
        } else if (element.status === "Visitado") {
          totalPedidosVisitados += 1;
        } else {
          totalPedidosNoVisitado += 1;
        }
      });
      this.setState({
        totalPedidos: totalPedidos,
        totalPedidosEntregados: totalPedidosEntregados,
        totalPedidosVisitados: totalPedidosVisitados,
        totalPedidosNoVisitado: totalPedidosNoVisitado,
      });
    } else {
      this.setState({ dataPedidos: [] });
    }
  };

  getPremios = async () => {
    let totalPremios = 0,
      totalPremiosEntregados = 0,
      totalPremiosVisitados = 0,
      totalPremiosNoVisitado = 0;
    let res = await Service.getRawResponse( BODEGA + 
      `/getPremios?id_salida_reparto=${this.state.selectedItem.id_salida_reparto}`
    );
    if (res.status === 200) {
      const r = await res.json();
      this.setState({ dataPremios: r });
      r.forEach((element) => {
        totalPremios += 1;
        if (element.status === "Entregado") {
          totalPremiosEntregados += 1;
        } else if (element.status === "Visitado") {
          totalPremiosVisitados += 1;
        } else {
          totalPremiosNoVisitado += 1;
        }
      });
      this.setState({
        totalPremios: totalPremios,
        totalPremiosEntregados: totalPremiosEntregados,
        totalPremiosVisitados: totalPremiosVisitados,
        totalPremiosNoVisitado: totalPremiosNoVisitado,
      });
    } else {
      this.setState({ dataPremios: [] });
    }
  };

  //Añadir una salida a ruta
  addSalidaRuta = async (data) => {
    let res = await Service.postRawResponse(BODEGA + "/SalidaRuta", data);
    if (res.status === 201) {
      this.props.setLoading(true);
      await this.getTransportesRuta();
      alertSuccess("Salida a ruta registrada", 4000, "center");
    } else {
      console.log(res);
    }
  };

  //Añadir una llegada a ruta
  addLlegadaRuta = async (data) => {
    let res = await Service.postRawResponse( BODEGA + "/LlegadaRuta", data);
    if (res.status === 201) {
      this.props.setLoading(true);
      await this.getTransportesRuta();
      alertSuccess("Llegada a ruta registrada", 4000, "center");
    } else {
      console.log(res);
      alertError("Error", 4000, "center");
    }
  };

  getTransportesRuta = async () => {
    let porteo = getPorteoOrDefault(
      this.state.inputPorteo,
      this.props.userInfo.porteo
    );
    let result = await Service.getRawResponse( BODEGA + `/SalidaRuta?por=${porteo}`);
    if (result.status === 200) {
      const r = await result.json();
      console.log(r);
      this.setState({ dataTransporteRuta: r });
    } else {
      this.setState({ dataTransporteRuta: [] });
    }
    this.props.setLoading(false);
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.getRuta();
    this.getPiso();
    this.getTransportesRuta();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputPorteo !== this.state.inputPorteo) {
      this.getRuta();
      this.getPiso();
      this.getTransportesRuta();
    }
  }

  render() {
    return (
      <div id={VENTANA_ESTATUS_DE_PISO} className="container is-fluid box">
        <div className="row">
          <div className="columns">
            <div className="column is-10">
              <label className="title is-5"> Estatus de piso </label>{" "}
            </div>{" "}
            <div className="column is-2 has-text-right"></div>
          </div>
        </div>
        <br></br>

        <div className="row">
          <div className="tabs">
            <ul>
              <li
                onClick={() => this.setState({ activeItem: "Ruta" })}
                className={
                  this.state.activeItem === "Ruta"
                    ? "active-chido"
                    : "btns-estatus-piso"
                }
              >
                <Icon icon="road" styles="icon" />
                Ruta
              </li>

              <li
                onClick={() => this.setState({ activeItem: "Piso" })}
                className={
                  this.state.activeItem === "Piso"
                    ? "active-chido"
                    : "btns-estatus-piso"
                }
              >
                <Icon icon="cube" styles="icon" />
                Piso
              </li>
            </ul>
          </div>
        </div>
        <br></br>

        <div className="row">
          <div className="columns">
            <div className="column is-7">
              <span className="subtitle is-6 has-text-weight-bold">
                Fecha: {new Date().toLocaleDateString()}
              </span>
            </div>
            <div className="column is-3">
              <InputSearch
                onChange={this.handleInputChange}
                name="buscar"
                placeholder="Buscar..."
                key="buscarPor"
              />
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputChange}
                data={this.props.userInfo.porteo}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
          </div>
        </div>
        <br></br>

        <div className="row">
          <Table
            columns={
              this.state.activeItem === "Ruta" ? COLUMNS_RUTA : COLUMNS_PISO
            }
            getTable={this.getTable}
            setItem={this.setItem}
          />
        </div>

        {this.state.activeItem === "Ruta" ? (
          <div>
            <br />
            <div className="row">
              <div className="columns">
                <div className="column">
                  <label className="title is-5">Transportes en ruta</label>
                </div>
                <div className="column is-offset-8">
                  <button
                    className="button is-link is-rounded"
                    onClick={this.modalSalidaRuta}
                    tooltip="Salida a ruta"
                    icon="plus"
                  >
                    Salida a ruta
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <Table
                columns={getColumnsTransportesEnRuta(this.props.userInfo.rol)}
                getTable={this.getTableTransportesRuta}
                setItem={this.setItemTransportesRuta}
              />
            </div>
          </div>
        ) : (
          <div>
            <br />
            <div className="row">
              <div className="columns">
                <div className="column">
                  <label className="title is-5">
                    Paquetes en piso de otros clientes
                  </label>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <Table
                columns={COLUMNS_PISO_OTROS_CLIENTES}
                getTable={this.getTablePisoOtrosClientes}
                setItem={this.setItemPisoOtrosClientes}
              />
            </div>
          </div>
        )}
        <DetallesDeRuta
          rutaActual={this.state.selectedItem}
          dataPedidos={this.state.dataPedidos}
          dataPremios={this.state.dataPremios}
          totalPedidos={this.state.totalPedidos}
          totalPedidosEntregados={this.state.totalPedidosEntregados}
          totalPedidosVisitados={this.state.totalPedidosVisitados}
          totalPedidosNoVisitado={this.state.totalPedidosNoVisitado}
          totalPremios={this.state.totalPremios}
          totalPremiosEntregados={this.state.totalPremiosEntregados}
          totalPremiosVisitados={this.state.totalPremiosVisitados}
          totalPremiosNoVisitado={this.state.totalPremiosNoVisitado}
        />

        <ModalRegresoRuta
          selectedItem={this.state.selectedItem}
          add={this.addLlegadaRuta}
        />
        <ModalSalidaRuta add={this.addSalidaRuta} />
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EstatusDePiso);
