import React from "react";
import { connect } from "react-redux";

const LoadingPage = ({ isLoading }) => {
  const mostrar = isLoading ? "" : "none";

  return (
    <div style={{ display: mostrar }} className="loading-page">
      <div className="loading-container">
        <i className="fa fa-circle-o-notch faster-spin fa-5x fa-fw"></i>
      </div>
    </div>
  );
};

const mapStateToProps = ({ sesionReducer }) => ({
  isLoading: sesionReducer.isLoading,
});

export default connect(mapStateToProps)(LoadingPage);
