export var editIcon = function (cell, formatterParams) { //plain text value
  return "<span title='Modificar' class='icon has-text-link '><i class='fa fa-pencil-square-o fa-lg'></i></span>";
};

export var deleteIcon = function (cell, formatterParams) { //plain text value
  return "<span title='Eliminar' class='icon has-text-danger'><i class='fa fa-trash-o fa-lg'></i></span>";
};
export var addManIcon = function (cell, formatterParams) { //plain text value
  return "<span title='Agregar' class='icon has-text-warning'><i class='fa fa-wrench fa-lg'></i></span>";
};
export var addIcon = function (cell, formatterParams) { //plain text value
  return "<span title='Agregar' class='icon has-text-success'><i class='fa fa-plus-circle fa-2x'></i></span>";
};
export var infoIcon = function (cell, formatterParams) { //plain text value
  return "<span title='Información' class='icon has-text-link'><i class='fa fa-info-circle fa-lg'></i></span>";
};

export var viewIcon = function (cell, formatterParams) { //plain text value
  return "<span title='Ver detalles' class='icon has-text-link fa-stack'>"  +
  "<i class='fa fa-circle fa-stack-2x'></i>" +
  "<i class='fa fa-eye fa-stack-1x fa-inverse'></i></span>";
};

export var assignIcon = function (cell, formatterParams) { //plain text value
  return "<span title='Regreso' class='icon has-text-link'><i class='fa fa-mail-reply fa-lg'></i></span>";
};