// React
import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

// components
import { Table } from "../utilidades/Table";
import { FormAddProveedor } from "./FormAddProveedor";
import { DeleteProveedor } from "./DeleteProveedor";
import { FormModProveedor } from "./FormModProveedor";
// import { SelectPorteo } from "../utilidades/SelectPorteo";

// jquery
import $ from "jquery";
import { alertSuccess } from "../utilidades/Alertas";
import { InputSearch } from "../common/InputSearch";
import { SelectOption } from "../common/SelectOption";
import { ButtonRounded } from "../common/ButtonRounded";
import { getColumns } from "./ColumnsProveedores";
import {
  CATEGORIAS_PROVEEDORES,
  MODAL_ADD_PROVEEDOR,
} from "./ConstantsProveedores";
import { clearSession, hasAccess } from "../utilidades/DataHelpers";
import { ROLES } from "../utilidades/Constantes.js";
import { Service } from "../../services/Service";
import { INVENTARIO, PROVEEDORES } from "../../services/Config";

class Proveedores extends React.Component {
  state = {
    inputCategoria: "",
    inputNombre: "",
    flag: true,
    selectedItem: "",
    table: null,
    categorias: [],
    inputPorteo: null,
    porteo: [],
    isMounted: false,
    datos: [],
    currentPage: 0,
    totalPages: 0,
    completedData: [],
    dataForFilters: [],
  };

  handleInputChangeEmpresa = (e) => {
    this.filtrarTabla(e.target.value, "empresa_proveedor");
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputNombre: e.target.value,
    });
  };

  handleInputChangeCategoria = (e) => {
    const categoria = this.state.categorias.find( element => element.Id_categoria == e.target.value).nombre
    this.filtrarTabla(categoria, "categoria_proveedor");
    this.setState({
      inputCategoria: categoria,
    });
    this.setState({
      inputNombre: "",
    });
  };

  //Cuando se usa un filtro específico no se toma en cuenta la Paginación por ello se filtra de toda la data disponible y no de la data segmentada
  filtrarTabla = (valorAFiltrar, campoAFiltrar) => {
    const datosFiltrados = this.state.dataForFilters.filter((element) => {
      const campo = element[campoAFiltrar].toString().toLowerCase();
      return campo.includes(valorAFiltrar.toLowerCase());
    });
    this.setState({
      datos: datosFiltrados,
    });
  };

  //Controles de Paginación de la Tabla
  first = () => {
    this.setState({ currentPage: 0 });
    this.setState({
      datos: this.state.completedData[0],
    });
    //Reseteo de los inputs a valores predeterminados
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputNombre: "",
    });
  };
  prev = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
    this.setState({
      datos: this.state.completedData[this.state.currentPage - 1],
    });
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputNombre: "",
    });
  };
  next = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.setState({
      datos: this.state.completedData[this.state.currentPage + 1],
    });
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputNombre: "",
    });
  };
  last = () => {
    this.setState({ currentPage: this.state.completedData.length - 1 });
    this.setState({
      datos: this.state.completedData[this.state.completedData.length - 1],
    });
    this.setState({
      inputCategoria: "Categoria",
    });
    this.setState({
      inputNombre: "",
    });
  };

  //Obtener categorias
  getCategorias = async () => {
    let result = await Service.getRawResponse(PROVEEDORES + `/getCategorias`);
    if (result.status === 200) {
      const res = await result.json();
      console.log(res);
      this.setState({ categorias: res });
    }
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.datos);
      //De momento los filtros como parametros a las Tablas con Paginación ya no son necesarios ya que se actualizó la funcionalidad localmente
      // table.setFilter([
      //   {
      //     field: "categoria_proveedor",
      //     type: "like",
      //     value: this.state.inputCategoria,
      //   },
      //   {
      //     field: "empresa_proveedor",
      //     type: "like",
      //     value: this.state.inputNombre,
      //   },
      // ]);
    }
  };

  handleClick(e) {
    // if(sessionStorage.getItem('rol') === 'admin'){
    //   $("#selectPorteo").toggleClass("is-active");
    // }else{
    $(`#${MODAL_ADD_PROVEEDOR}`).toggleClass("is-active");
    // }
  }

  //Obtener proveedores
  getProveedores = async () => {
    let result = await Service.getRawResponse(PROVEEDORES);
    let data = [];
    // Verifica si el componente aún está montado
    if (this.state.isMounted) {
      if (result.status === 200) {
        data = await result.json();
        const dataSegmentada = data
          .map((elemento, indice, arreglo) => {
            if (indice % 10 === 0) {
              return arreglo.slice(indice, indice + 10);
            }
            return null; // O puedes devolver null para omitir elementos que no deben estar en el nuevo arreglo.
          })
          .filter(Boolean); // Filtramos los elementos nulos generados por el map.
        this.setState({ dataForFilters: data }); //Data completa
        this.setState({ completedData: dataSegmentada }); //Data completa segmentada por cada 10 elementos
        this.setState({ datos: dataSegmentada[this.state.currentPage] }); //Data renderizada en 10 elementos
        this.setState({ totalPages: dataSegmentada.length });
      } else {
        this.setState({ datos: [] });
      }
      this.props.setLoading(false);
    }
  };

  //Añadir un proveedor
  add = async (proveedor) => {
    console.log("SMN");
    let res = await Service.postRawResponse(PROVEEDORES, proveedor);
    if (res.status === 201) {
      //const r = await res.text();
      //if (r === 'Proveedor existente')
      //    alertError('Ya existe un proveedor con ese código', 4000, 'center')
      //else {
      alertSuccess("Proveedor agregado", 4000, "center");
      this.getProveedores(); //'',sessionStorage.getItem('porteo'));
      //}
    } else {
      console.log(res);
    }
  };

  getPorteo = async (por) => {
    let res = null;
    // eslint-disable-next-line eqeqeq
    if (por == 0) {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=0`);
    } else {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403) {
      clearSession();
    } else {
      this.setState({ porteo: "" });
    }
  };

  //Modificar un proveedor
  update = async (proveedor) => {
    const res = await Service.putRawResponse(PROVEEDORES, proveedor);
    if (res.status === 200) {
      alertSuccess("Proveedor modificado", 4000, "center");
      this.getProveedores();
    }
  };

  //Eliminar un proveedor
  remove = async () => {
    const proveedor = { id: this.state.selectedItem["Id_proveedor"] };
    const res = await Service.deleteRawResponse(PROVEEDORES, proveedor);
    if (res.status === 200) {
      alertSuccess("Proveedor eliminado", 4000, "center");
      this.getProveedores("", "");
    }
  };

  //Elemento seleccionado en la tabla
  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  turnOn = () => {
    this.setState({ flag: true });
  };

  turnOff = () => {
    this.setState({ flag: false });
  };

  //Iniciar la lista
  componentDidMount() {
    this.props.setLoading(true);
    this.setState({ isMounted: true });
    this.getProveedores();
    this.getCategorias();

    if (
      hasAccess(this.props.userInfo.rol, [ROLES.ADMIN, ROLES.ENCARGADO_PORTEO])
    )
      this.getPorteo(0);
    else this.getPorteo(sessionStorage.getItem("porteo"));
  }
  componentWillUnmount() {
    this.setState({ isMounted: false }); // Establece la bandera isMounted en false cuando el componente se desmonta
  }

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="columns">
            <div className="column is-10">
              <label className="title is-5"> Proveedores </label>{" "}
            </div>{" "}
            {hasAccess(this.props.userInfo.rol, [
              ROLES.ADMIN,
              ROLES.ENCARGADO_PORTEO,
              ROLES.ENCARGADO_MANTENIMIENTO,
              ROLES.ENCARGADO_COMPRAS,
            ]) ? (
              <div className="column is-2 has-text-right">
                <ButtonRounded
                  onClick={this.handleClick}
                  tooltip="Nuevo Proveedor"
                  icon="plus"
                  classColor="is-success"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="columns">
            <div className="column is-12">
              <div className="columns">
                <div className="column is-4">
                  <InputSearch
                    onChange={this.handleInputChangeEmpresa.bind(this)}
                    name="inputNombre"
                    placeholder="Empresa..."
                    key="buscarPorEmpresa"
                  />
                </div>
                <div className="column is-2">
                  <SelectOption
                    name="inputCategoria"
                    defaultValue="Categoria"
                    value={this.state.inputCategoria}
                    onChange={this.handleInputChangeCategoria.bind(this)}
                    data={this.state.categorias}
                    mapValueFrom="Id_categoria"
                    mapTextFrom="nombre"
                  />
                </div>
              </div>
              <div className="row">
                <Table
                  columns={getColumns(this.props.userInfo.rol)}
                  setItem={this.setItem}
                  getTable={this.getTable}
                />
                <div className="controls-table">
                  <div className="">
                    Página {this.state.currentPage + 1} de{" "}
                    {this.state.totalPages}
                  </div>
                  <div>
                    <ButtonRounded
                      onClick={this.first}
                      tooltip="Primera página"
                      icon="angle-double-left"
                      classColor="is-light has-text-black"
                    />
                    <ButtonRounded
                      onClick={this.prev}
                      disabled={this.state.currentPage === 0 ? true : false}
                      tooltip="Anterior"
                      icon="angle-left"
                      classColor="is-light has-text-black"
                    />
                    <ButtonRounded
                      onClick={this.next}
                      tooltip="Siguiente"
                      icon="angle-right"
                      classColor="is-light has-text-black"
                      disabled={
                        this.state.currentPage ===
                        this.state.completedData.length - 1
                          ? true
                          : false
                      }
                    />
                    <ButtonRounded
                      onClick={this.last}
                      tooltip="Ultima página"
                      icon="angle-double-right"
                      classColor="is-light has-text-black"
                    />
                  </div>
                </div>
              </div>
            </div>
            {hasAccess(this.props.userInfo.rol, [
              ROLES.ADMIN,
              ROLES.ENCARGADO_PORTEO,
              ROLES.ENCARGADO_MANTENIMIENTO,
              ROLES.ENCARGADO_COMPRAS,
            ]) ? (
              <FormAddProveedor
                add={this.add}
                categorias={this.state.categorias}
              />
            ) : null}

            {this.props.userInfo.rol === ROLES.ADMIN ? (
              <FormModProveedor
                nom="asd"
                update={this.update}
                selectedItem={this.state.selectedItem}
                turnOn={this.turnOn}
                turnOff={this.turnOff}
                flag={this.state.flag}
                categorias={this.state.categorias}
              />
            ) : null}
            {this.props.userInfo.rol === ROLES.ADMIN ? (
              <DeleteProveedor remove={this.remove} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Proveedores);
