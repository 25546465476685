import React from "react";
import $ from "jquery";

import { SelectOption } from "../common/SelectOption";
import { MODAL_MODIFICAR_USUARIO } from "./ConstantsUsuarios";
import { alertWarning } from "../utilidades/Alertas";
import { getRoles } from "../utilidades/Constantes";
import { Service } from "../../services/Service"
import { INVENTARIO } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

export class FormModificarUsuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Id_usuario: "",
      iUsuario: "",
      iPassword: "",
      iRole: "",
      iNombre: "",
      iPorteo: "",
      roles: getRoles(),
      porteos: [],
    };
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='password'],input[type='text'],select"
    );
    for (const element of elements) {
      element.value = "";
    }
    this.setState({
      iUsuario: "",
      iPassword: "",
      iRole: "",
      iPorteo: "",
      iNombre: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_MODIFICAR_USUARIO}`).toggleClass("is-active");
    this.props.turnOn();
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    e.preventDefault();

    let { iUsuario, iPassword, iRole, iPorteo, iNombre } = this.state;

    if (iUsuario && iPassword && iRole && iPorteo && iNombre) {
      let id = this.props.selectedItem["Id_usuario"];
      this.props.mod({
        id: id,
        iUsuario,
        iPassword,
        iRole,
        iPorteo,
        iNombre,
      });
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  initUser = () => {
    this.setState({
      iUsuario: this.props.selectedItem["nickname"],
      // iPassword: this.props.selectedItem["password"],
      iRole: this.props.selectedItem["tipo_usuario"],
      iPorteo: this.props.selectedItem["Id_porteo"],
      iNombre: this.props.selectedItem["name"],
    });

    this.props.turnOff();
  };

  getPorteo = async (por) => {
    let res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=0`);
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteos: r });
    } else if (res.status === 403){
      clearSession()
    } else {
      this.setState({ porteos: "" });
    }
  };

  componentDidMount = () => {
    this.getPorteo();
  };

  render() {
    if ($(`#${MODAL_MODIFICAR_USUARIO}`).is(":visible") && this.props.flag) {
      this.initUser();
    }
    return (
      <div id={MODAL_MODIFICAR_USUARIO} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Modificar usuario</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iUsuario">
                      Usuario
                    </label>
                    <input
                      id="iUsuario"
                      name="iUsuario"
                      value={this.state.iUsuario}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Usuario"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iChoferResponsable">
                      Contraseña
                    </label>
                    <input
                      id="iPassword"
                      name="iPassword"
                      value={this.state.iPassword}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="password"
                      placeholder="Contraseña"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iRole">
                      Rol
                    </label>
                    <SelectOption
                      name="iRole"
                      defaultValue={this.state.iRole}
                      value={this.state.iRole}
                      onChange={this.handleInputChange.bind(this)}
                      data={this.state.roles}
                      mapValueFrom="id"
                      mapTextFrom="name"
                      classColor="is-success"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iPorteo">
                      Porteo
                    </label>
                    <SelectOption
                      name="iPorteo"
                      defaultValue="Seleccione un porteo"
                      value={this.state.iPorteo}
                      onChange={this.handleInputChange.bind(this)}
                      data={this.state.porteos}
                      mapValueFrom="Id_porteo"
                      mapTextFrom="nombre"
                      classColor="is-success"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iNombre">
                      Nombre de la persona
                    </label>
                    <input
                      id="iNombre"
                      name="iNombre"
                      value={this.state.iNombre}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Nombre de la persona"
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
