import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

import $ from "jquery";
import { Table } from "../utilidades/Table";
import { COLUMNS_USUARIOS } from "./ColumnsUsuarios";
import { SelectOption } from "../common/SelectOption";
import { InputSearch } from "../common/InputSearch";
import { clearSession, formatPorteoData } from "../utilidades/DataHelpers";
import { MODAL_ADD_USUARIO } from "./ConstantsUsuarios";
import { ButtonRounded } from "../common/ButtonRounded";
import { FormAddUsuario } from "./FormAddUsuario";
import { FormModificarUsuario } from "./FormModificarUsuario";
import { FormDeleteUsuario } from "./FormDeleteUsuario";
import { alertSuccess } from "../utilidades/Alertas";
import { Service } from "../../services/Service";

// define columns for transportes table

class Usuarios extends React.Component {
  state = {
    data: [],
    flag: true,
    selectedItem: "",
    table: null,
    inputPorteo: "",
    inputNameUsuario: "",
    inputRoleUsuario: "",
    selectedOption: "opcion1",
    inputPlaceholder: "Buscar por nombre...",
  };
  handleInputPorteo = (e) => {
    this.setState({ inputPorteo: e.target.value });
  };
  handleInputNameUsuario = (e) => {
    this.setState({ inputNameUsuario: e.target.value });
  };
  handleInputRoleUsuario = (e) => {
    this.setState({ inputRoleUsuario: e.target.value });
  };
  handleOptionChange = (event) => {
    this.setState({ selectedOption: event.target.value });

    // Cambia el placeholder del input en función de la opción seleccionada
    if (event.target.value === "opcion1") {
      this.setState({ inputPlaceholder: "Buscar por nombre..." });
    } else if (event.target.value === "opcion2") {
      this.setState({ inputPlaceholder: "Buscar por role..." });
    }
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.data);
      table.setFilter([
        {
          field: "name",
          type: "like",
          value: this.state.inputNameUsuario,
        },
        {
          field: "tipo_usuario",
          type: "like",
          value: this.state.inputRoleUsuario,
        },
        {
          field: "Id_porteo",
          type: "like",
          value: this.state.inputPorteo.split("/")[0],
        },
      ]);
    }
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  modalAddUsuario(e) {
    $(`#${MODAL_ADD_USUARIO}`).toggleClass("is-active");
  }

  getUsuarios = async () => {
    let result = await Service.getRawResponse(`/users`);
    if (result.status === 200) {
      this.setState({ data: await result.json() });
    } else if (result.status === 403) {
      clearSession();
    } else {
      this.setState({ data: [] });
    }
    this.props.setLoading(false);
  };
  //Añadir usuario
  addUsuario = async (data) => {
    let res = await Service.postRawResponse("/users", data);
    if (res.status === 201) {
      this.props.setLoading(true);
      alertSuccess("Usuario registrado", 4000, "center");
      this.getUsuarios();
    } else {
      console.log(res);
    }
  };

  //Modificar un proveedor
  update = async (data) => {
    let res = await Service.putRawResponse("/users", data);
    if (res.status === 201) {
      this.props.setLoading(true);
      alertSuccess("Usuario modificado", 4000, "center");
      this.getUsuarios();
    } else {
      console.log(res);
    }
  };

  //Elemento seleccionado en la tabla
  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  turnOn = () => {
    this.setState({ flag: true });
  };

  turnOff = () => {
    this.setState({ flag: false });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.getUsuarios();
  }

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="column is-3">
            <label className="title is-5">Usuarios</label>
          </div>
        </div>
        <div className="row columns">
          <div className="column control">
            <label className="radio">
              <input
                type="radio"
                name="foobar"
                value="opcion1"
                checked={this.state.selectedOption === "opcion1"}
                onChange={this.handleOptionChange}
              />{" "}
              Nombre
            </label>
            <label className="radio rounded">
              <input
                type="radio"
                name="foobar"
                value="opcion2"
                checked={this.state.selectedOption === "opcion2"}
                onChange={this.handleOptionChange}
              />{" "}
              Role
            </label>
          </div>
        </div>
        <div className="row">
          <div className="columns">
            <div className="column is-4">
              {this.state.selectedOption === "opcion1" ? (
                <InputSearch
                  onChange={this.handleInputNameUsuario}
                  name="inputUsuario"
                  placeholder={this.state.inputPlaceholder}
                />
              ) : (
                <InputSearch
                  onChange={this.handleInputRoleUsuario}
                  name="inputUsuario"
                  placeholder={this.state.inputPlaceholder}
                />
              )}
            </div>

            <div className="column is-3">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputPorteo}
                data={formatPorteoData(this.props.userInfo.porteo)}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>

            <div className="column is-offset-4">
              <ButtonRounded
                onClick={this.modalAddUsuario}
                tooltip="Nuevo usuario"
                icon="plus"
                classColor="is-success"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <Table
            columns={COLUMNS_USUARIOS}
            setItem={this.setItem}
            getTable={this.getTable}
          />
        </div>

        <FormAddUsuario userInfo={this.props.userInfo} add={this.addUsuario} />
        <FormModificarUsuario
          userInfo={this.props.userInfo}
          mod={this.update}
          selectedItem={this.state.selectedItem}
          turnOn={this.turnOn}
          turnOff={this.turnOff}
          flag={this.state.flag}
        />
        <FormDeleteUsuario />
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
