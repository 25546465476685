import React from 'react'

export const ButtonRounded = ({
    onClick,
    tooltip,
    icon,
    classColor,
    disabled = false
}) => (
    <button
        onClick={onClick}
        data-tooltip={tooltip}
        className={`button is-rounded is-circle ${classColor}`}
        disabled={disabled}
    >
        <span className="icon is-large">
            <i className={`fa fa-${icon}`}></i>
        </span>
    </button>
) 