import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'


function changeColors() {
  $('#link-inicio').removeClass('is-blue-color')
  $('#link-inicio').addClass('has-text-black')

  $('#link-nosotros').removeClass('is-blue-color')
  $('#link-nosotros').addClass('has-text-black')

  $('#link-contacto').removeClass('has-text-black')
  $('#link-contacto').addClass('is-blue-color')
  document.title = 'Logistic Express del Sur | Soluciones Logísticas | Contacto'
  window.scrollTo(0, 0);
}
export const Preguntas = () => (
  <section className="hero is-link">
    <div className="hero-body">
      <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap"}} className="container has-text-centered">
        <span className="subtitle is-size-4">
          ¿TIENES ALGUNA PREGUNTA ACERCA DE NUESTROS SERVICIOS?
      </span>
        <Link
          onClick={() => changeColors()}
          to='/contacto'
          className='button is-success'>CONTÁCTANOS</Link>

      </div>
    </div>
  </section>
)