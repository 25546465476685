import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function isLogin(){
    if(sessionStorage.getItem('user') && sessionStorage.getItem('pass')){   
        return true;
    }
    else
        return false;
}
const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            isLogin() 
            ? <Component {...props} />
            : <Redirect to={
                {pathname: '/login', 
                state: { message: 'Debes iniciar sesión'}
            }} />
        )} />
    );
};
export default PrivateRoute;