import React from "react";
import $ from "jquery";

import { SelectOption } from "../common/SelectOption";
import { MODAL_MODIFICAR_SERVICIO } from "./ConstantsConfiguraciones";
import { alertWarning } from "../utilidades/Alertas";
import { Service } from "../../services/Service";
import { INVENTARIO } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

export class FormModificarServicio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iIdServicio: "",
      iServicio: "",
    };
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='password'],input[type='text'],select"
    );
    for (const element of elements) {
      element.value = "";
    }
    this.setState({
      iServicio: "",
      iIdServicio: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_MODIFICAR_SERVICIO}`).toggleClass("is-active");
    this.props.turnOn();
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    e.preventDefault();

    let { iServicio } = this.state;

    if (iServicio) {
      // let id = this.props.selectedItem["Id_porteo"];
      let id = this.props.selectedItem.Id_servicio;
      console.log(id);
      this.props.mod({
        iIdServicio: id,
        iServicio,
      });
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  initPorteo = () => {
    this.setState({
      iServicio: this.props.selectedItem["nombre_servicio"],
    });

    this.props.turnOff();
  };

  componentDidMount = () => {
    // this.getPorteo();
  };

  render() {
    if ($(`#${MODAL_MODIFICAR_SERVICIO}`).is(":visible") && this.props.flag) {
      this.initPorteo();
    }
    return (
      <div id={MODAL_MODIFICAR_SERVICIO} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              Modificar servicio de mantenimiento
            </p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iServicio">
                      Servicio
                    </label>
                    <input
                      id="iServicio"
                      name="iServicio"
                      value={this.state.iServicio}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Servicio de mantenimiento"
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
