import React from "react";

// jquery
import $ from "jquery";

// components
import { Table } from "../utilidades/Table";
import { MODAL_ADD_SERVICIOS } from "./ConstantsMantenimiento";
import { Service } from "../../services/Service";
import { MANTENIMIENTO } from "../../services/Config";

// columns for table
let columns = [
  { title: "#", field: "id" },
  { title: "SERVICIO", field: "servicio" },
];

// datos example

export class ModalAddServicios extends React.Component {
  state = {
    iServicio: "",
    arrayServicios: [],
    selectedItem: "",
    servicios: null,
    table: null,
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.arrayServicios);
    }
  };

  limpiarC = () => {
    this.setState({
      iServicio: "",
      arrayServicios: [],
      selectedItem: "",
    });
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_ADD_SERVICIOS}`).toggleClass("is-active");
    this.limpiarC();
  };

  handleAgregar = (e) => {
    e.preventDefault();
    let { iServicio, arrayServicios } = this.state;
    let aux = arrayServicios;
    aux.push({ id: aux.length + 1, servicio: iServicio });
    this.setState({ arrayServicios: aux });
  };

  // backend
  handleSubmmit = (e) => {
    this.props.setServicios(this.state.arrayServicios);
    $(`#${MODAL_ADD_SERVICIOS}`).toggleClass("is-active");
    this.limpiarC();
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
  };

  getServicios = async () => {
    let res = await Service.getRawResponse(MANTENIMIENTO + "/getServicios");
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ servicios: r });
    } else {
      this.setState({ servicios: "" });
    }
  };

  componentDidMount() {
    this.getServicios();
  }

  render() {
    return (
      <div id={MODAL_ADD_SERVICIOS} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Agregar servicios</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-2">
                    <label className="label" htmlFor="iServicio">
                      Servicio
                    </label>
                  </div>

                  <div className="column is-5">
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iServicio"
                          name="iServicio"
                          value={this.state.iServicio}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un servicio
                          </option>
                          {this.state.servicios != null ? (
                            Object.values(this.state.servicios).map(
                              (servicio) => {
                                return (
                                  <option
                                    key={servicio.Id_servicio}
                                    id={servicio.Id_servicio}
                                    name={servicio.Id_servicio}
                                    value={servicio.nombre_servicio}
                                  >
                                    {servicio.nombre_servicio}
                                  </option>
                                );
                              }
                            )
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4 button-bottom">
                    <button
                      onClick={this.handleAgregar}
                      title="Agregar"
                      className="button is-rounded is-success is-circle"
                    >
                      <span className="icon">
                        <i className="fa fa-plus"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <Table
                      columns={columns}
                      setItem={this.setItem}
                      getTable={this.getTable}
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
