import React from "react";
import $ from "jquery";
import { alertWarning } from "../utilidades/Alertas";
import { MODAL_UPDATE_TRABAJADOR } from "./ConstantsPorteadores";
import { Service } from "../../services/Service";
import { PROVEEDORES } from "../../services/Config";

export class FormModTrabajador extends React.Component {
  state = {
    iNomTM: "",
    iAPTM: "",
    iAMTM: "",
    iCelTM: "",
    iTelTM: "",
    iCorM: "",
    iEstM: "",
    iCiuM: "",
    iCalM: "",
    iCPM: "",
    iNomEmergenciaM: "",
    iNumEmergenciaM: "",
    iNSSM: "",
    iFIM: "",
    iTSM: "",
    iNEM: "",
    iPueM: "",
    iFNM: "",
    iFVM: "",
    iActM: false,
    iFotoM: "",
    url: "",
    estados: "",
    ciudades: "",
    iParentescoM: "",
    iGradoEstudiosM: "",
    iEdoCivilM: "",
    iTallaM: "",
    iBancoM: "",
    iNumCuentaM: "",
    iNumTarjetaM: "",
    iVitaliciaM: false,
    iAplicaLicenciaM: false,
  };

  handleClick = (e) => {
    $(`#${MODAL_UPDATE_TRABAJADOR}`).toggleClass("is-active");
    this.limpiarC();
  };

  handleInputChange = (e) => {
    if (e.target.name === "iEstM") {
      this.getCiudades(e.target.value);
    }
    if (
      e.target.name === "iActM" ||
      e.target.name === "iVitaliciaM" ||
      e.target.name === "iAplicaLicenciaM"
    ) {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  //Limpiar inputs
  limpiarC = () => {
    $("#filenamem").text("Sin imagen");
    let elements = document.querySelectorAll(
      "input[type='text'],input[type='file'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iNomTM: "",
      iAPTM: "",
      iAMTM: "",
      iCelTM: "",
      iTelTM: "",
      iCorM: "",
      iEstM: "",
      iCiuM: "",
      iCalM: "",
      iCPM: "",
      iNomEmergenciaM: "",
      iNumEmergenciaM: "",
      iNSSM: "",
      iFIM: "",
      iTSM: "",
      iNEM: "",
      iPueM: "",
      iFNM: "",
      iFVM: "",
      iActM: false,
      iFotoM: "",
      url: "",
      iParentescoM: "",
      iGradoEstudiosM: "",
      iEdoCivilM: "",
      iTallaM: "",
      iBancoM: "",
      iNumCuentaM: "",
      iNumTarjetaM: "",
      iVitaliciaM: false,
      iAplicaLicenciaM: false,
    });
  };

  _inputImg = (e) => {
    $("#filenamem").text(e.target.files[0].name);
    document.getElementById("imgView").src = URL.createObjectURL(
      e.target.files[0]
    );
    this.setState({
      iFotoM: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    });
  };

  handleSubmmit = (e) => {
    e.preventDefault();
    let {
      iNomTM,
      iAPTM,
      iAMTM,
      iCelTM,
      iTelTM,
      iCorM,
      iEstM,
      iCiuM,
      iCalM,
      iCPM,
      iNomEmergenciaM,
      iNumEmergenciaM,
      iNSSM,
      iFIM,
      iTSM,
      iNEM,
      iPueM,
      iFNM,
      iActM,
      iFotoM,
      iFVM,
      iParentescoM,
      iGradoEstudiosM,
      iEdoCivilM,
      iTallaM,
      iBancoM,
      iNumCuentaM,
      iNumTarjetaM,
      iVitaliciaM,
      iAplicaLicenciaM,
    } = this.state;
    if (
      iNomTM &&
      iAPTM &&
      iAMTM &&
      iCelTM &&
      iTelTM &&
      iCorM &&
      iEstM &&
      iCiuM &&
      iCalM &&
      iCPM &&
      iNomEmergenciaM &&
      iNumEmergenciaM &&
      iNSSM &&
      iFIM &&
      iTSM &&
      iNEM &&
      iPueM &&
      iFNM &&
      iParentescoM &&
      iGradoEstudiosM &&
      iEdoCivilM &&
      iTallaM &&
      iBancoM &&
      iNumCuentaM &&
      iNumTarjetaM
    ) {
      let formData = new FormData();
      formData.append("Id_trabajador", this.props.selectedItem.Id_trabajador);
      formData.append("iNomTM", iNomTM);
      formData.append("iAPTM", iAPTM);
      formData.append("iAMTM", iAMTM);
      formData.append("iCelTM", iCelTM);
      formData.append("iTelTM", iTelTM);
      formData.append("iCorM", iCorM);
      formData.append("iEstM", iEstM);
      formData.append("iCiuM", iCiuM);
      formData.append("iCalM", iCalM);
      formData.append("iCPM", iCPM);
      formData.append("iNomEmergenciaM", iNomEmergenciaM);
      formData.append("iNumEmergenciaM", iNumEmergenciaM);
      formData.append("iNSSM", iNSSM);
      formData.append("iFIM", iFIM);
      formData.append("iTSM", iTSM);
      formData.append("iNEM", iNEM);
      formData.append("iPueM", iPueM);
      formData.append("iFNM", iFNM);
      formData.append("iFVM", iAplicaLicenciaM ? iFVM : null);
      formData.append("iActM", iActM);
      formData.append("file", iFotoM);
      formData.append("iParentescoM", iParentescoM);
      formData.append("iGradoEstudiosM", iGradoEstudiosM);
      formData.append("iEdoCivilM", iEdoCivilM);
      formData.append("iTallaM", iTallaM);
      formData.append("iBancoM", iBancoM);
      formData.append("iNumCuentaM", iNumCuentaM);
      formData.append("iNumTarjetaM", iNumTarjetaM);
      formData.append("iVitaliciaM", iAplicaLicenciaM ? iVitaliciaM : false);
      formData.append("iAplicaLicenciaM", iAplicaLicenciaM);

      this.props.mod(formData);
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  //Añadir estados
  getEstados = async () => {
    let result = await Service.getRawResponse( PROVEEDORES +`/estados`);
    if (result.status === 200) {
      let estados = await result.json();
      this.setState({ estados: estados });
    } else {
      this.setState({ estados: [] });
    }
  };

  //Añadir ciudades
  getCiudades = async (estado) => {
    let result = await Service.getRawResponse( PROVEEDORES + `/ciudades?estado=${estado}`);
    if (result.status === 200) this.setState({ ciudades: await result.json() });
    else this.setState({ ciudades: [] });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItem) {
      this.setState({
        iNomTM: nextProps.selectedItem.nombre_trabajador,
        iAPTM: nextProps.selectedItem.apellido_paterno_trabajador,
        iAMTM: nextProps.selectedItem.apellido_materno_trabajador,
        iCelTM: nextProps.selectedItem.celular_trabajador,
        iTelTM: nextProps.selectedItem.telefono_trabajador,
        iCorM: nextProps.selectedItem.correo_trabajador,
        iEstM: nextProps.selectedItem.estado_trabajador,
        iCiuM: nextProps.selectedItem.ciudad_trabajador,
        iCalM: nextProps.selectedItem.domicilio_trabajador,
        iCPM: nextProps.selectedItem.cp_trabajador,
        iNomEmergenciaM: nextProps.selectedItem.nombre_contacto_emergencia,
        iNumEmergenciaM: nextProps.selectedItem.numero_contacto_emergencia,
        iNSSM: nextProps.selectedItem.NSS_trabajador,
        iFIM:
          nextProps.selectedItem.fecha_ingreso !== undefined
            ? nextProps.selectedItem.fecha_ingreso.split("T")[0]
            : "",
        iTSM: nextProps.selectedItem.tipo_sangre,
        iNEM: nextProps.selectedItem.no_trabajador,
        iPueM: nextProps.selectedItem.puesto,
        iFNM:
          nextProps.selectedItem.fecha_nacimiento !== undefined
            ? nextProps.selectedItem.fecha_nacimiento.split("T")[0]
            : "",
        iFVM:
          nextProps.selectedItem.vencimiento_licencia !== null
            ? nextProps.selectedItem.vencimiento_licencia.split("T")[0]
            : "",
        iActM: nextProps.selectedItem.activo_trabajador === 1 ? true : false,
        iParentescoM: nextProps.selectedItem.parentesco_contacto_emergencia,
        iGradoEstudiosM: nextProps.selectedItem.estudios,
        iEdoCivilM: nextProps.selectedItem.estado_civil,
        iTallaM: nextProps.selectedItem.talla_camiseta,
        iBancoM: nextProps.selectedItem.banco,
        iNumCuentaM: nextProps.selectedItem.no_cuenta,
        iNumTarjetaM: nextProps.selectedItem.no_tarjeta,
        iVitaliciaM:
          nextProps.selectedItem.licencia_vitalicia === 1 ? true : false,
        iAplicaLicenciaM:
          nextProps.selectedItem.licencia_vitalicia === 1 ||
          nextProps.selectedItem.vencimiento_licencia !== null
            ? true
            : false,
      });
      this.getCiudades(nextProps.selectedItem["estado_trabajador"]);
    }
  }

  componentDidMount() {
    this.getEstados();
  }

  render() {
    return (
      <div id={MODAL_UPDATE_TRABAJADOR} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Modificar Trabajador</p>
            <button
              title="Cerrar"
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form id="formularioMod" encType="multipart/form-data">
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos personales
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNomTM">
                      Nombre
                    </label>
                    <input
                      id="iNomTM"
                      name="iNomTM"
                      value={this.state.iNomTM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Nombre(s)"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iAPTM">
                      Apellido Paterno
                    </label>
                    <input
                      id="iAPTM"
                      name="iAPTM"
                      value={this.state.iAPTM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Apellido paterno"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iAMTM">
                      Apellido Materno
                    </label>
                    <input
                      id="iAMTM"
                      name="iAMTM"
                      value={this.state.iAMTM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Apellido materno"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iCelTM">
                      Número de celular
                    </label>
                    <input
                      id="iCelTM"
                      name="iCelTM"
                      value={this.state.iCelTM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Número de celular"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iTelTM">
                      Número de la empresa
                    </label>
                    <input
                      id="iTelTM"
                      name="iTelTM"
                      value={this.state.iTelTM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Número de teléfono"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCorM">
                      Correo electrónico
                    </label>
                    <input
                      id="iCorM"
                      name="iCorM"
                      value={this.state.iCorM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="email"
                      placeholder="Correo electrónico"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iFNM">
                      Fecha de nacimiento
                    </label>
                    <input
                      id="iFNM"
                      name="iFNM"
                      value={this.state.iFNM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNSSM">
                      NSS_trabajador
                    </label>
                    <input
                      id="iNSSM"
                      name="iNSSM"
                      value={this.state.iNSSM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="NSS_trabajador"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iTSM">
                      Tipo de sangre
                    </label>
                    <input
                      id="iTSM"
                      name="iTSM"
                      value={this.state.iTSM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Tipo de sangre"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iEstM">
                      Estado
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEstM"
                          name="iEstM"
                          value={this.state.iEstM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un estado
                          </option>
                          {this.state.estados
                            ? Object.values(this.state.estados).map(
                                (estado) => {
                                  return (
                                    <option
                                      key={estado.estado}
                                      value={estado.estado}
                                    >
                                      {estado.estado}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCiuM">
                      Ciudad
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iCiuM"
                          name="iCiuM"
                          value={this.state.iCiuM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una ciudad
                          </option>
                          {this.state.ciudades
                            ? Object.values(this.state.ciudades).map(
                                (ciudad) => {
                                  return (
                                    <option
                                      key={ciudad.ciudad}
                                      value={ciudad.ciudad}
                                    >
                                      {ciudad.ciudad}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCalM">
                      Calle y número
                    </label>
                    <input
                      id="iCalM"
                      name="iCalM"
                      value={this.state.iCalM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Calle #"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iCPM">
                      Código Postal
                    </label>
                    <input
                      id="iCPM"
                      name="iCPM"
                      value={this.state.iCPM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Código postal"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iGradoEstudiosM">
                      Máx. grado estudios
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iGradoEstudiosM"
                          name="iGradoEstudiosM"
                          value={this.state.iGradoEstudiosM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Primaria">Primaria</option>
                          <option value="Secundaria">Secundaria</option>
                          <option value="Bachillerato">Bachillerato</option>
                          <option value="Licenciatura">Licenciatura</option>
                          <option value="Maestría">Maestría</option>
                          <option value="Doctorado">Doctorado</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4">
                    <label className="label" htmlFor="iEdoCivilM">
                      Estado civil
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEdoCivilM"
                          name="iEdoCivilM"
                          value={this.state.iEdoCivilM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Soltero">Soltero</option>
                          <option value="Casado">Casado</option>
                          <option value="Divorciado">Divorciado</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iTallaM">
                      Talla de camiseta
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iTallaM"
                          name="iTallaM"
                          value={this.state.iTallaM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="XS">XS</option>
                          <option value="S">S</option>
                          <option value="M">M</option>
                          <option value="L">L</option>
                          <option value="XL">XL</option>
                          <option value="XXL">XXL</option>
                          <option value="XXXL">XXXL</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-8">
                    <label className="label" htmlFor="file-js-example">
                      Foto personal
                    </label>
                    {this.props.selectedItem["foto_trabajador"] ? (
                      <figure className="image is-128x128">
                        <img
                          id="imgView"
                          style={{ width: 126, height: 126 }}
                          src={this.props.selectedItem["foto_trabajador"]}
                          alt={this.props.selectedItem["nombre_trabajador"]}
                        />
                      </figure>
                    ) : (
                      null
                    )}
                    <br />
                    <div
                      id="file-js-example"
                      className="file has-name is-success is-fullwidth"
                    >
                      <label className="file-label">
                        <input
                          onChange={this._inputImg}
                          className="file-input"
                          type="file"
                          name="file"
                          accept="image/*"
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fa fa-file-image-o"></i>
                          </span>
                          <span className="file-label">
                            Seleccionar imagen…
                          </span>
                        </span>
                        <span
                          id="filenamem"
                          className="file-name"
                          style={{ textAlign: "center" }}
                        >
                          {this.props.selectedItem["nombre_trabajador"]}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Contacto de emergencia
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNomEmergenciaM">
                      Nombre
                    </label>
                    <input
                      id="iNomEmergenciaM"
                      name="iNomEmergenciaM"
                      value={this.state.iNomEmergenciaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Nombre"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNumEmergenciaM">
                      Número de contacto
                    </label>
                    <input
                      id="iNumEmergenciaM"
                      name="iNumEmergenciaM"
                      value={this.state.iNumEmergenciaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Número"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iParentescoM">
                      Parentesco
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iParentescoM"
                          name="iParentescoM"
                          value={this.state.iParentescoM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Padre/Madre">Padre/Madre</option>
                          <option value="Hermano/Hermana">
                            Hermano/Hermana
                          </option>
                          <option value="Esposo/Esposa">Esposo/Esposa</option>
                          <option value="Primo/Prima">Primo/Prima</option>
                          <option value="Tío/Tía">Tío/Tía</option>
                          <option value="Sobrino/Sobrina">
                            Sobrino/Sobrina
                          </option>
                          <option value="Hijo/Hija">Hijo/Hija</option>
                          <option value="Abuelo/Abuela">Abuelo/Abuela</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos de la empresa
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNEM">
                      Número de Empleado
                    </label>
                    <input
                      id="iNEM"
                      name="iNEM"
                      value={this.state.iNEM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="No. Empelado"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iPueM">
                      Puesto
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iPueM"
                          name="iPueM"
                          value={this.state.iPueM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un puesto
                          </option>
                          <option value="Chofer">Chofer</option>
                          <option value="Aux. Admin">Aux. Admin</option>
                          <option value="Intendente">Intendente</option>
                          <option value="Enc. de porteo">Enc. de porteo</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iFIM">
                      Fecha de ingreso
                    </label>
                    <input
                      id="iFIM"
                      name="iFIM"
                      value={this.state.iFIM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="checkbox">
                      <input
                        name="iAplicaLicenciaM"
                        id="iAplicaLicenciaM"
                        value={this.state.iAplicaLicenciaM}
                        checked={this.state.iAplicaLicenciaM}
                        onChange={this.handleInputChange.bind(this)}
                        type="checkbox"
                      />
                    </label>
                    <label className="subtitle has-text-grey">
                      {" "}
                      Licencia de conducir
                    </label>
                  </div>
                </div>
              </div>

              {this.state.iAplicaLicenciaM ? (
                <div className="row">
                  <div className="columns is-vcentered">
                    <div className="column is-4">
                      <label className="checkbox">
                        <input
                          name="iVitaliciaM"
                          id="iVitaliciaM"
                          checked={this.state.iVitaliciaM}
                          onChange={this.handleInputChange.bind(this)}
                          type="checkbox"
                        />{" "}
                        Licencia vitalicia
                      </label>
                    </div>
                    {this.state.iVitaliciaM ? (
                      null
                    ) : (
                      <div className="column is-5">
                        <label className="label" htmlFor="iFVM">
                          Vencimiento de licencia
                        </label>
                        <input
                          id="iFVM"
                          name="iFVM"
                          value={this.state.iFVM}
                          onChange={this.handleInputChange.bind(this)}
                          className="input is-rounded is-success"
                          type="date"
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iActM">
                      Activo
                    </label>
                    <label className="switch">
                      <input
                        id="iActM"
                        name="iActM"
                        checked={this.state.iActM}
                        defaultValue={false}
                        onChange={this.handleInputChange.bind(this)}
                        type="checkbox"
                      />
                      <span className="sliderInput"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Información Bancaria
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iBancoM">
                      Banco
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iBancoM"
                          name="iBancoM"
                          value={this.state.iBancoM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione uno
                          </option>
                          <option value="Santander">Santander</option>
                          <option value="Banamex">Banamex</option>
                          <option value="BBVA Bancomer">BBVA Bancomer</option>
                          <option value="Otro">Otro</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNumCuentaM">
                      Número de cuenta
                    </label>
                    <input
                      id="iNumCuentaM"
                      name="iNumCuentaM"
                      value={this.state.iNumCuentaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNumTarjetaM">
                      Número de tarjeta
                    </label>
                    <input
                      id="iNumTarjetaM"
                      name="iNumTarjetaM"
                      value={this.state.iNumTarjetaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
