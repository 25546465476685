import React from "react";

export const SinNotificaciones = () => (
  <div className="has-text-centered">
    <p>
      <span className="icon is-large has-text-black">
        <i className="fa fa-bell fa-3x"></i>
      </span>
    </p>
    <br />
    <p className="title is-6">No tienes ninguna Notificación</p>
    <label className="subtitle is-6">
      Aquí aparecerán todas<p></p> tus notificaciones
    </label>
  </div>
);
