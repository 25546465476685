import React from "react";

// images
import RelojImg from "../../images/reloj.png";
import SeguridadImg from "../../images/seguridad.png";
import VerificadoImg from "../../images/verificado.png";

export const Frases = () => (
  <div className="paquete-back">
    <div className="container hero-body">
      <div className="columns">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
          className="column is-12"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{ width: "80%" }}
              className="is-size-3 is-uppercase has-text-black text-responsive has-text-centered"
            >
              <span>Entregamos tus paquetes de forma segura y </span>
              <span style={{ fontWeight: "bolder" }}>
                <br className="number-responsive" />
                <span>oportuna a todos tus clientes en</span>
                <br className="number-responsive" /> tiempo récord
              </span>
            </div>
          </div>
          <br />

          <div
            style={{ display: "flex", justifyContent: "space-around" }}
            className=""
          >
            <div className="">
              <figure className="image is-48x48">
                <img src={SeguridadImg} alt="Paquetes seguros" />
              </figure>
            </div>
            <div className="">
              <figure className="image is-48x48">
                <img src={VerificadoImg} alt="Envios seguros" />
              </figure>
            </div>
            <div className="">
              <figure className="image is-48x48">
                <img src={RelojImg} alt="Entregas a tiempo" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
