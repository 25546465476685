import $ from "jquery";
import { ROLES } from "../utilidades/Constantes";
import { editIcon, deleteIcon, infoIcon } from "../utilidades/TableIcons";
import {
  MODAL_DELETE_TRABAJADOR,
  MODAL_DETALLES_TRABAJADOR,
  MODAL_UPDATE_TRABAJADOR,
} from "./ConstantsPorteadores";

let COLUMNS_PORTEADORES = [
  {
    formatter: infoIcon,
    width: 40,
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_TRABAJADOR}`).toggleClass("is-active");
    },
  },
  { title: "CÓDIGO", field: "no_trabajador" },
  { title: "NOMBRE", field: "nombre_completo" },
  { title: "CEL EMPRESARIAL", field: "telefono_trabajador" },
  { title: "TEL PERSONAL", field: "celular_trabajador" },
  { title: "CIUDAD", field: "ciudad_trabajador" },
  { title: "PUESTO", field: "puesto" },
];

let editColumn = {
  formatter: editIcon,
  width: 40,
  cellClick: function (e, cell) {
    $(`#${MODAL_UPDATE_TRABAJADOR}`).toggleClass("is-active");
  },
};

let deleteColumn = {
  formatter: deleteIcon,
  width: 40,
  cellClick: function (e, cell) {
    $(`#${MODAL_DELETE_TRABAJADOR}`).toggleClass("is-active");
    $("#deletedata").text(cell.getRow().getData().nombre_trabajador);
  },
};

const editOption = [ROLES.ADMIN, ROLES.RH];
const deleteOption = [ROLES.ADMIN, ROLES.RH];

export const getColumns = (userRole) => {
  if (
    editOption.includes(userRole) &&
    !COLUMNS_PORTEADORES.includes(editColumn)
  ) {
    COLUMNS_PORTEADORES.push(editColumn);
  }
  if (
    deleteOption.includes(userRole) &&
    !COLUMNS_PORTEADORES.includes(deleteColumn)
  ) {
    COLUMNS_PORTEADORES.push(deleteColumn);
  }

  return COLUMNS_PORTEADORES;
};
