import React from "react";

import $ from "jquery";
import { Service } from "../../services/Service";
import { NOTIFICACIONES } from "../../services/Config";

export class ModNotificacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iNombreNM: '',
      iDescripcionNM: '',
      iCuantosKMM: '',
      iTransporteNM: '',
      transportes: ''
    }
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  //Limpiar inputs
  limpiarC = () => {

    var elements = document.querySelectorAll("input[type='text'],input[type='number'],select")
    for (var i = 0; i < elements.length; i++) {
      elements[i].value = ''
    }
    this.setState({
      iNombreNM: '',
      iDescripcionNM: '',
      iCuantosKMM: '',
      iTransporteNM: ''
    })
  }

  handleClick = (e) => {
    $("#modnotificacion").toggleClass("is-active");
    this.limpiarC();
  }

  handleSubmmit = (e) => {
    e.preventDefault();
    const {
      iNombreNM,
      iDescripcionNM,
      iCuantosKMM,
      iTransporteNM
    } = this.state;
    if (
      iNombreNM &&
      iDescripcionNM &&
      iCuantosKMM &&
      iTransporteNM
    ) {
      this.props.update({
        id: this.props.selectedItem['Id_cNotificacion'],
        iNombreNM,
        iDescripcionNM,
        iCuantosKMM,
        iTransporteNM
      });
      this.handleClick();
    }
    else
      alert("Faltan campos");
  }

  //Get transportes
  getTransportes = () => {
    Service.getRawResponse( NOTIFICACIONES +'/getTransporte')
      .then(response => response.json())
      .then(result => {
        this.setState({ transportes: result })
      });
  }

  componentDidMount() {
    this.getTransportes();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItem) {
      this.setState({
        iNombreNM: nextProps.selectedItem['nombre'],
        iDescripcionNM: nextProps.selectedItem['descripcion'],
        iCuantosKMM: nextProps.selectedItem['cadaCuantosKm'],
        iTransporteNM: nextProps.selectedItem['transporte'],
      });
    }
  }

  render() {
    return (
      <div id="modnotificacion" className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Modificar Notificación</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iNombreNM">
                      Nombre
                    </label>
                    <input
                      id='iNombreNM'
                      name='iNombreNM'
                      value={this.state.iNombreNM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Mantenimiento preventivo"
                    />
                  </div>

                  <div className="column is-6">
                    <label className="label" htmlFor="iDescripcionNM">
                      Descripción
                    </label>
                    <input
                      id='iDescripcionNM'
                      name='iDescripcionNM'
                      value={this.state.iDescripcionNM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Recomendado por el cliente"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iCuantosKMM">
                      Cada cuantos KM
                    </label>
                    <input
                      id='iCuantosKMM'
                      name='iCuantosKMM'
                      value={this.state.iCuantosKMM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="6000"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iTransporteNM">
                      Transporte
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id='iTransporteNM'
                          name='iTransporteNM'
                          value={this.state.iTransporteNM}
                          onChange={this.handleInputChange.bind(this)}>
                          <option value='' defaultValue>Seleccione un transporte</option>
                          <option value='Todos' defaultValue>Todos</option>
                          {
                            this.state.transportes != null ? (
                              Object.values(this.state.transportes).map((transporte) => {
                                return (<option key={transporte.placa} value={transporte.placa}>{transporte.placa}</option>)
                              })
                            ) : (
                                <option value=''>No hay datos</option>
                              )
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Actualizar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
