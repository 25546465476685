import React from 'react';

// components
import { UserLogged } from './UserLogged';

// REDUX
import { connect } from 'react-redux'
import { Sesion } from '../../redux/actions/SesionActions'

// jquery
import $ from 'jquery'

$(document).ready(function () {
    $(".navbar-burger").click(function () {
        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");
        $("#navbarBasicSesion").toggleClass("is-active");
        $("#navbarButtonSesion").toggleClass("is-active");
    });
    $("#notification-number").empty();
    $('#notification-number').append($('.verde').length + $('.amarillo').length + $('.naranja').length);
});

class StatusBar extends React.Component {
    state = {
        notiCount: $('.verde').length + $('.amarillo').length + $('.naranja').length
    }

    handleLogOut() {
        sessionStorage.clear();
    }

    panelNotificaciones() {
        $('.panel').toggleClass('visible');
    }

    render() {
        return (
            <nav className="navbar is-link" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <div className='navbar-item'>
                        <span className="fa fa-user" />
                    </div>
                    <div className='navbar-item'>
                        /
                    </div>
                    <div className="navbar-item">
                        <label>LOGISTIC</label>
                    </div>
                    <div className='navbar-item'>
                        /
                    </div>
                    <div className="navbar-item">
                        <UserLogged>{this.props.userInfo.user}</UserLogged>
                    </div>
                    <a href='# ' id='navbarButtonSesion' role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicSesion">
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                    </a>
                </div>

                <div id="navbarBasicSesion" className="navbar-menu">
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons">
                                <a href='# ' role='button' onClick={this.panelNotificaciones} className="notification-trigger has-tooltip-bottom" id='notificationcount' data-tooltip="Notificaciones">
                                    <i className="fa fa-bell" />
                                    <div className='notification-num' id='notification-number' >{this.state.notiCount}</div>
                                </a>
                                <a href='/login' title='Cerrar Sesión' className="navbar-link" onClick={this.handleLogOut}>
                                    <i className="fa fa-sign-out" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({ sesionReducer }) => ({
    userInfo: sesionReducer.userInfo
})

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (isLoading) => dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
        logOut: () => dispatch({ type: Sesion.LOG_OUT })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);