import React from "react";

// images
import Logo from "../../images/LOGOLOGISTIC.png";

// jquery
import $ from "jquery";

import { Link } from "react-router-dom";


// function to menu on mobile view
$(document).ready(function () {
  // Check for click events on the navbar burger icon
  $(".navbar-burger").click(function () {
    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");
    $("#navbarBasic").toggleClass("is-active");
    $("#navbarButton").toggleClass("is-active");
  });
});
const ESTILO = "navbar-item is-rounded has-text-weight-bold has-text-black";

const idLinks = ["link-inicio", "link-nosotros", "link-contacto"]

function habilitar(link) {
  idLinks.forEach(id => {
    // si es igual, habilita el color azul
    if (link === id) { 
      $('#'+id).removeClass('has-text-black')
      $('#'+id).addClass('is-blue-color')
    } 
    // si no, habilita el color gris
    else {
      $('#'+id).removeClass('is-blue-color')
      $('#'+id).addClass('has-text-black')
    }
  })
}

function changeColors(activo) {
  if (activo === '/') {
    habilitar("link-inicio")
    document.title = 'Logistic Express del Sur | Soluciones Logísticas | Inicio'
  } else if (activo === '/nosotros' || activo === '/nosotros/') {
    habilitar("link-nosotros")
    document.title = 'Logistic Express del Sur | Soluciones Logísticas | Nosotros'
  } else if (activo === '/contacto' || activo === '/contacto/') {
    habilitar("link-contacto")
    document.title = 'Logistic Express del Sur | Soluciones Logísticas | Contacto'
  }
  //console.log(activo)

}
export default class HomeBar extends React.Component {
  state = {
    activo: '/'
  }
  componentDidMount() {
    //console.log(window.location.pathname)
    changeColors(window.location.pathname)
    this.setState({ activo: window.location.pathname })
  }

  render() {
    return (
      <div className=''>
        <nav
          className="navbar container"
          role="navigation"
          aria-label="main navigation"
        >
          <div style={{ alignItems: "center"}} className="navbar-brand is-spaced is-back-home">
            <a href='/' style={{ display: 'flex', alignItems: 'center', paddingLeft: 35, paddingTop: 1, paddingBottom: 1, marginTop: '10px', marginBottom: '10px' }}>
              <img src={Logo} width="180" height="40" alt="Logistic Express del Sur"/>
            </a>

            <a
              href="# "
              id="navbarButton"
              role="button"
              className="navbar-burger burger is-back-home"
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasic"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasic" className="navbar-menu is-back-home">
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <Link id='link-inicio' onClick={() => changeColors('/')} to='/' className={ESTILO}>INICIO</Link>
                  <Link id='link-nosotros' onClick={() => changeColors('/nosotros')} to='/nosotros' className={ESTILO}>NOSOTROS</Link>
                  <Link id='link-contacto' onClick={() => changeColors('/contacto')} to='/contacto' className={ESTILO}>CONTACTO</Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
