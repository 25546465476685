import React from "react";
import $ from "jquery";
import { alertWarning } from "../../utilidades/Alertas";
import { MODAL_REGRESO_RUTA } from "./ConstantsEstatusDePiso";
import { SelectOption } from "../../common/SelectOption";

export class ModalRegresoRuta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iGasolinaFinal: "",
      iKmFinal: "",
      iObservacionesUnidad: "",
      iMontoCargado: "",
      iLitrosCargados: "",
      iFechaHora: "",
      nivelesGasolina: [
        {
          Id_nivel: "1/4",
          valor: "1/4",
        },
        {
          Id_nivel: "1/2",
          valor: "1/2",
        },
        {
          Id_nivel: "3/4",
          valor: "3/4",
        },
        {
          Id_nivel: "1",
          valor: "1",
        },
      ],
    };
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='datetime-local'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iGasolinaFinal: "",
      iKmFinal: "",
      iObservacionesUnidad: "",
      iMontoCargado: "",
      iLitrosCargados: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_REGRESO_RUTA}`).toggleClass("is-active");
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    const {
      iGasolinaFinal,
      iKmFinal,
      iObservacionesUnidad,
      iMontoCargado,
      iLitrosCargados,
      iFechaHora,
    } = this.state;

    if (
      iGasolinaFinal &&
      iKmFinal &&
      iObservacionesUnidad &&
      iMontoCargado &&
      iLitrosCargados &&
      iFechaHora
    ) {
      this.props.add({
        Id_salida_reparto: this.props.selectedItem.Id_salida_reparto,
        Id_transporte: this.props.selectedItem.Id_transporte,
        nivel_gasolina_inicial: this.props.selectedItem.nivel_gasolina_inicial,
        kilometraje_inicial: this.props.selectedItem.kilometraje_inicial,
        iGasolinaFinal,
        iKmFinal,
        iObservacionesUnidad,
        iMontoCargado,
        iLitrosCargados,
        iFechaHora,
      });
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  getTransportes = async (por, pla) => {};

  componentDidMount() {}

  render() {
    return (
      <div id={MODAL_REGRESO_RUTA} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Registrar regreso de ruta</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-6">
                    <span className="has-text-bold is-inline-block">
                      Folio de salida a ruta:{" "}
                    </span>
                    <span>{this.props.selectedItem.Id_salida_reparto}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label">Transporte:</label>
                    <span>{this.props.selectedItem.placa}</span>
                  </div>
                  <div className="column is-6">
                    <label className="label">Chofer Responsable:</label>
                    <span>{this.props.selectedItem.chofer}</span>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iGasolinaFinal">
                      Nivel de gasolina final
                    </label>
                    <SelectOption
                      name="iGasolinaFinal"
                      defaultValue="Gasolina final"
                      value={this.state.iGasolinaFinal}
                      onChange={this.handleInputChange.bind(this)}
                      data={this.state.nivelesGasolina}
                      mapValueFrom="Id_gasolina_final"
                      mapTextFrom="valor"
                      classColor="is-success"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iKmFinal">
                      Kilometraje final
                    </label>
                    <input
                      name="iKmFinal"
                      value={this.state.iKmFinal}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Kilometraje final"
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaHora">
                      Fecha y hora
                    </label>
                    <input
                      name="iFechaHora"
                      value={this.state.iFechaHora}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="datetime-local"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <label className="label" htmlFor="iObservacionesUnidad">
                      Observaciones de la unidad
                    </label>
                    <textarea
                      id="iObservacionesUnidad"
                      name="iObservacionesUnidad"
                      value={this.state.iObservacionesUnidad}
                      onChange={this.handleInputChange.bind(this)}
                      className="textarea is-success"
                      rows="3"
                      placeholder="Observaciones de la unidad de transporte"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Carga de combustible (solo si es el caso)
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iMontoCargado">
                      Monto cargado $
                    </label>
                    <input
                      name="iMontoCargado"
                      value={this.state.iMontoCargado}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Monto cargado $"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iLitrosCargados">
                      Litros cargados
                    </label>
                    <input
                      name="iLitrosCargados"
                      value={this.state.iLitrosCargados}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Litros cargados"
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
