import React from "react";
import { alertWarning } from "../utilidades/Alertas";
import $ from "jquery";
import { ROLES } from "../utilidades/Constantes";

import { connect } from "react-redux";
import { Sesion } from "../../redux/actions/SesionActions";
import { MODAL_ADD_ARTICULO } from "./ConstantsInventario";
import { Service } from "../../services/Service";
import { INVENTARIO } from "../../services/Config";

class FormAddArticulo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iNombreA: "",
      iMarcaA: "",
      iCategoriaA: "",
      iCodigoA: "",
      iPrecioA: "",
      iDescripcionA: "",
      iMedidaA: "-",
      iMedidaText: "",
      iCajasA: 0,
      iPiezasA: "",
      iFacturaA: "",
      iProveedorA: "",
      iMinA: "",
      iMaxA: "",
      iImagen: "",
      proveedores: "",
      nuevaCategoria: false,
      nuevaUnidadDeMedida: false,
    };
  }

  handleClick = (e) => {
    $(`#${MODAL_ADD_ARTICULO}`).toggleClass("is-active");
    this.limpiarC();
    this.setState({ nuevaCategoria: false, nuevaUnidadDeMedida: false });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "iCategoriaA") {
      this.getProveedores(e.target.value);
    }
  };

  //Limpiar inputs
  limpiarC = () => {
    $("#filename").text("Sin imagen");
    let elements = document.querySelectorAll(
      "input[type='text'],input[type='file'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iNombreA: "",
      iMarcaA: "",
      iCategoriaA: "",
      iCodigoA: "",
      iPrecioA: "",
      iDescripcionA: "",
      iMedidaA: "",
      iMedidaText: "",
      iCajasA: 0,
      iPiezasA: "",
      iFacturaA: "",
      iProveedorA: "",
      iMinA: "",
      iMaxA: "",
      iImagen: "",
      url: "",
    });
  };

  //Añadir proveedores
  getProveedores = async (categoria) => {
    try {
      await Service.getRawResponse( INVENTARIO + `/getProveedores?categoria=${categoria}`)
        .then((response) => response.json())
        .then((result) => {
          this.setState({ proveedores: result });
        });
    } catch (e) {}
  };

  _inputImg = (e) => {
    $("#filename").text(e.target.files[0].name);
    this.setState({
      iImagen: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    });
  };

  // backend
  handleSubmmit = async (e) => {
    e.preventDefault();
    let {
      iNombreA,
      iMarcaA,
      iCategoriaA,
      iCodigoA,
      iPrecioA,
      iDescripcionA,
      iMedidaA,
      iMedidaText,
      iCajasA,
      iPiezasA,
      iFacturaA,
      iProveedorA,
      iMinA,
      iMaxA,
      iImagen,
    } = this.state;
    if (
      iNombreA &&
      iMarcaA &&
      iCategoriaA &&
      iCodigoA &&
      iPrecioA &&
      iDescripcionA &&
      (iMedidaA || iMedidaText) &&
      iPiezasA &&
      iFacturaA &&
      iProveedorA &&
      iMinA &&
      iMaxA &&
      iImagen
    ) {
      this.props.setLoading(true);
      let cantidad;
      // if (!iCajasA || iCajasA === 0) {
      //     cantidad = iPiezasA;
      // }
      // else {
      cantidad = iPiezasA * iCajasA;
      // }

      let formData = new FormData();
      formData.append("porteo", sessionStorage.getItem("porteo"));
      formData.append("nombre", iNombreA);
      formData.append("marca", iMarcaA);
      formData.append("categoria", iCategoriaA);
      formData.append("codigo", iCodigoA);
      formData.append("precio", iPrecioA);
      formData.append("descripcion", iDescripcionA);
      formData.append("medida", iMedidaText ? iMedidaText : iMedidaA);
      formData.append("cantidad", cantidad);
      formData.append("factura", iFacturaA);
      formData.append("proveedor", iProveedorA);
      formData.append("minimo", iMinA);
      formData.append("maximo", iMaxA);
      formData.append("file", iImagen);
      await this.props.add(formData);
      this.handleClick();
      this.props.setLoading(false);
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  changeInputCategoria = () => {
    this.setState({ nuevaCategoria: !this.state.nuevaCategoria });
  };

  changeInputUnidadDeMedida = () => {
    this.setState({ nuevaUnidadDeMedida: !this.state.nuevaUnidadDeMedida });
  };

  componentDidMount() {}

  render() {
    return (
      <div id={MODAL_ADD_ARTICULO} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Nuevo Articulo</p>
            <button
              title="Cerrar"
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <form id="formularioAdd" encType="multipart/form-data">
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNombreA">
                      Nombre
                    </label>
                    <input
                      id="iNombreA"
                      name="iNombreA"
                      value={this.state.iNombreA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Nombre"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iMarcaA">
                      Marca
                    </label>
                    <input
                      id="iMarcaA"
                      name="iMarcaA"
                      value={this.state.iMarcaA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Marca"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCategoriaA">
                      Categoría
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iCategoriaA"
                          name="iCategoriaA"
                          value={this.state.iCategoriaA}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una categoría
                          </option>
                          {this.props.categorias
                            ? Object.values(this.props.categorias).map(
                                (categoria) => {
                                  return (
                                    <option
                                      key={categoria.Id_categoria}
                                      value={categoria.nombre}
                                    >
                                      {categoria.nombre}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iCodigoA">
                      Código
                    </label>
                    <input
                      id="iCodigoA"
                      name="iCodigoA"
                      value={this.state.iCodigoA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Código"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iPrecioA">
                      Precio unitario
                    </label>
                    <input
                      id="iPrecioA"
                      name="iPrecioA"
                      value={this.state.iPrecioA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Precio"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iDescripcionA">
                      Descripción
                    </label>
                    <input
                      id="iDescripcionA"
                      name="iDescripcionA"
                      value={this.state.iDescripcionA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Descripción"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <span className="is-space-between">
                      <span
                        style={{
                          display: "inline-block",
                          marginBottom: "0.5em",
                        }}
                      >
                        <label className="label" htmlFor="iMedidaA">
                          Unidad de medida
                        </label>
                      </span>
                      {this.props.userInfo.rol === ROLES.ADMIN ? (
                        <span>
                          <a
                            onClick={this.changeInputUnidadDeMedida}
                            data-tooltip="Nueva unidad de medida"
                            className="has-tooltip-left has-text-success is-outlined"
                          >
                            <span className="icon is-small">
                              <i
                                className={
                                  this.state.nuevaUnidadDeMedida
                                    ? "fa fa-times"
                                    : "fa fa-plus"
                                }
                              ></i>
                            </span>
                          </a>
                        </span>
                      ) : null}
                    </span>
                    {this.state.nuevaUnidadDeMedida ? (
                      <input
                        id="MedidaText"
                        name="iMedidaText"
                        value={this.state.iMedidaText}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="text"
                        placeholder="Unidad"
                      />
                    ) : (
                      <div className="control">
                        <div className="select is-rounded is-success is-fullwidth">
                          <select
                            id="iMedidaA"
                            name="iMedidaA"
                            value={this.state.iMedidaA}
                            onChange={this.handleInputChange.bind(this)}
                          >
                            <option value="" defaultValue>
                              Seleccione una unidad de medida
                            </option>
                            {this.props.unidadesMedida
                              ? Object.values(this.props.unidadesMedida).map(
                                  (um) => {
                                    return (
                                      <option
                                        key={um.Id_unidad_de_medida}
                                        value={um.nombre}
                                      >
                                        {um.nombre}
                                      </option>
                                    );
                                  }
                                )
                              : null}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCajasA">
                      Cantidad de articulos
                    </label>
                    <input
                      id="iCajasA"
                      name="iCajasA"
                      value={this.state.iCajasA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Cajas"
                    />
                  </div>

                  <div className="column is-4">
                    <label className="label" htmlFor="iPiezasA">
                      Cantidad de unidad de medida
                    </label>
                    <input
                      id="iPiezasA"
                      name="iPiezasA"
                      value={this.state.iPiezasA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Piezas"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iFacturaA">
                      Factura
                    </label>
                    <input
                      id="iFacturaA"
                      name="iFacturaA"
                      value={this.state.iFacturaA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Factura"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iProveedorA">
                      Proveedor
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iProveedorA"
                          name="iProveedorA"
                          value={this.state.iProveedorA}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un proveedor
                          </option>
                          {Object.values(this.state.proveedores).map(
                            (proveedor) => {
                              return (
                                <option
                                  key={proveedor.Id_proveedor}
                                  value={proveedor.empresa_proveedor}
                                >
                                  {proveedor.empresa_proveedor}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iMinA">
                      Mínimo
                    </label>
                    <input
                      id="iMinA"
                      name="iMinA"
                      value={this.state.iMinA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Mínimo"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iMaxA">
                      Máximo
                    </label>
                    <input
                      id="iMaxA"
                      name="iMaxA"
                      value={this.state.iMaxA}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Máximo"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <label className="label" htmlFor="file-js-example">
                      Imagen
                    </label>
                    {this.state.url ? (
                      <figure className="image is-256x256">
                        <img src={this.state.url} alt={this.state.url} />
                      </figure>
                    ) : (
                      null
                    )}
                    <br />
                    <div
                      id="file-js-example"
                      className="file has-name is-success is-fullwidth"
                    >
                      <label className="file-label">
                        <input
                          onChange={this._inputImg}
                          className="file-input"
                          type="file"
                          name="file"
                          accept="image/*"
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <i className="fa fa-file-image-o"></i>
                          </span>
                          <span className="file-label">
                            Seleccionar imagen…
                          </span>
                        </span>
                        <span
                          id="filename"
                          className="file-name"
                          style={{ textAlign: "center" }}
                        >
                          Sin imagen
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAddArticulo);
