import React, { useEffect, useRef } from "react";
import "tabulator-tables/dist/css/tabulator.min.css";
import "tabulator-tables/dist/js/tabulator.min.js";
import Tabulator from "tabulator-tables"; // Importa Tabulator

function Tabla({
  data,
  columnas,
  registroSeleccionado,
  onRegistroSeleccionado,
}) {
  const tableRef = useRef(null);

  useEffect(() => {
    // Inicializar Tabulator en el elemento de referencia de la tabla
    const table = new Tabulator(tableRef.current, {
      data,
      layout: "fitColumns",
      columns: columnas, // Utiliza las columnas proporcionadas como props
      selectable: true, // Habilitar selección de filas
      rowClick: function (e, row) {
        // Manejar la selección de la fila
        const registro = row.getData();
        onRegistroSeleccionado(registro);
      },
    });

    // Limpieza cuando el componente se desmonta
    return () => {
      table.destroy();
    };
  }, [data, columnas, onRegistroSeleccionado]);

  return <div ref={tableRef}></div>;
}

export default Tabla;
