import React from 'react'
//import InstagramIcon from '../../images/instagram.png'
//import FacebookIcon from '../../images/facebook.png'

export const HeadInfo = () => (
    <div className='has-background-link'>
        <div style={{ paddingTop: 5, paddingBottom: 5 }} className='container hero-body is-font-home'>
            <div className='columns is-centered'>
                <div className='column is-2 header-buttons'>
                    <a target="_blank" style={{ border: "none" }} href='https://www.facebook.com/LOGISTICEXPRESSDELSUR' rel="noopener noreferrer" className="button is-rounded is-circle is-white is-outlined">
                        <span className="icon is-small is-left">
                            <i className="fa fa-facebook"></i>
                        </span>
                    </a>
                    <a target="_blank" style={{ border: "none" }} href='https://www.instagram.com/logistic_express/' rel="noopener noreferrer" className="button is-rounded is-circle is-white is-outlined">
                        <span className="icon is-small is-left">
                            <i className="fa fa-instagram"></i>
                        </span>
                    </a>
                    <a target="_blank" style={{ border: "none" }} href='https://api.whatsapp.com/send?phone=523411511386&text=Atención%20al%20cliente' rel="noopener noreferrer" className="button is-rounded is-circle is-success is-outlined">
                        <span className="icon is-small is-left">
                            <i className="fa fa-whatsapp"></i>
                        </span>
                    </a>
                </div>
                <div className='column is-5 has-text-white header-info'>
                    <span style={{ width: 40 }} className="icon is-left has-text-white">
                        <i className='fa fa-envelope is-size-4'> </i>
                    </span> <span className='number-responsive'>Escribenos</span> <span style={{ paddingLeft: 5 }} className='is-title-cyan'><a className="is-title-cyan" href="mailto:paqueterialogistic@gmail.com">paqueterialogistic@gmail.com</a></span>
                </div>
                <div className='column is-5 has-text-white header-info' >
                    <span style={{ width: 40 }} className="icon is-left has-text-white">
                        <i className='fa fa-phone is-size-4'> </i>
                    </span>
                    <span className='number-responsive'>Llamanos</span>
                    <span style={{ paddingLeft: 'inherit' }} className='is-title-cyan'><a className="is-title-cyan" href="tel:3414106511">341-410-65-11</a> <span className='number-responsive'><span className='has-text-white'>ó</span> <a className="is-title-cyan" href="tel:3414106458">341-410-64-58</a></span></span>
                </div>
            </div>
        </div>


    </div>
)