import $ from "jquery";
import {
  DETALLES_MANTENIMIENTO,
  VENTANA_MANTENIMIENTO,
} from "../mantenimiento/ConstantsMantenimiento";
import { ROLES } from "../utilidades/Constantes";
import {
  editIcon,
  deleteIcon,
  addManIcon,
  infoIcon,
  assignIcon,
} from "../utilidades/TableIcons";
import {
  MODAL_BORRAR_TRANSPORTE,
  MODAL_DETALLES_TRANSPORTE,
  MODAL_MODIFICAR_TRANSPORTE,
  MODAL_REGRESO_TRANSPORTE,
} from "./ConstantsTransportes";

let COLUMNS_TRANSPORTES = [
  {
    formatter: infoIcon,
    width: 40,
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_TRANSPORTE}`).toggleClass("is-active");
    },
  },
  //{ title: "No.", field: "no" },
  { title: "NO. ECONÓMICO", field: "no_economico" },
  {
    title: "PLACA",
    field: "placa",
  },
  { title: "MARCA", field: "marca" },
  { title: "MODELO", field: "modelo" },
  { title: "PORTEO", field: "nombre" },
];

let editColumnTransportes = {
  formatter: editIcon,
  width: 40,
  cellClick: function (e, cell) {
    $(`#${MODAL_MODIFICAR_TRANSPORTE}`).toggleClass("is-active");
  },
};

let deleteColumnTransportes = {
  formatter: deleteIcon,
  width: 40,
  cellClick: function (e, cell) {
    $(`#${MODAL_BORRAR_TRANSPORTE}`).toggleClass("is-active");
    $("#deletedata").text(cell.getRow().getData().placa);
  },
};

// let addMantenimientoColumn = {
//   formatter: addManIcon,
//   width: 40,
//   cellClick: function (e, cell) {
//     $(`#${VENTANA_MANTENIMIENTO}`).show("slow");
//     $(`#${DETALLES_MANTENIMIENTO}`).empty();
//     $(`#${DETALLES_MANTENIMIENTO}`).append(
//       "<Strong>Placa:</Strong> " + cell.getRow().getData().placa + " "
//     );
//     $(`#${DETALLES_MANTENIMIENTO}`).append(
//       "<Strong>Marca:</Strong> " + cell.getRow().getData().marca + " "
//     );
//     $(`#${DETALLES_MANTENIMIENTO}`).append(
//       "<Strong>Modelo:</Strong> " + cell.getRow().getData().modelo
//     );
//   },
// };

const editOption = [ROLES.ADMIN];
const deleteOption = [ROLES.ADMIN];
const addMantenimientoOption = [ROLES.ADMIN];

export const getColumnsTransportes = (userRole = "") => {
  if (
    editOption.includes(userRole) &&
    !COLUMNS_TRANSPORTES.includes(editColumnTransportes)
  ) {
    COLUMNS_TRANSPORTES.push(editColumnTransportes);
  }
  if (
    deleteOption.includes(userRole) &&
    !COLUMNS_TRANSPORTES.includes(deleteColumnTransportes)
  ) {
    COLUMNS_TRANSPORTES.push(deleteColumnTransportes);
  }
  // if (
  //   addMantenimientoOption.includes(userRole) &&
  //   !COLUMNS_TRANSPORTES.includes(addMantenimientoColumn)
  // ) {
  //   COLUMNS_TRANSPORTES.push(addMantenimientoColumn);
  // }

  return COLUMNS_TRANSPORTES;
};

let COLUMNS_TRANSPORTES_CIRCULACION = [
  { title: "NO. ECONÓMICO", field: "no_economico" },
  { title: "PLACA", field: "placa" },
  { title: "MOTIVO DE USO", field: "motivo_de_uso" },
  { title: "CHOFER", field: "chofer" },
  { title: "QUIEN ASIGNO", field: "quien_asigno" },
];

let regresoTransporteColumn = {
  formatter: assignIcon,
  width: 40,
  cellClick: function (e, cell) {
    $(`#${MODAL_REGRESO_TRANSPORTE}`).toggleClass("is-active");
  },
};

const regresoTransporteOption = [ROLES.ADMIN, ROLES.ENCARGADO_PORTEO];

export const getColumnsTransportesCirculacion = (userRole = "") => {
  if (
    regresoTransporteOption.includes(userRole) &&
    !COLUMNS_TRANSPORTES_CIRCULACION.includes(regresoTransporteColumn)
  ) {
    COLUMNS_TRANSPORTES_CIRCULACION.push(regresoTransporteColumn);
  }
  return COLUMNS_TRANSPORTES_CIRCULACION;
};
