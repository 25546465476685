import React from 'react'

export const InputIcon = ({
    icon
}) => (
    <div className="control">
        <a href="www.google.com" className="button is-rounded is-static">
            <span className="icon is-small is-left">
                <i className={`fa fa-${icon} has-text-info`}></i>
            </span>
        </a>
    </div>
) 