import React from 'react'
import { InputIcon } from './InputIcon'

export const InputSearch = ({
    onChange,
    iconLeft,
    iconRight = "search",
    name,
    placeholder = "Buscar..."
}) => (
    <div className="field has-addons">
        {
            iconLeft
                ? <InputIcon icon={iconLeft} />
                : null
        }
        <div className="control is-expanded">
            <input
                name={name}
                onChange={onChange}
                className="input is-rounded"
                type="text"
                placeholder={placeholder}
            />
        </div>
        <InputIcon icon={iconRight}/>
    </div>
) 