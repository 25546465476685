import React from "react";
import $ from "jquery";

import { MODAL_REGRESO_TRANSPORTE } from "./ConstantsTransportes";
import { SelectOption } from "../common/SelectOption";

export class ModalRegresoTransporte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iGasolinaFinal: "",
      iKmFinal: "",
      iObservacionesUnidad: "",
      iMontoCargado: "",
      iLitrosCargados: "",
    };
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='datetime-local'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iGasolinaFinal: "",
      iKmFinal: "",
      iObservacionesUnidad: "",
      iMontoCargado: "",
      iLitrosCargados: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_REGRESO_TRANSPORTE}`).toggleClass("is-active");
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {};

  getTransportes = async (por, pla) => {};

  componentDidMount() {}

  render() {
    return (
      <div id={MODAL_REGRESO_TRANSPORTE} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Regreso de transporte</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label">Transporte</label>
                    <span>Transporte</span>
                  </div>
                  <div className="column is-6">
                    <label className="label">Chofer Responsable</label>
                    <span>Julio Torres</span>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iGasolinaFinal">
                      Nivel de gasolina final
                    </label>
                    <SelectOption
                      name="iGasolinaFinal"
                      defaultValue="Gasolina final"
                      value={this.state.iGasolinaFinal}
                      onChange={this.handleInputPorteo}
                      data={null}
                      mapValueFrom="Id_gasolina_final"
                      mapTextFrom="valor"
                      classColor="is-success"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iKmFinal">
                      Kilometraje final
                    </label>
                    <input
                      id="iKmFinal"
                      name="iKmFinal"
                      value={this.state.iKmFinal}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Km final"
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaInicial">
                      Fecha y hora
                    </label>
                    <input
                      id="iFechaInicial"
                      name="iFechaInicial"
                      value={this.state.iFechaInicial}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                      placeholder="Fecha y hora"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-12">
                    <label className="label" htmlFor="iObservacionesUnidad">
                      Observaciones de la unidad
                    </label>
                    <textarea
                      id="iObservacionesUnindad"
                      name="iObservacionesUnindad"
                      value={this.state.iObservacionesUnindad}
                      onChange={this.handleInputChange.bind(this)}
                      className="textarea is-success"
                      rows="3"
                      placeholder="Observaciones de la unidad de transporte"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Carga de combustible (solo si es el caso)
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iMontoCargado">
                      Monto cargado $
                    </label>
                    <input
                      id="iMontoCargado"
                      name="iMontoCargado"
                      value={this.state.iMontoCargado}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="$"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iLitrosCargados">
                      Litros cargados
                    </label>
                    <input
                      id="iLitrosCargados"
                      name="iLitrosCargados"
                      value={this.state.iLitrosCargados}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Litros"
                    />
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
