import React from "react";

//import { Link } from 'react-router-dom'
//import { Menu } from '../components/Menu'
import StatusBar from "../../components/utilidades/StatusBar";
import $ from "jquery";

// modules for menu
import Dashboard from "../../components/dashboard/Dashboard";
import Ontime from "../../components/ontime/Ontime";
import Proveedores from "../../components/proveedores/Proveedores";
import Porteadores from "../../components/porteadores/Porteadores";
import Transportes from "../../components/transportes/Transportes";
import Mantenimiento from "../../components/mantenimiento/Mantenimiento";
import Inventario from "../../components/inventario/Inventario";
import ConsumoGasolina from "../../components/consumogasolina/ConsumoGasolina";
import Usuarios from "../../components/usuarios/Usuarios";
import Configuraciones from "../../components/configuraciones/Configuraciones";
import { Notificacion } from "../../components/utilidades/Notificacion";
import { Notificacion2 } from "../../components/utilidades/Notificacion2";
import { ConfiguracionNoti } from "../../components/notificaciones/ConfiguracionNoti";
import { SinNotificaciones } from "../../components/notificaciones/SinNotificaciones";
//import { Bodega } from '../../components/bodega/Bodega'
import Embarques from "../../components/bodega/embarques/Embarques";
import EstatusDePiso from "../../components/bodega/estatusDePiso/EstatusDePiso";

import { connect } from "react-redux";
import { Sesion } from "../../redux/actions/SesionActions";

//import { alertSuccess, alertInfo, alertWarning, alertError } from '../../components/utilidades/Alertas'
import { MODULES, ROLES } from "../../components/utilidades/Constantes";
import SeguimientoEntregas from "../../components/seguimientoentregas/SeguimientoEntregas";
import { Icon } from "../../components/common/simple/Icon";
import "./dropdown-bodega.css";
import { Service } from "../../services/Service";
import { INVENTARIO, NOTIFICACIONES } from "../../services/Config";
import { clearSession } from "../../components/utilidades/DataHelpers";

// delete notification
$(function () {
  $("#boton1").on("click", "button", function (e) {
    if ($(e.target).attr("class") === "fa fa-check-square fix-icon") {
      $(this).remove();
      notiUpdate();
    }
  });
});

// update notification number
function notiUpdate() {
  $("#notification-number").empty();
  $("#notification-number").append(
    $(".verde").length +
      $(".lila").length +
      $(".amarillo").length +
      $(".naranja").length +
      $(".azul").length
  );
}

class Home extends React.Component {
  #modulesAllowed = new Map();

  state = {
    name: null,
    module: null,
    notificaciones: "",
    notificacionesLic: "",
    notificacionesCre: "",
    notificacionesVV: "",
    notificacionesPol: "",
    notiHistorial: "",
    historial: false,
  };

  getNotificaciones = async () => {
    let response = await Service.getRawResponse(
      NOTIFICACIONES + `/getNotificaciones?act=1&tipo=${"MAN"}`
    );
    if (response.status === 200) {
      let result = await response.json();
      this.setState({ notificaciones: "" });
      this.setState({ notificaciones: result });
      notiUpdate();
    } else {
      //alertError('Hubo un error', 2000, 'center');
    }
  };

  getNotificacionesLic = async () => {
    let response = await Service.getRawResponse(
      NOTIFICACIONES + `/getNotificaciones?act=1&tipo=${"LIC"}`
    );
    if (response.status === 200) {
      let result = await response.json();
      this.setState({ notificacionesLic: "" });
      this.setState({ notificacionesLic: result });
      notiUpdate();
    } else {
      //alertError('Hubo un error', 2000, 'center');
    }
  };

  getNotificacionesCre = async () => {
    let response = await Service.getRawResponse(
      NOTIFICACIONES + `/getNotificaciones?act=1&tipo=${"CRE"}`
    );
    if (response.status === 200) {
      let result = await response.json();
      this.setState({ notificacionesCre: "" });
      this.setState({ notificacionesCre: result });
      notiUpdate();
    } else {
      //alertError('Hubo un error', 2000, 'center');
    }
  };

  getNotificacionesVV = async () => {
    let response = await Service.getRawResponse(
      NOTIFICACIONES + `/getNotificaciones?act=1&tipo=${"VV"}`
    );
    if (response.status === 200) {
      let result = await response.json();
      this.setState({ notificacionesVV: "" });
      this.setState({ notificacionesVV: result });
      notiUpdate();
    } else {
      //alertError('Hubo un error', 2000, 'center');
    }
  };

  getNotificacionesPol = async () => {
    let response = await Service.getRawResponse(
      NOTIFICACIONES + `/getNotificaciones?act=1&tipo=${"POL"}`
    );
    if (response.status === 200) {
      let result = await response.json();
      this.setState({ notificacionesPol: "" });
      this.setState({ notificacionesPol: result });
      notiUpdate();
    } else {
      //alertError('Hubo un error', 2000, 'center');
    }
  };

  getHistorialNotificaciones = async () => {
    let response = await Service.getRawResponse(
      NOTIFICACIONES + `/getNotificaciones?act=0&tipo=${"MAN"}`
    );
    if (response.status === 200) {
      let result = await response.json();
      this.setState({ notiHistorial: result });
      notiUpdate();
    } else {
      //alertError('Hubo un error', 2000, 'center');
    }
  };

  getPorteo = async (por) => {
    let res = null;
    if (por == 0) {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      this.props.setUserInfo({
        user: sessionStorage.getItem("user"),
        rol: sessionStorage.getItem("rol"),
        porteo: r,
      });
    } else if (res.status === 403) {
      clearSession();
    } else {
      this.props.setUserInfo({ user: "", rol: "", porteo: "" });
    }
  };

  // show notification panel
  panelNotificaciones() {
    $(".panel").toggleClass("visible");
  }

  configuracionNoti = () => {
    this.setState({ name: "ConfiguracionNoti", module: <ConfiguracionNoti /> });
    this.panelNotificaciones();
  };

  async getTransportes() {
    let res = await Service.getRawResponse(NOTIFICACIONES + "/getTransportes");
    if (res.status === 200) {
      this.setState({ not: res.json() });
      this.state.not.forEach((element) => {
        if (parseFloat(element.kilometraje) % 5000 === 0) {
          this.state.notificaciones.push({
            color: "naranja",
            icono: "triangle",
            titulo: "Servicio cada 5,000",
            placa: element.placa,
            texto: "Por desgaste",
          });
        }
      });
    }
  }

  updateNotificacionMan = async (datos) => {
    let res = await Service.putRawResponse(
      NOTIFICACIONES + "/setNotificacionMan",
      datos
    );
    if (res.status === 200) {
      this.getAll();
    }
  };

  getAll = async () => {
    this.getNotificaciones();
    this.getNotificacionesCre();
    this.getNotificacionesLic();
    this.getNotificacionesVV();
    this.getNotificacionesPol();
    this.getHistorialNotificaciones();
    this.getPorteo(sessionStorage.getItem("porteo"));
  };

  setAll = async () => {
    //this.setNotificaciones();
    //this.setNotificacionesVV();
    //this.setNotificacionesLicencias();
    //this.setNotificacionesPol();
    //this.setNotificacionesCredito();
  };

  updateNotificacion = async (datos) => {
    try {
      await Service.putRawResponse(NOTIFICACIONES + "/setNotificacion", datos);
      this.getAll();
    } catch (e) {
      console.log(e);
    }
  };

  setModulesAllowed() {
    // this.#modulesAllowed.set(MODULES.DASHBOARD, [
    //   ROLES.ADMIN,
    //   ROLES.ENCARGADO_PORTEO,
    // ]);
    this.#modulesAllowed.set(MODULES.ONTIME, [
      ROLES.ADMIN,
      ROLES.AUXILIAR_ADMINISTRATIVO,
      ROLES.ENCARGADO_PORTEO,
    ]);
    this.#modulesAllowed.set(MODULES.PERSONAL, [
      ROLES.ADMIN,
      ROLES.AUXILIAR_ADMINISTRATIVO,
      ROLES.ENCARGADO_PORTEO,
      ROLES.RH,
    ]);
    this.#modulesAllowed.set(MODULES.TRANSPORTES, [
      ROLES.ADMIN,
      ROLES.ENCARGADO_MANTENIMIENTO,
      ROLES.ENCARGADO_ALMACEN,
      ROLES.ENCARGADO_PORTEO,
    ]);
    this.#modulesAllowed.set(MODULES.MANTENIMIENTO, [
      ROLES.ADMIN,
      ROLES.ENCARGADO_MANTENIMIENTO,
      ROLES.ENCARGADO_ALMACEN,
      ROLES.AUXILIAR_ADMINISTRATIVO,
      ROLES.ENCARGADO_PORTEO,
    ]);
    this.#modulesAllowed.set(MODULES.INVENTARIO, [
      ROLES.ADMIN,
      ROLES.ENCARGADO_COMPRAS,
      ROLES.ENCARGADO_PORTEO,
      ROLES.ENCARGADO_INVENTARIO,
    ]);
    this.#modulesAllowed.set(MODULES.PROVEEDORES, [
      ROLES.ADMIN,
      ROLES.ENCARGADO_COMPRAS,
      ROLES.ENCARGADO_MANTENIMIENTO,
      ROLES.AUXILIAR_ADMINISTRATIVO,
      ROLES.ENCARGADO_PORTEO,
    ]);
    this.#modulesAllowed.set(MODULES.BODEGA, [
      ROLES.ADMIN,
      ROLES.ENCARGADO_ALMACEN,
      ROLES.ENCARGADO_PORTEO,
    ]);
    this.#modulesAllowed.set(MODULES.SEGUIMIENTO_DE_ENTREGAS, [
      ROLES.ADMIN,
      ROLES.AUXILIAR_ADMINISTRATIVO,
      ROLES.ENCARGADO_ALMACEN,
      ROLES.ENCARGADO_PORTEO,
    ]);
    this.#modulesAllowed.set(MODULES.CONSUMO_DE_GASOLINA, [
      ROLES.ADMIN,
      ROLES.ENCARGADO_PORTEO,
    ]);
    this.#modulesAllowed.set(MODULES.USUARIOS, [ROLES.ADMIN]);
    this.#modulesAllowed.set(MODULES.CONFIGURACIONES, [ROLES.ADMIN]);
  }

  setDefaultModule = () => {
    this.setModulesAllowed();
    const { rol } = this.props.userInfo;

    var defaultName = null;
    this.#modulesAllowed.forEach(
      // eslint-disable-next-line eqeqeq
      (value, key) => {
        //console.log(value, key)
        if (value.includes(rol) && defaultName === null) {
          defaultName = key;
        }
      }
    );
    var defaultComponent;
    switch (defaultName) {
      // case MODULES.DASHBOARD:
      //   defaultComponent = <Dashboard />;
      //   break;
      case MODULES.ONTIME:
        defaultComponent = <Ontime />;
        break;
      case MODULES.PERSONAL:
        defaultComponent = <Porteadores />;
        break;
      case MODULES.TRANSPORTES:
        defaultComponent = <Transportes />;
        break;
      case MODULES.MANTENIMIENTO:
        defaultComponent = <Mantenimiento />;
        break;
      case MODULES.INVENTARIO:
        defaultComponent = <Inventario />;
        break;
      case MODULES.PROVEEDORES:
        defaultComponent = <Proveedores />;
        break;
      case MODULES.BODEGA:
        defaultComponent = <Embarques />;
        break;
      case MODULES.CONSUMO_DE_GASOLINA:
        defaultComponent = <ConsumoGasolina />;
        break;
      default:
        defaultComponent = <div className="has-text-centered">Bienvenido</div>;
        break;
    }
    this.setState({ name: defaultName, module: defaultComponent });
  };

  getModuleDashboard() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <Dashboard />,
              name: MODULES.DASHBOARD,
            })
          }
          className={this.state.name === MODULES.DASHBOARD ? "is-active" : null}
        >
          <Icon icon="bar-chart" styles="menu-icon" /> Dashboard
        </a>
      </li>
    );
  }

  getModuleOntime() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({ module: <Ontime />, name: MODULES.ONTIME })
          }
          className={this.state.name === MODULES.ONTIME ? "is-active" : null}
        >
          <Icon icon="clock-o" styles="menu-icon" /> Ontime
        </a>
      </li>
    );
  }

  getModulePersonal() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <Porteadores />,
              name: MODULES.PERSONAL,
            })
          }
          className={this.state.name === MODULES.PERSONAL ? "is-active" : null}
        >
          <Icon icon="users" styles="menu-icon" /> Personal
        </a>
      </li>
    );
  }

  getModuleTransportes() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <Transportes />,
              name: MODULES.TRANSPORTES,
            })
          }
          className={
            this.state.name === MODULES.TRANSPORTES ? "is-active" : null
          }
        >
          <Icon icon="truck" styles="menu-icon" /> Transportes
        </a>
      </li>
    );
  }

  getModuleMantenimiento() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <Mantenimiento />,
              name: MODULES.MANTENIMIENTO,
            })
          }
          className={
            this.state.name === MODULES.MANTENIMIENTO ? "is-active" : null
          }
        >
          <Icon icon="wrench" styles="menu-icon" /> Mantenimiento
        </a>
      </li>
    );
  }

  getModuleInventario() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <Inventario />,
              name: MODULES.INVENTARIO,
            })
          }
          className={
            this.state.name === MODULES.INVENTARIO ? "is-active" : null
          }
        >
          <Icon icon="list-ol" styles="menu-icon" /> Inventario
        </a>
      </li>
    );
  }

  getModuleProveedores() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <Proveedores />,
              name: MODULES.PROVEEDORES,
            })
          }
          className={
            this.state.name === MODULES.PROVEEDORES ? "is-active" : null
          }
        >
          <Icon icon="handshake-o" styles="menu-icon" /> Proveedores
        </a>
      </li>
    );
  }

  getModuleBodega() {
    return (
      <div className="dropdown is-hoverable normal">
        <li className="dropdown-trigger">
          <i className="fa fa-cubes menu-icon"></i>
          <span aria-haspopup="true" aria-controls="dropdown-menu">
            <span> Bodega</span>
            <span className="icon is-small">
              <Icon icon="angle-down" />
            </span>
          </span>
        </li>
        <div
          style={{ width: "100%" }}
          className="dropdown-menu"
          id="dropdown-menu-bodega"
          role="menu"
        >
          <div className="dropdown-content" style={{ cursor: "pointer" }}>
            <span
              href="# "
              className={
                (this.state.name === MODULES.EMBARQUES
                  ? "is-active-custom fa fa-arrow-right "
                  : null) + " dropdown-item"
              }
              onClick={() =>
                this.setState({
                  module: <Embarques />,
                  name: MODULES.EMBARQUES,
                })
              }
            >
              {" "}
              Embarques
            </span>
            <span
              href="# "
              className={
                (this.state.name === MODULES.ESTATUS_DE_PISO
                  ? " is-active-custom fa fa-arrow-right "
                  : null) + " dropdown-item"
              }
              onClick={() =>
                this.setState({
                  module: <EstatusDePiso />,
                  name: MODULES.ESTATUS_DE_PISO,
                })
              }
            >
              {" "}
              Estatus de piso
            </span>
          </div>
        </div>
      </div>
    );
  }

  getModuleSeguimientoEntregas() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <SeguimientoEntregas />,
              name: MODULES.SEGUIMIENTO_DE_ENTREGAS,
            })
          }
          className={
            this.state.name === MODULES.SEGUIMIENTO_DE_ENTREGAS
              ? "is-active"
              : null
          }
        >
          <Icon icon="search" styles="menu-icon" /> Seg. Entregas
        </a>
      </li>
    );
  }

  getModuleConsumoGasolina() {
    return (
      <li>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <ConsumoGasolina />,
              name: MODULES.CONSUMO_DE_GASOLINA,
            })
          }
          className={
            this.state.name === MODULES.CONSUMO_DE_GASOLINA ? "is-active" : null
          }
        >
          <Icon icon="tachometer" styles="menu-icon" /> Consumo gasolina
        </a>
      </li>
    );
  }

  getModuleUsuarios() {
    return (
      <li style={{ paddingTop: "60%" }}>
        <a
          href="# "
          onClick={() =>
            this.setState({ module: <Usuarios />, name: MODULES.USUARIOS })
          }
          className={this.state.name === MODULES.USUARIOS ? "is-active" : null}
        >
          <Icon icon="key" styles="menu-icon" /> Usuarios
        </a>
      </li>
    );
  }
  getModuleConfiguraciones() {
    return (
      <li s>
        <a
          href="# "
          onClick={() =>
            this.setState({
              module: <Configuraciones />,
              name: MODULES.CONFIGURACIONES,
            })
          }
          className={
            this.state.name === MODULES.CONFIGURACIONES ? "is-active" : null
          }
        >
          <Icon icon="gear" styles="menu-icon" /> Configuraciones
        </a>
      </li>
    );
  }

  getModuleAllowed = (myModule) => {
    this.setModulesAllowed();
    const { rol } = this.props.userInfo;
    var module;

    switch (myModule) {
      // case MODULES.DASHBOARD:
      //   module = this.#modulesAllowed.get(MODULES.DASHBOARD).includes(rol)
      //     ? this.getModuleDashboard()
      //     : null
      //   return module;
      case MODULES.ONTIME:
        module = this.#modulesAllowed.get(MODULES.ONTIME).includes(rol)
          ? this.getModuleOntime()
          : null;
        return module;
      case MODULES.PERSONAL:
        module = this.#modulesAllowed.get(MODULES.PERSONAL).includes(rol)
          ? this.getModulePersonal()
          : null;
        return module;
      case MODULES.TRANSPORTES:
        module = this.#modulesAllowed.get(MODULES.TRANSPORTES).includes(rol)
          ? this.getModuleTransportes()
          : null;
        return module;
      case MODULES.MANTENIMIENTO:
        module = this.#modulesAllowed.get(MODULES.MANTENIMIENTO).includes(rol)
          ? this.getModuleMantenimiento()
          : null;
        return module;
      case MODULES.INVENTARIO:
        module = this.#modulesAllowed.get(MODULES.INVENTARIO).includes(rol)
          ? this.getModuleInventario()
          : null;
        return module;
      case MODULES.PROVEEDORES:
        module = this.#modulesAllowed.get(MODULES.PROVEEDORES).includes(rol)
          ? this.getModuleProveedores()
          : null;
        return module;
      case MODULES.BODEGA:
        module = this.#modulesAllowed.get(MODULES.BODEGA).includes(rol)
          ? this.getModuleBodega()
          : null;
        return module;
      case MODULES.SEGUIMIENTO_DE_ENTREGAS:
        module = this.#modulesAllowed
          .get(MODULES.SEGUIMIENTO_DE_ENTREGAS)
          .includes(rol)
          ? this.getModuleSeguimientoEntregas()
          : null;
        return module;
      case MODULES.CONSUMO_DE_GASOLINA:
        module = this.#modulesAllowed
          .get(MODULES.CONSUMO_DE_GASOLINA)
          .includes(rol)
          ? this.getModuleConsumoGasolina()
          : null;
        return module;
      case MODULES.USUARIOS:
        module = this.#modulesAllowed.get(MODULES.USUARIOS).includes(rol)
          ? this.getModuleUsuarios()
          : null;
        return module;
      case MODULES.CONFIGURACIONES:
        module = this.#modulesAllowed.get(MODULES.CONFIGURACIONES).includes(rol)
          ? this.getModuleConfiguraciones()
          : null;
        return module;
      default:
        return null;
    }
  };

  componentDidMount() {
    $("#notification-number").empty();
    $("#notification-number").append(
      $(".verde").length + $(".amarillo").length + $(".naranja").length
    );
    if (sessionStorage.getItem("refresh") === "1") {
      sessionStorage.setItem("refresh", "2");
      window.location.reload();
    }

    this.setAll();
    this.getAll();

    document.title = "Logistic Express del Sur";

    this.setDefaultModule();
    //this.getHistorialNotificaciones();
  }

  render() {
    return (
      <div className="">
        <div className="row">
          <StatusBar />
        </div>

        <div className="row box">
          <div className="columns">
            <div className="column is-2" style={{ padding: 0 }}>
              <aside className="menu">
                <p className="menu-label">Menú</p>
                <ul className="menu-list">
                  {this.getModuleAllowed(MODULES.ONTIME)}
                  {this.getModuleAllowed(MODULES.PERSONAL)}
                  {this.getModuleAllowed(MODULES.TRANSPORTES)}
                  {this.getModuleAllowed(MODULES.MANTENIMIENTO)}
                  {this.getModuleAllowed(MODULES.INVENTARIO)}
                  {this.getModuleAllowed(MODULES.PROVEEDORES)}
                  {this.getModuleAllowed(MODULES.BODEGA)}
                  {this.getModuleAllowed(MODULES.SEGUIMIENTO_DE_ENTREGAS)}
                  {this.getModuleAllowed(MODULES.CONSUMO_DE_GASOLINA)}
                  {this.getModuleAllowed(MODULES.USUARIOS)}
                  {this.getModuleAllowed(MODULES.CONFIGURACIONES)}
                </ul>
              </aside>
            </div>
            <div className="column is-10">{this.state.module}</div>

            <div className="panel">
              <div className="title-panel">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {this.state.historial ? (
                      <a
                        href="# "
                        onClick={() => this.setState({ historial: false })}
                        className="has-tooltip-bottom"
                        data-tooltip="Regresar"
                      >
                        <span className="icon has-text-black">
                          <i className="fa fa-arrow-left fa-lg"></i>
                        </span>
                      </a>
                    ) : (
                      <a
                        href="# "
                        onClick={() => this.setState({ historial: true })}
                        className="has-tooltip-bottom"
                        data-tooltip="Historial"
                      >
                        <span className="icon has-text-black">
                          <i className="fa fa-history fa-lg"></i>
                        </span>
                      </a>
                    )}
                  </div>
                  {this.state.historial ? "Historial" : "Notificaciones"}
                  <div>
                    <a
                      href="# "
                      onClick={this.configuracionNoti}
                      data-tooltip="Configuración"
                      className="has-tooltip-bottom"
                    >
                      <span className="icon has-text-black">
                        <i className="fa fa-cog fa-lg"></i>
                      </span>
                    </a>
                    <a
                      href="# "
                      onClick={this.panelNotificaciones}
                      data-tooltip="Cerrar"
                      className="has-tooltip-bottom"
                    >
                      <span className="icon has-text-black">
                        <i className="fa fa-times fa-lg"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <ul className="notification-bar" id="notificaciones">
                {this.state.historial
                  ? this.state.notiHistorial.length === 0
                    ? ""
                    : this.state.notiHistorial.map((n) => {
                        return (
                          <Notificacion
                            color={"verde"}
                            titulo={n.nombre}
                            icono={"triangle"}
                            placa={n.transporte}
                            texto={n.descripcion}
                            key={n.Id_notificacion}
                            encargado={n.encargadoMant}
                            porteo={n.encargadoPorteo}
                            chofer={n.chofer}
                            alta={n.fechaAlta.split("T")[0]}
                            baja={n.fechaBaja}
                          />
                        );
                      })
                  : this.state.notificaciones.length === 0
                  ? null
                  : this.state.notificaciones.map((n) => {
                      return (
                        <Notificacion
                          Id_not={n.Id_nMantenimiento}
                          color={"verde"}
                          titulo={n.nombre}
                          icono={"triangle"}
                          placa={n.transporte}
                          texto={n.descripcion}
                          key={n.Id_notificacion}
                          encargado={n.encargadoMant}
                          porteo={n.encargadoPorteo}
                          chofer={n.chofer}
                          alta={n.fechaAlta.split("T")[0]}
                          updateNotificacionMan={this.updateNotificacionMan}
                        />
                      );
                    })}
                {this.state.historial
                  ? null
                  : this.state.notificacionesLic === undefined
                  ? null
                  : this.state.notificacionesLic.length === 0
                  ? null
                  : this.state.notificacionesLic.map((n) => {
                      return (
                        <Notificacion2
                          Id_not={n.Id_notificacion}
                          color={"amarillo"}
                          titulo={n.nombre}
                          icono={"triangle"}
                          placa={n.trabajador}
                          texto={n.descripcion}
                          key={n.Id_notificacion}
                          alta={n.fechaAlta.split("T")[0]}
                          updateNotificacion={this.updateNotificacion}
                        />
                      );
                    })}
                {this.state.historial
                  ? null
                  : this.state.notificacionesCre === undefined
                  ? null
                  : this.state.notificacionesCre.length === 0
                  ? null
                  : this.state.notificacionesCre.map((n) => {
                      return (
                        <Notificacion2
                          Id_not={n.Id_notificacion}
                          color={"naranja"}
                          titulo={n.nombre}
                          icono={"triangle"}
                          placa={n.transporte}
                          texto={n.descripcion}
                          key={n.Id_notificacion}
                          alta={n.fechaAlta.split("T")[0]}
                          updateNotificacion={this.updateNotificacion}
                        />
                      );
                    })}
                {this.state.historial
                  ? null
                  : this.state.notificacionesVV === undefined
                  ? null
                  : this.state.notificacionesVV.length === 0
                  ? null
                  : this.state.notificacionesVV.map((n) => {
                      return (
                        <Notificacion2
                          Id_not={n.Id_notificacion}
                          color={"azul"}
                          titulo={n.nombre}
                          icono={"triangle"}
                          placa={n.transporte}
                          texto={n.descripcion}
                          key={n.Id_notificacion}
                          alta={n.fechaAlta.split("T")[0]}
                          updateNotificacion={this.updateNotificacion}
                        />
                      );
                    })}
                {this.state.historial
                  ? null
                  : this.state.notificacionesPol === undefined
                  ? null
                  : this.state.notificacionesPol.length === 0
                  ? null
                  : this.state.notificacionesPol.map((n) => {
                      return (
                        <Notificacion2
                          Id_not={n.Id_notificacion}
                          color={"lila"}
                          titulo={n.nombre}
                          icono={"triangle"}
                          placa={n.transporte}
                          texto={n.descripcion}
                          key={n.Id_notificacion}
                          alta={n.fechaAlta.split("T")[0]}
                          updateNotificacion={this.updateNotificacion}
                        />
                      );
                    })}
                {$(".verde").length +
                  $(".lila").length +
                  $(".amarillo").length +
                  $(".naranja").length +
                  $(".azul").length ===
                0 ? (
                  <SinNotificaciones />
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
    setUserInfo: (userInfo) =>
      dispatch({ userInfo: userInfo, type: Sesion.SET_USER_INFO }),
  };
};
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
