import { editIcon } from "../utilidades/TableIcons";
import { MODAL_MODIFICAR_USUARIO } from "./ConstantsUsuarios";
import $ from "jquery";

export const COLUMNS_USUARIOS = [
  { title: "USUARIO", field: "nickname" },
  { title: "NOMBRE", field: "name" },
  { title: "ROLE", field: "tipo_usuario" },
  { title: "PORTEO", field: "nombre" },
  {
    formatter: editIcon,
    width: 40,
    cellClick: function (e, cell) {
      $(`#${MODAL_MODIFICAR_USUARIO}`).toggleClass("is-active");
    },
  },
  //Al momento no se pueden eliminar usuarios desde sistema
  //   {
  //     formatter: deleteIcon,
  //     width: 40,
  //     cellClick: function (e, cell) {
  //       $(`#${MODAL_BORRAR_USUARIO}`).toggleClass("is-active");
  //       $("#deletedata").text();
  //     },
  //   },
];
