// React
import React from 'react';

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from 'react-redux'

import { Article } from './Article';

class Dashboard extends React.Component {
  render() {
    return (
      <div className='container is-fluid box'>

        <div className='row'>
          <div className='columns'>

            <div className='column'>
            <Article title='Entregas' color='message is-dark'>
                2500
              </Article>
            </div>

            <div className='column'>
            <Article title='Esta semana' color='message is-warning'>
                2500
              </Article>
            </div>

            <div className='column'>
            <Article title='Este mes' color='message is-link'>
                2500
              </Article>
            </div>

            <div className='column' >
              <Article title='Total de entregas' color='message is-success'>
                2500
              </Article>
            </div>
          </div>
        </div>

        <br/>

      <div className='row has-text-centered'>
      <label className='title is-5'>Resultados obtenidos por porteador</label>
      </div>
        <div className='row'>
          

          <div className='columns'>
            <div className='column has-text-centered'>
              <label className='title is-5'>Grafica</label>
            </div>

            <div className='column has-text-centered'>
              <label className='title is-5'>Grafica</label>
            </div>
          </div>

        </div>

      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo
})

const mapDispatchToProps = (dispatch) => {
  return {
      setLoading: (isLoading) => dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)