/*
import React from 'react';
import 'react-tabulator/lib/styles.css';
import { ReactTabulator } from 'react-tabulator'
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

export class Table extends React.Component {

    rowClick = (e, row) => {
        if (this.props.setItem)
            this.props.setItem(row.getData());
    };

    render() {
        const options = {
            height: 300,
            tooltips: true,
            placeholder: 'No data',
            rowClick: this.rowClick
        }
        const { data, columns } = this.props
        const datos = [];
        for (var i in data)
            datos.push(data[i])
        return (
            <ReactTabulator
                data={datos}
                columns={columns}
                options={options}
                layout={"fitDataTable"}
            />
        );
    }
}
*/
import React from "react";
import "react-tabulator/lib/styles.css";
import Tabulator from "tabulator-tables";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

export class Table extends React.Component {
  rowClick = (e, row) => {
    if (this.props.setItem) this.props.setItem(row.getData());
  };

  el = React.createRef();

  tabulator = null;

  componentDidMount() {
    let { columns } = this.props;
    this.tabulator = new Tabulator(this.el, {
      columns: columns,
      height: 350,
      resizableColumns: true,
      tooltips: true,
      placeholder: "Sin datos",
      rowClick: this.rowClick,
      layout: "fitColumns",

      //pagination: true, //enable pagination
      //paginationMode: "remote", //enable remote pagination
      //ajaxURL: "http://testdata.com/data", //set url for ajax request
      //ajaxParams: { token: "ABC123" }, //set any standard parameters to pass with the request
      //paginationSize: 10, //optional parameter to request a certain number of rows per page
    });
  }

  //add table holder element to DOM
  render() {
    if (this.props.getTable) this.props.getTable(this.tabulator);
    if (this.props.getTable2) this.props.getTable2(this.tabulator);
    return <div ref={(el) => (this.el = el)} />;
  }
}
