// react
import React from 'react';

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table/lib'
// fuente a utilizar
import ProductSans from './style/ProductSansRegular.ttf'
import ProductSansBold from './style/ProductSansBold.ttf'
// estilos
import { styles } from './style/Styles'
//LOGOTIPO
import logo from './../../images/LOGOLOGISTIC.png'

Font.register({
    family: 'ProductSans',
    fonts: [
        { src: ProductSans }, // font-style: normal, font-weight: normal
        { src: ProductSansBold, fontWeight: 'bold' },
    ]
});

export const InventarioFaltantes = ({ porteo, faltantes, data, f = new Date() }) => (
    <Document>
        <Page size="A4" style={styles.page} >
            <Text style={{ position: 'absolute', bottom: 5, left: 0, right: 0, textAlign: "center", color: "#4a4963", fontSize: "11" }} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />

            <View style={styles.logoTitle}>
                <Image style={styles.image} src={logo} />
                <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
            </View>
            <View style={styles.info}>
                <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
                <Text style={styles.contacto}>Tel: (341) 410-6511 ó (341) 410-6458</Text>
            </View>
            <Text style={styles.subtitulo}>Reporte de faltantes</Text>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Porteo:</Text>
                    <Text style={styles.parametros}>{porteo}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Fecha:</Text>
                    <Text style={styles.parametros}>{f.getFullYear() + '-' + ('0' + (f.getMonth() + 1)).slice(-2) + '-' + ('0' + f.getDate()).slice(-2)}</Text>
                </View>
            </View>
            <View>
                <Table data={data}>
                    <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <TableCell style={styles.tablaHeader} weighting={0.1}>#</TableCell>
                        <TableCell style={styles.tablaHeader}>ARTICULO</TableCell>
                        <TableCell style={styles.tablaHeader} >STOCK SISTEMA</TableCell>
                        <TableCell style={styles.tablaHeader} >STOCK REAL</TableCell>
                        <TableCell style={styles.tablaHeader} >FALTANTES</TableCell>
                    </TableHeader>
                    <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <DataTableCell style={styles.tabla} weighting={0.1} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.articulo} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.stocks.toLocaleString()} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.stockr} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.faltantes.toLocaleString()} />
                    </TableBody>
                </Table>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%", paddingTop: "70" }}>
                <View style={{ display: "flex", flexDirection: "row", alignContent: "flex-start" }}>
                    <View>
                        <Text style={{ borderBottom: "1 px solid", width: 180 }} render={({ pageNumber, totalPages }) => (
                            pageNumber === totalPages && (
                                <View  >
                                    <Text style={{ fontSize: "12", color: "black", textAlign: "center" }}>Firma aqui</Text>
                                </View>
                            )
                        )} fixed />
                        <Text render={({ pageNumber, totalPages }) => (
                            pageNumber === totalPages && (
                                <View  >
                                    <Text style={{ fontSize: "12", color: "black", textAlign: "center" }}>Responsable de auditoría</Text>
                                </View>
                            )
                        )} fixed />
                    </View>
                </View>
                <View style={{ display: "flex", flexDirection: "row", alignContent: "flex-start" }}>
                    <View>
                        <Text style={{ borderBottom: "1 px solid", width: 180 }} render={({ pageNumber, totalPages }) => (
                            pageNumber === totalPages && (
                                <View  >
                                    <Text style={{ fontSize: "12", color: "black", textAlign: "center" }}>Firma aqui</Text>
                                </View>
                            )
                        )} fixed />
                        <Text render={({ pageNumber, totalPages }) => (
                            pageNumber === totalPages && (
                                <View >
                                    <Text style={{ fontSize: "12", color: "black", textAlign: "center" }}>Encargado de porteo</Text>
                                </View>
                            )
                        )} fixed />
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);