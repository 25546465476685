import React from "react";
import $ from "jquery";
import { alertWarning } from "../utilidades/Alertas";
import { MODAL_MODIFICAR_TRANSPORTE } from "./ConstantsTransportes";
import { Service } from "../../services/Service";
import { PROVEEDORES } from "../../services/Config";

export class FormModTransporte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iPlacaM: "",
      iNoEcoM: "",
      iTipoUnidadM: "",
      iNoSerieM: "",
      iModeloM: "",
      iColorM: "",
      iMarcaM: "",
      iSubmarcaM: "",
      iCamperM: "",
      iStatusFM: "",
      iEmpresaM: "",
      iPolizaM: "",
      iEstadoRM: "",
      iRefrendoM: "",
      iPolizaVencimientoM: "",
      iStatusFVencimientoM: null,
      iKmM: "",
      iNivelGasolinaM: "",
      iCapacidadGasolinaM: "",
      iRendimientoTecnicoM: "",
    };
  }

  handleClick = (e) => {
    this.limpiarC();
    $(`#${MODAL_MODIFICAR_TRANSPORTE}`).toggleClass("is-active");
  };

  handleInputChange = (e) => {
    if (e.target.name === "iStatusFM")
      this.setState({ iStatusFVencimientoM: null });
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='text'],input[type='file'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iPlacaM: "",
      iNoEcoM: "",
      iTipoUnidadM: "",
      iNoSerieM: "",
      iModeloM: "",
      iColorM: "",
      iMarcaM: "",
      iSubmarcaM: "",
      iCamperM: "",
      iStatusFM: "",
      iEmpresaM: "",
      iPolizaM: "",
      iEstadoRM: "",
      iRefrendoM: "",
      iPolizaVencimientoM: "",
      iStatusFVencimientoM: null,
      iKmM: "",
      iNivelGasolinaM: "",
      iCapacidadGasolinaM: "",
      iRendimientoTecnicoM: "",
    });
  };

  // backend
  handleSubmmit = (e) => {
    e.preventDefault();
    let {
      iPlacaM,
      iNoEcoM,
      iTipoUnidadM,
      iNoSerieM,
      iModeloM,
      iColorM,
      iMarcaM,
      iSubmarcaM,
      iCamperM,
      iStatusFM,
      iEmpresaM,
      iPolizaM,
      iEstadoRM,
      iRefrendoM,
      iPolizaVencimientoM,
      iStatusFVencimientoM,
      iKmM,
      iNivelGasolinaM,
      iCapacidadGasolinaM,
      iRendimientoTecnicoM,
    } = this.state;
    if (
      iPlacaM &&
      iNoEcoM &&
      iTipoUnidadM &&
      iNoSerieM &&
      iModeloM &&
      iColorM &&
      iMarcaM &&
      iSubmarcaM &&
      iStatusFM &&
      iEmpresaM &&
      iPolizaM &&
      iEstadoRM &&
      iRefrendoM &&
      iPolizaVencimientoM &&
      // iKmM &&
      //iNivelGasolinaM &&
      iCapacidadGasolinaM &&
      iRendimientoTecnicoM
    ) {
      let formData = new FormData();
      formData.append("id", this.props.selectedItem.Id_transporte);
      formData.append("iPlacaM", iPlacaM);
      formData.append("iNoEcoM", iNoEcoM);
      formData.append("iNoSerieM", iNoSerieM);
      formData.append("iTipoUnidadM", iTipoUnidadM);
      formData.append("iModeloM", iModeloM);
      formData.append("iColorM", iColorM);
      formData.append("iMarcaM", iMarcaM);
      formData.append("iSubmarcaM", iSubmarcaM);
      formData.append("iCamperM", iCamperM);
      formData.append("iStatusFM", iStatusFM);
      formData.append("iEmpresaM", iEmpresaM);
      formData.append("iPolizaM", iPolizaM);
      formData.append("iEstadoRM", iEstadoRM);
      formData.append("iRefrendoM", iRefrendoM);
      formData.append("iPolizaVencimientoM", iPolizaVencimientoM);
      formData.append("iStatusFVencimientoM", iStatusFVencimientoM);
      formData.append("iKmM", iKmM);
      formData.append("iNivelGasolinaM", iNivelGasolinaM);
      formData.append("iCapacidadGasolinaM", iCapacidadGasolinaM);
      formData.append("iRendimientoTecnicoM", iRendimientoTecnicoM);
      this.props.mod(formData);
      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItem)
      if (nextProps.selectedItem.status_financiero_vencimiento !== null)
        this.setState({
          iPlacaM: nextProps.selectedItem.placa,
          iNoEcoM: nextProps.selectedItem.no_economico,
          iTipoUnidadM: nextProps.selectedItem.tipo_unidad,
          iNoSerieM: nextProps.selectedItem.no_serie,
          iModeloM: nextProps.selectedItem.modelo,
          iColorM: nextProps.selectedItem.color,
          iMarcaM: nextProps.selectedItem.marca,
          iSubmarcaM: nextProps.selectedItem.submarca,
          iCamperM: nextProps.selectedItem.camper,
          iStatusFM: nextProps.selectedItem.status_financiero,
          iEmpresaM: nextProps.selectedItem.empresa_seguro,
          iPolizaM: nextProps.selectedItem.poliza_seguro,
          iEstadoRM: nextProps.selectedItem.estado_registro,
          iRefrendoM: nextProps.selectedItem.refrendo,
          //   iVerificacionM: nextProps.selectedItem.verificacion_vehicular,
          iKmM: nextProps.selectedItem.kilometraje,
          iCapacidadGasolinaM: nextProps.selectedItem.capacidad_gasolina,
          iNivelGasolinaM: nextProps.selectedItem.nivel_gasolina,
          iRendimientoTecnicoM: nextProps.selectedItem.rendimiento_tecnico,
          iPolizaVencimientoM:
            nextProps.selectedItem.poliza_vencimiento.split("T")[0],
          iStatusFVencimientoM:
            nextProps.selectedItem.status_financiero_vencimiento.split("T")[0],
        });
      else
        this.setState({
          iPlacaM: nextProps.selectedItem.placa,
          iNoEcoM: nextProps.selectedItem.no_economico,
          iTipoUnidadM: nextProps.selectedItem.tipo_unidad,
          iNoSerieM: nextProps.selectedItem.no_serie,
          iModeloM: nextProps.selectedItem.modelo,
          iColorM: nextProps.selectedItem.color,
          iMarcaM: nextProps.selectedItem.marca,
          iSubmarcaM: nextProps.selectedItem.submarca,
          iCamperM: nextProps.selectedItem.camper,
          iStatusFM: nextProps.selectedItem.status_financiero,
          iEmpresaM: nextProps.selectedItem.empresa_seguro,
          iPolizaM: nextProps.selectedItem.poliza_seguro,
          iEstadoRM: nextProps.selectedItem.estado_registro,
          iRefrendoM: nextProps.selectedItem.refrendo,
          //   iVerificacionM: nextProps.selectedItem.verificacion_vehicular,
          iKmM: nextProps.selectedItem.kilometraje,
          iCapacidadGasolinaM: nextProps.selectedItem.capacidad_gasolina,
          iNivelGasolinaM: nextProps.selectedItem.nivel_gasolina,
          iRendimientoTecnicoM: nextProps.selectedItem.rendimiento_tecnico,
          iPolizaVencimientoM:
            nextProps.selectedItem.poliza_vencimiento.split("T")[0],
          iStatusFVencimientoM: null,
        });
  }

  //Añadir estados
  getEstados = async () => {
    let result = await Service.getRawResponse(PROVEEDORES + "/estados");
    if (result.status === 200) {
      let estados = await result.json();
      this.setState({ estados: estados });
    } else {
      this.setState({ estados: [] });
    }
  };

  componentDidMount() {
    this.getEstados();
  }

  render() {
    return (
      <div id={MODAL_MODIFICAR_TRANSPORTE} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Modificar Transporte</p>
            <button
              title="Cerrar"
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form id="formularioAdd" encType="multipart/form-data">
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos particulares
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iPlacaM">
                      Placa
                    </label>
                    <input
                      id="iPlacaM"
                      name="iPlacaM"
                      value={this.state.iPlacaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Placa"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNoEcoM">
                      No. Económico
                    </label>
                    <input
                      id="iNoEcoM"
                      name="iNoEcoM"
                      value={this.state.iNoEcoM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="No. Económico"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iTipoUnidadM">
                      Tipo de unidad
                    </label>
                    <input
                      id="iTipoUnidadM"
                      name="iTipoUnidadM"
                      value={this.state.iTipoUnidadM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Tipo de unidad"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iNoSerieM">
                      No. Serie
                    </label>
                    <input
                      id="iNoSerieM"
                      name="iNoSerieM"
                      value={this.state.iNoSerieM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="No. Serie"
                      disabled={true}
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iModeloM">
                      Modelo
                    </label>
                    <input
                      id="iModeloM"
                      name="iModeloM"
                      value={this.state.iModeloM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Modelo"
                      disabled={true}
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iColorM">
                      Color
                    </label>
                    <input
                      id="iColorM"
                      name="iColorM"
                      value={this.state.iColorM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Color"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iMarcaM">
                      Marca
                    </label>
                    <input
                      id="iMarcaM"
                      name="iMarcaM"
                      value={this.state.iMarcaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Marca"
                      disabled={true}
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iSubmarcaM">
                      Submarca
                    </label>
                    <input
                      id="iSubmarcaM"
                      name="iSubmarcaM"
                      value={this.state.iSubmarcaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Submarca"
                      disabled={true}
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCamperM">
                      Camper
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iCamperM"
                          name="iCamperM"
                          value={this.state.iCamperM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una opción
                          </option>
                          <option value={1}>Si</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iEstadoRM">
                      Estado de registro
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iEstadoRM"
                          name="iEstadoRM"
                          value={this.state.iEstadoRM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un estado
                          </option>
                          {this.state.estados
                            ? Object.values(this.state.estados).map(
                                (estado) => {
                                  return (
                                    <option
                                      key={estado.estado}
                                      value={estado.estado}
                                    >
                                      {estado.estado}
                                    </option>
                                  );
                                }
                              )
                            : null}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-8">
                    <label className="label" htmlFor="iRefrendoM">
                      Ubicación de refrendos (URL)
                    </label>
                    <input
                      id="iRefrendoM"
                      name="iRefrendoM"
                      value={this.state.iRefrendoM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="URL"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iKmM">
                      Kilometraje
                    </label>
                    <input
                      id="iKmM"
                      name="iKmM"
                      value={this.state.iKmM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Kilometraje"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iNivelGasolinaM">
                      Nivel de gasolina
                    </label>
                    <input
                      id="iNivelGasolinaM"
                      name="iNivelGasolinaM"
                      value={this.state.iNivelGasolinaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Nivel"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iCapacidadGasolinaM">
                      Capacidad del tanque (litros)
                    </label>
                    <input
                      id="iCapacidadGasolinaM"
                      name="iCapacidadGasolinaM"
                      value={this.state.iCapacidadGasolinaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="Litros"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iRendimientoTecnicoM">
                      Rendimiento técnico (km/litro)
                    </label>
                    <input
                      id="iRendimientoTecnicoM"
                      name="iRendimientoTecnicoM"
                      value={this.state.iRendimientoTecnicoM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="number"
                      placeholder="km/litro"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Datos de la Póliza
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iEmpresaM">
                      Empresa del seguro
                    </label>
                    <input
                      id="iEmpresaM"
                      name="iEmpresaM"
                      value={this.state.iEmpresaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Empresa de seguro"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iPolizaM">
                      Póliza de seguro
                    </label>
                    <input
                      id="iPolizaM"
                      name="iPolizaM"
                      value={this.state.iPolizaM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="Póliza de seguro"
                    />
                  </div>
                  <div className="column is-4">
                    <label className="label" htmlFor="iPolizaVencimientoM">
                      Fecha de vencimiento
                    </label>

                    <input
                      id="iPolizaVencimientoM"
                      name="iPolizaVencimientoM"
                      value={this.state.iPolizaVencimientoM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column">
                    <label className="subtitle has-text-grey">
                      Estatus Financiero
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label" htmlFor="iStatusFM">
                      Estatus financiero
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iStatusFM"
                          name="iStatusFM"
                          value={this.state.iStatusFM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value={this.state.iStatusFM} defaultValue>
                            {this.state.iStatusFM}
                          </option>
                          <option value="Pagado">Pagado</option>
                          <option value="Credito">A crédito</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.iStatusFM === "Credito" ? (
                    <div className="column is-4">
                      <label className="label" htmlFor="iStatusFVencimientoM">
                        Fecha de vencimiento
                      </label>

                      <input
                        id="iStatusFVencimientoM"
                        name="iStatusFVencimientoM"
                        value={this.state.iStatusFVencimientoM}
                        onChange={this.handleInputChange.bind(this)}
                        className="input is-rounded is-success"
                        type="date"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
