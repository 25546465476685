import $ from 'jquery'
import { viewIcon } from '../../utilidades/TableIcons';
import { MODAL_DETALLES_EMBARQUE } from './ConstantsEmbarques';

export const COLUMNS_EMBARQUES = [

    //{ title: "#", field: "Id_embarque", width: 180 },
    { title: "Folio de embarque", field: "folio_embarque"},
    {
        title: "Fecha de recepcion", field: "fecha_recepcion_embarque", formatter: (cell) => {
            return cell.getRow().getData().fecha_recepcion_embarque.split('T')[0];
        }
    },
    {
        title: "Fecha de factura", field: "fecha_factura_embarque", formatter: (cell) => {
            return cell.getRow().getData().fecha_factura_embarque.split('T')[0];
        }
    },
    {
        formatter: viewIcon, width: 40, cellClick: function (e, cell) {
            $(`#${MODAL_DETALLES_EMBARQUE}`).toggleClass("is-active");
            // // console.log(cell.getData().Id_embarque);
            // this.props.getEmbarque(cell.getData().Id_embarque);



            // console.log(new Date(cell.getData().fecha_recepcion_embarque).toLocaleDateString("es-MX"));
            // $('#iFolioEmbarque').val(cell.getData().Id_embarque);
            // $('#iFechaRecepcion').val(new Date(cell.getData().fecha_recepcion_embarque).toLocaleDateString("es-MX"));
            // $('#iFechaFactura').val(new Date(cell.getData().fecha_factura_embarque).toLocaleDateString("es-MX"));
        }
    }
];