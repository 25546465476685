import React from "react";
import $ from "jquery";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { InventarioPersonal } from "../reportes/InventarioPersonal";
import { alertError, alertWarning } from "../utilidades/Alertas";
import { MODAL_REPORTE_PERSONAL } from "./ConstantsInventario";
import { Service } from "../../services/Service";
import { INVENTARIO, REPORTES } from "../../services/Config";
import { clearSession } from "../utilidades/DataHelpers";

export class ReportePersonal extends React.Component {
  state = {
    iPorteoRP: "",
    iTrabajadorRP: "",
    iFechaIRP: "",
    iFechaFRP: "",
    descargar: false,
    data: "",
    periodo: "",
    porteo: null,
  };

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setState({ descargar: false });
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='datetime-local'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iPorteoRP: "",
      iTrabajadorRP: "",
      iFechaIRP: "",
      iFechaFRP: "",
      descargar: false,
      data: "",
      periodo: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_REPORTE_PERSONAL}`).toggleClass("is-active");
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    let { iPorteoRP, iTrabajadorRP, iFechaIRP, iFechaFRP } = this.state;
    if (iPorteoRP && iTrabajadorRP && iFechaIRP && iFechaFRP) {
      this.setState({ data: "" });
      this.getReporte();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  getReporte = async () => {
    let res = await Service.getRawResponse( REPORTES + 
      `/getReportePersonal?por=${this.state.iPorteoRP}&feI=${this.state.iFechaIRP}&feF=${this.state.iFechaFRP}&tra=${this.state.iTrabajadorRP}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({
        data: r,
        periodo: this.state.iFechaIRP + " / " + this.state.iFechaFRP,
      });
      this.setState({ descargar: true });
    } else
      alertError("No existen datos para generar el reporte", 4000, "center");
  };

  getPorteo = async (por) => {
    let res = null;
    // eslint-disable-next-line eqeqeq
    if (por == 0) {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse( INVENTARIO + `/getPorteo?por=${por}`);
    }
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403){
      clearSession()
    } else this.setState({ porteo: "" });
  };

  componentDidMount() {
    this.getPorteo(sessionStorage.getItem("porteo"));
  }

  render() {
    return (
      <div id={MODAL_REPORTE_PERSONAL} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Reporte de Personal</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            />
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iPorteoRP">
                      Porteo
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iPorteoRP"
                          name="iPorteoRP"
                          value={this.state.iPorteoRP}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un porteo
                          </option>
                          {
                            sessionStorage.getItem("porteo") == 0 ? (
                              <option key="0" value="0">
                                Todos
                              </option>
                            ) : (
                              null
                            )
                          }
                          {this.state.porteo !== null ? (
                            Object.values(this.state.porteo).map((p) => {
                              return (
                                <option
                                  key={p.Id_porteo}
                                  value={p.Id_porteo + "/" + p.nombre}
                                >
                                  {p.nombre}
                                </option>
                              );
                            })
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="column is-6">
                    <label className="label" htmlFor="iTrabajadorRP">
                      Trabajador
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iTrabajadorRP"
                          name="iTrabajadorRP"
                          value={this.state.iTrabajadorRP}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un trabajador
                          </option>
                          <option value="Juan Perez">Juan Perez</option>
                          <option value="Carlos Alonso">Carlos Alonso</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaIRP">
                      Fecha inicial
                    </label>
                    <input
                      id="iFechaIRP"
                      name="iFechaIRP"
                      value={this.state.iFechaIRP}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaFRP">
                      Fecha Final
                    </label>
                    <input
                      id="iFechaFRP"
                      name="iFechaFRP"
                      value={this.state.iFechaFRP}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-3">
                    {this.state.descargar ? (
                      <PDFDownloadLink
                        className="button is-rounded is-danger"
                        document={
                          <InventarioPersonal
                            porteo={this.state.iPorteoRP.split("/")[1]}
                            periodo={this.state.periodo}
                            trabajador={this.state.iTrabajadorRP}
                            data={this.state.data}
                          />
                        }
                        fileName={
                          "InventarioPersonal_" +
                          new Date().toLocaleDateString() +
                          ".pdf"
                        }
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Generando..." : "Descargar"
                        }
                      </PDFDownloadLink>
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Generar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
