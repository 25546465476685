import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";
// eslint-disable-next-line no-unused-vars
import $, { get } from "jquery";
import { Table } from "../utilidades/Table";
import { alertSuccess } from "../utilidades/Alertas";
import { FormaAddEstatusEnvio } from "./FormAddEstatusEnvio";
import { ModalDetallesEnvio } from "./ModalDetallesEnvio";
import { SelectOption } from "../common/SelectOption";
import { InputSearch } from "../common/InputSearch";
import {
  clearSession,
  formatPorteoDataSimple,
} from "../utilidades/DataHelpers";
import { getColumns } from "./ColumnsSeguimientoEntregas";
import { Service } from "../../services/Service";
import { INVENTARIO, SEGUIMIENTO_ENTREGAS } from "../../services/Config";

class SeguimientoEntregas extends React.Component {
  state = {
    data: "",
    dataDetalles: "",
    filtroNumeroRastreo: "",
    table: null,
    selectedItem: "",
    filtroEstatus: "",
    numeroSeguimiento: "",
    inputPorteo: "",
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.data);
      table.setFilter([
        {
          field: "Num_guia",
          type: "like",
          value: this.state.filtroNumeroRastreo,
        },
        {
          field: "estatus",
          type: "like",
          value: this.state.filtroEstatus,
        },
        /*{
                  field: "Id_porteo",
                  type: "like",
                  value: this.state.inputPorteo.split("/")[0],
                },*/
      ]);
    }
  };

  getPorteo = async (por) => {
    let res = null;
    if (por == 0) {
      res = await Service.getRawResponse(INVENTARIO + `/getPorteos?por=${por}`);
    } else {
      res = await Service.getRawResponse(
        INVENTARIO + `/Inventario/getPorteo?por=${por}`
      );
    }
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteo: r });
    } else if (res.status === 403) {
      console.log("ffff");
      clearSession();
    } else {
      this.setState({ porteo: "" });
    }
  };

  handleInputChange = (e) => {
    // this.setState({ filtroEstatus: e.target.value });
    this.setState({ [e.target.name]: e.target.value });
  };

  setItem = (selectedItem) => {
    this.setState({ selectedItem: selectedItem });
    this.getSeguimientoDeEntregasDetalles();
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.getSeguimientoDeEntregas();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputPorteo !== this.state.inputPorteo) {
      this.getSeguimientoDeEntregas();
    }
  }

  //Añadir un estatus al log
  add = async (seguimiento) => {
    let res = await Service.postRawResponse(SEGUIMIENTO_ENTREGAS, seguimiento);
    if (res.status === 200) {
      //const r = await res.text();
      //if (r === 'Proveedor existente')
      //    alertError('Ya existe un proveedor con ese código', 4000, 'center')
      //else {
      alertSuccess("Seguimiento registrado", 4000, "center");
      this.getSeguimientoDeEntregas(); //'',sessionStorage.getItem('porteo'));
      //}
    } else {
    }
  };

  getSeguimientoDeEntregasDetalles = async () => {
    let result = await Service.getRawResponse(
      SEGUIMIENTO_ENTREGAS +
        `/detalles?id=${this.state.selectedItem.Id_paquete || 0}`
    );
    if (result.status === 200) {
      this.setState({ dataDetalles: await result.json() });
    } else this.setState({ dataDetalles: [] });

    // this.setState({ data: [{ "no_rastreo": 1, "estatus": "PENDIENTE", "fecha_recepcion": "10/10/2022" }] });
  };

  getSeguimientoDeEntregas = async () => {
    this.props.setLoading(false);

    let porteo = this.state.inputPorteo;
    if (this.props.userInfo.porteo.length === 1) {
      porteo = this.props.userInfo.porteo[0].Id_porteo;
    } else if (porteo === "" && this.props.userInfo.porteo.length > 1) {
      porteo = 0;
    }
    let result = await Service.getRawResponse(
      SEGUIMIENTO_ENTREGAS + `?por=${porteo}`
    );
    if (result.status === 200) {
      this.setState({ data: await result.json() });
    } else if (result.status === 403) {
      clearSession();
    } else this.setState({ data: [] });

    // this.setState({ data: [{ "no_rastreo": 1, "estatus": "PENDIENTE", "fecha_recepcion": "10/10/2022" }] });
  };

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="column is-3">
            <label className="title is-5">Seguimiento de entregas</label>
          </div>
        </div>

        <div className="row">
          <div className="columns">
            <div className="column is-5">
              <InputSearch
                onChange={this.handleInputChange.bind(this)}
                name="filtroNumeroRastreo"
                iconLeft="barcode"
                placeholder="Rastrear numero"
                key="filtroNumeroRastreo"
              />
            </div>

            <div className="column is-2">
              <SelectOption
                name="filtroEstatus"
                defaultValue="Estatus"
                value={this.state.filtroEstatus}
                onChange={this.handleInputChange.bind(this)}
                data={null}
              />
            </div>

            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputChange.bind(this)}
                data={formatPorteoDataSimple(this.props.userInfo.porteo)}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <Table
            columns={getColumns(this.props.userInfo.rol)}
            setItem={this.setItem}
            getTable={this.getTable}
          />
        </div>

        <div className="row"></div>

        <FormaAddEstatusEnvio
          idPaquete={this.state.selectedItem.Id_paquete}
          add={this.add}
        />
        <ModalDetallesEnvio
          data={this.state.dataDetalles}
          item={this.state.selectedItem}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeguimientoEntregas);
