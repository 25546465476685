// react
import React from 'react';

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table/lib'
// fuente a utilizar
import ProductSans from './style/ProductSansRegular.ttf'
import ProductSansBold from './style/ProductSansBold.ttf'
// estilos
import { styles } from './style/Styles'
//LOGOTIPO
import logo from './../../images/LOGOLOGISTIC.png'

Font.register({
    family: 'ProductSans',
    fonts: [
        { src: ProductSans }, // font-style: normal, font-weight: normal
        { src: ProductSansBold, fontWeight: 'bold' },
    ]
});

export const InventarioPersonal = ({ porteo, trabajador, periodo, data }) => (
    <Document>
        <Page size="A4" style={styles.page} >
            <View style={styles.logoTitle}>
                <Image style={styles.image} src={logo} />
                <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
            </View>
            <View style={styles.info}>
                <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
                <Text style={styles.contacto}>Tel: (341) 410-6511 ó (341) 410-6458</Text>
            </View>
            <Text style={styles.subtitulo}>Reporte de Personal</Text>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Porteo:</Text>
                    <Text style={styles.parametros}>{porteo}</Text>
                </View>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Periodo:</Text>
                    <Text style={styles.parametros}>{periodo}</Text>
                </View>
            </View>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Trabajador:</Text>
                    <Text style={styles.parametros}>{trabajador}</Text>
                </View>
            </View>
            <View>
                <Table data={data} >
                    <TableHeader includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <TableCell style={styles.tablaHeader}  weighting={0.1}>#</TableCell>
                        <TableCell style={styles.tablaHeader} >ARTICULO</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.3}>CANTIDAD</TableCell>
                        <TableCell style={styles.tablaHeader} weighting={0.3}>FECHA</TableCell>
                    </TableHeader>
                    <TableBody includeLeftBorder={false} includeTopBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                        <DataTableCell style={styles.tabla} weighting={0.1} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.codigo_producto} />
                        <DataTableCell style={styles.tabla} weighting={0.3} getContent={(r) => r.cantidad_movimiento.toLocaleString()} />
                        <DataTableCell style={styles.tabla} weighting={0.3} getContent={(r) => r.fecha_movimiento.substring(0, 10)} />
                    </TableBody>
                </Table>
            </View>
        </Page>
    </Document>
);