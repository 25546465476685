import React from 'react'
import $ from 'jquery'
import { alertWarning } from "./Alertas"
import { Service } from '../../services/Service'
import { INVENTARIO } from '../../services/Config'
import { clearSession } from './DataHelpers'

export class SelectPorteo extends React.Component {
    
    state = {
        iPorteo: 0,
        porteos: []
    }

    getPorteos = async (por) => {
        let res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
        if(res.status === 200){
            let r = await res.json();
            this.setState({ porteos: r });
        } else if (res.status === 403){
            clearSession()
        }
        else{
            this.setState({ porteos: '' });
        }
    }

    handleClick(e) {
        $("#selectPorteo").toggleClass("is-active");
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount(){
        this.getPorteos(sessionStorage.getItem('porteo'));
    }

    handleSubmmit = (e) => {
        let { iPorteo } = this.state;
        if ( iPorteo ) {
            sessionStorage.setItem('porteo',iPorteo);
            $(this.props.modal).toggleClass("is-active");
            this.handleClick();
        }
        else
            alertWarning("No se ha seleccionado un porteo", 4000, "center")
    }

    render() {
        return (
            <div id='selectPorteo' className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Elige un porteo</p>
                        <button onClick={this.handleClick} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        <form>
                            <div className='columns'>

                                <div className='column is-6'>
                                <label className="label" htmlFor='iPorteo'>Porteo:</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                    id='iPorteo'
                                                    name='iPorteo'
                                                    value={this.state.iPorteo}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione el porteo de origen</option>
                                                    {
                                                        this.state.porteos !== null ? (
                                                            Object.values(this.state.porteos).map((p) => {
                                                                return (<option key={p.Id_porteo} value={p.Id_porteo}>{p.nombre}</option>)
                                                            })
                                                        ) : (
                                                                <option value=''>No hay datos</option>
                                                            )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                </div>
                            </div>

                        </form>
                    </section>
                    <footer className="modal-card-foot">
                        <button onClick={this.handleSubmmit} className="button is-rounded is-success">Aceptar</button>
                        <button onClick={this.handleClick} className="button is-rounded is-warning" >Cancelar</button>
                    </footer>
                </div>
            </div>
        )
    }
}