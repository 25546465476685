import React from "react";

// components
import { Paragraph } from "./Paragraph";

// image
import EntregaImg from "../../images/ENTREGA.jpg";

export const MisionVision = () => (
  <div className="container hero-body">
    <div className="row">
      <div className="columns">
        <div className="column is-5">
          <figure className="image is-3by2">
            <img
              loading="lazy"
              className="is-img-rounded"
              src={EntregaImg}
              alt="Paquetes seguros"
            />
          </figure>
        </div>
        <div className="column is-7">
          <Paragraph title="MISIÓN">
            Entregar segura y oportunamente nuestras entregas que nos confian
            nuestros clientes, apoyándonos en un equipo humano especializado,
            responsable e íntegro, con la finalidad de que nuestros clientes
            tengan un servicio de calidad.
          </Paragraph>
          <br />
          <Paragraph title="VISIÓN">
            Ser una empresa destacada y reconocida en las areas del transporte.
            Facilitando soluciones logísticas, generando lazos con nuestros
            clientes, mediante los servicios, rapidos, seguros y con los
            estándares mas altos de calidad.
          </Paragraph>
        </div>
      </div>
    </div>
    <br />
  </div>
);
