import $ from 'jquery';
import { assignIcon } from '../../utilidades/TableIcons';
import { MODAL_REGRESO_RUTA, VENTANA_DETALLES_RUTA, VENTANA_ESTATUS_DE_PISO } from './ConstantsEstatusDePiso';
import { ROLES } from '../../utilidades/Constantes'

export const COLUMNS_RUTA = [
    //{ title: "#", field: "Id_embarque" },
    {
        title: "Ruta", field: "ruta", cellClick: function (e, cell) {
            $(`#${VENTANA_DETALLES_RUTA}`).show("")
            $(`#${VENTANA_ESTATUS_DE_PISO}`).hide("")
        }, formatter: function (cell, formatterParams) {
            var value = cell.getValue();
            return "<span style='color: blue; text-decoration: underline blue; color:blue; display: inline-block; line-height: .25em; padding: .75em; width: 135px; text-align: left;'> " + value + " </span>";
        }
    },
    {
        title: "Porteador", field: "name", bottomCalc: function (values, data, calcParams) {
            return "TOTALES";
        }
    },
    { title: "Vehiculo", field: "placa" },
    { title: "Folio de pedidos", field: "folio_pedidos" },
    { title: "Pedidos", field: "pedidos", bottomCalc: "sum" },
    { title: "Cajas", field: "cajas_pedidos", bottomCalc: "sum" },
    { title: "Folio de premios", field: "folio_premios" },
    { title: "Premios", field: "premios", bottomCalc: "sum" },
    { title: "Cajas", field: "cajas_premios", bottomCalc: "sum" },
];

export const COLUMNS_PISO = [
    //{ title: "#", field: "Id_embarque" },
    {
        title: "Ruta", field: "ruta", bottomCalc: function (values, data, calcParams) {
            return "TOTALES";
        }
    },
    { title: "Pedidos", field: "pedidos", bottomCalc: "sum", },
    { title: "Cajas", field: "cajas_pedidos", bottomCalc: "sum" },
    { title: "Premios", field: "premios", bottomCalc: "sum" },
    { title: "Cajas", field: "cajas_premios", bottomCalc: "sum" },
];

export const COLUMNS_PEDIDOS = [
    //{ title: "#", field: "Id_embarque" },
    { title: "Pedido", field: "codigo_paquete" },
    { title: "Veliz", field: "veliz" },
    { title: "Cliente", field: "cliente" },
    { title: "Estatus", field: "status" },
    { title: "GPS", field: "gps", formatter: "tickCross" },
];

export const COLUMNS_PREMIOS = [
    //{ title: "#", field: "Id_embarque" },
    { title: "Premio", field: "codigo_paquete" },
    { title: "Veliz", field: "veliz" },
    { title: "Cliente", field: "cliente" },
    { title: "Estatus", field: "status" },
    { title: "GPS", field: "gps", formatter: "tickCross" },
];

export const COLUMNS_PISO_OTROS_CLIENTES = [
    { title: "NO. GUIA", field: "no_guia" },
    { title: "CLIENTE", field: "cliente" },
    { title: "FECHA LÍMITE DE ENTREGA", field: "fecha_limite" },
    { title: "CIUDAD DESTINO", field: "ciudad_destino" }
]

let COLUMNS_TRANSPORTES_EN_RUTA = [
    { title: "FOLIO DE SALIDA", field: "Id_salida_reparto" },
    { title: "TRANSPORTE", field: "placa" },
    { title: "RUTAS", field: "rutas" },
    { title: "CHOFER", field: "chofer" },
    // { title: "QUIEN ASIGNO", field: "quien_asigno" },
    { title: "Llegó?", field: "llego" },
]

let regresoRutaColumn = {
    formatter: assignIcon, width: 40, cellClick: function (e, cell) {
        $(`#${MODAL_REGRESO_RUTA}`).toggleClass("is-active");
    }
}

const regresoRutaOption = [ROLES.ADMIN]

export const getColumnsTransportesEnRuta = (userRole) => {
    if (regresoRutaOption.includes(userRole)
        && !COLUMNS_TRANSPORTES_EN_RUTA.includes(regresoRutaColumn)) {
        COLUMNS_TRANSPORTES_EN_RUTA.push(regresoRutaColumn)
    }

    return COLUMNS_TRANSPORTES_EN_RUTA
} 