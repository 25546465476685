import { toast } from "bulma-toast";

// mensaje --> lo que quieres que aparezca en la alerta 
// posicion --> top-left, top-center, top-right, center, bottom-left, bottom-center, and bottom-right
// duracion (milisegundos) --> 3000 ó 4000 esta bien, pero puede ser más o menos
// light --> define si la alerta tiene color claro o obscuro

// Alerta de exito
export const alertSuccess = function (mensaje, duracion = 4000, posicion = 'top-center', light = true) {
    toast({
        message: '<span><i class="fa fa-check-circle"></i> </span> ' + mensaje,
        type: "is-success " + (light ? "is-light" : ""),
        position: posicion,
        duration: duracion,
        dismissible: true,
        pauseOnHover: true,
    });
}

// Alerta de error
export const alertError = function (mensaje, duracion = 4000, posicion = 'top-center', light = true) {
    toast({
        message: '<span><i class="fa fa-times-circle"></i> </span> ' + mensaje,
        type: "is-danger " + (light ? "is-light" : ""),
        position: posicion,
        duration: duracion,
        dismissible: true,
        pauseOnHover: true
    });
}

// Alerta de información
export const alertInfo = function (mensaje, duracion = 4000, posicion = 'top-center', light = true) {
    toast({
        message: '<span><i class="fa fa-info-circle"></i> </span> ' + mensaje,
        type: "is-link " + (light ? "is-light" : ""),
        position: posicion,
        duration: duracion,
        dismissible: true,
        pauseOnHover: true
    });
}

// Alerta para una advertencia
export const alertWarning = function (mensaje, duracion = 4000, posicion = 'top-center', light = true) {
    toast({
        message: '<span><i class="fa fa-exclamation-circle"></i> </span> ' + mensaje,
        type: "is-warning " + (light ? "is-light" : ""),
        position: posicion,
        duration: duracion,
        dismissible: true,
        pauseOnHover: true
    });
}