import React from 'react'

import { TitleAlternativoDos } from './Title'
import { Paragraph } from './Paragraph'

export const Valores = () => (
    <div className="container hero-body">
        <div className='row has-text-centered'>
            <TitleAlternativoDos>VALORES</TitleAlternativoDos>
        </div>
        <div className='row'>
            <div className='columns'>
                <div className='column is-5'>
                    <Paragraph title='HONESTIDAD'>
                        Es decir la verdad, ser transparente y justo en tu trabajo, con tu familia y
                        contigo mismo.
                    </Paragraph>
                </div>
                <div className='column is-6 is-offset-1'>
                    <Paragraph title='LEALTAD'>
                        En las buenas y en las malas. No defraudar la confianza depositada en tu persona.
                    </Paragraph>
                </div>
            </div>
        </div>
        <br />
        <div className='row'>
            <div className='columns'>
                <div className='column is-5'>
                    <Paragraph title='CONFIABILIDAD'>
                        Nuestro segundo nombre es confiable, por ello nos puedes confiar hasta tus hijos.
                    </Paragraph>
                </div>
                <div className='column is-6 is-offset-1'>
                    <Paragraph title='RESPETO'>
                        La palabra respeto es tan maravillosa para nosotros, significa tantas cosas,
                        abarca la puntualidad, la empatía con tus clientes y los nuestros,
                        el respeto fluye en todo nuestro equipo.
                    </Paragraph>
                </div>
            </div>
        </div>
        <br />
    </div>
)

