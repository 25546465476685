import { Sesion } from '../actions/SesionActions'

const sesionState = {
    userInfo: {},
    isLoading: false
}

export const sesionReducer = (state = sesionState, action) => {
    switch (action.type) {
        case Sesion.SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case Sesion.SET_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo
            }
        case Sesion.LOG_OUT:
            return {
                ...state,
                userInfo: {}
            }
        default:
            return state
    }
}