import React from "react";

import { Sesion } from "../../redux/actions/SesionActions";
import { connect } from "react-redux";

import { Table } from "../utilidades/Table";
import { InputSearch } from "../common/InputSearch";
import { SelectOption } from "../common/SelectOption";
import { ConsumoItem } from "./ConsumoItem";
import { getPorteo } from "../../services/GeneralDataService";
import { COLUMNS_CONSUMO_GASOLINA } from "./ColumnsConsumoGasolina";
import {
  clearSession,
  formatPorteoDataSimple,
  getPorteoOrDefault,
} from "../utilidades/DataHelpers";
import { ModalDetallesConsumo } from "./ModalDetallesConsumo";
import { alertSuccess, alertError } from "../utilidades/Alertas";
import { Service } from "../../services/Service";
import { CONSUMO_GASOLINA } from "../../services/Config";

class ConsumoGasolina extends React.Component {
  state = {
    data: "",
    dataDetalles: "",
    table: null,
    selectedItem: "",
    filtroPlaca: "",
    porteo: "",
    inputCostoPorLitro: "",
    costoLitro: 0,
    consumoDinero: 0,
    consumoLitros: 0,
    kmRecorridos: 0,
    showEditInput: false,
    inputRuta: "",
    inputPorteo: "",
    rutas: [],
    months: [
      { mes: 1, nombre: "Enero" },
      { mes: 2, nombre: "Febrero" },
      { mes: 3, nombre: "Marzo" },
      { mes: 4, nombre: "Abril" },
      { mes: 5, nombre: "Mayo" },
      { mes: 6, nombre: "Junio" },
      { mes: 7, nombre: "Julio" },
      { mes: 8, nombre: "Agosto" },
      { mes: 9, nombre: "Septiembre" },
      { mes: 10, nombre: "Octubre" },
      { mes: 11, nombre: "Noviembre" },
      { mes: 12, nombre: "Diciembre" },
    ],
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.data);
      table.setFilter([
        {
          field: "placa",
          type: "like",
          value: this.state.filtroPlaca,
        } ,
      ]);
    }
  };

  setPorteo = async (por) => {
    this.setState({ porteo: getPorteo(por) });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.inputPorteo !== this.state.inputPorteo) {
      this.getConsumos();
      // this.getConsumoDinero();
      // this.getConsumoLitros();
      // this.getCostoPorLitro();
      // this.getRutas();
      this.getData();
    }
    if (prevState.inputMes !== this.state.inputMes) {
      this.getConsumos();
      // this.getConsumoDinero();
      // this.getConsumoLitros();
      // this.getCostoPorLitro();
      // this.getRutas();
      this.getData();
    }
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setItem = (selectedItem) => {
    console.log(selectedItem);
    this.setState({ selectedItem: selectedItem });
    this.getDetallesData(selectedItem.Id_transporte, selectedItem.Id_ruta);
  };

  actualizarCostoPorLitro = async (e) => {
    let formData = new FormData();
    formData.append("porteo", this.state.inputPorteo || 1);
    formData.append("costo", this.state.inputCostoPorLitro);
    let res = await Service.postSimpleRawResponse(CONSUMO_GASOLINA , formData);
    if (res.status === 200) {
      alertSuccess("Precio actualizado", 4000, "center");
      await this.getCostoPorLitro();
      this.setState({ showEditInput: false });
      //}
    } else if (res.status === 500) {
      alertError("Ocurrio un error", 4000, "center");
    }
  };

  setShowEditInput = () => {
    this.setState({ showEditInput: !this.state.showEditInput });
  };

  //Obtener Costo Por Litro
  getCostoPorLitro = async () => {
    let porteo = getPorteoOrDefault(
      this.state.inputPorteo,
      this.props.userInfo.porteo
    );
    let result = await Service.getRawResponse(CONSUMO_GASOLINA + `/costoLitro?por=${porteo}`);
    if (result.status === 200) {
      let data = await result.json();
      this.setState({ costoLitro: data[0].costo || 0 });
    } else this.setState({ costoLitro: 0 });
  };

  //Obtener rutas
  getRutas = async () => {
    let porteo = getPorteoOrDefault(
      this.state.inputPorteo,
      this.props.userInfo.porteo
    );
    let result = await Service.getRawResponse(CONSUMO_GASOLINA + `/getRutas?por=${porteo}`);
    if (result.status === 200) {
      let data = await result.json();
      this.setState({ rutas: data });
    } else this.setState({ rutas: [] });
  };

  //Obtener consumos
  getConsumos = async () => {
    let porteo = getPorteoOrDefault(
      this.state.inputPorteo,
      this.props.userInfo.porteo
    );
    let result = await Service.getRawResponse( CONSUMO_GASOLINA + 
      `/consumos?por=${porteo}&mes=${this.state.inputMes || 0}`
    );
    if (result.status === 200) {
      let data = await result.json();
      this.setState({
        consumoDinero: data[0].consumo_dinero || 0,
        consumoLitros: data[0].consumo_litros || 0,
        kmRecorridos: data[0].kilometros_recorridos || 0,
      });
    } else if (result.status === 403 ) {
    clearSession()
    }else
      this.setState({ consumoDinero: 0, consumoLitros: 0, kmRecorridos: 0 });
  };

  //Obtener info
  getData = async () => {
    let porteo = getPorteoOrDefault(
      this.state.inputPorteo,
      this.props.userInfo.porteo
    );
    let result = await Service.getRawResponse( CONSUMO_GASOLINA + 
      `?por=${porteo}&mes=${this.state.inputMes || 0}`
    );
    if (result.status === 200) {
      this.setState({ data: await result.json() });
    } else this.setState({ data: [] });
  };

  getDetallesData = async (id, ruta) => {
    let result = await Service.getRawResponse(CONSUMO_GASOLINA + `/detalles?id=${id}`);
    if (result.status === 200) {
      this.setState({ dataDetalles: await result.json() });
    } else this.setState({ dataDetalles: [] });
  };

  componentDidMount() {
    this.props.setLoading(true);
    this.getData();
    this.props.setLoading(false);
    this.getConsumos();
    // this.getConsumoDinero()
    // this.getConsumoLitros()
    // this.getKmRecorridos()
    // this.getRutas()
  }

  render() {
    return (
      <div className="container is-fluid box">
        <div className="row">
          <div className="columns">
            <div className="column is-6">
              <label className="title is-5">Consumo de gasolina</label>
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputPorteo"
                defaultValue="Porteo"
                value={this.state.inputPorteo}
                onChange={this.handleInputChange.bind(this)}
                data={formatPorteoDataSimple(this.props.userInfo.porteo)}
                mapValueFrom="Id_porteo"
                mapTextFrom="nombre"
              />
            </div>
            <div className="column is-2">
              <SelectOption
                name="inputMes"
                defaultValue="Mes"
                value={this.state.inputMes}
                onChange={this.handleInputChange.bind(this)}
                data={this.state.months}
                mapValueFrom="mes"
                mapTextFrom="nombre"
              />
            </div>
          </div>
        </div>

        <br />
        <div className="row">
          <div className="columns">
            <div className="column is-4">
              <ConsumoItem
                title="Consumo al momento"
                data={this.state.consumoDinero}
                dataType="MXN"
                dataExtra={`${this.state.consumoLitros} LITROS`}
              />
            </div>
            <div className="column is-4">
              <ConsumoItem
                title="Kilometros al momento"
                data={this.state.kmRecorridos}
                dataType="KM"
              />
            </div>
          </div>
        </div>
        <br />

        <div className="row">
          <div className="column is-4">
            <InputSearch
              onChange={this.handleInputChange.bind(this)}
              name="filtroPlaca"
              placeholder="Placas de la unidad..."
              key="buscarPorPlaca"
            />
          </div>
        </div>

        <div className="row">
          <Table
            columns={COLUMNS_CONSUMO_GASOLINA}
            setItem={this.setItem}
            getTable={this.getTable}
          />
        </div>

        <br />
        <ModalDetallesConsumo
          transporte={this.state.selectedItem.placa}
          data={this.state.dataDetalles}
          id={this.state.selectedItem.Id_transporte}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ sesionReducer }) => ({
  userInfo: sesionReducer.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({ isLoading: isLoading, type: Sesion.SET_LOADING }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumoGasolina);
