export const CATEGORIAS = [
    { value: "Refacciones", text: "Refacciones" },
    { value: "Inmobiliario/Activos", text: "Inmobiliario/Activos" },
    { value: "Herramientas", text: "Herramientas" },
    { value: "Limpieza", text: "Limpieza" },
    { value: "Papelería", text: "Papelería" }
]

// MODALES ACTIVADOS CON LA TABLA
export const MODAL_DETALLES_PRODUCTO = "modaldetallesproducto"
export const MODAL_MODIFICAR_ARTICULO = "modificararticulo"
export const MODAL_BORRAR_ARTICULO = "deletearticulo"

// FORMULARIOS
export const MODAL_TRANSFERENCIA = "formtransferencia"
export const MODAL_MOVIMIENTO = "formmovimiento"
export const MODAL_ADD_ARTICULO = "formaddarticulo"

// REPORTES
export const MODAL_REPORTES_MENU = "reportesmenu"
export const MODAL_REPORTE_PRODUCTOS = "reporteproductos"
export const MODAL_REPORTE_PERSONAL = "reportepersonal"
export const MODAL_REPORTE_FALTANTES = "reportefaltantes"
export const MODAL_REPORTE_ES = "reportees"