import { alertWarning } from "./Alertas"

export function formatPorteoData(data) {
  return Object.values(data).map(item => {
    return { Id_porteo: item.Id_porteo + '/' + item.nombre, nombre: item.nombre }
  })
}

export function formatPorteoDataSimple(data) {
  return Object.values(data).map(item => {
    return { Id_porteo: item.Id_porteo, nombre: item.nombre }
  })
}

export function formatRutasData(data) {
  return Object.values(data).map(item => {
    return { Id_ruta: item.Id_ruta, nombre: item.nombre_ruta }
  })
}

export function hasAccess(userRole, validRoles) {
  return validRoles.includes(userRole)
}

export function getPorteoOrDefault(inputPorteo, porteoInfo) {
  let porteo = inputPorteo
  if (porteoInfo.length === 1) {
      porteo = porteoInfo[0].Id_porteo
  } else if (porteo === "" && porteoInfo.length > 1){
      porteo = 0
  }
  return porteo
}

export function clearSession() {
  sessionStorage.clear()
  window.location.href = '/login';
}