import React from "react";
import { alertWarning } from "../../utilidades/Alertas";
import $ from "jquery";
import { Icon } from "../../common/simple/Icon";
import { Table } from "../../utilidades/Table";
import { read, utils } from "xlsx";
import { SelectOption } from "../../common/SelectOption";

const COLUMNS_EMBARQUE_MASIVO = [
  { title: "Numero de guia", field: "num_guia" },
  { title: "Tipo", field: "tipo_paquete" },
  { title: "Nombre Cliente", field: "nombre_cliente" },
  { title: "Domicilio", field: "domicilio_entrega" },
  { title: "Cajas", field: "cajas" },
  { title: "Ruta", field: "ruta" },
  { title: "Destino", field: "destino" },
  { title: "Folio", field: "folio" },
  { title: "Factura", field: "factura" },
  {
    title: "GPS",
    field: "gps",
    formatter: (cell, formatterParams) => {
      var value = cell.getValue();
      // eslint-disable-next-line eqeqeq
      return value == 1
        ? `<i class="fa fa-check has-text-success"></i>`
        : `<span class="has-text-danger">X</span>`;
    },
  },
  { title: "Ciudad", field: "ciudad" },
  { title: "Peso (kg)", field: "peso" },
];

export class AgregarEmbarqueMasivo extends React.Component {
  state = {
    iFechaRecepcion: "",
    iFolioEmbarque: "",
    iFechaFactura: "",
    nombreDelArchivo: "Sin archivo",
    embarqueData: [],
    mostrarCantidad: false,
    inputPorteo: null
  };

  handleClick = (e) => {
    $(`#${this.props.modalName}`).toggleClass("is-active");
    this.limpiarC();
  };

  //Limpiar inputs
  limpiarC = () => {
    this.setState({
      embarqueData: [],
      iFechaRecepcion: "",
      iFolioEmbarque: "",
      iFechaFactura: "",
      nombreDelArchivo: "Sin archivo",
      mostrarCantidad: false,
    });
    document.getElementById("embarquepaquetes").value = null;
    console.log("Limpiado");
  };

  handleSubmmit = async (e) => {
    const {
      iFechaRecepcion,
      iFolioEmbarque,
      iFechaFactura,
      nombreDelArchivo,
      embarqueData,
    } = this.state;
    if (
      iFechaRecepcion &&
      iFolioEmbarque &&
      iFechaFactura &&
      nombreDelArchivo &&
      embarqueData
    ) {
      this.props.add({
        iFechaRecepcion,
        iFolioEmbarque,
        iFechaFactura,
        nombreDelArchivo,
        embarqueData,
        Id_porteo: this.state.inputPorteo,
      });

      this.handleClick();
    } else alertWarning("Faltan Campos", 4000, "center");
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  async componentDidMount() {}

  inputFile = async (e) => {
    let fileName = e.target.files[0].name;
    this.setState({ nombreDelArchivo: fileName, mostrarCantidad: true });

    const f = await e.target.files[0].arrayBuffer();
    const wb = read(f);
    const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
      header: 1,
    });
    // let columns = data[0].map((r) => ({ key: r, name: r }));
    // let rows = data.slice(1).map((r) => r.reduce((acc, x, i) => {
    //     acc[data[0][i]] = x;
    //     return acc;
    //   }, {}));
    let rows = data.slice(1).map((r) => {
      return {
        num_guia: r[0],
        tipo_paquete: r[1]
          ? r[1] === "pedido" || r[1] === "premio"
            ? r[1]
            : "pedido"
          : "pedido",
        nombre_cliente: r[2],
        domicilio_entrega: r[3],
        cajas: r[4],
        ruta: r[5],
        destino: r[6],
        folio: r[7],
        factura: r[8],
        gps: r[9],
        ciudad: r[10],
        peso: r[11],
      };
    });

    this.setState({ embarqueData: rows });

    // const reader = new FileReader();

    // reader.onload = (e) => {
    //     const delim = ','
    //     const str = e.target.result;

    //     const headers = str.slice(0, str.indexOf('\n')).split(delim);
    //     const rows = str.slice(str.indexOf('\n') + 1).split('\n');

    //     const jsonData = rows.map(row => {
    //         const values = row.split(delim);
    //         const eachObject = headers.reduce((obj, header, i) => {
    //             obj[header] = values[i];
    //             return obj;
    //         }, {})

    //         if (eachObject['Numero de guia'] !== undefined
    //             && eachObject['Numero de guia'] !== '') {
    //             return eachObject;
    //         }
    //     }).filter(paquete => paquete !== undefined)

    //     this.setState({ embarqueData: jsonData })
    //     console.log(jsonData)

    // }

    // reader.readAsText(file);

    //console.log(reader.result)
  };

  getTable = (table) => {
    if (table !== null) {
      table.setData(this.state.embarqueData);
    }
  };

  render() {
    return (
      <div id={this.props.modalName} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card modal-lg">
          <header className="modal-card-head">
            <p className="modal-card-title">
              Agregar embarque: {this.props.cliente}
            </p>
            <button
              title="Cerrar"
              onClick={this.handleClick}
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              <div className="row">
                <div className="columns">
                  <div className="column is-3">
                    <label className="label" >
                        Porteo
                    </label>
                      <SelectOption
                        name="inputPorteo"
                        defaultValue="Porteo"
                        value={this.state.inputPorteo}
                        onChange={this.handleInputChange}
                        data={this.props.porteos}
                        mapValueFrom="Id_porteo"
                        mapTextFrom="nombre"
                        classColor="is-success"
                      />
                  </div>
                  <div className="column is-3">
                    <label className="label" htmlFor="iFolioEmbarque">
                      Folio de embarque
                    </label>
                    <input
                      name="iFolioEmbarque"
                      value={this.state.iFolioEmbarque}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="text"
                      placeholder="125489789869"
                    />
                  </div>
                  <div className="column is-3">
                    <label className="label" htmlFor="iFechaRecepcion">
                      Fecha recepción
                    </label>
                    <input
                      name="iFechaRecepcion"
                      value={this.state.iFechaRecepcion}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                  <div className="column is-3">
                    <label className="label" htmlFor="iFechaFactura">
                      Fecha factura
                    </label>
                    <input
                      name="iFechaFactura"
                      value={this.state.iFechaFactura}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="columns">
                  <div className="column">
                    <div className="file has-name is-boxed is-centered">
                      <label className="file-label">
                        <input
                          id="embarquepaquetes"
                          className="file-input"
                          type="file"
                          name="embarquepaquetes"
                          accept=".xlsx"
                          onChange={this.inputFile}
                        />
                        <span className="file-cta">
                          <span className="file-icon">
                            <Icon icon="upload" />
                          </span>
                          <span className="file-label">
                            Agregar paquetes por CSV
                          </span>
                        </span>
                      </label>
                    </div>
                    <br />
                    <span
                      className="file-name has-text-centered"
                      style={{ maxWidth: "inherit" }}
                    >
                      {this.state.nombreDelArchivo}
                    </span>
                  </div>
                </div>
              </div>

              {this.state.mostrarCantidad ? (
                <div className="row">
                  <div className="columns">
                    <div className="column">
                      <span className="has-text-bold has-text-centered">
                        Total de paquetes: {this.state.embarqueData.length}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </form>

            <div className="row">
              <div className="columns">
                <div className="column">
                  <Table
                    columns={COLUMNS_EMBARQUE_MASIVO}
                    getTable={this.getTable}
                  />
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
