import React from 'react'
import { alertWarning } from '../utilidades/Alertas'
import $ from 'jquery'

import { fecha } from '../utilidades/Fecha'
import {MODAL_TRANSFERENCIA} from "./ConstantsInventario";
import { Service } from '../../services/Service';
import { INVENTARIO } from '../../services/Config';

export class FormTransferencia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iArticuloT: '',
            iCantidadT: '',
            iOrigenT: '',
            iDestinoT: '',
            iObservacionesT: '',
            iFechaHoraT: '',
            porteoOrigen: null,
            porteosDestino: '',
            flag: true,
            productos: null
        }
    }

    // Get each character from the input Nombre
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name === "iOrigenT") {
            this.porteoDestino(e.target.value);
            this.getProductos(e.target.value)
        }
    }

    //Limpiar inputs
    limpiarC = () => {

        let elements = document.querySelectorAll("input[type='datetime-local'],input[type='number'],select")
        for (let i = 0; i < elements.length; i++) {
            elements[i].value = ''
        }
        this.setState({
            iArticuloT: '',
            iCantidadT: '',
            iOrigenT: '',
            iDestinoT: '',
            iObservacionesT: '',
            iFechaHoraT: ''
        })
    }

    handleClick = (e) => {
        $(`#${MODAL_TRANSFERENCIA}`).toggleClass("is-active");
        this.setState({ flag: true });
        this.limpiarC();
    }

    // backend 
    handleSubmmit = (e) => {
        let {
            iArticuloT,
            iCantidadT,
            iOrigenT,
            iDestinoT,
            iObservacionesT,
            iFechaHoraT
        } = this.state;
        if (
            iArticuloT &&
            iCantidadT &&
            iOrigenT &&
            iDestinoT &&
            iObservacionesT &&
            iFechaHoraT
        ) {
            this.props.transferencia({
                iArticulo: iArticuloT,
                iCantidad: iCantidadT,
                iOrigen: iOrigenT,
                iDestino: iDestinoT,
                iObservaciones: iObservacionesT,
                iFechaHora: iFechaHoraT
            });
            this.handleClick();
        }
        else
            alertWarning("Faltan Campos", 4000, "center")
    }

    porteoOrigen = async () => {
        let res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${sessionStorage.getItem('porteo')}`);
        if(res.status === 200){
            let r = await res.json();
            this.setState({ porteoOrigen: r });
        }
        else
            this.setState({ porteoOrigen: [] });
    }

    porteoDestino = async (por) => {
        let res = await Service.getRawResponse( INVENTARIO + `/getPorteos?por=${por}`);
        if(res.status === 200){
            let r = await res.json();
            this.setState({ porteosDestino: r });
        }
        else
            this.setState({ porteoDestino: [] });
    }

    getProductos = async (por) => {
        let res = await Service.getRawResponse( INVENTARIO + `/getProductos?por=${por}`);
        if(res.status === 200){
            let r = await res.json();
            this.setState({ productos: r });
        }
        else
            this.setState({ productos: [] });
    }

    componentDidMount() {
        this.porteoOrigen();
    }

    render() {
        if ($(`#${MODAL_TRANSFERENCIA}`).is(":visible") && this.state.flag) {
            this.setState({ iFechaHoraT: fecha(), flag: false });
        }
        return (
            <div id={MODAL_TRANSFERENCIA} className="modal">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Nueva Transferencia</p>
                        <button onClick={this.handleClick} title='Cerrar' className="delete" aria-label="close"/>
                    </header>
                    <section className="modal-card-body">
                        <form>
                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-6'>
                                        <label className="label" htmlFor='iOrigenT'>Porteo Origen</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                    id='iOrigenT'
                                                    name='iOrigenT'
                                                    value={this.state.iOrigenT}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione el porteo de origen</option>
                                                    {
                                                        this.state.porteoOrigen !== null ? (
                                                            Object.values(this.state.porteoOrigen).map((p) => {
                                                                return (<option key={'origen-'+p.Id_porteo} value={p.Id_porteo}>{p.nombre}</option>)
                                                            })
                                                        ) : (
                                                                <option value=''>No hay datos</option>
                                                            )
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='column is-6'>
                                        <label className="label" htmlFor='iDestinoT'>Porteo Destino</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                    id='iDestinoT'
                                                    name='iDestinoT'
                                                    value={this.state.iDestinoT}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione el porteo destino</option>
                                                    {
                                                        Object.values(this.state.porteosDestino).map((porteo) => {
                                                            return (<option key={'destino-'+porteo.Id_porteo} value={porteo.Id_porteo}>{porteo.nombre}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />

                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-6'>
                                        <label className="label" htmlFor='iArticuloT'>Articulo</label>
                                        <div className="control">
                                            <div className="select is-rounded is-success is-fullwidth">
                                                <select
                                                    id='iArticuloT'
                                                    name='iArticuloT'
                                                    value={this.state.iArticuloT}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                    <option value='' defaultValue>Seleccione un articulo</option>
                                                    {
                                                        this.state.productos !== null ? (
                                                            Object.values(this.state.productos).map((producto) => {
                                                                return (<option key={producto.codigo_producto} value={producto.codigo_producto}>{producto.nombre_producto}</option>)
                                                            })
                                                        ) : (
                                                                <option value=''>No hay datos</option>
                                                            )
                                                    }

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='column is-6'>
                                        <label className="label" htmlFor='iCantidadT'>Cantidad</label>
                                        <input
                                            id='iCantidadT'
                                            name='iCantidadT'
                                            value={this.state.iCantidadT}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            min='1'
                                            steps='1'
                                            pattern="\d*"
                                            type='number'
                                            placeholder='Cantidad' />
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className='row'>
                                <label className='label' htmlFor='iObservacionesT'>Observaciones</label>
                                <textarea
                                    id='iObservacionesT'
                                    name='iObservacionesT'
                                    value={this.state.iObservacionesT}
                                    onChange={this.handleInputChange.bind(this)}
                                    className="textarea is-success"
                                    rows='3'
                                    placeholder="Observaciones..."></textarea>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='columns'>
                                    <div className='column is-6'>
                                        <label className='label' htmlFor='iFechaHoraT'>Fecha y Hora</label>
                                        <input
                                            id='iFechaHoraT'
                                            name='iFechaHoraT'
                                            value={this.state.iFechaHoraT}
                                            onChange={this.handleInputChange.bind(this)}
                                            className="input is-rounded is-success"
                                            type="datetime-local" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                    <footer className="modal-card-foot">
                        <button onClick={this.handleSubmmit} className="button is-rounded is-success">Guardar</button>
                        <button onClick={this.handleClick} className="button is-rounded is-warning" >Cancelar</button>
                    </footer>
                </div>
            </div>
        )
    }
}