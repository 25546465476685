export const MODAL_REPORTE_POLIZA = 'reportepoliza'
export const MODAL_REPORTE_VERIFICACIONES = 'reporteverificaciones'
export const MODAL_REPORTE_FV = 'reportefv'

export const MODAL_TRANSFERENCIA = 'formtransferenciat'
export const MODAL_ADD_TRANSPORTE = 'formaddtransporte'

export const MODAL_MODIFICAR_TRANSPORTE = 'formmodtransporte'
export const MODAL_BORRAR_TRANSPORTE = 'deletetransporte'

export const MODAL_DETALLES_TRANSPORTE = 'modaldetallestransporte'
export const MODAL_SALIDA_TRANSPORTE = 'modalsalidatransporte'
export const MODAL_REGRESO_TRANSPORTE = 'modalregresotransporte'