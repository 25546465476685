import React from "react";
import { alertWarning } from "../utilidades/Alertas";
import $ from "jquery";

import { fecha } from "../utilidades/Fecha";
import { MODAL_MOVIMIENTO } from "./ConstantsInventario";
import { Service } from "../../services/Service";
import { INVENTARIO } from "../../services/Config";

export class FormMovimiento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iPorteoM: "",
      iFechaHoraM: "",
      iProductoM: "",
      iCantidadM: "",
      iDetallesM: "",
      iAccionM: "",
      iDestinatarioM: "",
      flag: true,
      productos: "",
      porteos: "",
      destinatarios: "",
    };
  }

  // Get each character from the input Nombre
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    let porteo = "";
    if (sessionStorage.getItem("porteo") === "0") {
      porteo = this.state.iPorteoM;
    } else {
      porteo = sessionStorage.getItem("porteo");
    }
    if (
      e.target.value === "SalidaRefacciones" ||
      e.target.value === "EntradaRefacciones"
    ) {
      this.getRefacciones(porteo);
      this.getTransportes(porteo);
    }
  };

  //Limpiar inputs
  limpiarC = () => {
    let elements = document.querySelectorAll(
      "input[type='datetime-local'],input[type='number'],select"
    );
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
    this.setState({
      iFechaHoraM: "",
      iPorteoM: "",
      iProductoM: "",
      iCantidadM: "",
      iDetallesM: "",
      iAccionM: "",
      iDestinatarioM: "",
    });
  };

  handleClick = (e) => {
    $(`#${MODAL_MOVIMIENTO}`).toggleClass("is-active");
    this.setState({ flag: true });
    this.limpiarC();
  };

  // backend
  handleSubmmit = (e) => {
    let {
      iPorteoM,
      iFechaHoraM,
      iProductoM,
      iCantidadM,
      iDetallesM,
      iAccionM,
      iDestinatarioM,
    } = this.state;
    let porteo = "";
    if (sessionStorage.getItem("porteo") === "0") {
      porteo = iPorteoM;
      if (iFechaHoraM && iProductoM && iCantidadM && iAccionM && iPorteoM) {
        this.props.movimiento({
          iPorteo: iPorteoM,
          iFechaHora: iFechaHoraM,
          iProducto: iProductoM,
          iCantidad: iCantidadM,
          iDetalles: iDetallesM,
          iAccion: iAccionM,
          porteo: iPorteoM,
          iDestinatario: iDestinatarioM,
        });
        this.handleClick();
      } else alertWarning("Faltan Campos", 4000, "center");
    } else {
      if (iFechaHoraM && iProductoM && iCantidadM && iAccionM) {
        this.props.movimiento({
          iPorteo: iPorteoM,
          iFechaHora: iFechaHoraM,
          iProducto: iProductoM,
          iCantidad: iCantidadM,
          iDetalles: iDetallesM,
          iAccion: iAccionM,
          porteo: sessionStorage.getItem("porteo"),
          iDestinatario: iDestinatarioM,
        });
        this.handleClick();
      } else alertWarning("Faltan Campos", 4000, "center");
    }
  };

  getRefacciones = async (porteo) => {
    let res = await Service.getRawResponse(
      INVENTARIO + `/getRefacciones?por=${porteo}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ productos: r });
    } else this.setState({ productos: [] });
  };
  getTransportes = async (porteo) => {
    let res = await Service.getRawResponse(
      INVENTARIO + `/getTransportes?por=${porteo}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ destinatarios: r });
    } else this.setState({ destinatarios: [] });
  };
  getProductos = async () => {
    let res = await Service.getRawResponse(
      INVENTARIO + `/getProductosLP?por=${sessionStorage.getItem("porteo")}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ productos: r });
    } else this.setState({ productos: [] });
  };

  getPorteos = async () => {
    let res = await Service.getRawResponse(
      INVENTARIO + `/getPorteos?por=${sessionStorage.getItem("porteo")}`
    );
    if (res.status === 200) {
      let r = await res.json();
      this.setState({ porteos: r });
    } else this.setState({ porteos: [] });
  };

  componentDidMount() {
    this.getPorteos();
  }

  render() {
    if ($(`#${MODAL_MOVIMIENTO}`).is(":visible") && this.state.flag) {
      this.setState({ iFechaHoraM: fecha(), flag: false });
    }
    return (
      <div id={MODAL_MOVIMIENTO} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Nuevo Movimiento</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <form>
              {sessionStorage.getItem("porteo") === "0" ? (
                <div className="row">
                  <div className="columns">
                    <div className="column is-6">
                      <label className="label" htmlFor="porteo">
                        Porteo
                      </label>
                      <div className="control">
                        <div className="select is-rounded is-success is-fullwidth">
                          <select
                            id="iPorteoM"
                            name="iPorteoM"
                            value={this.state.iPorteoM}
                            onChange={this.handleInputChange.bind(this)}
                          >
                            <option value="" defaultValue>
                              Seleccione un porteo
                            </option>
                            {this.state.porteos != null ? (
                              Object.values(this.state.porteos).map(
                                (porteo) => {
                                  return (
                                    <option
                                      key={porteo.Id_porteo}
                                      value={porteo.Id_porteo}
                                    >
                                      {porteo.nombre}
                                    </option>
                                  );
                                }
                              )
                            ) : (
                              <option value="">No hay datos</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iAccionM">
                      Acción
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iAccionM"
                          name="iAccionM"
                          value={this.state.iAccionM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione una acción
                          </option>
                          {sessionStorage.getItem("rol") !==
                          "encargado_inventario" ? (
                            <option value="Entrada">Entrada</option>
                          ) : null}

                          <option value="EntradaRefacciones">
                            Entrada de refacciones de mantenimiento
                          </option>

                          {sessionStorage.getItem("rol") !==
                          "encargado_inventario" ? (
                            <option value="Salida">Salida</option>
                          ) : null}
                          <option value="SalidaRefacciones">
                            Salida de refacciones X mantenimiento
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iFechaHoraM">
                      Fecha y Hora
                    </label>
                    <input
                      id="iFechaHoraM"
                      name="iFechaHoraM"
                      value={this.state.iFechaHoraM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      type="datetime-local"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns">
                  <div className="column is-6">
                    <label className="label" htmlFor="iProductoM">
                      {this.state.iAccionM === "SalidaRefacciones"
                        ? "Refacción"
                        : "Producto"}
                    </label>
                    <div className="control">
                      <div className="select is-rounded is-success is-fullwidth">
                        <select
                          id="iProductoM"
                          name="iProductoM"
                          value={this.state.iProductoM}
                          onChange={this.handleInputChange.bind(this)}
                        >
                          <option value="" defaultValue>
                            Seleccione un producto
                          </option>
                          {this.state.productos != null ? (
                            Object.values(this.state.productos).map(
                              (producto) => {
                                return (
                                  <option
                                    key={producto.codigo_producto}
                                    value={producto.codigo_producto}
                                  >
                                    {producto.nombre_producto}
                                  </option>
                                );
                              }
                            )
                          ) : (
                            <option value="">No hay datos</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="column is-6">
                    <label className="label" htmlFor="iCantidadM">
                      Cantidad
                    </label>
                    <input
                      id="iCantidadM"
                      name="iCantidadM"
                      value={this.state.iCantidadM}
                      onChange={this.handleInputChange.bind(this)}
                      className="input is-rounded is-success"
                      min="1"
                      steps="1"
                      pattern="\d*"
                      type="number"
                      placeholder="Cantidad"
                    />
                  </div>
                </div>
              </div>

              {this.state.iAccionM === "Salida" ||
              this.state.iAccionM === "SalidaRefacciones" ||
              this.state.iAccionM === "EntradaRefacciones" ? (
                <div className="row">
                  <div className="columns">
                    <div className="column is-6">
                      <label className="label" htmlFor="iDestinatarioM">
                        {this.state.iAccionM === "SalidaRefacciones"
                          ? "Transporte"
                          : "Destinatario"}
                      </label>
                      <div className="control">
                        <div className="select is-rounded is-success is-fullwidth">
                          <select
                            id="iDestinatarioM"
                            name="iDestinatarioM"
                            value={this.state.iDestinatarioM}
                            onChange={this.handleInputChange.bind(this)}
                          >
                            <option value="" defaultValue>
                              Seleccione un destinatario
                            </option>
                            {this.state.destinatarios != null ? (
                              Object.values(this.state.destinatarios).map(
                                (destinatario) => {
                                  return (
                                    <option
                                      key={destinatario.Id_transporte}
                                      value={destinatario.placa}
                                    >
                                      {destinatario.placa}
                                    </option>
                                  );
                                }
                              )
                            ) : (
                              <option value="">No hay datos</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.iAccionM === "EntradaRefacciones" ? (
                <div className="row">
                  <div className="columns">
                    <div className="column is-6">
                      <label className="label" htmlFor="iDetallesM">
                        Detalles
                      </label>
                      <textarea
                        id="iDetallesM"
                        name="iDetallesM"
                        value={this.state.iDetallesM} // Establece el valor del textarea basado en el estado
                        onChange={this.handleInputChange.bind(this)} // Maneja el cambio en el textarea
                        rows={4} // Número de filas del textarea
                        cols={50} // Número de columnas del textarea
                        placeholder="Registra las razones del movimiento..."
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </form>
          </section>
          <footer className="modal-card-foot">
            <button
              onClick={this.handleSubmmit}
              className="button is-rounded is-success"
            >
              Guardar
            </button>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
