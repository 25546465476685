import React from "react";

// images
//import InstagramIcon from '../../images/instagram.png'
//import FacebookIcon from '../../images/facebook.png'

export class ContactoInfo extends React.Component {
  render() {
    return (
      <div className="is-gray-color">
        <div className="container row hero-body ">
          <div className="columns">
            <div className="column is-3">
              <p className="bd-footer-link-title ">
                <strong className="is-title-cyan">Servicio al cliente</strong>
              </p>
              <p className="bd-footer-link has-text-white">
                <span className="bd-footer-link-icon is-blue-color">
                  <i className="fa fa-envelope"></i>
                </span>
                <a
                  className="has-text-white"
                  href="mailto:paqueterialogistic@gmail.com"
                >
                  paqueterialogistic@gmail.com
                </a>
              </p>
              <p className="bd-footer-link has-text-white">
                <span className="bd-footer-link-icon is-blue-color">
                  <i className="fa fa-phone"></i>
                </span>
                <a className="has-text-white" href="tel:3414106511">
                  341-410-65-11
                </a>{" "}
                o{" "}
                <a className="has-text-white" href="tel:3414106458">
                  341-410-64-58
                </a>
              </p>
              <p className="bd-footer-link has-text-white">
                <span className="bd-footer-link-icon has-text-success">
                  <i className="fa fa-whatsapp"></i>
                </span>

                <a
                  target="_blank"
                  rel="noopener noreferrer" // Agregando rel="noopener noreferrer" siguiendo prácticas de seguridad para enlaces que abren en nuevas ventanas o pestañas del navegador.
                  className="has-text-white"
                  href="https://api.whatsapp.com/send?phone=523411511386&text=Atención%20al%20cliente"
                >
                  341-151-13-86
                </a>
              </p>
            </div>

            <div className="column is-3">
              <p className="bd-footer-link-title">
                <strong className="is-title-cyan">
                  Únete a nuestro equipo
                </strong>
              </p>
              <p className="bd-footer-link has-text-white">
                Manda tu CV al correo:
              </p>
              <p className="bd-footer-link has-text-white">
                <span className="bd-footer-link-icon is-blue-color">
                  <i className="fa fa-envelope"></i>
                </span>
                <a
                  className="has-text-white"
                  href="mailto:paqueterialogistic@gmail.com"
                >
                  paqueterialogistic@gmail.com
                </a>
              </p>
            </div>

            <div className="column is-4">
              <p className="bd-footer-link-title">
                <strong className="is-title-cyan">Seamos socios</strong>
              </p>
              <p className="bd-footer-link has-text-white">
                <span className="bd-footer-link-icon is-blue-color">
                  <i className="fa fa-map-marker"></i>
                </span>
                Cd. Guzmán Jal, México,
              </p>
              <p className="bd-footer-link has-text-white">
                Col. Molino viejo CP. 49078,
              </p>
              <p className="bd-footer-link has-text-white">
                Ignacio Comonfort #287
              </p>
              <p className="bd-footer-link has-text-white">
                <span className="bd-footer-link-icon is-blue-color">
                  <i className="fa fa-envelope"></i>
                </span>
                <a
                  className="has-text-white"
                  href="mailto:logistics_express@live.com.mx"
                >
                  logistics_express@live.com.mx
                </a>
              </p>
              <p className="bd-footer-link has-text-white">
                <span className="bd-footer-link-icon is-blue-color">
                  <i className="fa fa-phone"></i>
                </span>
                <a className="has-text-white" href="tel:3414106511">
                  341-410-65-11
                </a>{" "}
                o{" "}
                <a className="has-text-white" href="tel:3414106458">
                  341-410-64-58
                </a>
              </p>
            </div>
            <div className="column is-2">
              <p className="bd-footer-link-title">
                <strong className="is-title-cyan">¡Síguenos!</strong>
              </p>
              <a
                style={{ border: "none" }}
                href="https://www.facebook.com/LOGISTICEXPRESSDELSUR"
                className="button is-rounded is-circle is-white is-outlined"
              >
                <span className="icon is-small is-left ">
                  <i className="fa fa-facebook"></i>
                </span>
              </a>
              <a
                style={{ border: "none" }}
                href="https://www.instagram.com/logistic_express/"
                className="button is-rounded is-circle is-white is-outlined"
              >
                <span className="icon is-small is-left">
                  <i className="fa fa-instagram"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
