import React from 'react'

// Components
//import { Fortalezas } from '../components/homepage/Fortalezas'
import { Preguntas } from '../../components/homepage/Preguntas'
//import { Carousel } from '../components/homepage/Carousel'
import { Listas } from '../../components/homepage/Listas'
import { Frases } from '../../components/homepage/Frases'

//import EmpleadosImg from '../../images/Scroll/LOGISTIC.jpeg'
import FlechasImg from '../../images/flechas.png'
import { Carousel } from '../../components/homepage/Carousel'

export default class HomePage extends React.Component {
    render() {
        return (
            <div>
                <Carousel/>
                <div className="container hero-body" style={{ display: "flex", justifyContent: "space-between"}}>
                    <img src={FlechasImg} alt='Logistic del sur' width='100px' />
                    <img src={FlechasImg} alt='Logistic del sur' width='100px' />
                    <img src={FlechasImg} alt='Logistic del sur' width='100px' />
                </div>
                <Frases />
                <Listas />
                <Preguntas />
            </div>
        )
    }
}