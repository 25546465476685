import { editIcon, deleteIcon } from "../utilidades/TableIcons";
import $ from "jquery";
import {
  MODAL_DETALLES_PRODUCTO,
  MODAL_MODIFICAR_ARTICULO,
  MODAL_BORRAR_ARTICULO,
} from "./ConstantsInventario";
import { ROLES } from "../utilidades/Constantes";

let COLUMNS_INVENTARIO = [
  {
    title: "",
    headerSort: false,
    field: "img_producto",
    width: 100,
    formatter: function (cell, formatterParams) {
      return (
        "<img width='120' height='120' alt='image' src=" +
        cell.getValue() +
        " />"
      );
    },
    cellClick: function (e, cell) {
      $(`#${MODAL_DETALLES_PRODUCTO}`).toggleClass("is-active");
    },
  },
  { title: "CÓDIGO", field: "codigo_producto", width: 100 },
  { title: "ARTICULO", field: "nombre_producto" },
  {
    title: "DISPONIBILIIDAD",
    hozAlign: "center",
    width: 200,
    field: "cantidad_producto",
    formatter: function (cell, formatterParams) {
      let value = cell.getValue();
      if (parseInt(value) === 0) {
        return (
          "<span style='background-color: rgb(221, 191, 197); border-radius: 45px; color:black; display: inline-block; line-height: .25em; padding: .75em; width: 135px; text-align: center;'> " +
          value +
          " en existencia </span>"
        );
      } else if (parseInt(value) <= 50) {
        return (
          "<span style='background-color: rgb(203, 211, 135); border-radius: 45px; color:black; display: inline-block; line-height: .25em; padding: .75em; width: 135px; text-align: center;'> " +
          value +
          " en existencia </span>"
        );
      } else {
        return (
          "<span style='background-color: rgb(142, 192, 130); border-radius: 45px; color:black; display: inline-block; line-height: .25em; padding: .75em; width: 135px; text-align: center;'> " +
          value +
          " en existencia </span>"
        );
      }
    },
  },
  {
    title: "PRECIO UNITARIO",
    field: "precio_producto",
    width: 150,
    hozAlign: "center",
    formatter: "money",
    formatterParams: {
      decimal: ".",
      thousand: ",",
      symbol: "$",
      precision: 2,
    },
  },
  {
    title: "PORTEO",
    field: "nombre",
    width: 100,
  },
];

let editColumn = {
  formatter: editIcon,
  width: 40,
  headerSort: false,
  cellClick: function (e, cell) {
    $(`#${MODAL_MODIFICAR_ARTICULO}`).toggleClass("is-active");
  },
};
let deleteColumn = {
  formatter: deleteIcon,
  width: 40,
  headerSort: false,
  cellClick: function (e, cell) {
    $(`#${MODAL_BORRAR_ARTICULO}`).toggleClass("is-active");
    $("#deletedata").text(cell.getRow().getData().nombre);
  },
};

const editOption = [ROLES.ADMIN];
const deleteOption = [ROLES.ADMIN];

export const getColumns = (userRole) => {
  if (
    editOption.includes(userRole) &&
    !COLUMNS_INVENTARIO.includes(editColumn)
  ) {
    COLUMNS_INVENTARIO.push(editColumn);
  }
  if (
    deleteOption.includes(userRole) &&
    !COLUMNS_INVENTARIO.includes(deleteColumn)
  ) {
    COLUMNS_INVENTARIO.push(deleteColumn);
  }

  return COLUMNS_INVENTARIO;
};
