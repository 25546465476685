// react
import React from 'react';

// libreria react-pdf y react-pdf-table
import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import { Table, TableCell, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table/lib'
// fuente a utilizar
import ProductSans from './style/ProductSansRegular.ttf'
import ProductSansBold from './style/ProductSansBold.ttf'
// estilos
import { styles } from './style/Styles'
//LOGOTIPO
import logo from './../../images/LOGOLOGISTIC.png'

Font.register({
    family: 'ProductSans',
    fonts: [
        { src: ProductSans }, // font-style: normal, font-weight: normal
        { src: ProductSansBold, fontWeight: 'bold' },
    ]
});

export const TrabajadoresLicencias = ({ porteo, data }) => (
    <Document>
        <Page size="A4" style={styles.page} >
            {
                console.log(data)
            }
            <View style={styles.logoTitle}>
                <Image style={styles.image} src={logo} />
                <Text style={styles.titulo}>LOGISTIC EXPRESS DEL SUR</Text>
            </View>
            <View style={styles.info}>
                <Text style={styles.contacto}>Email: logistic_express@live.com.mx</Text>
                <Text style={styles.contacto}>Tel: (341) 410-6511 ó (341) 410-6458</Text>
            </View>
            <Text style={styles.subtitulo}>
                Licencias por vencer
                </Text>
            <View style={styles.sectionDetalles}>
                <View style={styles.unDetalle}>
                    <Text style={styles.detalles}>Porteo:</Text>
                    <Text style={styles.parametros}>{porteo}</Text>
                </View>
            </View>
            <View>
                <Table data={data} >
                    <TableHeader
                        textAlign={"center"}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        includeLeftBorder={false}
                        includeRightBorder={false} >
                        <TableCell style={styles.tablaHeader} weighting={0.1}>#</TableCell>
                        <TableCell style={styles.tablaHeader} >CÓDIGO</TableCell>
                        <TableCell style={styles.tablaHeader} >NOMBRE</TableCell>
                        <TableCell style={styles.tablaHeader} >FECHA VENCIMIENTO</TableCell>
                    </TableHeader>
                    <TableBody
                        textAlign={"center"}
                        includeTopBorder={false}
                        includeBottomBorder={false}
                        includeLeftBorder={false}
                        includeRightBorder={false}>
                        <DataTableCell style={styles.tabla} weighting={0.1} getContent={(r) => (data.indexOf(r) + 1).toLocaleString()} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.no_trabajador} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.nombre_trabajador} />
                        <DataTableCell style={styles.tabla} getContent={(r) => r.vencimiento_licencia.split('T')[0]} />
                    </TableBody>
                </Table>
            </View>
        </Page>
    </Document>
);