import React from "react";
import $ from "jquery";
import { MovimientoItem } from "./MovimientoItem";
import { MODAL_DETALLES_ENVIO } from "./ConstantsSeguimientoEntregas";

export class ModalDetallesEnvio extends React.Component {
  state = {
    indicador: "",
    data: [
      {
        id: 1,
        estatus: "en camino",
        fecha: "1/2/2021",
        hora: "9:55PM",
        detalles: "Entregar el dia sabado",
        dia: "Sabado",
      },
      {
        id: 2,
        estatus: "en camino",
        fecha: "1/2/2021",
        hora: "9:55PM",
        detalles: "Entregar el dia sabado",
        dia: "Viernes",
      },
      {
        id: 3,
        estatus: "en camino",
        fecha: "1/2/2021",
        hora: "9:55PM",
        detalles: "Entregar el dia sabado",
        dia: "Viernes",
      },
      {
        id: 4,
        estatus: "en camino",
        fecha: "1/2/2021",
        hora: "9:55PM",
        detalles: "Entregar el dia sabado",
        dia: "Viernes",
      },
      {
        id: 5,
        estatus: "en camino",
        fecha: "1/2/2021",
        hora: "9:55PM",
        detalles: "Entregar el dia sabado",
        dia: "Viernes",
      },
    ],
  };

  handleClick = (e) => {
    $(`#${MODAL_DETALLES_ENVIO}`).toggleClass("is-active");
  };

  componentDidMount() {
    this.setIndicador("miercoles");
    //this.getPorteo(sessionStorage.getItem('porteo'));
  }

  setIndicador = (dia) => {
    let indicador = "fa fa-circle indicador-" + dia;
    this.setState({ indicador: indicador });
  };

  render() {
    return (
      <div id={MODAL_DETALLES_ENVIO} className="modal">
        <div className="modal-background"></div>
        <div className="modal-card" style={{ width: "60%" }}>
          <header className="modal-card-head">
            <p className="modal-card-title">Detalles del envío</p>
            <button
              onClick={this.handleClick}
              title="Cerrar"
              className="delete"
              aria-label="close"
            ></button>
          </header>
          <section className="modal-card-body">
            <div>
              <div className="row">
                <div className="columns is-centered">
                  <div className="column is-3 is-text-centered">
                    <div className="has-text-success has-text-bold">
                      <span className=" is-inline-block-s is-padding-detalles">
                        Estatus:{" "}
                      </span>
                      <span>{this.props.item.estatus ?? "-"}</span>
                    </div>
                    <span>
                      {this.props.item.fecha
                        ? new Date(this.props.item.fecha).toLocaleDateString(
                            "es-mx"
                          )
                        : null}
                    </span>
                  </div>

                  <div className="column is-3 is-text-centered">
                    <span className="has-text-bold is-inline-block-s is-padding-detalles">
                      No. de rastreo:{" "}
                    </span>
                    <span>{this.props.item.Num_guia}</span>
                  </div>
                  <div className="column is-3 is-text-centered">
                    <span className="has-text-bold is-inline-block-s is-padding-detalles">
                      Visitas:{" "}
                    </span>
                    <span>-</span>
                  </div>
                  <div className="column is-3 is-text-centered">
                    <span className="has-text-bold is-inline-block-s is-padding-detalles">
                      Fecha recepción:{" "}
                    </span>
                    <span className="is-padding-detalles">
                      {this.props.item.fecha_recepcion_embarque
                        ? new Date(this.props.item.fecha_recepcion_embarque).toLocaleDateString(
                            "es-mx"
                          )
                        : null}
                    </span>
                    <span
                      className={
                        this.props.item.fecha
                          ? "fa fa-circle indicador-" +
                            new Date(this.props.item.fecha_recepcion_embarque).toLocaleDateString(
                              "es-mx",
                              { weekday: "long" }
                            )
                          : null
                      }
                    >
                      <i></i>
                    </span>
                  </div>
                </div>
                <hr />
              </div>

              <div className="row is-padding-movimientos">
                <div className="columns">
                  <div className="column is-4">
                    <label className="label is-success">
                      Todas las actualizaciones
                    </label>
                  </div>
                  <div className="column is-8">
                    <label className=" label icon is-large is-left">
                      <i className="fa fa-long-arrow-up"></i>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="columns is-centered is-padding-movimientos">
                  <div className="column is-12">
                    {this.props.data ? (
                      Object.values(this.props.data).map((p) => {
                        return (
                          <MovimientoItem
                            key={p.id}
                            dia={new Date(p.fecha).toLocaleDateString("es-mx", {
                              weekday: "long",
                            })}
                            fecha={new Date(p.fecha).toLocaleString("es-MX", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                            estatus={p.estatus.toUpperCase()}
                            detalles={p.notas}
                          />
                        );
                      })
                    ) : (
                      <div value="">No hay actualizaciones</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <span></span>
            <button
              onClick={this.handleClick}
              className="button is-rounded is-warning"
            >
              Cerrar
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
